<div *ngIf="!api.isSummary" class="wrap">
    <div class="loading">
        <div class="bounceball"></div>
        <div class="text">LOADING...</div>
    </div>
</div>
<div *ngIf="api.onSend" class="text-center">
    <img src={{api.loadingGif}} class="rounded img-fluid" alt="...">
</div>

<div *ngIf="!api.onSend">
    <div *ngIf="!api.isdemo">
        <div *ngIf="api.isSummaryTitle">
            <div class="flex-container">
                <div class="price-box" [ngStyle]="{'font-size':api.isdemo ? '13px' : '17px'}"
                    style="font-weight: bold;padding-bottom: 2px;">QTY</div>
                <div class="price-box" [ngStyle]="{'font-size':api.isdemo ? '13px' : '17px'}"
                    style="flex-grow: 6;padding-bottom: 2px; text-align: center; font-weight: bold"> ITEMS </div>
                <div class="price-box" [ngStyle]="{'font-size':api.isdemo ? '13px' : '17px'}"
                    style="font-weight: bold;padding-bottom: 2px;">PRICE</div>
            </div>
            <hr style=" margin-top: 5px; margin-bottom: 5px;">
        </div>
    </div>
    <div *ngIf="api.isdemo">
        <h6 class="demo-title">Order Summary</h6>
        <hr style=" margin-top: 5px; margin-bottom: 5px;">
    </div>


    <div *ngFor="let item of api.items; index as i">
        <div class="flex-container">
            <div class="qty-box" [ngStyle]="{'font-size':api.isdemo ? '14px' : '18px'}" id="qty-box-{{i}}">
                {{api.qty[i]}}</div>
            <div class="title-box" [ngStyle]="{'font-size':api.isdemo ? '14px' : '18px'}" id="title-box-{{i}}"
                style="flex-grow: 6">{{api.sizes[i].replace('-1', '')}} {{api.cursts[i].replace('-1', '')}}
                {{api.itemNames[i]}}
                <!-- <i   *ngIf = "api.isEditBtn" id = "edit-box-{{i}}" style = "color: #31afd5" (click) = 
        "onEdit(i)" class="material-icons">
            edit
            </i>  --><span *ngIf="!api.isPaid"> <span *ngIf="api.isDeleteBtn" id="delete-box-{{i}}"
                        style="color: #31afd5; font-weight: 400; text-transform:lowercase; padding: 0px 4px;"
                        [ngStyle]="{'color':api.primaryColor}" (click)="onEdit(i)"> <span><i>Edit</i></span> </span>
                    <span *ngIf="api.isDeleteBtn" style="padding: 0px 7px; font-weight: bold;">|</span>
                    <span *ngIf="api.isDeleteBtn" id="delete-box-{{i}}"
                        style="color: red; font-weight: 400; text-transform:lowercase; padding: 0px 4px; "
                        (click)="removeItem(i)"> <span><i>remove</i></span> </span></span></div>

            <!-- <div *ngIf = "api.isDeleteBtn" class = "price-box" id = "delete-box-{{i}}"  style = "color: gray" (click) = "removeItem(i)"> <i class="material-icons">
        delete
        </i> </div> -->
            <div class="price-box" [ngStyle]="{'font-size':api.isdemo ? '13px' : '16px'}" id="price-box-{{i}}">
                <span *ngIf = "api.isRewardsArr[i]">$0.00</span>  <span *ngIf = "!api.isRewardsArr[i]">{{api.amounts[i]}} </span></div>
        </div>
        <div class="flex-container" [hidden]="api.sauce[i] === '-1'">
            <div class="qty-box"></div>
            <div class="half-box" [ngStyle]="{'font-size':api.isdemo ? '13px' : '16px'}" id="sauce-box-{{i}}"
                style="flex-grow:4"> <b>Sauce:</b> {{api.sauce[i]}} </div>
            <div class="price-box"> </div>
        </div>
        <div class="flex-container" [hidden]="api.bun[i] === '-1'">
            <div class="qty-box"></div>
            <div class="half-box" [ngStyle]="{'font-size':api.isdemo ? '13px' : '16px'}" id="bun-box-{{i}}"
                style="flex-grow:4"> <b>Bun:</b> {{api.bun[i]}} </div>
            <div class="price-box"> </div>
        </div>
        
        <div class="flex-container" [hidden]="api.piece[i] === '-1'">
            <div class="qty-box"></div>
            <div class="half-box" [ngStyle]="{'font-size':api.isdemo ? '13px' : '16px'}" id="topping-box-{{i}}"
                style="flex-grow:4"><b>Pieces:</b> {{api.piece[i]}}</div>
            <div class="price-box"> </div>
        </div>
        
        <div class="flex-container" [hidden]="api.toppings[i] === '-1'">
            <div class="qty-box"></div>
            <div class="half-box" [ngStyle]="{'font-size':api.isdemo ? '13px' : '16px'}" id="topping-box-{{i}}"
                style="flex-grow:4"><b>Toppings:</b> {{api.toppings[i]}}</div>
            <div class="price-box"> </div>
        </div>
        <div class="flex-container" [hidden]="api.firstHalf[i] === '-1'">
            <div class="qty-box"></div>
            <div class="half-box" [ngStyle]="{'font-size':api.isdemo ? '13px' : '16px'}" id="first-half-box-{{i}}"
                style="flex-grow:4"> <b>First Half:</b> {{api.firstHalf[i]}} </div>
            <div class="price-box"> </div>
        </div>
        <div class="flex-container" [hidden]="api.secondHalf[i] === '-1'">
            <div class="qty-box"></div>
            <div class="half-box" [ngStyle]="{'font-size':api.isdemo ? '13px' : '16px'}" id="sec-half-box-{{i}}"
                style="flex-grow:4"> <b>Second Half:</b> {{api.secondHalf[i]}} </div>
            <div class="price-box"> </div>
        </div>
        <div class="flex-container" [hidden]="api.seasoning[i] === '-1'">
            <div class="qty-box"></div>
            <div class="half-box" [ngStyle]="{'font-size':api.isdemo ? '13px' : '16px'}" id="seasoning-box-{{i}}"
                style="flex-grow:4"> <b> Seasoning:</b> {{api.seasoning[i]}} </div>
            <div class="price-box"> </div>
        </div>

        <div class="flex-container" [hidden]="api.bread[i] === '-1'">
            <div class="qty-box"></div>
            <div class="half-box" [ngStyle]="{'font-size':api.isdemo ? '13px' : '16px'}" id="bread-box-{{i}}"
                style="flex-grow:4"><b> Bread:</b> {{api.bread[i]}} </div>
            <div class="price-box"> </div>
        </div>
        <div class="flex-container" [hidden]="api.drizzle[i] === '-1'">
            <div class="qty-box"></div>
            <div class="half-box" [ngStyle]="{'font-size':api.isdemo ? '13px' : '16px'}" id="drizzle-box-{{i}}"
                style="flex-grow:4"> <b>Drizzle:</b> {{api.drizzle[i]}} </div>
            <div class="price-box"> </div>
        </div>
        <div class="flex-container" [hidden]="api.fry[i] === '-1'">
            <div class="qty-box"></div>
            <div class="half-box" [ngStyle]="{'font-size':api.isdemo ? '13px' : '16px'}" id="fry-box-{{i}}"
                style="flex-grow:4"> <b>Fry:</b> {{api.fry[i]}} </div>
            <div class="price-box"> </div>
        </div>
        <div class="flex-container" [hidden]="api.cheese[i] === '-1'">
            <div class="qty-box"></div>
            <div class="half-box" [ngStyle]="{'font-size':api.isdemo ? '13px' : '16px'}" id="cheese-box-{{i}}"
                style="flex-grow:4"> <b>Cheese:</b> {{api.cheese[i]}} </div>
            <div class="price-box"> </div>
        </div>

  <div class="flex-container" [hidden]="api.tenderPrep[i] === '-1'">
    <div class="qty-box"></div>
    <div class="half-box" [ngStyle]="{'font-size':api.isdemo ? '13px' : '16px'}" id="tenderPrep-box-{{i}}"
        style="flex-grow:4"> <b>Tender Prep:</b> {{api.tenderPrep[i]}} </div>
    <div class="price-box"> </div>
</div>
<div class="flex-container" [hidden]="api.syrupChoice[i] === '-1'">
    <div class="qty-box"></div>
    <div class="half-box" [ngStyle]="{'font-size':api.isdemo ? '13px' : '16px'}" id="syrupChoice-box-{{i}}"
        style="flex-grow:4"> <b>Syrup Choice:</b> {{api.syrupChoice[i]}} </div>
    <div class="price-box"> </div>
</div>
<div class="flex-container" [hidden]="api.silverware[i] === '-1'">
    <div class="qty-box"></div>
    <div class="half-box" [ngStyle]="{'font-size':api.isdemo ? '13px' : '16px'}" id="silverware-box-{{i}}"
        style="flex-grow:4"> <b>Silverware:</b> {{api.silverware[i]}} </div>
    <div class="price-box"> </div>
</div>
<div class="flex-container" [hidden]="api.pieSlice[i] === '-1'">
    <div class="qty-box"></div>
    <div class="half-box" [ngStyle]="{'font-size':api.isdemo ? '13px' : '16px'}" id="pieSlice-box-{{i}}"
        style="flex-grow:4"> <b>Pie Slice:</b> {{api.pieSlice[i]}} </div>
    <div class="price-box"> </div>
</div>
<div class="flex-container" [hidden]="api.remove[i] === '-1'">
    <div class="qty-box"></div>
    <div class="half-box" [ngStyle]="{'font-size':api.isdemo ? '13px' : '16px'}" id="remove-box-{{i}}"
        style="flex-grow:4"> <b>Remove:</b> {{api.remove[i]}} </div>
    <div class="price-box"> </div>
</div>
<div class="flex-container" [hidden]="api.proteinChoice[i] === '-1'">
    <div class="qty-box"></div>
    <div class="half-box" [ngStyle]="{'font-size':api.isdemo ? '13px' : '16px'}" id="proteinChoice-box-{{i}}"
        style="flex-grow:4"> <b>Protein Choice:</b> {{api.proteinChoice[i]}} </div>
    <div class="price-box"> </div>
</div>
<div class="flex-container" [hidden]="api.meatChoice[i] === '-1'">
    <div class="qty-box"></div>
    <div class="half-box" [ngStyle]="{'font-size':api.isdemo ? '13px' : '16px'}" id="meatChoice-box-{{i}}"
        style="flex-grow:4"> <b>Meat Choice:</b> {{api.meatChoice[i]}} </div>
    <div class="price-box"> </div>
</div>
<div class="flex-container" [hidden]="api.iceCreamFlavor[i] === '-1'">
    <div class="qty-box"></div>
    <div class="half-box" [ngStyle]="{'font-size':api.isdemo ? '13px' : '16px'}" id="iceCreamFlavor-box-{{i}}"
        style="flex-grow:4"> <b>Ice Cream Flavor:</b> {{api.iceCreamFlavor[i]}} </div>
    <div class="price-box"> </div>
</div>
<div class="flex-container" [hidden]="api.fixins[i] === '-1'">
    <div class="qty-box"></div>
    <div class="half-box" [ngStyle]="{'font-size':api.isdemo ? '13px' : '16px'}" id="fixins-box-{{i}}"
        style="flex-grow:4"> <b>Fixins:</b> {{api.fixins[i]}} </div>
    <div class="price-box"> </div>
</div>
<div class="flex-container" [hidden]="api.eggPrep[i] === '-1'">
    <div class="qty-box"></div>
    <div class="half-box" [ngStyle]="{'font-size':api.isdemo ? '13px' : '16px'}" id="eggPrep-box-{{i}}"
        style="flex-grow:4"> <b>Egg Prep:</b> {{api.eggPrep[i]}} </div>
    <div class="price-box"> </div>
</div>
<div class="flex-container" [hidden]="api.dippingSauce[i] === '-1'">
    <div class="qty-box"></div>
    <div class="half-box" [ngStyle]="{'font-size':api.isdemo ? '13px' : '16px'}" id="dippingSauce-box-{{i}}"
        style="flex-grow:4"> <b>Dipping Sauce:</b> {{api.dippingSauce[i]}} </div>
    <div class="price-box"> </div>
</div>
<div class="flex-container" [hidden]="api.dairy[i] === '-1'">
    <div class="qty-box"></div>
    <div class="half-box" [ngStyle]="{'font-size':api.isdemo ? '13px' : '16px'}" id="dairy-box-{{i}}"
        style="flex-grow:4"> <b>Dairy:</b> {{api.dairy[i]}} </div>
    <div class="price-box"> </div>
</div>
<div class="flex-container" [hidden]="api.prep[i] === '-1'">
    <div class="qty-box"></div>
    <div class="half-box" [ngStyle]="{'font-size':api.isdemo ? '13px' : '16px'}" id="prep-box-{{i}}"
        style="flex-grow:4"> <b>Prep:</b> {{api.prep[i]}} </div>
    <div class="price-box"> </div>
</div>
<div class="flex-container" [hidden]="api.sauceType[i] === '-1'">
    <div class="qty-box"></div>
    <div class="half-box" [ngStyle]="{'font-size':api.isdemo ? '13px' : '16px'}" id="sauceType-box-{{i}}"
        style="flex-grow:4"> <b>Sauce Type:</b> {{api.sauceType[i]}} </div>
    <div class="price-box"> </div>
</div>
<div class="flex-container" [hidden]="api.chips[i] === '-1'">
    <div class="qty-box"></div>
    <div class="half-box" [ngStyle]="{'font-size':api.isdemo ? '13px' : '16px'}" id="chips-box-{{i}}"
        style="flex-grow:4"> <b>Chips:</b> {{api.chips[i]}} </div>
    <div class="price-box"> </div>
</div>
<div class="flex-container" [hidden]="api.pasta[i] === '-1'">
    <div class="qty-box"></div>
    <div class="half-box" [ngStyle]="{'font-size':api.isdemo ? '13px' : '16px'}" id="pasta-box-{{i}}"
        style="flex-grow:4"> <b>Pasta:</b> {{api.pasta[i]}} </div>
    <div class="price-box"> </div>
</div>
<div class="flex-container" [hidden]="api.condiments[i] === '-1'">
    <div class="qty-box"></div>
    <div class="half-box" [ngStyle]="{'font-size':api.isdemo ? '13px' : '16px'}" id="condiments-box-{{i}}"
        style="flex-grow:4"> <b>Condiments:</b> {{api.condiments[i]}} </div>
    <div class="price-box"> </div>
</div>
<div class="flex-container" [hidden]="api.buttersAndJams[i] === '-1'">
    <div class="qty-box"></div>
    <div class="half-box" [ngStyle]="{'font-size':api.isdemo ? '13px' : '16px'}" id="buttersAndJams-box-{{i}}"
        style="flex-grow:4"> <b>Butters And Jams:</b> {{api.buttersAndJams[i]}} </div>
    <div class="price-box"> </div>
</div>
<div class="flex-container" [hidden]="api.breadChoice[i] === '-1'">
    <div class="qty-box"></div>
    <div class="half-box" [ngStyle]="{'font-size':api.isdemo ? '13px' : '16px'}" id="breadChoice-box-{{i}}"
        style="flex-grow:4"> <b>Bread Choice:</b> {{api.breadChoice[i]}} </div>
    <div class="price-box"> </div>
</div>
<div class="flex-container" [hidden]="api.addOns[i] === '-1'">
    <div class="qty-box"></div>
    <div class="half-box" [ngStyle]="{'font-size':api.isdemo ? '13px' : '16px'}" id="addOns-box-{{i}}"
        style="flex-grow:4"> <b>Add Ons:</b> {{api.addOns[i]}} </div>
    <div class="price-box"> </div>
</div>
<div class="flex-container" [hidden]="api.addCheese[i] === '-1'">
    <div class="qty-box"></div>
    <div class="half-box" [ngStyle]="{'font-size':api.isdemo ? '13px' : '16px'}" id="addCheese-box-{{i}}"
        style="flex-grow:4"> <b>Add Cheese:</b> {{api.addCheese[i]}} </div>
    <div class="price-box"> </div>
</div>
<div class="flex-container" [hidden]="api.threeSelections[i] === '-1'">
    <div class="qty-box"></div>
    <div class="half-box" [ngStyle]="{'font-size':api.isdemo ? '13px' : '16px'}" id="threeSelections-box-{{i}}"
        style="flex-grow:4"> <b>3 Selections:</b> {{api.threeSelections[i]}} </div>
    <div class="price-box"> </div>
</div>
<div class="flex-container" [hidden]="api.twoSides[i] === '-1'">
    <div class="qty-box"></div>
    <div class="half-box" [ngStyle]="{'font-size':api.isdemo ? '13px' : '16px'}" id="twoSides-box-{{i}}"
        style="flex-grow:4"> <b>Two Sides:</b> {{api.twoSides[i]}} </div>
    <div class="price-box"> </div>
</div>

        <div class="flex-container" [hidden]="api.onSide[i] === '-1'">
            <div class="qty-box"></div>
            <div class="half-box" [ngStyle]="{'font-size':api.isdemo ? '13px' : '16px'}" id="onSide-box-{{i}}"
                style="flex-grow:4"> <b>On Side:</b> {{api.onSide[i]}} </div>
            <div class="price-box"> </div>
        </div>
        <div class="flex-container" [hidden]="api.crustTopper[i] === '-1'">
            <div class="qty-box"></div>
            <div class="half-box" [ngStyle]="{'font-size':api.isdemo ? '13px' : '16px'}" id="crustTopper-box-{{i}}"
                style="flex-grow:4"> <b>Crust Topper:</b> {{api.crustTopper[i]}} </div>
            <div class="price-box"> </div>
        </div>

        <div class="flex-container" [hidden]="api.breadtype[i] === '-1'">
            <div class="qty-box"></div>
            <div class="half-box" [ngStyle]="{'font-size':api.isdemo ? '13px' : '16px'}" id="breadtype-box-{{i}}"
                style="flex-grow:4"> <b>Bread Type:</b> {{api.breadtype[i]}} </div>
            <div class="price-box"> </div>
        </div>

        <div class="flex-container" [hidden]="api.beverageChoice[i] === '-1'">
            <div class="qty-box"></div>
            <div class="half-box" [ngStyle]="{'font-size':api.isdemo ? '13px' : '16px'}" id="beverageChoice-box-{{i}}"
                style="flex-grow:4"> <b>Beverage Choice:</b> {{api.beverageChoice[i]}} </div>
            <div class="price-box"> </div>
        </div>

        <div class="flex-container" [hidden]="api.reciepe[i] === '-1'">
            <div class="qty-box"></div>
            <div class="half-box" [ngStyle]="{'font-size':api.isdemo ? '13px' : '16px'}" id="reciepe-box-{{i}}"
                style="flex-grow:4"> <b>Recipe:</b> {{api.reciepe[i]}} </div>
            <div class="price-box"> </div>
        </div>


        

        
        <div class="flex-container" [hidden]="api.wingType[i] === '-1'">
            <div class="qty-box"></div>
            <div class="half-box" [ngStyle]="{'font-size':api.isdemo ? '13px' : '16px'}" id="wing-box-{{i}}"
                style="flex-grow:4"><b>Wing Type:</b> {{api.wingType[i]}} </div>
            <div class="price-box"> </div>
        </div>
        <div class="flex-container" [hidden]="api.sideBread[i] === '-1'">
            <div class="qty-box"></div>
            <div class="half-box" [ngStyle]="{'font-size':api.isdemo ? '13px' : '16px'}" id="sideBread-box-{{i}}"
                style="flex-grow:4"><b>Side Bread:</b> {{api.sideBread[i]}} </div>
            <div class="price-box"> </div>
        </div>


        

        <div class="flex-container" [hidden]="api.defaults[i] === '-1'">
            <div class="qty-box"></div>
            <div class="half-box" [ngStyle]="{'font-size':api.isdemo ? '13px' : '16px'}" id="defaults-box-{{i}}"
                style="flex-grow:4"><b>Defaults:</b> {{api.defaults[i]}} </div>
            <div class="price-box"> </div>
        </div>

        <div class="flex-container" [hidden]="api.addies[i] === '-1'">
            <div class="qty-box"></div>
            <div class="half-box" [ngStyle]="{'font-size':api.isdemo ? '13px' : '16px'}" id="addies-box-{{i}}"
                style="flex-grow:4"> <b> Customizations:</b> {{api.addies[i]}} </div>
            <div class="price-box"> </div>
        </div>

      <!--   <div class="flex-container" [hidden]="api.allergies[i] === '-1'">
            <div class="qty-box"></div>
            <div class="half-box" [ngStyle]="{'font-size':api.isdemo ? '13px' : '16px'}" id="allergies-box-{{i}}"
                style="flex-grow:4"><b>Allergies:</b> {{api.allergies[i]}} </div>
            <div class="price-box"> </div>
        </div> -->

        <div class="flex-container" [hidden]="api.sides[i] === '-1'">
            <div class="qty-box"></div>
            <div class="half-box" [ngStyle]="{'font-size':api.isdemo ? '13px' : '16px'}" id="side-box-{{i}}"
                style="flex-grow:4"> <b>Sides:</b> {{api.sides[i]}} </div>
            <div class="price-box"> </div>
        </div>
        <div class="flex-container" [hidden]="api.flavor[i] === '-1'">
            <div class="qty-box"></div>
            <div class="half-box" [ngStyle]="{'font-size':api.isdemo ? '13px' : '16px'}" id="flavor-box-{{i}}"
                style="flex-grow:4"> <b>Flavor:</b> {{api.flavor[i]}} </div>
            <div class="price-box"> </div>
        </div>

        
        <div class="flex-container" [hidden]="api.bake[i] === '-1'">
            <div class="qty-box"></div>
            <div class="half-box" [ngStyle]="{'font-size':api.isdemo ? '13px' : '16px'}" id="bake-box-{{i}}"
                style="flex-grow:4"> <b>Bake:</b> {{api.bake[i]}} </div>
            <div class="price-box"> </div>
        </div>
        <div class="flex-container" [hidden]="api.cut[i] === '-1'">
            <div class="qty-box"></div>
            <div class="half-box" [ngStyle]="{'font-size':api.isdemo ? '13px' : '16px'}" id="cut-box-{{i}}"
                style="flex-grow:4"> <b> Cut:</b> {{api.cut[i]}} </div>
            <div class="price-box"> </div>
        </div>

       
        
        <div class="flex-container" [hidden]="api.dipping[i] === '-1'">
            <div class="qty-box"></div>
            <div class="half-box" [ngStyle]="{'font-size':api.isdemo ? '13px' : '16px'}" id="dipping-box-{{i}}"
                style="flex-grow:4"> <b>Dipping:</b> {{api.dipping[i]}} </div>
            <div class="price-box"> </div>
        </div>



        <div class="flex-container" [hidden]="api.gravy[i] === '-1'">
            <div class="qty-box"></div>
            <div class="half-box" [ngStyle]="{'font-size':api.isdemo ? '13px' : '16px'}" id="gravy-box-{{i}}"
                style="flex-grow:4"> <b>Gravy:</b> {{api.gravy[i]}} </div>
            <div class="price-box"> </div>
        </div>

        <div class="flex-container" [hidden]="api.tenderChoice[i] === '-1'">
            <div class="qty-box"></div>
            <div class="half-box" [ngStyle]="{'font-size':api.isdemo ? '13px' : '16px'}" id="tenderChoice-box-{{i}}"
                style="flex-grow:4"> <b>Tender Choice:</b> {{api.tenderChoice[i]}} </div>
            <div class="price-box"> </div>
        </div>
        <div class="flex-container" [hidden]="api.enchilada[i] === '-1'">
            <div class="qty-box"></div>
            <div class="half-box" [ngStyle]="{'font-size':api.isdemo ? '13px' : '16px'}" id="enchilada-box-{{i}}"
                style="flex-grow:4"> <b>Enchilada:</b> {{api.enchilada[i]}} </div>
            <div class="price-box"> </div>
        </div>
        <div class="flex-container" [hidden]="api.guacamole[i] === '-1'">
            <div class="qty-box"></div>
            <div class="half-box" [ngStyle]="{'font-size':api.isdemo ? '13px' : '16px'}" id="guacamole-box-{{i}}"
                style="flex-grow:4"> <b>Guacamole:</b> {{api.guacamole[i]}} </div>
            <div class="price-box"> </div>
        </div>
        

        <div class="flex-container" [hidden]="api.quesadilla[i] === '-1'">
            <div class="qty-box"></div>
            <div class="half-box" [ngStyle]="{'font-size':api.isdemo ? '13px' : '16px'}" id="quesadilla-box-{{i}}"
                style="flex-grow:4"> <b>Quesadilla:</b> {{api.quesadilla[i]}} </div>
            <div class="price-box"> </div>
        </div>
        <div class="flex-container" [hidden]="api.soup[i] === '-1'">
            <div class="qty-box"></div>
            <div class="half-box" [ngStyle]="{'font-size':api.isdemo ? '13px' : '16px'}" id="soup-box-{{i}}"
                style="flex-grow:4"> <b>Soup:</b> {{api.soup[i]}} </div>
            <div class="price-box"> </div>
        </div>

        <div class="flex-container" [hidden]="api.burrito[i] === '-1'">
            <div class="qty-box"></div>
            <div class="half-box" [ngStyle]="{'font-size':api.isdemo ? '13px' : '16px'}" id="burrito-box-{{i}}"
                style="flex-grow:4"> <b>Burrito:</b> {{api.burrito[i]}} </div>
            <div class="price-box"> </div>
        </div>












        <div class="flex-container" [hidden]="api.dip[i] === '-1'">
            <div class="qty-box"></div>
            <div class="half-box" [ngStyle]="{'font-size':api.isdemo ? '13px' : '16px'}" id="dip-box-{{i}}"
                style="flex-grow:4"> <b>Dip:</b> {{api.dip[i]}} </div>
            <div class="price-box"> </div>
        </div>
        <div class="flex-container" [hidden]="api.style[i] === '-1'">
            <div class="qty-box"></div>
            <div class="half-box" [ngStyle]="{'font-size':api.isdemo ? '13px' : '16px'}" id="style-box-{{i}}"
                style="flex-grow:4"> <b>Style:</b> {{api.style[i]}} </div>
            <div class="price-box"> </div>
        </div>



        <div class="flex-container" [hidden]="api.wingDip[i] === '-1'">
            <div class="qty-box"></div>
            <div class="half-box" [ngStyle]="{'font-size':api.isdemo ? '13px' : '16px'}" id="wingDip-box-{{i}}"
                style="flex-grow:4"> <b>Wing Dip:</b> {{api.wingDip[i]}} </div>
            <div class="price-box"> </div>
        </div>
        <div class="flex-container" [hidden]="api.sauceBase[i] === '-1'">
            <div class="qty-box"></div>
            <div class="half-box" [ngStyle]="{'font-size':api.isdemo ? '13px' : '16px'}" id="sauce-base-box-{{i}}"
                style="flex-grow:4"> <b>Sauce Base:</b> {{api.sauceBase[i]}} </div>
            <div class="price-box"> </div>
        </div>
        <div class="flex-container" [hidden]="api.extra[i] === '-1'">
            <div class="qty-box"></div>
            <div class="half-box" [ngStyle]="{'font-size':api.isdemo ? '13px' : '16px'}" id="extra-box-{{i}}"
                style="flex-grow:4"> <b>Extras:</b> {{api.extra[i]}} </div>
            <div class="price-box"> </div>
        </div>

        <div class="flex-container" [hidden]="api.milk[i] === '-1'">
            <div class="qty-box"></div>
            <div class="half-box" [ngStyle]="{'font-size':api.isdemo ? '13px' : '16px'}" id="milk-box-{{i}}"
                style="flex-grow:4"> <b>Milk:</b> {{api.milk[i]}} </div>
            <div class="price-box"> </div>
        </div>
        <div class="flex-container" [hidden]="api.required[i] === '-1'">
            <div class = "qty-box"></div>
            <div class = "half-box" [ngStyle]="{'font-size':api.isdemo ? '13px' : '16px'}" id = "required-box-{{i}}" style="flex-grow:4"> <b>Required:</b> {{api.required[i]}} </div>
            <div class = "price-box"> </div>
          </div>
        <div class="flex-container" [hidden]="api.temp[i] === '-1'">
            <div class="qty-box"></div>
            <div class="half-box" [ngStyle]="{'font-size':api.isdemo ? '13px' : '16px'}" id="temp-box-{{i}}"
                style="flex-grow:4"> <b>Temp:</b> {{api.temp[i]}} </div>
            <div class="price-box"> </div>
        </div>

        <div class="flex-container" [hidden]="api.options[i] === '-1'">
            <div class="qty-box"></div>
            <div class="half-box" [ngStyle]="{'font-size':api.isdemo ? '13px' : '16px'}" id="options-box-{{i}}"
                style="flex-grow:4"> <b>Options:</b> {{api.options[i]}} </div>
            <div class="price-box"> </div>
        </div>
        <div class="flex-container" [hidden]="api.caf[i] === '-1'">
            <div class="qty-box"></div>
            <div class="half-box" [ngStyle]="{'font-size':api.isdemo ? '13px' : '16px'}" id="caf-box-{{i}}"
                style="flex-grow:4"> <b>Caf:</b> {{api.caf[i]}} </div>
            <div class="price-box"> </div>
        </div>

        <div class="flex-container" [hidden]="api.seasoningPackets[i] === '-1'">
            <div class="qty-box"></div>
            <div class="half-box" [ngStyle]="{'font-size':api.isdemo ? '13px' : '16px'}" id="seasoning-box-{{i}}"
                style="flex-grow:4"> <b>Seasoning Packets:</b> {{api.seasoningPackets[i]}} </div>
            <div class="price-box"> </div>
        </div>
        <div class="flex-container" [hidden]="api.dressing[i] === '-1'">
            <div class="qty-box"></div>
            <div class="half-box" [ngStyle]="{'font-size':api.isdemo ? '13px' : '16px'}" id="dressing-box-{{i}}"
                style="flex-grow:4"> <b>Dressing:</b> {{api.dressing[i]}} </div>
            <div class="price-box"> </div>
        </div>

        <div class="flex-container" [hidden]="api.notes[i] === '-1'">
            <div class="qty-box"></div>
            <div class="half-box" [ngStyle]="{'font-size':api.isdemo ? '13px' : '16px'}" id="notes-box-{{i}}"
                style="flex-grow:4"> <b>Notes:</b> {{api.notes[i]}} </div>
            <div class="price-box"> </div>
        </div>
        <div class="flex-container" [hidden]="api.meatTopping[i] === '-1'">
            <div class="qty-box"></div>
            <div class="half-box" [ngStyle]="{'font-size':api.isdemo ? '13px' : '16px'}" id="meatTopping-box-{{i}}"
                style="flex-grow:4"> <b>Meat Topping:</b> {{api.meatTopping[i]}} </div>
            <div class="price-box"> </div>
        </div>
        <div class="flex-container" [hidden]="api.crustFlip[i] === '-1'">
            <div class="qty-box"></div>
            <div class="half-box" [ngStyle]="{'font-size':api.isdemo ? '13px' : '16px'}" id="crustFlip-box-{{i}}"
                style="flex-grow:4"> <b>Crust Flip:</b> {{api.crustFlip[i]}} </div>
            <div class="price-box"> </div>
        </div>
        <div class="flex-container" [hidden]="api.veggieTopping[i] === '-1'">
            <div class="qty-box"></div>
            <div class="half-box" [ngStyle]="{'font-size':api.isdemo ? '13px' : '16px'}" id="veggieTopping-box-{{i}}"
                style="flex-grow:4"> <b>Veggie Topping:</b> {{api.veggieTopping[i]}} </div>
            <div class="price-box"> </div>
        </div>
        <div class="flex-container" [hidden]="api.shell[i] === '-1'">
            <div class="qty-box"></div>
            <div class="half-box" [ngStyle]="{'font-size':api.isdemo ? '13px' : '16px'}" id="shell-box-{{i}}"
                style="flex-grow:4"> <b>Shell:</b> {{api.shell[i]}} </div>
            <div class="price-box"> </div>
        </div>
        <div class="flex-container" [hidden]="api.tortilla[i] === '-1'">
            <div class="qty-box"></div>
            <div class="half-box" [ngStyle]="{'font-size':api.isdemo ? '13px' : '16px'}" id="tortilla-box-{{i}}"
                style="flex-grow:4"> <b>Tortilla:</b> {{api.tortilla[i]}} </div>
            <div class="price-box"> </div>
        </div>
        <div class="flex-container" [hidden]="api.rice[i] === '-1'">
            <div class="qty-box"></div>
            <div class="half-box" [ngStyle]="{'font-size':api.isdemo ? '13px' : '16px'}" id="rice-box-{{i}}"
                style="flex-grow:4"> <b>Rice:</b> {{api.rice[i]}} </div>
            <div class="price-box"> </div>
        </div>
        <div class="flex-container" [hidden]="api.beans[i] === '-1'">
            <div class="qty-box"></div>
            <div class="half-box" [ngStyle]="{'font-size':api.isdemo ? '13px' : '16px'}" id="beans-box-{{i}}"
                style="flex-grow:4"> <b>Beans:</b> {{api.beans[i]}} </div>
            <div class="price-box"> </div>
        </div>
        <div class="flex-container" [hidden]="api.protein[i] === '-1'">
            <div class="qty-box"></div>
            <div class="half-box" [ngStyle]="{'font-size':api.isdemo ? '13px' : '16px'}" id="protein-box-{{i}}"
                style="flex-grow:4"> <b>Protein:</b> {{api.protein[i]}} </div>
            <div class="price-box"> </div>
        </div>

        

        <div class="flex-container" [hidden]="api.tacoTortilla[i] === '-1'">
            <div class="qty-box"></div>
            <div class="half-box" [ngStyle]="{'font-size':api.isdemo ? '13px' : '16px'}" id="tacoTortilla-box-{{i}}"
                style="flex-grow:4"> <b>Taco Tortilla:</b> {{api.tacoTortilla[i]}} </div>
            <div class="price-box"> </div>
        </div>
        <div class="flex-container" [hidden]="api.lettuce[i] === '-1'">
            <div class="qty-box"></div>
            <div class="half-box" [ngStyle]="{'font-size':api.isdemo ? '13px' : '16px'}" id="lettuce-box-{{i}}"
                style="flex-grow:4"> <b>Lettuce:</b> {{api.lettuce[i]}} </div>
            <div class="price-box"> </div>
        </div>
        <div class="flex-container" [hidden]="api.yours[i] === '-1'">
            <div class="qty-box"></div>
            <div class="half-box" [ngStyle]="{'font-size':api.isdemo ? '13px' : '16px'}" id="yours-box-{{i}}"
                style="flex-grow:4"> <b>Yours:</b> {{api.yours[i]}} </div>
            <div class="price-box"> </div>
        </div>



        <div class="flex-container" [hidden]="api.queso[i] === '-1'">
            <div class="qty-box"></div>
            <div class="half-box" [ngStyle]="{'font-size':api.isdemo ? '13px' : '16px'}" id="queso-box-{{i}}"
                style="flex-grow:4"> <b>Queso:</b> {{api.queso[i]}} </div>
            <div class="price-box"> </div>
        </div>
        
        <div class="flex-container" [hidden]="api.salsa[i] === '-1'">
            <div class="qty-box"></div>
            <div class="half-box" [ngStyle]="{'font-size':api.isdemo ? '13px' : '16px'}" id="salsa-box-{{i}}"
                style="flex-grow:4"> <b>Salsa:</b> {{api.salsa[i]}} </div>
            <div class="price-box"> </div>
        </div>
       <!--  <div class="flex-container" [hidden]="api.crustFlip[i] === '-1'">
            <div class="qty-box"></div>
            <div class="half-box" [ngStyle]="{'font-size':api.isdemo ? '13px' : '16px'}" id="crustFlip-box-{{i}}"
                style="flex-grow:4"> <b>Crust Flip:</b> {{api.crustFlip[i]}} </div>
            <div class="price-box"> </div>
        </div> -->

        <div class="flex-container" [hidden]="api.drink[i] === '-1'">
            <div class="qty-box"></div>
            <div class="half-box" [ngStyle]="{'font-size':api.isdemo ? '13px' : '16px'}" id="drink-box-{{i}}"
                style="flex-grow:4"> <b>Drink:</b> {{api.drink[i]}} </div>
            <div class="price-box"> </div>
        </div>
        <div class="flex-container" [hidden]="api.drinks[i] === '-1'">
            <div class="qty-box"></div>
            <div class="half-box" [ngStyle]="{'font-size':api.isdemo ? '13px' : '16px'}" id="drinks-box-{{i}}"
                style="flex-grow:4"> <b>Drinks:</b> {{api.drinks[i]}} </div>
            <div class="price-box"> </div>
        </div>


        <div class="flex-container" [hidden]="api.meat[i] === '-1'">
            <div class="qty-box"></div>
            <div class="half-box" [ngStyle]="{'font-size':api.isdemo ? '13px' : '16px'}" id="meat-box-{{i}}"
                style="flex-grow:4"> <b>Style:</b> {{api.meat[i]}} </div>
            <div class="price-box"> </div>
        </div>
        <div class="flex-container" [hidden]="api.espresso[i] === '-1'">
            <div class="qty-box"></div>
            <div class="half-box" [ngStyle]="{'font-size':api.isdemo ? '13px' : '16px'}" id="espresso-box-{{i}}"
                style="flex-grow:4"> <b>Espresso:</b> {{api.espresso[i]}} </div>
            <div class="price-box"> </div>
        </div>
        <div class="flex-container" [hidden]="api.kidsDrink[i] === '-1'">
            <div class="qty-box"></div>
            <div class="half-box" [ngStyle]="{'font-size':api.isdemo ? '13px' : '16px'}" id="kidsDrink-box-{{i}}"
                style="flex-grow:4"> <b>Kids Drink:</b> {{api.kidsDrink[i]}} </div>
            <div class="price-box"> </div>
        </div>

        <div class="flex-container" [hidden]="api.sideTortilla[i] === '-1'">
            <div class="qty-box"></div>
            <div class="half-box" [ngStyle]="{'font-size':api.isdemo ? '13px' : '16px'}" id="sideTortilla-box-{{i}}"
                style="flex-grow:4"> <b>Side Tortilla:</b> {{api.sideTortilla[i]}} </div>
            <div class="price-box"> </div>
        </div>

        

        <div class="flex-container" [hidden]="api.additionals[i] === '-1'">
            <div class="qty-box"></div>
            <div class="half-box" [ngStyle]="{'font-size':api.isdemo ? '13px' : '16px'}" id="additionals-box-{{i}}"
                style="flex-grow:4"> <b>Extras:</b> {{api.additionals[i]}} </div>
            <div class="price-box"> </div>
        </div>
 
      <!--  <div class="flex-container" [hidden]="api.calories[i] === '-1'">
            <div class="qty-box"></div>
            <div class="half-box" [ngStyle]="{'font-size':api.isdemo ? '13px' : '16px'}" id="calories-box-{{i}}"
                style="flex-grow:4"> <b>Calories:</b> {{api.calories[i]}} </div>
            <div class="price-box"> </div>
        </div> -->

        <div class="flex-container" [hidden]="api.side[i] === '-1'">
            <div class="qty-box"></div>
            <div class="half-box" [ngStyle]="{'font-size':api.isdemo ? '13px' : '16px'}" id="side2-box-{{i}}"
                style="flex-grow:4"> <b>Side:</b> {{api.side[i]}} </div>
            <div class="price-box"> </div>
        </div>
        <div class="flex-container" [hidden]="api.sidetwo[i] === '-1'">
            <div class="qty-box"></div>
            <div class="half-box" [ngStyle]="{'font-size':api.isdemo ? '13px' : '16px'}" id="sidetwo-box-{{i}}"
                style="flex-grow:4"> <b>Side Two:</b> {{api.sidetwo[i]}} </div>
            <div class="price-box"> </div>
        </div>
        <div class="flex-container" [hidden]="api.modNA[i] === '-1'">
            <div class="qty-box"></div>
            <div class="half-box" [ngStyle]="{'font-size':api.isdemo ? '13px' : '16px'}" id="modNA-box-{{i}}"
                style="flex-grow:4"> <b>Not Available:</b> {{api.modNA[i]}} </div>
            <div class="price-box"> </div>
        </div>
        <div *ngIf="api.restaurant.isUpsize">
            <div class="flex-container" *ngIf="api.isUpSize[i]" style="padding-top: 7px;" (click)="upSize(i)">
                <div class="qty-box"></div>
                <div class="half-box" [ngStyle]="{'font-size':api.isdemo ? '13px' : '16px'}" id="dressing-box-{{i}}"
                    style="flex-grow:4; color: #3A91D7;  text-decoration: underline; font-size: 12px; font-weight: 400 !important;">
                    <b> <i>{{api.upSizeDisplayString[i]}}</i> </b> </div>
                <div class="price-box"> </div>
            </div>
        </div>
        <!--
  <div class="flex-container">
        <div class = "qty-box" id = "qty-box-{{i}}"> </div>
        <div class = "half-box"  style="flex-grow: 6; color: black; padding-top: 12px;"> 
        <span *ngIf = "api.isDeleteBtn" id = "delete-box-{{i}}"  style = "color: #31afd5; padding: 0px 4px; font-weight: bold; " (click) = "onEdit(i)" > <i>Edit</i> </span>
        <span *ngIf = "api.isDeleteBtn" style = "padding: 0px 7px; font-weight: bold;">|</span>
        <span  *ngIf = "api.isDeleteBtn" id = "delete-box-{{i}}"  style = "color: red; padding: 0px 4px; font-weight: bold;"  (click) = "removeItem(i)">  <i>remove</i> </span>
        <span *ngIf = "api.isDeleteBtn" style = "padding: 0px 7px; font-weight: bold;">|</span>
        <span  *ngIf = "api.isDeleteBtn" id = "delete-box-{{i}}"  style = "color: black; padding: 0px 4px; font-weight: bold;"  (click) = "removeItem(i)">  <i>Make it Large for $2 more</i> </span> </div>
    <div class = "price-box" id = "price-box-{{i}}"> </div>

  </div> -->

        <!-- <div class="flex-container" [hidden]="api.notApplicable[i] === '-1'">
      <div class = "qty-box"></div>
      <div class = "half-box" style="flex-grow:4"> Not available on this item: {{api.notApplicable[i]}} </div>
      <div class = "price-box"> </div>
  </div> -->
        <hr [style.border]="showBtn===i ? '2px solid #fff ' : '2px solid #eee'" style="margin-bottom: 2px;">
        <span *ngIf="showBtn===i">
            <div *ngIf="api.isEditItemBox">
                <div style="display: flex; justify-content: center">
                    <div class="input-group" style="width: 80%">
                        <textarea #textbox1 name="text" rows="5" autofocus [(ngModel)]="editItemText"
                            style="height: 80px;" class="form-control editBox" aria-label="With textarea"></textarea>
                    </div>
                </div>

                <div style="display: flex; justify-content: center; padding: 7px">
                    <div [attr.id]="'undoBtn'+i" *ngIf="showBtn===i" style="padding: 5px;">
                        <button class="btn btn-success" (click)="editItem()" type="button"
                            [ngStyle]="{'background':api.primaryColor}" style="font-size: 16px;"> Update</button>
                    </div>
                    <div [attr.id]="'undoBtn'+i" *ngIf="showBtn===i" style="padding: 5px;">
                        <button class="btn btn-success" (click)="cancelEdit()" type="button"
                            style="border: black; font-size: 16px; background-color: black"> Cancel </button>
                    </div>
                </div>
            </div>
        </span>
    </div>
    <div class="flex-container" [hidden]="api.naItems === '0'">
        <div class="title-box" [ngStyle]="{'font-size':api.isdemo ? '14px' : '15px'}" style="flex-grow: 4;
        padding-left: 10px;
        font-size: 15px;
        font-weight: 600;
        color: crimson;
        padding-top: 0px;">Not Available: {{api.naItems}} </div>

    </div>
    <hr style="margin: 2px;" [hidden]="api.naItems === '0'">
    <!--UPsell-->
    <div *ngIf="api.restaurant.isUpsell">
        <div *ngIf="api.isShowUpsell">
            <app-up-sell-items></app-up-sell-items>
        </div>
    </div>


    <!-- ADD Item form view state-->
    <p class="pL15 pT15 f-16 bold mB5" *ngIf="api.method.mtype.toLowerCase()=='pickup' && api.isContactFree && api.curbData.make != '' && api.curbData.model != '' && api.curbData.color != ''"> {{api.curbData.color}} {{api.curbData.make}} {{api.curbData.model}} 
        <span style="font-weight: 400; text-transform:lowercase; " [ngStyle]="{'color':api.primaryColor}"
        (click)="editCurb()">
        <i class="pL5">edit </i>  </span> |
        <span 
        style="color: red; font-weight: 400; text-transform:lowercase; padding: 0px 4px; "
        (click)="removeCurb()"><i>remove</i> </span>   
    </p>
    <div *ngIf="api.isAddItemBox" style="padding: 10px;">
        <div *ngIf = "api.restaurant.name != 'BCPizza'">
        <div *ngIf="api.isSplInst">
            <h4 style="font-size: 14px;
    margin:0px;
    font-family: helvetica;
    padding-bottom: 17px;
    font-weight: 400;"> "{{api.splInstText}}"
                <span  class="pL15" style="font-weight: 400; text-transform:lowercase; " [ngStyle]="{'color':api.primaryColor}"
                    (click)="onEditSpl()">
                    <i>Edit</i> </span>
                <!-- <span style = "padding: 0px 3px;">|</span>
     <span style = "color: red; font-weight: 400; text-transform:lowercase;"  (click) = "onRemoveSpl()">  <i>remove</i> </span> -->
            </h4>
            <!-- <h6 style  = "font-size: 15px;
    font-family: helvetica;
    word-spacing: .75px;
    font-weight: bold;
    padding-bottom: 15px;"></h6> -->
        </div>
    </div>
        <div class="input-group" style = "padding-bottom: 10px;">
            <!--    <div class="input-group-addon" style = "padding-left: 0px; padding-right: 0px;">
                    <i (click) = "addItem()" class="material-icons" style = "font-size: 30px;">
                            add_circle
                            </i>
                  </div> -->
            <input [(ngModel)]="addItemText" type="text" class="form-control" placeholder="Add any item or promo to your cart"
                aria-label="Recipient's username" aria-describedby="basic-addon2">
            <div class="input-group-addon" style="padding: 0px;">
                <button [ngStyle]="{'background':api.primaryColor}" style="border-radius: 0px 5px 5px 0px;"
                    class="btn btn-success add-btn" (click)="addItem()" type="button">Add</button>
            </div>
        </div>

        <div *ngIf = "!api.restaurant.name == 'BCPizza' || !api.restaurant.name == 'Freebirds' ">
        <div *ngIf="!api.isSplInst">
            <div *ngIf="!addSplInst"
                style="padding: 12px; display: flex; justify-content: flex-end; padding-right: 0px;">
                <button [ngStyle]="{'background':api.primaryColor}" style="border-radius: 5px"
                    class="btn btn-success add-btn" (click)="splInst()" type="button">Add Special Instructions</button>
            </div>
            <div *ngIf="addSplInst">
                <div style="display: flex; justify-content: center">
                    <div class="input-group" style="width: 100%; padding-top: 12px;">
                        <textarea rows="3" [(ngModel)]="api.splInstText" class="form-control editBox"
                            placeholder="Add your special instructions" aria-label="With textarea"></textarea>
                    </div>
                </div>

                <div style="display: flex; justify-content: center; padding: 7px">
                    <div style="padding: 5px;">
                        <button class="btn btn-success" (click)="onSplInst()" type="button"
                            [ngStyle]="{'background':api.primaryColor}" style=" font-size: 16px;"> Add</button>
                    </div>
                    <div style="padding: 5px;">
                        <button class="btn btn-success" (click)="cancelSplInst()" type="button"
                            style="border: black; font-size: 16px; background-color: black"> Cancel </button>
                    </div>
                </div>

            </div>
        </div>
    </div>
       
    <div  class="" *ngIf = "api.name == 'freebirds' &&  api.puchLoggedin && api.rewards.isRewards" >
        <app-rewards-component *ngIf = "!api.rewards.isRewardsSelected" (item)="setItem($event)"></app-rewards-component>
    </div> 
        <div  class="" *ngIf = "api.name != 'freebirds' && api.rewards.isRewards" >
            <app-rewards-component *ngIf = "!api.rewards.isRewardsSelected" (item)="setItem($event)"></app-rewards-component>
        </div> 

        <hr style="margin: 4px;" *ngIf="api.isAddItemBox">

    </div>

    <!-- EDIT ITEM in view State-->
    <!-- <div *ngIf = "api.isEditItemBox">
    <div style = "display: flex; justify-content: center">
            <div class="input-group" style = "width: 80%">
                    <textarea rows = "3" [(ngModel)] = "editItemText"   class="form-control editBox" aria-label="With textarea"></textarea>
            </div>
    </div>
        
        <div style = "display: flex; justify-content: center; padding: 7px">
            <div style = "padding: 5px;">
                <button class="btn btn-success"   (click) = "editItem()" type="button" style = "font-size: 16px;" [ngStyle]="{{background:api.primaryColor}}"> Update</button>  
            </div>
            <div style = "padding: 5px;">
             <button class="btn btn-success"   (click) = "cancelEdit()" type="button" style = "border: black; font-size: 16px; background-color: black"> Cancel </button>  
            </div>
        </div>
</div> -->

    <div class="flex-container" [hidden]="api.keywordText === '-1'">
        <div class="keyword-box" style="flex-grow:4; padding: 10px;  font-size: large">{{api.keywordText}} </div>
    </div>
    <div class="flex-container" [hidden]="api.errorText === '-1'">
        <div class="keyword-box" style="flex-grow:4; padding: 10px; font-size: large">{{api.errorText}} </div>
    </div>
    <div class="flex-container" [hidden]="api.irrelevantText === '-1'">
        <div class="keyword-box" style="flex-grow:4; padding: 10px;  font-size: large">{{api.irrelevantText}} </div>
    </div>
    <div style = "padding: 20px;" *ngIf = "api.keywordText !== '-1' || api.errorText !== '-1' || api.irrelevantText !== '-1'">
        <!--    <div class="input-group-addon" style = "padding-left: 0px; padding-right: 0px;">
                <i (click) = "addItem()" class="material-icons" style = "font-size: 30px;">
                        add_circle
                        </i>
              </div> -->
        <textarea rows = "2" [(ngModel)]="addItemText" type="text" class="form-control" placeholder="I want..."
            aria-label="Recipient's username" aria-describedby="basic-addon2"> </textarea>

        <div style="padding: 20px 0px; display: flex; justify-content: center;">
            <button [ngStyle]="{'background':api.primaryColor}" style="width: 75%;"
                class="btn btn-success add-btn" (click)="addItem()" type="button">Add</button>
        </div>
    </div>
    <!--
<div class="flex-container" [hidden]="api.naItems === '-1'" >
    <div class = "half-box" style="flex-grow:4" >Not Available: {{api.naItems}} </div>
    <hr>
</div> -->

    <!--
<app-method *ngIf = "!api.isEdit && !api.isState_0 && !api.onSend"[hidden] = "api.homePageDis"></app-method>  -->
    <div *ngIf="!api.isdemo">
        <div *ngIf="!api.isPaid">
            <div class="price-list" style="padding-top:0px">
                <!--  <div class="flex-container-price" [hidden]= "api.itemsTotal === -1">
              <div>Items total:</div>
              <div>${{api.itemsTotal}}</div> </div> -->
              <div class="flex-container-price" *ngIf = "api.rewards.isRewards"  >
                <div *ngIf = "!api.rewards.isRewardsSelected"> Redeemable Reward:</div>
                <div *ngIf = "!api.rewards.isRewardsSelected">{{api.rewards.availablePoints}} Pts</div>
                  </div>
                <div class="flex-container-price" [hidden]="api.saving === '0.00'">
                    <div style = "text-transform: capitalize;"> You Saved (<span *ngIf = "api.rewards.isRewardsSelected"> Promo: {{api.couponName}} & {{api.rewards.code}}) </span> <span *ngIf = "!api.rewards.isRewardsSelected"> Promo: {{api.couponName}})</span> </div>
                    <div>-${{api.saving}} </div>
                </div>
                <!--    <div class="flex-container-price" [hidden]= "api.subtotal === 0">
                      <div>Items subtotal:</div>
                      <div>${{api.subtotal}}</div> </div> -->
                <div class="flex-container-price" [hidden]="api.delivery_fee === '0.00'">
                    <div> Delivery fee:</div>
                    <div>${{api.delivery_fee}}</div>
                </div>
                <popover-content #myPopover [closeOnClickOutside]="true">
                    <div><span>Tax:</span> <span style="float: right">&nbsp;${{api.tax}}</span></div>
                    <div *ngIf="api.serviceFee != '0.00'"><span>Service Fee:</span> <span
                            style="float: right">${{api.serviceFee}}</span></div>
                    <div *ngIf="api.serviceFee != '0.00'"><span style="color: grey">Digital Convenience Fee</span></div>
                </popover-content>
                <div class="flex-container-price" [hidden]="api.tax === '0.00'">
                    <div>Tax <span *ngIf="api.serviceFee != '0.00'"> &amp; fees </span>: <span
                            *ngIf="api.serviceFee != '0.00'">&nbsp;<i [popover]="myPopover" popoverPlacement="top"
                                class="fas fa-info-circle"></i></span></div>
                    <div>${{api.calcTaxnFee}}</div>
                </div>
                <div class="flex-container-price" [hidden]="api.tip === 0.00" *ngIf="api.tipDisplay">
                    <div>Tip:</div>
                    <div>${{api.tip}}</div>
                </div>
            </div>

            <div *ngIf="api.isTotalBox">
                <table class="table" [hidden]="api.showTotal" [ngStyle]="">
                    <thead *ngIf = "api.keywordText === '-1' && api.errorText === '-1' && api.irrelevantText === '-1'"class="table" [ngStyle]="">
                        <tr class="flex-container-table" [ngStyle]="">
                            <th scope="col" style="font-size: 17px;
            padding-top: 0px;" [ngStyle]="">TOTAL</th>
                            <th scope="col" style="font-size: 17px;
            padding-top: 0px;" [ngStyle]="">${{(api.total + api.tip).toFixed(2)}}</th>
                        </tr>
                    </thead>
                </table>
            </div>

        </div>
        <!--PAID PRICING-->
        <div *ngIf="api.isPaid">
            <div class="price-list" style="padding-top:0px">
                <!--  <div class="flex-container-price" [hidden]= "api.itemsTotal === 0">
                            <div>Items total:</div>
                            <div>${{api.itemsTotal}}</div> </div> -->
                <div class="flex-container-price" [hidden]="api.paidSavings === '0.00'">
                    <div> You Saved (Promo: {{api.paidCouponName}})</div>
                    <div>-${{api.paidSavings}} </div>
                </div>
                <!--    <div class="flex-container-price" [hidden]= "api.subtotal === 0">
                                    <div>Items subtotal:</div>
                                    <div>${{api.subtotal}}</div> </div> -->
                <div class="flex-container-price" [hidden]="api.paidDeliveryFee === '0.00'">
                    <div> Delivery fee:</div>
                    <div>${{api.paidDeliveryFee}}</div>
                </div>



                <popover-content #paidPopover [closeOnClickOutside]="true">
                    <div><span>Tax:</span> <span style="float: right">&nbsp;${{api.paidTax}}</span></div>
                    <div *ngIf="api.serviceFee != '0.00'"><span>Service Fee:</span> <span
                            style="float: right">${{api.serviceFee}}</span></div>
                    <div *ngIf="api.serviceFee != '0.00'"><span style="color: grey">Digital Convenience Fee</span></div>
                </popover-content>





                <div class="flex-container-price" [hidden]="api.paidTax=== '0.00'">
                    <div>Tax <span *ngIf="api.serviceFee != '0.00'"> &amp; fees </span>:
                        <span *ngIf="api.serviceFee != '0.00'" [popover]="paidPopover" popoverPlacement="top"
                            class="material-icons" style="font-size: 18px">
                            info
                        </span>
                    </div>
                    <div>${{api.calcTaxnFee}}</div>
                </div>

                <div class="flex-container-price" [hidden]="api.paidTip === '0'">
                    <div>Tip:</div>
                    <div>${{api.paidTip}}</div>
                </div>
            </div>


            <table class="table" [hidden]="api.paidTotal === '0.0'">
                <thead class="table">
                    <tr class="flex-container-table">
                        <th scope="col" style="font-size: 17px;
                                    padding-top: 0px;">TOTAL</th>
                        <th scope="col" style="font-size: 17px;
                                    padding-top: 0px;">${{(api.paidTotal)}}</th>
                    </tr>
                </thead>
            </table>
        </div>
        <hr style="margin-top: 5px; margin-bottom: 5px" />
<div *ngIf="chainName=='jets' &&  api.quickOrder.isQuickName">
   <div style="margin-top: 25px">
      <p *ngIf="coupons.displayType == 'auto'" class="pL10 bold pB5 mB0">
         <i class="fa fa-check-circle green" aria-hidden="true"></i> Best
         fitting coupon automatically applied. If you change items we will
         check again.
      </p>
      <p *ngIf="coupons.displayType == 'manual'" class="pL10 bold pB5 mB0">
         Hot Deals: Add items on your cart and click the button.
         <button
            type="button"
            style="color: white;"
            [ngStyle]="{ background: api.primaryColor }"
            class="btn btn-success mL15"
            >
         Click here to apply the best available deal
         </button>
      </p>
      <div class="mL10 mR10 pB30">
         <span class="bold pB15">Coupons:</span>
         <span *ngFor="let list of coupons.couponList; index as i">
         <i
            *ngIf="list.isApplied == true"
            class="fa fa-check-circle green mL5 f-15"
            aria-hidden="true"
            ></i
            ><span class="green f-15 bold" *ngIf="list.isApplied == true"
            >&nbsp;{{ list.displayName }}
         <span
            class="f-15"
            style="color: black"
            *ngIf="i < coupons.couponList.length - 1"
            >|</span
            ></span
            >
         <span class="f-15" *ngIf="list.isApplied == false"
            >&nbsp;{{ list.displayName }}
         <span
            style="color: black"
            *ngIf="i < coupons.couponList.length - 1"
            >|</span
            ></span
            >
         </span>
      </div>
   </div>
</div>

    </div>







    <!--
<div>
    <div class="flex-container">
      <div class = "qty-box">2</div>
      <div class = "title-box" style="flex-grow: 6">20 oz Bottle Drink </div>
      <div class = "price-box">$1.41</div>
    </div>
    <div class="flex-container">
        <div class = "qty-box"></div>
        <div class = "modi-box" style="flex-grow: 5">Diet Pepsi</div>
        <div class = "price-box"> </div>
    </div>
    <hr>
  </div>
  <div>
      <div class="flex-container">
        <div class = "qty-box">1</div>
        <div class = "title-box" style="flex-grow: 6"> Greek Salad</div>
        <div class = "price-box">$4.48</div>
      </div>
      <div class="flex-container">
          <div class = "qty-box"></div>
          <div class = "modi-box" style="flex-grow: 5"> Regular </div>
          <div class = "price-box"> </div>
      </div>
      <hr> -->




    <!-- <div
  class="form-group edit-box">
<textarea
  readonly
  type = "text"
  class="form-control rounded-0 summary-box animated fadeInDown"
  id="exampleFormControlTextarea2"
  rows="20"
  [ngStyle] = "{
    'background' : isActive ? 'lightgrey': 'white',
    'opacity' : isActive ? '0.6' : '1',
    'border' : isActive ? '0px' : 'solid 1px'
  }"
  [(ngModel)] = "summary"></textarea>
</div>
-->