
<div style="display: flex; justify-content: center" *ngIf="!api.isOrdrSummary">
    <img src= {{api.loadingGif}} class="rounded img-fluid" alt="...">
</div>
<div *ngIf="api.isOrdrSummary">
    <mat-card style = "padding: 2px;">
        <app-method *ngIf = "!api.isOnlyPayment"></app-method>
        <app-summary *ngIf = "!api.isOnlyPayment"> </app-summary>



        <div *ngIf="!api.isBraintree">
            
            <div *ngIf = "api.restaurant.rid == '+15512911234'" style = "display: flex; justify-content: center;">
                <tr _ngcontent-c10="" class="flex-container-table"><th _ngcontent-c10="" scope="col" style="font-size: 16px;
                    padding-top:0px; padding-bottom: 0px; color: #b22222;">Use this demo credit card info:</th>
        </tr>
        <tr _ngcontent-c10="" class="flex-container-table"><th _ngcontent-c10="" scope="col" style="font-size: 12px;
                    padding-top: 0px; color: #b22222; border-top: 0px solid #b22222;">4111-1111-1111-1111</th><th _ngcontent-c10="" scope="col" style="font-size: 12px;
                    padding-top: 0px; color:#b22222; border-top: 0px solid #dee2e6;">Exp: 12/29</th><th _ngcontent-c10="" scope="col" style="font-size: 12px;
                    padding-top: 0px; color:#b22222; border-top: 0px solid #dee2e6;">CVV: 199</th>
        </tr>
            </div>
            <div *ngIf = "api.dropin == 'braintree'">
            <ngx-braintree [getClientToken]="getClientToken" [createPurchase]="createPurchase" [chargeAmount]="55.55"
                (paymentStatus)="onPaymentStatus($event)" [allowChoose]="true" [currency]="'USD'">

                <div class="ngxButtons">
                    <style>
                        button {
                            background-color: #5cb85c;
                            color: #ffffff;
                            border: none;
                            border-radius: 4px;
                            height: 40px;
                            line-height: 40px;
                            font-size: 16px;
                            cursor: pointer;
                            margin-right: 20px;
                        }


                        button:disabled {
                            background-color: #5cb85c;
                            color: #ffffff;
                            border: none;
                            border-radius: 4px;
                            height: 40px;
                            line-height: 40px;
                            font-size: 16px;
                            cursor: not-allowed;
                            margin-right: 20px;
                        }
                    </style>
                    <div *ngIf="loadButton">
                        <div *ngIf="!isSaveCard">
                            <div class="check-box">
                              <mat-checkbox color="primary" [(ngModel)]="api.isSaveCard"></mat-checkbox>
                              
                              <span class="check-txt"> Save this card for future purchases.</span>
                            </div>
                          
         </div>
                        <app-text-marketing-check> </app-text-marketing-check>
                        <div *ngIf="!isSaveCard" class="wrapper-center ">
                            <button mat-raised-button ngxPay (click)="onSaveCard()" color="primary"
                                [ngStyle]="{'background':api.primaryColor}" style="color: white"
                                class="pay-btn animated fadeInDown">

                                <span *ngIf="isSaveCard">
                                    <!-- <i class="material-icons md-light md-36 m-icon" >
                                                directions_run</i> -->
                                    Submit Your Order
                                </span>
                                <span *ngIf="!isSaveCard">
                                    <!--  <span class="material-icons">
                                                credit_card
                                                </span> --><span *ngIf = "!api.isOnlyPayment"> Add Card </span> <span *ngIf = "api.isOnlyPayment"> Next </span>
                                </span>
                            </button>

                        </div>
                        <div *ngIf="isSaveCard" class="wrapper-center ">
                            <button mat-raised-button ngxPay (click)="onSubmit()" color="primary"
                                style="color: white; height: 48px;" [ngStyle]="{'background':api.primaryColor}"
                                class="pay-btn animated fadeInDown">
                                <i class="material-icons md-light md-36 m-icon">
                                    directions_run</i>
                                Submit Your Order </button>
                        </div>
                        <div *ngIf = "!api.isOnlyPayment" class="wrapper-center " [hidden]="paymentSubmitted">
                            <button mat-raised-button type="button"
                                [ngStyle]="{'color':api.primaryColor, 'border-color' : api.primaryColor}"
                                class="back-btn  btn-payment" (click)="navigateToPreviousPage()">
                                Back </button>
                        </div>
                    </div>
                </div>
            </ngx-braintree>
        </div>
        <div *ngIf = "api.dropin == 'worldpay'" style = "padding: 2px;">
            <app-worldpay></app-worldpay>
            <div class="wrapper-center " [hidden]="paymentSubmitted">
                <button mat-raised-button type="button"
                    [ngStyle]="{'color':api.primaryColor, 'border-color' : api.primaryColor}"
                    class="back-btn  btn-payment" (click)="navigateToPreviousPage()">
                    Back </button>
            </div>
        </div>
        </div>
        <div>
            <app-pin-payment *ngIf="api.isBraintree"> </app-pin-payment>
        </div>
    </mat-card>
</div>