<div *ngIf="!api.quickOrder.isQuickName">
    <mat-card style="padding: 10px;">
        <app-method></app-method>
        <form #f="ngForm" (submit)="submit(f)">
        
            <div class="input-group input-group-lg input-txt">
                <div class="input-group-addon" style="padding-bottom: 4px; background: white">
                    <i class="fa fa-phone" aria-hidden="true"></i>
                </div>
                <input readonly [(ngModel)]="displayNum" name="phoneNumber" id="pinVal_2" type="text"
                    class="form-control phone-number">
            </div>

            <label *ngIf="chainname!='jets'" class='name-txt'>Name:</label>
            <label *ngIf="chainname=='jets'" class='name-txt'>First Name:</label>
            <div>
            <div class="input-group input-group-lg input-txt">
                <div class="input-group-addon" style="background: white;  ">
                    <i class="fa fa-user" aria-hidden="true"></i>
                </div>
                <div>
                    <input required matInput autocomplete="off" minlength="3" maxlength="15" style="margin-top: 0px;"
                        id="name-text" type="text" class="form-control" aria-label="Default"
                        aria-describedby="inputGroup-sizing-default" placeholder="Enter your name" value
                        pattern="^[a-zA-Z]+(?: [a-zA-Z]+)*$"
                        [(ngModel)]="api.userName" name="nameText" #first="ngModel" (blur)="blur(first)" (change)="name_fn(first)">
                </div>
                
            </div>
            <div class="alert alert-danger" *ngIf="first.touched && !first.valid">
                <div *ngIf="first?.errors.required"> Please enter your name</div>
                <div *ngIf="first?.errors.minlength"> Minimum 3 character is required </div>
                <div *ngIf="first?.errors.pattern"> Alphabetic character is expected </div>
            </div>
        </div>
        <div *ngIf="chainname==='jets'">
            <label class='name-txt'>Last Name:</label>
            <div class="">
                <div class="input-group input-group-lg input-txt">
                    <div class="input-group-addon" style="background: white;  ">
                        <i class="fa fa-user" aria-hidden="true"></i>
                    </div>
                    <div>
                        <input required matInput autocomplete="off" minlength="1" maxlength="15" style="margin-top: 0px;"
                            id="name-text-last" type="text" class="form-control" 
                            placeholder="Enter your last name" value
                            pattern="^[a-zA-Z]+(?: [a-zA-Z]+)*$"
                            [(ngModel)]="api.customer.lastName" name="lastname" #last="ngModel"  (blur)="blurLast(last)" (change)="name_fn_last(last)">
                    </div>
                    
                </div> 
                <div class="alert alert-danger" *ngIf="last.touched && !last.valid">
                    <div *ngIf="last?.errors.required"> Please enter your last name</div>
                    <div *ngIf="last?.errors.minlength"> Minimum 1 character is required in last name </div>
                    <div *ngIf="last?.errors.pattern"> Alphabetic character is expected in last name </div>
                </div>
            </div>
        </div>
            
            
            <div class="container wrapper animated fadeInDown  ">
                <button mat-raised-button type="button" class="back-btn"
                    [ngStyle]="{'color':api.primaryColor, 'border-color' : api.primaryColor}" style="border-radius: 5px"
                    (click)="onBack()">
                    Back </button>

                <button mat-raised-button type="submit" [ngStyle]="{background:api.primaryColor}" [ngClass]="!f.valid?'next-disable':''"
                    style="border-radius: 5px" class="send-btn animated fadeInDown" [disabled]="!f.valid"> Next
                </button>


            </div>

        </form>
    </mat-card>
</div>


<div *ngIf="api.quickOrder.isQuickName">
    <mat-card style="padding: 10px;">
        <app-method></app-method>
        <form #f="ngForm" (submit)="result(f)">

            <div class="input-group input-group-lg input-txt">
                <div class="input-group-addon" style="padding-bottom: 4px; background: white">
                    <i class="fa fa-phone" aria-hidden="true"></i>
                </div>
                <input required [(ngModel)]="api.quickOrder.custId" appPhoneMask matInput autocomplete="off"
                    minlength="9" maxlength="20" style="margin-top: 0 !important" name="phoneNumber" id="pinVal_2"
                    type="tel" class="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default"
                    placeholder="Enter your Phone Number" value #name1="ngModel">
            </div>
            <div class="alert alert-danger" *ngIf="name1.touched && !name1.valid">
                <div *ngIf="name1?.errors.required"> Please enter your phone number</div>
                <div *ngIf="name1?.errors.minlength"> 10 digits is required </div>
            </div>


            <label class='name-txt'>Name:</label>
            <div class="input-group input-group-lg input-txt">
                <div class="input-group-addon" style="background: white;  ">
                    <i class="fa fa-user" aria-hidden="true"></i>
                </div>
                <div>
                    <input required matInput autocomplete="off" minlength="3" maxlength="30" style="margin-top: 0px;"
                        id="name-text" type="text" class="form-control" aria-label="Default"
                        aria-describedby="inputGroup-sizing-default" placeholder="Enter your Name" value
                        [(ngModel)]="api.userName" name="nameText"
                        pattern="^[a-zA-Z]+(?: [a-zA-Z]+)*$"
                        #name="ngModel" (blur)="blur(name)" (change)="name_fn(name)">
                </div>
            </div>
            <div class="alert alert-danger" *ngIf="name.touched && !name.valid">
                <div *ngIf="name?.errors.required"> Please enter your name</div>
                <div *ngIf="name?.errors.minlength"> Minimum 3 character is required </div>
                <div *ngIf="name?.errors.pattern"> Alphabetic character is expected </div>
            </div>




            <div class="container animated fadeInDown ">
                <div class="row">
                    <div class="col wrapper">
                        <button mat-raised-button type="button" class="back-btn"
                            [ngStyle]="{'color':api.primaryColor, 'border-color' : api.primaryColor}"
                            style="width: 100%;" (click)="onBack()"> Back </button>
                    </div>
                    <div class="col wrapper" *ngIf="!isNext">
                        <button mat-raised-button type="submit" style="width: 100%;"
                            class="send-btn animated fadeInDown " [disabled]="!f.valid"> Next </button>
                    </div>
                    <div class="col wrapper" *ngIf="isNext">
                        <button mat-raised-button type="submit" style="width: 100%; background-color: gray !important"
                            class="send-btn"> Next </button>
                    </div>
                </div>
            </div>

            <!--  <div class = "container wrapper animated fadeInDown ">
                                  <button
                                      mat-raised-button
                                      type="button"
                                      class="back-btn"
                                      (click) = "onBack()" >  Back </button>
                  
                                  <button
                                    mat-raised-button
                                    *ngIf = "!isNext"
                                    type = "submit"
                                    class="send-btn animated fadeInDown "
                                    [disabled]="!f.valid"
                                     >  Next  </button>
                                  <button
                                    mat-raised-button
                                    *ngIf = "isNext"
                                    class="send-btn animated fadeInDown ">
                                    <img  *ngIf = "isNext"  style = " width: 12%; padding-left: 10px;" src="http://indismart.in/wp-content/uploads/2018/09/loaderimg.gif" > 
                                </button>
                  
                                </div> -->

        </form>
    </mat-card>
</div>