import { APICenterService } from './../apicenter.service';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '../../../node_modules/@angular/forms';



@Component({
  selector: 'app-pin-set',
  templateUrl: './pin-set.component.html',
  styleUrls: ['./pin-set.component.css']
})
export class PinSetComponent implements OnInit {

  userPin_1: string;
  userPin_2: string;
  wrongPin = true;
  pinSet = true;
  isPinSaved = false;
  displayNum;

  checkList = {
    caps: [],
    small: [],
    number: [],
    symbols: []
  };
  isCheckList = {
    isCaps: false,
    isSmall: false,
    isNumber: false,
    isSymbols: false
  };
  validateCheckList = {
    caps : null,
    small: null,
    number: null,
    symbols: null
  };

  isPasswordWrong = false;
  isQuickName;


  constructor(public  api: APICenterService) {

  }

  ngOnInit() {
    /* const objTest = {
      'contact' : this.api.isContact,
      'isTip' : this.api.isTip,
      'payatStore' : this.api.payatStore,
      'payOption' : this.api.payOption,
      'payOnline' : this.api.payOnline,
      'isPinStored' : this.api.isPinStored,
      'isBt' : this.api.isBt,
      'isBraintree' : this.api.isBraintree
    };
    console.log(objTest); */
     // this.isQuickName = false;
     if (this.api.quickOrder.isQuickName) {
        this.displayNum = this.api.quickOrder.custId;
     } else {
        this.displayNum = this.api.formatPhone(this.api.customer.cid);
     }
     // console.log(this.api.quickOrder.custId)
  }


  submit(f: NgForm) {
    this.api.actionbutton("save_pin",	"SP001")
    this.api.clickValue = 'New_PIN';
    this.api.clickAnalytics();
    // console.log('form')
    // console.log(f.value)
    this.userPin_1 = f.value['pinText-1'];
    // console.log(this.userPin_1);
    this.userPin_2 = f.value['pinText-2'];
    this.validate();
    this.api.pinFailure = false;
    if (this.api.isOnlyPayment) {
      this.isPinSaved = true;
      this.api.setPin()
    } else {
      this.nextState();
    }
  }

  submitPassword(f: NgForm) {
    this.api.actionbutton("save_pin",	"SP001")
    this.api.clickValue = 'New_PASSWORD';
    this.api.clickAnalytics();
    this.resetPasswordVal();
    // console.log('form')
    // console.log(f.value)
    this.userPin_1 = f.value['pinText-1'];
    // console.log(this.userPin_1);
    this.userPin_2 = f.value['pinText-2'];
    this.passwordCheck(this.userPin_2);
    if (this.isPasswordWrong) {
      console.log('password format wrong');
    } else {
      // Move to next page
      // console.log('Rite format password');
      this.api.pinFailure = false;
      this.api.enteredPin =  this.userPin_2;
      this.nextState();
    }
    // this.validate();
  }

passwordCheck(a) {
  for (let i = 0; i < a.length; i++) {
    if (a.charCodeAt(i) >= 65 && a.charCodeAt(i) <= 90 ) {
      // console.log(a.charCodeAt(i))
      this.checkList.caps.push(a.charAt(i));
    }
    if (a.charCodeAt(i) >= 97 && a.charCodeAt(i) <= 122 ) {
      // console.log(a.charCodeAt(i))
      this.checkList.small.push(a.charAt(i));
    }
    if (a.charCodeAt(i) >= 48 && a.charCodeAt(i) <= 57 ) {
      // console.log(a.charCodeAt(i))
      this.checkList.number.push(a.charAt(i));
    }
    if (a.charCodeAt(i) >= 32 && a.charCodeAt(i) <= 47 ) {
      // console.log(a.charCodeAt(i))
      this.checkList.symbols.push(a.charAt(i));
    }
    if (a.charCodeAt(i) >= 58 && a.charCodeAt(i) <= 64 ) {
      // console.log(a.charCodeAt(i))
      this.checkList.symbols.push(a.charAt(i));
    }
    if (a.charCodeAt(i) >= 91 && a.charCodeAt(i) <= 96 ) {
      // console.log(a.charCodeAt(i))
      this.checkList.symbols.push(a.charAt(i));
    }
    if (a.charCodeAt(i) >= 123 && a.charCodeAt(i) <= 126 ) {
      // console.log(a.charCodeAt(i))
      this.checkList.symbols.push(a.charAt(i));
    }
  }
  this.setErrorTxt();
}

setErrorTxt() {
  if (this.checkList.caps.length === 0) {
    this.isCheckList.isCaps = true;
    this.isPasswordWrong = true;
    this.validateCheckList.caps = 'Missing english alphabet uppercase letter (A-Z)';
  }
  if (this.checkList.small.length === 0) {
    this.isCheckList.isSmall = true;
    this.isPasswordWrong = true;
    this.validateCheckList.small = 'Missing english alphabet lowercase letter (a-z)';
  }
  if (this.checkList.number.length === 0) {
    this.isCheckList.isNumber = true;
    this.isPasswordWrong = true;
    this.validateCheckList.number = 'Missing decimal digit number (0-9)';
  }
  if (this.checkList.symbols.length === 0) {
    this.isCheckList.isSymbols = true;
    this.isPasswordWrong = true;
    this.validateCheckList.symbols = `Missing Special characters such as ~!@#$%^&*_-+=`;
  }
}

nextState() {

if ((this.api.isPayOnline) && (this.api.isPayAtDoor) && (this.api.isPayAtStore)) {
  console.log('all Are true');
  if (this.api.isDelivery) {
    this.api.checkTipState = true
  }
  if (this.api.method.mtype === "PICKUP") {
  if (this.api.checkTipState || !this.api.isDelivery)  {
    // Pickup Order
    if (this.api.isShowTipState) {
      // SHow the tip State
      if (this.api.isResetPinClicked) {
        // Reset PIN is clicked
        this.api.cardPaymentState();
      } else {
        // Rest PIN not clicked
        this.api.tipState();
      }

    } else  {
      // dont show the tip state
      this.api.cardPaymentState();

    }
  }} else {
    // delivery order
    if (!(this.api.isResetPinClicked)) {
    this.api.tipState();
  } else {
    this.api.cardPaymentState();
  }


  }


} else if ((this.api.isPayAtDoor) && (this.api.isPayAtStore)) {
      // console.log ('pay at door and pay at store is true');
   } else if ((this.api.isPayOnline) && (this.api.isPayAtDoor) ) {
        // console.log ('pay online  and pay at door ');
          if (this.api.pinState === '0') {
            // New Customer
            if (this.api.isShowTipState) {
              // Show Tip State
              this.api.tipState();
            } else {
              // No Tip State
              // Hide Tip State
              this.api.cardPaymentState();
            }
          } else {
            // existing customer
            this.api.cardPaymentState();
          }


       } else if ((this.api.isPayOnline) && (this.api.isPayAtStore) ) {
             // console.log('pay online and pay at store');
             if (this.api.isDelivery) {
              this.api.checkTipState = true
            }
            if (this.api.method.mtype === "PICKUP") {
            if (this.api.checkTipState || !this.api.isDelivery)  {
                // pickup order
                if (this.api.isShowTipState) {
                  // SHow TIP State
                  if (this.api.isResetPinClicked) {

                  } else {
                    this.api.tipState();
                  }

                } else {
                  // DOnt show TIP state
                  this.api.cardPaymentState();
                }

              } }else {
                // console.log("delivery order")
                // delivery order
                if (this.api.isResetPinClicked) {
                  // console.log("isRestPinClicked")
                } else {
                  if (this.api.isShowTipState) {
                    // Show Tip State
                    this.api.tipState();

                  } else {
                    // No Tip State
                    this.api.cardPaymentState();
                  }
                   // this.api.tipState();
                   // console.log("isTipSTATE selected")
                }
              }

           } else {
                if (this.api.isPayOnline)  {
                          // console.log('online is true');
                          // online is true
                          if (this.api.isDelivery) {
                            this.api.checkTipState = true
                          }
                          if (this.api.method.mtype === "PICKUP") {
                          if (this.api.checkTipState || !this.api.isDelivery) {
                                  // Pick up orders

                                     if (this.api.isShowTipState) {

                                       // show Tip State
                                       this.api.tipState();

                                     } else {
                                       // with out Tip State
                                       this.api.cardPaymentState();

                                     }

                                } }else {
                                       // delivery order
                                       this.api.tipState();
                                }

                }

               }
  }


  validate() {
    if (!(this.userPin_2 === this.userPin_2)) {
      // console.log('The PIN doesnt match');
      this.wrongPin = false;
      this.pinSet = true;
    } else {
      // console.log('Congrates !!! your PIN is SET');
      // console.log(this.api.pinFailure);
      if (this.api.pinFailure) {
        this.api.isBt = true;
        this.api.isBraintree = false;
        this.api.pinFailure = false;
        this.api.enteredPin =  this.userPin_2;
      } else {
        this.pinSet = false;
        this.wrongPin = true;
        this.api.enteredPin =  this.userPin_2;
        this.api.isPinStored = false;
      }

    }
  }

  resetPasswordVal() {
    this.checkList.caps = [];
    this.checkList.small = [];
    this.checkList.number = [];
    this.checkList.symbols = [];
    this.isCheckList.isCaps = false;
    this.isCheckList.isSmall = false;
    this.isCheckList.isNumber = false;
    this.isCheckList.isSymbols = false;
    this.validateCheckList.caps = null;
    this.validateCheckList.small = null;
    this.validateCheckList.number = null;
    this.validateCheckList.symbols = null;
    this.isPasswordWrong = false;

  }

  onBack() {
    // // console.log('clicked with skip button')
    this.api.isDeleteBtn = true;
    this.api.isEditBtn = true;
    this.api.isAddItemBox = true;
    this.api.clickAanlysis.push('Back Save new PIN');
    this.api.isTip = true;
    // console.log('SETPIN ');
    // console.log(this.api.isTip);
    // console.log(this.api.isTip)
  }


}
