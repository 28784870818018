<mat-card *ngIf = "!api.isFraud">
  <h3 class = "card-txt" style = "font-size: x-large;">
      Payment Declined
  </h3>

  <h4 class = "card-txt">Don't worry. Your card was not charged. Please try again.</h4>
  <div class = "wrapper">

      <button mat-raised-button color="primary"  (click) = "reloadBt()">Try Again</button>
   
  </div>
  

  <h4 class = "card-txt-1">If you have question or think was a mistake, reachout to <a  href="mailto:info@ordrai.com" class="Blondie"> info@ordrai.com </a> </h4>
</mat-card>

<mat-card *ngIf = "api.isFraud">
  <h3 class = "card-txt" style = "font-size: x-large;">
    You've made too many payment attempts and are now locked out. 
  </h3>
  <h4 class = "card-txt">To place an order, you will need to go to jetspizza.com or call the store directly. Thanks.</h4>
  

  <h4 class = "card-txt-1">If you have question or think was a mistake, reachout to <a  href="mailto:info@ordrai.com" class="Blondie"> info@ordrai.com </a> </h4>
</mat-card>
 