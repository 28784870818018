<div [hidden]="api.payOption">
  <div class="container wrapper-col" *ngIf="api.isCashEligible">
    <app-method></app-method>
    <div
      class="wrapper-center"
      *ngIf="!this.api.isDelivery && this.api.isPayAtStore"
    >
      <button
        (click)="cash('store')"
        mat-raised-button
        [ngStyle]="{ background: api.primaryColor }"
        class="new-green pay-option animated fadeInDown"
      >
        <i class="material-icons md-light md-36 m-icon"> directions_run</i>
        Pay at Store<i class="material-icons md-light md-36 m-icon">
          fast_forward
        </i>
      </button>
    </div>

    <div
      class="wrapper-center"
      *ngIf="this.api.isDelivery && this.api.isPayAtDoor"
    >
      <button
        (click)="cash('door')"
        mat-raised-button
        [ngStyle]="{ background: api.primaryColor }"
        class="new-green pay-option animated fadeInDown"
      >
        <i class="material-icons md-light md-36 m-icon"> home</i>
        Pay at Door<i class="material-icons md-light md-36 m-icon">
          fast_forward
        </i>
      </button>
    </div>

    <div class="wrapper-center">
      <button
        (click)="credit()"
        mat-raised-button
        [ngStyle]="{ background: api.primaryColor }"
        class="new-green pay-option animated fadeInDown"
      >
        <i class="material-icons md-light md-36 m-icon"> credit_card</i>
        Pay Online
        <i class="material-icons md-light md-36 m-icon"> fast_forward </i>
      </button>
    </div>

    <div class="wrapper-center">
      <button
        mat-raised-button
        [ngStyle]="{
          color: api.primaryColor,
          'border-color': api.primaryColor
        }"
        type="button"
        class="back-btn"
        (click)="onBackSelect()"
      >
        Back
      </button>
    </div>
  </div>
</div>

<!-- IF Cash is selected, Button to display the pay at store  <div *ngIf = "ispay " [hidden] = "payAtStore">-->

<div [hidden]="api.payatStore">
  <div
    style="display: flex; justify-content: center"
    *ngIf="!api.isOrdrSummary"
  >
    <img src="{{ api.loadingGif }}" class="rounded img-fluid" alt="..." />
  </div>
  <div *ngIf="api.isOrdrSummary">
    <mat-card>
      <app-method></app-method>
      <app-summary></app-summary>
      <app-text-marketing-check> </app-text-marketing-check>
      <div class="wrapper-center" style="padding-top: 20px">
        <div class="wrapper-center" *ngIf="!api.pinMsg">
          <mat-progress-bar
            mode="indeterminate"
            *ngIf="api.pinMsg"
          ></mat-progress-bar>
          <button
            (click)="submitPayment()"
            mat-raised-button
            class="new-green pay-btn animated fadeInDown"
          >
            <i class="material-icons md-light md-36 m-icon"> directions_run</i>
            <b *ngIf="!api.pinMsg">Submit Your Order</b>
            <b *ngIf="api.pinMsg"> Processing Your Order</b>
            <img
              *ngIf="api.pinMsg"
              style="width: 12%; padding-left: 10px"
              src="http://indismart.in/wp-content/uploads/2018/09/loaderimg.gif"
            />
          </button>
        </div>
        <div
          *ngIf="api.pinMsg"
          class="alert alert-success wrapper-center"
          role="alert"
        >
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
          <b class="payment-box">Processing Your Order!</b>
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
          <!-- <img  style = " width: 12%; padding-left: 10px;" src="http://indismart.in/wp-content/uploads/2018/09/loaderimg.gif" > -->
        </div>
      </div>
      <div class="wrapper-center" [hidden]="paymentSubmitted">
        <button
          mat-raised-button
          type="button"
          class="back-btn"
          [ngStyle]="{
            color: api.primaryColor,
            'border-color': api.primaryColor
          }"
          (click)="onBackInstPay()"
        >
          Back
        </button>
      </div>
    </mat-card>
  </div>
</div>

<!-- IF CREDIT is selcted, Tip  option is displayed  <div *ngIf =  "isCredit || api.customer.cash_eligible === 0" [hidden] = "payOnline"> -->

<div [hidden]="api.payOnline">
  <div *ngIf="api.isPinStored">
    <app-pin-set></app-pin-set>
    <div class="container wrapper animated fadeInDown">
      <button
        mat-raised-button
        type="button"
        class="back-btn"
        [ngStyle]="{
          color: api.primaryColor,
          'border-color': api.primaryColor
        }"
        (click)="onBackPin()"
      >
        Back
      </button>
    </div>
  </div>
  <div *ngIf="!api.isPinStored">
    <app-method></app-method>
    <app-checkout></app-checkout>
    <!-- <label class = "label-txt"for="pinA"> Tip:</label> -->
    <div class="wrapper">
      <div>
        <button
          class="tip-f-btn"
          id="tip-button-0"
          mat-stroked-button
          (click)="tip_1_button()"
        >
          Cash Tip
        </button>
      </div>
      <div #myDiv>
        <button
          class="tip-f-btn"
          id="tip-button-1"
          [ngStyle]="{
            color: api.isDefaultTip ? 'green' : 'grey',
            'font-size': api.isDefaultTip ? 'x-large' : 'medium',
            border: api.isDefaultTip ? '2px solid green' : '1px solid grey'
          }"
          mat-stroked-button
          (click)="tip_2_button()"
        >
          15%
        </button>
      </div>
      <div>
        <button
          class="tip-f-btn"
          id="tip-button-2"
          mat-stroked-button
          (click)="tip_3_button()"
        >
          20%
        </button>
      </div>
      <div>
        <button
          class="tip-f-btn"
          id="tip-other"
          mat-stroked-button
          (click)="tip_4_button()"
        >
          Custom
        </button>
      </div>
    </div>
    <span *ngIf="api.name != 'simplesimon'">
      <label class="label-txt" style="font-size: 14px; padding-bottom: 0px">
        Cash tipping for awesome service is always possible.</label
      ><br />
      <label class="label-txt" style="font-size: 14px; padding-top: 1px"
        >Credit card tipping can only be done here and now.</label
      >
    </span>
    <div *ngIf="api.tipError" class="alert alert-danger" role="alert">
      {{api.tipErrorMessage}}
    </div>
    <form #f="ngForm" (submit)="submit(f)">
      <div class="container input-group input-txt" style="padding: 10px">
        <div
          class="input-group-addon"
          style="
            background: white;
            font-size: large;
            font-weight: bold;
            padding-bottom: 0px;
            padding-top: 0px;
          "
        >
          Tip $
        </div>

        <div>
          <input
            matInput
            autocomplete="off"
            placeholder="Enter Tip Amount "
            maxlength="2"
            style="margin-top: 0px; font-size: large"
            value="{{ api.tip }}"
            id="custom-tip-box"
            type="number"
            class="form-control {{tipType=='custom_percentage'?'':'disabled'}}"
            aria-label="Default"
            aria-describedby="inputGroup-sizing-default"
            [(ngModel)]="api.tip"
            (keypress)="tipChecker($event)"
            name="tip_amt"
            #tip="ngModel"
          />
        </div>
      </div>

      <div class="container wrapper animated fadeInDown">
        <button
          mat-raised-button
          type="button"
          [ngStyle]="{
            color: api.primaryColor,
            'border-color': api.primaryColor
          }"
          class="back-btn"
          (click)="onBack()"
        >
          Back
        </button>

        <button
          mat-raised-button
          type="submit"
          class="send-btn animated fadeInDown"
          [disabled]="!f.valid"
        >
          Next
        </button>
      </div>
    </form>
  </div>
</div>
