<div *ngIf="!loadingPage">
</div>
<div *ngIf="loadingPage">
  <div *ngIf="api.isMobile ||api.isTablet " class="body">
    <div class="head">
      <h2 class="text-center rockwell black-c pT30 f-22 mB0">INTRODUCING</h2>
      <h1 class="text-center black-c rockwell-nova  text-head mB0">SAMMY'S PIZZA<sup>®</sup> TEXT <br> TO ORDER! </h1>
      <p class="text-center black-c myriad f-18" *ngIf="discountTxt !== '*'">{{discountTxt}}</p>
      <p class="limited black-c" style=" margin-bottom: 0px; padding-left: 2px; padding-bottom: 5px; font-size: 12px">
        <!-- <span *ngIf="discountTxt !== '*'">Limited Time Offer.</span> --> <span *ngIf="isShowService">
          Text Convenience Fee of $0.58 applies to all text orders. </span> <span class = "black-c"> Standard message and data rates also
          apply.</span></p>

    </div>
    <div class="desc">
      <div class="step-one">
        <span class="one">1</span>
        <span class="box-pos" style="top:10px!important">
          <p class="box-t1">TEXT US YOUR ORDER</p>
          <p class="box-t2">TEXT 'HELLO' TO {{api.formatPhone(api.menuRestId)}} [STORE PHONE]</p>
 
        </span>
      </div>
      <div class="step-two">
        <span class="two">2</span>
        <span class="box-pos">
          <p style="color: black;" class="box-t1">RECEIVE A REPLY</p>
          <p style="color: black;" class="box-t2">REVIEW YOUR ORDER</p>

        </span>
      </div>
      <div class="step-three">
        <span class="three">3</span>
        <span class="box-pos">
          <p class="box-t1">CONFIRM YOUR ORDER</p>

          <p class="box-t3">ENJOY YOUR PIZZA!</p>
        </span>
      </div>
    </div>
    <div class="footer">

      <div *ngIf = "!storeFinder" (click)="orderNowBtn()" style="height: 300px;" class="text-center pT30">
        <div class="start-order" style="background:#D02C30">
          <span style="font-size: 45px;" class="">START YOUR ORDER</span>

        <p style="font-size: 21px; margin-top:-12px;">At Sammy's - {{restNickname}}</p>
        </div>


      </div>

      <div  *ngIf = "storeFinder" (click)="storeFinderBtn()" style="height: 300px;" class="text-center pT30">
        <div class="start-order" style="background:#D02C30">
          <span style="font-size: 45px;" class="">FIND A STORE</span>

        <!--   <p style="font-size: 21px; margin-top:-12px;">At Jet's - {{restNickname}}</p> -->
        </div>


      </div>


      <!-- <img src="/assets/images/hand.png"> -->
    </div>
  </div>

  <div *ngIf="api.isDesktopDevice" class="body row">
    <div class="cust-size pR0">
      <div class="head">
        <h2 class="text-center rockwell-nova black-c pT30 f-36 mB0">INTRODUCING</h2>
        <h1 style="word-wrap: break-word;" class="text-center black-c rockwell-nova  text-head-desk mB0">
         SAMMY'S PIZZA<sup>®</sup>
          TEXT <br>TO ORDER!</h1>
        <p class="text-center black-c  myriad f-24" *ngIf="discountTxt !== '*'">{{discountTxt}}</p>


        <p class="limited f-12 black-c " style="padding-left: 2px; margin-bottom: 0px; padding-bottom: 5px;">
          <!-- <span *ngIf="discountTxt !== '*'">Limited Time Offer. </span> --> <span *ngIf="isShowService">
            Text convenience fee of $0.58 applies to all text orders.</span> <span class = "black-c"> Standard message and data rates also
            apply.</span></p>


      </div>
      <div class="desc">
        <div class="step-one row mL0 mR0 w100 scp-align " style="display: inline-block; min-height: 130px;">
          <div class="col-sm-1 text-center pT30 m-aut pL20">
            <span class="one-d f-36">1</span>
          </div>
          <div class="col-sm-11 pL20">
            <span class="box-pos-d " style="top:10px!important">

              <p class="box-t1 f-36 mT15">TEXT US YOUR ORDER</p>
              <p class="box-t3 f-24"> TEXT 'HELLO' TO {{api.formatPhone(api.menuRestId)}} [STORE PHONE]</p>
    
     
            </span>
          </div>
        </div>
        <div class="step-two row mL0 mR0 w100 scp-align" style="display: inline-block;  min-height: 125px;">
          <div class="col-sm-1 text-center m-aut pT30 pL20 ">
            <span class="two-d f-36 t-0">2</span>
          </div>
          <div class="col-sm-11 pL20 pT20">
            <span class="box-pos-d pT10 ">
              <p style="color: black;" class="box-t1 black-c f-36">RECEIVE A REPLY</p>
              <p style="color: black;" class="box-t2 black-c f-24">REVIEW YOUR ORDER</p>
            </span>
          </div>
        </div>
        <div class="step-three-nb row mL0 mR0 w100 scp-align" style="display: inline-block;  min-height: 125px;">
          <div class="col-sm-1 text-center m-aut pT30 pL20 ">
            <span class="three-d f-36 t-0">3</span>
          </div>
          <div class="col-sm-11 pL20 pT20">
            <span class="box-pos-d pT10">
              <p class="box-t1 f-36">CONFIRM YOUR ORDER</p>

              <p class="box-t3 f-24">ENJOY YOUR PIZZA!</p>
            </span>
          </div>
        </div>
      </div>
    </div>

    <div class="cust-size pL0 back-pic">

      <div *ngIf = "!storeFinder" (click)="orderNowBtn()" style="margin-top:15%" class="text-center pT30">
        <div class="start-order mL50 mR50" style="background:#D02C30">
          <span style="font-size: 45px;" class="">START YOUR ORDER</span>

          <p style="font-size: 21px; margin-top:-12px;">At Sammy's - {{restNickname}}</p>
        </div>


      </div>

      <div *ngIf = "storeFinder" (click)="storeFinderBtn()" style="margin-top:15%" class="text-center pT30">
        <div class="start-order mL50 mR50" style="background:#D02C30">
          <span style="font-size: 45px;" class="">FIND A STORE</span>
        </div>


      </div>
      <!-- <img src="/assets/images/hand.png"> -->
    </div>
  </div>

  <div class="black-bg">
    <div class="container pT5 pB5">
      <div class="row">
        <div class="col footer-txt">
          <div>
            <div class="footer-txt"> © {{year}} <b>OrdrAi</b></div>
          </div>
        </div>
        <div class="col">
          <div style="float: right">
            <a class="a-txt" target="_blank" rel="noopener noreferrer" href="/terms">Terms </a> | <a class="a-txt"
              target="_blank" rel="noopener noreferrer" href="/privacy"> Privacy</a>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>
<!--JETS Dynamic JEO PAGE-
<div class="flex-container-row" *ngIf = "restName == 'jets'">
  <mat-card>
        <div class="flex-container">
                <div class = "img-box-1 animated fadeInDown"> <img src="https://ordr-assets.s3.us-east-2.amazonaws.com/logos/logo_01.png"  class="img-fluid rounded"></div>               
            </div>

      <div>
            <h4 class = "txt-info" style = "color: #ea2127; font-weight:bold; text-align: center; font-size: 25px; padding: 10px;">
                     Introducing Text Ordering  </h4>
      </div>

      <div class="flex-container-row"> 
            <div > <h4 class = "txt-info" style = " text-align: center; font-size: 17px; ">Try it at our {{restNickname}}</h4></div>
            <div (click) = "orderNowBtn()"> <h4 class = "txt-info" style = " text-align: center; font-size: 24px; padding-top: 10px; font-weight: bold; color:#ea2127"> {{restDisplayNo}} </h4> </div>
          </div>
          <div class="flex-container">
                <div class = "img-box-1 animated fadeInDown"> <img src="https://ordr-assets.s3.us-east-2.amazonaws.com/logos/2_jetman.jpg"  class="img-fluid rounded" style = "width: 70%;"></div>               
            </div>

     
       <div>
         <div class="container">
             
                <div class="row" *ngIf = "!isMob"> 
                <div class="col" style ="padding-left: 0px;">
                    <img  src="https://ordr-assets.s3.us-east-2.amazonaws.com/logos/2_jetman.jpg" style="width: 100%; " >
                </div>
                <div class="col" style ="padding-left: 0px; padding-right:0px;">
                        <div class="flex-container-row"> 
                          <div > <h4 class = "txt-info" style = "font-size: 17px; padding-top:22px; margin: 0px; margin-top: 15px;"> Try it at our {{restNickname}}</h4></div>
                          <div> <h4 class = "txt-info" style = "font-size: 22px; padding-top: 10px; font-weight: bold; color:#ea2127"> {{restDisplayNo}} </h4> </div>
                        </div>
                    </div>
                </div>
            -->
<!--  <div  class="row" *ngIf = "!isMob" >
                        <div>
                                <img  src="https://ordr-assets.s3.us-east-2.amazonaws.com/logos/2_jetman.jpg" class="img-fluid rounded" >
                            </div>
                    </div>  -->
<!--
                

              
              </div> 
      </div> 
      <div><h4 class = "txt-info">
        Use it for pickup or delivery. You can get anything on our menu. For a limited time get a <span style = "color: #ea2127; font-weight:bold;">  20% discount </span> automatically applied just for trying this feature.</h4></div> 
        <div class="flex-container">
            <div class = "img-box-1 animated fadeInDown"> <img src="https://ordr-assets.s3.us-east-2.amazonaws.com/logos/Joe+Page+Image.png"  class="img-fluid rounded"></div>               
        </div>
     
         <div>
            <div><h4 class = "txt-info" style = "color: black; font-weight: bold;">
                   Step 1: Text your order to the store </h4></div> 

                   <div class="talk-bubble tri-right round left-in" style = "float: left;">
                        <div class="talktext">
                          <p class = "txt" style = "font-size: 20px; font-family: helvetica;">Hi Jets! I want Large Pepperoni Pizza and an order of BBQ Wings for pickup please!
                               </p>
                        </div>
                      </div>

                   

            <div><h4 class = "txt-info" style = "color: black; font-weight: bold;">
                    Step 2: Receive a reply </h4></div> 

                    <div class="talk-bubble tri-right round right-in" style = " width: 90%; background-color: #e5e5eb; color: black;">
                            <div class="talktext" >
                                    <mat-form-field class="example-full-width" style = "width:100%">
                                            <textarea 
                                              matInput
                                              readonly
                                              style = "line-height: 13px; font-size: 15px;" 
                                              value = {{response}}
                                              rows = "17" ></textarea>
                                          </mat-form-field>
                                   
                            </div>
                          </div>

                 

            <div><h4 class = "txt-info" style = "color: black; font-weight: bold;">
                    Step 3: Confirm your order and enjoy! </h4></div> 
                     
      </div>  
      <div>
          <hr>

          <h5 class = "button-row offer-body">The	Fastest	Way	to	Order	Jet’s!</h5>
          <h6 class = "button-row offer-sub-info">GET 20% OFF MENU PRICE FOR ALL TEXT ORDERS</h6>
          <h6 class = "coupon-txt" style = "margin: 0px; font-size: 13px; font-family: Arial, Helvetica, sans-serif"><i>Limited	Time Offer.</i></h6>
          <h6 class = "coupon-txt" style = "margin: 0px; font-size: 13px; font-family: Arial, Helvetica, sans-serif"><i>Standard message and data rates apply.</i></h6>
        <div style = "padding: 7px;">
           <span style = "float: right;"> <a href="https://s3.us-east-2.amazonaws.com/ordr-assets/video/test.mp4">Video	Tutorial</a> </span> 
        </div>
      </div>
      <div *ngIf = "!isMob">
            <app-order-now (click) = "orderNowBtn()"> </app-order-now>
        </div>
      <app-footer></app-footer>
      <div class="container-fluid bottom" style = "display: flex; justify-content: center;"  >
          <div class= "row">
              <div *ngIf = "isMob">
                    <div class="col center-div wrapper"  >
                            <app-order-now (click) = "orderNowBtn()">
                               </app-order-now>
                          </div> 
              </div>

              <div *ngIf = "!isMob">
                     <div class="col center-div wrapper" style = "padding-left: 0px; padding-left: 150px;">
                            <app-order-now (click) = "orderNowBtn()">
                               </app-order-now>
                          </div>  
              </div>
               
          </div>
      </div>
      
  </mat-card>  
  </div>
-->