import { ProServiceService } from "./../pro-order/pro-service.service";
import { MethodChangeDialogComponent } from "./../method-change-dialog/method-change-dialog.component";
import { MenuDialogComponent } from "./../menu-dialog/menu-dialog.component";
import { Component, OnInit } from "@angular/core";
import { APICenterService } from "../apicenter.service";
import { MatDialog } from "@angular/material/dialog";
import { Subscription } from "rxjs";
import { LoginPopupComponent } from "../login-popup/login-popup.component";

@Component({
  selector: "app-method",
  templateUrl: "./method.component.html",
  styleUrls: ["./method.component.css"],
})
export class MethodComponent implements OnInit {
  isPickup: Boolean;
  storeName = " ";
  address: string;
  method: string;
  inContactState: boolean;
  onChange = false;
  subscription: Subscription;
  onUpdateMethod = false;
  restName = "";
  punchhSignInBtn = true;
  constructor(
    private pro: ProServiceService,
    public api: APICenterService,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    // console.log('@ method');
    let name = this.api.restaurant.name.replace("'", "").toLowerCase();
    this.restName = name;
    if (this.api.isPaid || name == "smoothieking" || name == "smoothie king") {
      this.api.isMethodDisplay = true;
    }
    if (this.restName === "jets" && this.api.isProOrder == true) {
      //this.punchhSignIn();
    }
    let username = localStorage.getItem("punchUserName");
    let password = localStorage.getItem("punchPassword");
    if (
      username != "" &&
      username != null &&
      username != undefined &&
      password != "" &&
      password != null &&
      password != undefined
    ) {
      this.punchhSignInBtn = false;
    } else {
      this.punchhSignInBtn = true;
    }
  }
  punchSignout() {
    this.api.punchPassword = "";
    this.api.punchUserName = "";
    localStorage.setItem("punchUserName", "");
    localStorage.setItem("punchPassword", "");
    let username = localStorage.getItem("punchUserName");
    let password = localStorage.getItem("punchPassword");
    if (
      username != "" &&
      username != null &&
      username != undefined &&
      password != "" &&
      password != null &&
      password != undefined
    ) {
      this.punchhSignInBtn = false;
    } else {
      this.punchhSignInBtn = true;
    }
    this.api.isLoading = true;
    this.api.onSend=true;
    this.api.resetItems();
    this.api.updateUserMsg();
    this.api.getsummaryData().subscribe((data) => {
      this.api.isLoading = true;
      this.api.onSend=false;
      console.log(data);
    });
  }
  punchhSignIn() {
    this.deviceDetect();
    let width;
    if(this.pro.mobile){
    width='99%'
    }
    else{
      width='40%';
    }
    const dialogRef = this.dialog.open(LoginPopupComponent, {
      width:width,
      disableClose: true,
      maxWidth: "unset",
    });

    dialogRef.afterClosed().subscribe(() => {
      // console.log('The dialog was closed');
    });
  }
  deviceDetect() {
    const isMobile = /iPhone|Android/i.test(navigator.userAgent);
    if (this.api.restaurant.rid == "+15512911234"){
      this.pro.mobile = true;
    } else {
      if (isMobile) {
        this.pro.mobile = true;
      } else {
        this.pro.mobile = false;
      }
    }
    
  }
  onMethodChange() {
    this.api.isFirstLoad = false;
    this.api.currentState = {
      viewState: true,
      isContact: false,
      isTip: false,
      payatStore: false,
      payOption: false,
      payOnline: false,
      isPinStored: false,
      isBt: false,
      isBraintree: false,
    };
    this.api.clickValue = "Edit-MethodBar";
    this.api.clickAnalytics();
    this.api.actionbutton("delivery_method_change", "MS001");
    this.api.isDeleteBtn = true;
    this.api.isEditBtn = true;
    this.api.isAddItemBox = true;
    // // console.log("changed is clicked")
    this.api.isEdit = false;
    this.api.isState_0 = false;
    this.api.isBt = false;
    this.api.isSuccess = false;
    this.api.isTip = false;
    this.api.isContact = false;
    this.api.checkoutAllergy = false;
  }

  onEdit() {
    this.api.isFirstLoad = false;
    this.api.isSwapMethod = true;
    this.api.isMethodChanged = true;
    this.api.actionbutton("edit_method", "MS004");
    this.onUpdateMethod = true;
    // // console.log('edit address');
    this.api.clickValue = "Edit Address";
    this.api.clickAnalytics();
    // // console.log('method is PICKUP - OPEN Dialog box');
    this.openDialog();
  }

  changeMethod() {
    this.api.isFirstLoad = false;
    this.api.isSwapMethod = true;
    this.api.isMethodChanged = true;
    this.api.actionbutton("delivery_method_change", "MS001");
    this.onUpdateMethod = true;
    // console.log('change method');
    this.api.clickValue = "Change Method";
    this.api.clickAnalytics();
    if (this.api.method.mtype.toLowerCase() === "pickup") {
      // TO delivery order ***************
      // console.log('method is PICKUP - OPEN Dialog box');
      this.openDialog();
      // this.onChange = true;
    } else {
      // console.log('method is DELIVERY - SWAP to PICKUP');
      this.pro.onBackObj.isPickup = true;
      if (this.api.isContactFree) {
        if (this.api.isFutureOrder) {
          this.api.customer.msg_text = `${this.api.customer.msg_text} at ${this.api.method.fOrder}, ${this.api.contactLessTxt}`;
        } else {
          this.api.customer.msg_text = `${this.api.customer.msg_text}, ${this.api.contactLessTxt}`;
        }
      } else {
        if (this.api.isFutureOrder) {
          this.api.customer.msg_text = `${this.api.customer.msg_text} at ${this.api.method.fOrder}`;
        } else {
          this.api.customer.msg_text = `${this.api.customer.msg_text}`;
        }
      }
      this.api.resetItems();
      this.api.onSend = true;
      this.api.updateUserMsg();
      /* this.subscription = this.api.updatePickup()
          .subscribe((x) => {
              this.api.ispickupValidator();
              if (this.api.keywordText !== '-1' || this.api.errorText !== '-1' || this.api.irrelevantText !== '-1') {
                this.api.isDeleteBtn = true;
                this.api.isEditBtn = true;
                this.api.isAddItemBox = true;
                this.api.isState_0 = false;
                this.api.isContact = false;
                this.api.isBt = false;
                this.api.isTip = false;
              }
              const objTest = {
                'contact' : this.api.isContact,
                'isTip' : this.api.isTip,
                'payatStore' : this.api.payatStore,
                'payOption' : this.api.payOption,
                'payOnline' : this.api.payOnline,
                'isPinStored' : this.api.isPinStored,
                'isBt' : this.api.isBt,
                'isBraintree' : this.api.isBraintree
              };


              // console.log(objTest);
              if (!this.api.currentState.isContact && !this.api.currentState.isTip && !this.api.currentState.isBt) {
                // view state
                this.api.viewState();
              }

              if (this.api.isContact) {
                // Name State
                console.log('Name state');
                this.api.nameState();
              }

              if (!this.api.isTip && !this.api.payatStore && this.api.payOption && this.api.payOnline ) {
                // Pay in store
                console.log('Pay in store');
                if (x.customer.isPayAtStore) {
                  // If paystore option available
                  this.api.payAtStoreState();
                } else {
                  if (this.api.pinState === '0') {
                    // New Customer
                    this.api.setPasswordState();
                  } else {
                    // existing customer
                    if (x.restaurant.isShowTipState) {
                      this.api.tipState();
                    } else {
                      this.api.pinPaymentState();
                    }
                  }

                }
              }

          if (!this.api.isTip && this.api.payatStore && !this.api.payOption && this.api.payOnline && !this.api.isPinStored ) {
                console.log('Pay Options tab');
                if (x.customer.isPayAtStore) {
                  // pay option tab
                  this.api.payOptionState();
                } else {
                  // Only Online order
                  if (x.restaurant.isShowTipState) {
                    if (this.api.pinState === '0') {
                      // New customer
                    this.api.setPasswordState();
                    } else {
                        // Existing Customer
                        this.api.tipState();
                    }
                  } else  {
                    this.api.nameState();
                  }
                }

              }

          if (!this.api.isTip && this.api.payatStore && this.api.payOption && !this.api.payOnline && !this.api.isPinStored ) {
                // Tip state
                console.log('Tip state');
                            // Pickup Order
                  if (x.restaurant.isShowTipState) {
                    // show tip state - Land Tip page
                    this.api.tipState();

                  } else {
                    // No tip state - land in view state
                    this.api.viewState();
                  }
              }

          if (!this.api.isTip && this.api.payatStore && this.api.payOption && !this.api.payOnline && this.api.isPinStored ) {
              // Set PIN/Password
              console.log('Set PIN or Password');
              this.api.setPasswordState();
              }

              if (this.api.currentState.isBt &&  this.api.isBraintree) {
                // PIN/Password Payment
                console.log(' PIN/Password Payment');
                if (x.restaurant.isShowTipState) {
                  this.api.defaultTip();
                  this.api.pinPaymentState();
                } else {
                  this.api.pinPaymentState();
                }
              }


              if (!this.api.isBt &&  !this.api.isBraintree) {
                // PIN/Password Payment
                console.log(' BT Payment');
                if (x.restaurant.isShowTipState) {
                  this.api.defaultTip();
                  this.api.cardPaymentState();
                } else {
                  this.api.cardPaymentState();
                }
              }
              this.api.resetCurrentState();
            }); */
    }

    /*this.api.isDeleteBtn = true;
    this.api.isEditBtn = true;
    this.api.isAddItemBox = true;
    console.log('change is clicked')
    this.api.isEdit = true;
    this.api.isState_0 = false;
    this.api.isBt = false;
    this.api.isSuccess = false;
    this.api.isTip = false;
    this.api.isContact = false;*/
  }

  openDialog(): void {
    this.api.clickValue = "Method Change";
    this.api.clickAnalytics();
    this.api.isMethodswap = true;
    const dialogRef = this.dialog.open(MethodChangeDialogComponent, {
      width: "90%",
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(() => {
      // console.log('The dialog was closed');
    });
  }
 
  openDialogMenu(): void {
    this.api.clickValue = "Menu";
    this.api.clickAnalytics();
    this.api.actionbutton("menu", "VS001");
    const dialogRef = this.dialog.open(MenuDialogComponent, {});

    dialogRef.afterClosed().subscribe((result) => {
      // console.log('The dialog was closed');
      this.api.actionbutton("menu_closed", "VS002");
    });
  }

  updateAddy() {}
  cancelAddy() {
    this.onChange = false;
  }
}
