  <div *ngIf = "!api.isMethodswap" class="">
    <div class="">
      <input (blur)="blur()" (focus)="focus()" style = "width:70%;display: inline;"
        class="form-control txt-box mL5" 
        placeholder="Enter your address" 
        [(ngModel)]="autocompleteInput"
        type="text"
        #addresstext>
      <input (blur)="blur()" (focus)="focus()" style = "width: 25.5%;display: inline; margin-left: 8px;"class="form-control  txt-box mL10" placeholder="Apt" (blur)="setapt()" type="text" [(ngModel)]="apt">
      </div> 
    </div>
      <div *ngIf = "api.isMethodswap" class="">
        <div>
          <p class = "title">Change Your Method to Delivery </p>
        </div>
        <hr style = "margin: 5px;">
        <div style = "padding: 10px 0px 5px">
          <input 
          class="form-control txt-box " 
          placeholder="Enter your address" 
          [(ngModel)]="autocompleteInput"
          type="text"
          #addresstext>
        </div>
        <div style = "padding: 5px 0px 10px ">
          <input style= "width: 50%; margin-left: 0px !important;"class="form-control  txt-box mL10" placeholder="Apt" (blur)="setapt()" type="text" [(ngModel)]="apt">
        </div>   
      </div> 