<div *ngIf="show">
<app-blaze [restMenuId]="restMenuId" *ngIf="restName=='blaze'"></app-blaze>
<app-hungryhowies [restMenuId]="restMenuId" *ngIf="restName=='hungryhowies'"></app-hungryhowies>
<app-jets [restMenuId]="restMenuId" *ngIf="restName=='jets'"></app-jets>
<app-romeo [restMenuId]="restMenuId" *ngIf="restName=='romeos'"></app-romeo>
<app-marcos [restMenuId]="restMenuId" *ngIf="restName=='marcos'"></app-marcos>
<app-master [restMenuId]="restMenuId" *ngIf="restName=='master'"></app-master>

<app-centercourt [restMenuId]="restMenuId" *ngIf="restName=='centercourt'"></app-centercourt>
<app-coolrunnings [restMenuId]="restMenuId" *ngIf="restName=='coolrunnings'"></app-coolrunnings>
<app-eastofchicago [restMenuId]="restMenuId" *ngIf="restName=='eastofchicago'"></app-eastofchicago>
<app-gattispizza [restMenuId]="restMenuId" *ngIf="restName=='gattis'"></app-gattispizza>
<app-pizzaworldusa [restMenuId]="restMenuId" *ngIf="restName=='pizzaworld'"></app-pizzaworldusa>
<app-hungerrush [restMenuId]="restMenuId" *ngIf="restName=='hungerrush'"></app-hungerrush>
<app-smoothie-king [restMenuId]="restMenuId" *ngIf="restName=='smoothie king'"></app-smoothie-king>
<app-wayback-burger [restMenuId]="restMenuId" *ngIf="restName=='wayback'"></app-wayback-burger>
<app-sammys-pizza [restMenuId]="restMenuId" *ngIf="restName=='sammys'"></app-sammys-pizza>
<app-gionios [restMenuId]="restMenuId" *ngIf="restName=='gioninos'"></app-gionios>
<app-bc-pizza [restMenuId]="restMenuId" *ngIf="restName=='bcpizza'"></app-bc-pizza>
<app-free-birds [restMenuId]="restMenuId" *ngIf="restName=='freebirds'"></app-free-birds>
<app-mannyandolgas [restMenuId]="restMenuId" *ngIf="restName=='MannyOlgas' || restName=='mannyolgas' "></app-mannyandolgas>
<app-giovannispizzapower [restMenuId]="restMenuId" *ngIf="restName=='giovannis'"></app-giovannispizzapower>
<app-wings-to-go [restMenuId]="restMenuId" *ngIf="restName=='wingstogo'"></app-wings-to-go>
<app-extreme-pizza [restMenuId]="restMenuId" *ngIf="restName=='extreme'"></app-extreme-pizza>
<app-black-jack [restMenuId]="restMenuId" *ngIf="restName=='blackjack'"></app-black-jack>
<app-chowly [restMenuId]="restMenuId" *ngIf="restName=='chowly'"></app-chowly>
<app-simple-simons [restMenuId]="restMenuId" *ngIf="restName=='simplesimon'"></app-simple-simons>
<app-little-caesars [restMenuId]="restMenuId" *ngIf="restName=='littlecaesars'"></app-little-caesars>
<app-fajita-petes  [restMenuId]="restMenuId" *ngIf="restName=='fajitapetes'"></app-fajita-petes>
<app-defilice-pizza [restMenuId]="restMenuId" *ngIf="restName=='defelicebros'"></app-defilice-pizza>
<app-paparomanos [restMenuId]="restMenuId" *ngIf="restName=='paparomanos'"></app-paparomanos>
<app-pizza-cottage [restMenuId]="restMenuId" *ngIf="restName=='pizzacottage'"></app-pizza-cottage>
<app-joseppis-pizza [restMenuId]="restMenuId" *ngIf="restName=='joseppispizza'"></app-joseppis-pizza>
<app-northern-lights [restMenuId]="restMenuId" *ngIf="restName=='northernlights'"></app-northern-lights>
</div>
