export class chowlyStore {
  constructor() {}

  findStore(sid) {
    switch (sid) {
      case "ch01":
        let sidInfo = {
          discountTxt: "FOR A LIMITED TIME GET 20% OFF ON ALL PIZZAS",
          isPro: false,
          isServiceFee: false,
          successTxt: "TEXT ORDERS ARE 20% OFF ON ALL PIZZAS",
          successPro: "PRO ORDERS ARE 20% OFF ON ALL PIZZAS",
          isTxt: true,
          rid: "+15178528905",
          nickName: "6575 Sugarloaf Pkwy location in Duluth",
          addy1: "Sugarloaf Pkwy",
          addy2: "Duluth, GA",
        };
        return sidInfo;
      case "ch02":
         sidInfo = {
          discountTxt: "FOR A LIMITED TIME GET 20% OFF ON ALL PIZZAS",
          isPro: false,
          isServiceFee: false,
          successTxt: "TEXT ORDERS ARE 20% OFF ON ALL PIZZAS",
          successPro: "PRO ORDERS ARE 20% OFF ON ALL PIZZAS",
          isTxt: true,
          rid: "+15179941884",
          nickName: "6575 Sugarloaf Pkwy location in Duluth",
          addy1: "Sugarloaf Pkwy",
          addy2: "Duluth, GA",
        };
        return sidInfo;

      default:
        sidInfo = {
          discountTxt: "*",
          isPro: false,
          isServiceFee: false,
          successTxt: "Texting is the fastest way to order Jet’s!",
          successPro: "PRO ORDERS ARE 20% OFF ON ALL PIZZAS",
          isTxt: true,
          rid: "empty",
          nickName: "empty",
          addy1: "empty",
          addy2: "empty",
        };
        return sidInfo;
    }
    // console.log(cust)
  }
}
