<div class="outer-box">
    <mat-card style="padding: 12px;">
        <p *ngIf="!api.quickOrder.isQuickName" class="text-2" [ngStyle]="{'color':api.primaryColor}"
            style="font-weight: bold;  text-align:  center"> Order confirmed. We will be texting you an ETA shortly.
            Thank You!</p>
        <p *ngIf="api.quickOrder.isQuickName && isProOrder && isTextOrder" class="text-2"
            [ngStyle]="{'color':api.primaryColor}" style="font-weight: bold;  text-align:  center"> Order confirmed. We
            will be texting you an ETA shortly. Thank You!</p>
        <p *ngIf="api.quickOrder.isQuickName && isProOrder && !isTextOrder" class="text-2"
            [ngStyle]="{'color':api.primaryColor}" style="font-weight: bold;  text-align:  center">Order Confirmed. Your
            ETA is approximately {{api.ETA}} minutes. Thank You!</p>
        <i class="material-icons m-icon" [ngStyle]="{'color':api.primaryColor}" style=" font-size: 75px;">
            check_circle
        </i>
    </mat-card>
</div>
<div class="outer-box" *ngIf = "api.isOnlyPayment">
    <mat-card style="padding: 12px;">
        <app-pin-set></app-pin-set>
        </mat-card>
    </div>
<!-- <div class = "outer-box">
<mat-card style = "padding: 10px;">
    <p class = "text-5">Your Feedback is valuable</p>
    <p class = "text-5">How would you rate your experience?</p> 
<div class="flex-container">
    <div class = "img-box-1 animated fadeInDown" (click) = "verySad()"  ><img src="https://s3.us-east-2.amazonaws.com/ordr-assets/emoji/0.png" class="img-thumbnail"></div>
    <div class = "img-box-1 animated fadeInDown" (click) =  "ok()"  > <img src="https://s3.us-east-2.amazonaws.com/ordr-assets/emoji/2.png"  class="img-thumbnail"></div>
    <div class = "img-box-1 animated fadeInDown" (click) = "happy()"  > <img src="https://s3.us-east-2.amazonaws.com/ordr-assets/emoji/grining.png"  class="img-thumbnail"></div>
    <div class = "img-box-1 animated fadeInDown" (click) = "love()" > <img src="https://s3.us-east-2.amazonaws.com/ordr-assets/emoji/4.png"  class="img-thumbnail"></div>
   
</div>
<p class = "text-5" style = "font-size: large"> {{msg}}</p>

<div *ngIf = "showSubmit">
        <div class="flex-container" *ngIf =  "!isScaleSub" style = "padding-top: 15px;">
                <button class = "back-btn"  mat-raised-button color="primary" (click) = "scaleSubmit()"> Submit </button>
          </div>
          
          <p  *ngIf =  "isScaleSub" class = "text-5" style = "font-size: large"> Thank you!</p>
</div>

</mat-card>
</div> -->
<!--
<div class = "outer-box">
<mat-card style = "padding: 10px;">
    <p class = "text-5">Your Feedback is valuable</p>
    <p class = "text-5">How would you rate your experience?</p> 
    <div class="flex-container">
    
                <app-rating-stars></app-rating-stars>
     
   
    </div>
    <div *ngIf = "showSubmit">
            <div class="flex-container" *ngIf =  "!isScaleSub" style = "padding-top: 15px;">
                    <button class = "back-btn"  mat-raised-button color="primary" (click) = "scaleSubmit()"> Submit </button>
              </div>
              
              <p  *ngIf =  "isScaleSub" class = "text-5" style = "font-size: large"> Thank you!</p>
    </div>
 

</mat-card>
</div> -->

<div class="outer-box"  *ngIf = "!api.isOnlyPayment">
    <mat-card style="padding: 10px;">
        <!-- <p class = "text-5">Your Feedback is valuable</p> -->
        <p *ngIf="!api.quickOrder.isQuickName" class="text-5" style="text-align: center">How likely are you to recommend
            text ordering to a friend?</p>
        <p *ngIf="api.quickOrder.isQuickName" class="text-5" style="text-align: center">How likely are you to recommend
            pro ordering to a friend?</p>
        <div class="flex-container" style="padding-bottom: 0px;">
            <!--  <div 
                class = "scale"
                (click) = "onZero('0')"
                [ngStyle]="{'background-color':isZero ? '#000000' : '#ffffff',
                            'color': isZero ? '#ffffff' : '#000000'  }" >0</div> -->
            <div class="scale" (click)="onZero('1')" [ngStyle]="{'background-color':isOne ? '#000000' : '#ffffff',
                            'color': isOne ? '#ffffff' : '#000000'  }">01</div>
            <div class="scale" (click)="onZero('2')" [ngStyle]="{'background-color':isTwo ? '#000000' : '#ffffff',
                                'color': isTwo ? '#ffffff' : '#000000'  }">02</div>
            <div class="scale" (click)="onZero('3')" [ngStyle]="{'background-color':isThree ? '#000000' : '#ffffff',
                            'color': isThree ? '#ffffff' : '#000000'  }">03</div>
            <div class="scale" (click)="onZero('4')" [ngStyle]="{'background-color':isFour ? '#000000' : '#ffffff',
                        'color': isFour ? '#ffffff' : '#000000'  }">04</div>
            <div class="scale" (click)="onZero('5')" [ngStyle]="{'background-color':isFive ? '#000000' : '#ffffff',
                    'color': isFive ? '#ffffff' : '#000000'  }">05</div>
        </div>
        <div class="flex-container">
            <div class="scale" (click)="onZero('6')" [ngStyle]="{'background-color':isSix ? '#000000' : '#ffffff',
                    'color': isSix ? '#ffffff' : '#000000'  }">06</div>
            <div class="scale" (click)="onZero('7')" [ngStyle]="{'background-color':isSeven ? '#000000' : '#ffffff',
                'color': isSeven ? '#ffffff' : '#000000'  }">07</div>
            <div class="scale" (click)="onZero('8')" [ngStyle]="{'background-color':isEight ? '#000000' : '#ffffff',
                    'color': isEight ? '#ffffff' : '#000000'  }">08</div>
            <div class="scale" (click)="onZero('9')" [ngStyle]="{'background-color':isNine ? '#000000' : '#ffffff',
                    'color': isNine ? '#ffffff' : '#000000'  }">09</div>
            <div class="scale" (click)="onZero('0')" [ngStyle]="{'background-color':isZero ? '#000000' : '#ffffff',
                                'color': isZero ? '#ffffff' : '#000000'  }">10</div>
        </div>
        <div *ngIf="showSubmit1">
            <div class="flex-container" *ngIf="!isScaleSub" style="padding-top: 15px;">
                <button class="back-btn" mat-raised-button
                    [ngStyle]="{'background-color':api.primaryColor, 'border-color' : api.primaryColor}" color="primary"
                    style="color: white" (click)="scaleSubmit()"> Submit </button>
            </div>

            <p *ngIf="isScaleSub" class="text-5" style="font-size: large"> Thank you!</p>
        </div>


    </mat-card>
</div>

<div class="outer-box"  *ngIf = "!api.isOnlyPayment">
    <mat-card>
        <!--  <p class= "text-2" style = "font-weight: bold; text-align: center">Spread the Word  </p>   -->
        <!--  <p class= "text-2" style = "font-weight: bold; text-align: center; font-size: 15px; text-transform: uppercase;"> {{api.restaurant.futureMktDiscounts}}</p> -->
        <p *ngIf="!api.quickOrder.isQuickName" class="text-2"
            style="font-weight: bold; text-align: center; font-size: 20px; text-transform: uppercase;">{{successTxt}}
        </p>
        <p *ngIf="api.quickOrder.isQuickName" class="text-2"
            style="font-weight: bold; text-align: center; font-size: 20px; text-transform: uppercase;">{{successPro}}
        </p>
        <p class="text-2" style="font-weight: bold; text-align: center; font-size: 10px;" *ngIf="discountTxt !== '*'">
            Limited Time Only</p>
        <div class="wrapper-center" *ngIf = "name == 'jets'">
            <app-refer-friend (click)="referFriend()"></app-refer-friend>
        </div>
    </mat-card>
</div>
<!--<div class = "outer-box">
    <mat-card style = "padding: 20px;"> 
     <p class= "text-2" style = "font-weight: bold; text-align: center">Help us spread the word!  </p>  
     <p class= "text-2" style = "font-weight: bold; text-align: center"> {{api.restaurant.refFrndDiscount}} </p> 
     <p class= "text-2" style = " font-size: 10px;font-weight: bold; text-align: center"> *conditions apply*  </p> 
     <div class = "wrapper-center">
            <app-refer-friend (click) = "referFriend()"></app-refer-friend>
     </div>
    </mat-card>
     
    </div> -->
<div class="outer-box">
    <mat-card>
        <p class="text-5" style="text-align: center"> For questions about your data, email info@ordrai.com or call (415)
            903-4020.</p>
    </mat-card>
</div>

<div class="outer-box">
    <mat-accordion>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title class="text-2" style="font-weight: bold; font-size: 16px;">
                    View Receipt
                </mat-panel-title>
            </mat-expansion-panel-header>
            <app-summary></app-summary>
            <div *ngIf="api.isSplInst">
                <h4 style="font-size: 17px;
        margin:0px;
        font-family: helvetica;
        padding-bottom: 17px;
        font-weight: 400;"> Special Instructions: "{{api.splInstText}}"
                </h4>
                </div>
        </mat-expansion-panel>
    </mat-accordion>
</div>
<div class="outer-box">
    <app-method></app-method>
</div>


<!--
<div class = "outer-box">
    <mat-card style = "padding: 20px;">
    <p class= "text-3"> To Make Change </p>
      <p class= "text-3"> Call at   <a style = "padding-left: 5px;" href="tel:{{api.restaurant.rid}}" class="Blondie"> (734) 728-6600  </a> </p>
    </mat-card>
</div>


<div class = "outer-box">
    <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title class= "text-2" style = "font-weight: bold; font-size: 16px;">
              View Your Detailed Order Receipt
            </mat-panel-title>
          </mat-expansion-panel-header>
         <mat-card>
           <app-summary></app-summary>
         </mat-card>

        </mat-expansion-panel>

      </mat-accordion>
    </div>
-->
<!---
<div class = "outer-box">
    <mat-card>
        <p class = "text-5">Your Feedback is valuable</p>
        <p class = "text-5">How would you rate your experience</p>
       
        <div class="container">
            <div class="row">
              <div class="col-4 wrapper"  (click) = "verySad()">
                  <img id = "verySad" alt="happy" src=" https://s3.us-east-2.amazonaws.com/ordr-assets/emoji/0.png" style="width:40%" >

              </div>
              <div class="col-4 wrapper" (click) =  "ok()">
                  <img id ="ok" src="https://s3.us-east-2.amazonaws.com/ordr-assets/emoji/2.png" style="width:40%" >

              </div>
              <div class="col-4 wrapper" (click) = "love()">
                  <img id = "love" alt="happy" src=" https://s3.us-east-2.amazonaws.com/ordr-assets/emoji/4.png" style="width:40%" >

              </div>
            </div>
          </div>
        -->
<!--
        <div class = "wrapper">
            <div class = "wrapper" (click) = "verySad()">
              <img id = "verySad" alt="happy" src=" https://s3.us-east-2.amazonaws.com/ordr-assets/emoji/0.png" style="width:50%" >
            </div> -->
<!-- <div (click) = "sad()">
                <img id ="sad" src="https://s3.us-east-2.amazonaws.com/ordr-assets/emoji/2.png" style="width: 85%" >
              </div> -->
<!--
              <div class = "wrapper" (click) =  "ok()">
                  <img id ="ok" src="https://s3.us-east-2.amazonaws.com/ordr-assets/emoji/2.png" style="width:50%" >
                </div> -->
<!--  <div  (click) = "happy()">
                    <img id = "happy" alt="happy" src="https://s3.us-east-2.amazonaws.com/ordr-assets/emoji/grining.png" style="width: 85%" >
                  </div> -->
<!--
                  <div class = "wrapper" (click) = "love()">
                      <img id = "love" alt="happy" src=" https://s3.us-east-2.amazonaws.com/ordr-assets/emoji/4.png" style="width:50%" >
                    </div>

        </div> -->
<!---
        <p class = "text-5" style = "font-size: large"> {{msg}}</p>
        <div class="wrapper-center" *ngIf = "isEmojiSelected">
            <button mat-raised-button color="primary" (click) = "onEmojiSubmitk()"> Submit </button>
            </div>
    </mat-card>
</div> -->



<!--

<div class = "outer-box">
    <mat-card style = "padding: 0px;" >
      <div *ngIf = "!isFeedback">
          <i class="material-icons  m-icon">
              speaker_notes
              </i>
          <div class = "text-box">
              <div class="input-group">
                  <textarea
                    class="form-control"
                    rows= "4"
                    placeholder="Your feedback is highly appreciated!"
                    aria-label="With textarea"></textarea>
                </div>
          </div>
          <div class="wrapper-center">
              <button mat-raised-button color="primary" (click) = "onFeedback()"> Submit </button>
              </div>
      </div>
       <div *ngIf = "isFeedback">
         <p class = "text-5" style = "font-size: 20px;"> Thanks for your Feedback !!!</p>
       </div>
    </mat-card>
</div>

-->
<!--

<mat-accordion>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title class= "text-2" style = "font-weight: bold; font-size: 16px;">
        Leave us your feedback
        </mat-panel-title>
      </mat-expansion-panel-header>
     <mat-card>
        <div class = "outer-box">

              <div *ngIf = "!isFeedback">
                  <div class = "text-box">
                      <div class="input-group">
                          <textarea
                            class="form-control"
                            rows= "4"
                            [(ngModel)] = "api.fbUserText"
                            placeholder="Your feedback is highly appreciated!"
                            aria-label="With textarea"></textarea>
                        </div>
                  </div>
                  <div class="wrapper-center">
                      <button mat-raised-button color="primary" (click) = "onFeedback()"> Submit </button>
                      </div>
              </div>
               <div *ngIf = "isFeedback">
                 <p class = "text-5" style = "font-size: 20px;"> Thanks for the Feedback !!!</p>
               </div>
        </div>
     </mat-card>

    </mat-expansion-panel>

  </mat-accordion> -->