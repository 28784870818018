import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ProServiceService {
  mobile: boolean;
  isProBack: boolean;
  emptySpace=false;
  stickyHead=false;
  isKeyBoard=false;
  onBackObj = {
    isPickup: false,
    isDelivery: false,
    part1: null,
    part2: null,
    custMsg: null,
    custAPt: null,
    custAddy: null
  };
  selectedSID: string;
  constructor() { }
}
