<div class="heighttoIframe">
  <div
    *ngIf="collapsed"
    (click)="
      sendHeightUpdate();
      collapsed = false;
      hovered = false;
      scrollToTarget(this.message_data.length - 1)
    "
    class="img-right pointer"
  >
    <!--<img
      class="disp-inline avatar"
      (mouseover)="(hovered)"
      width="50px"
      src="assets/images/demo/jetsman.png"
    /> -->
    <div
      (mouseover)="hovered = true"
      (mouseleave)="hovered = false"
      class="btn-container"
      style="background: none"
    >
      <button class="hbutton pointer">
        <span class="text mR10">Chat to Order</span>
        <div class="icon-container">
          <div class="icon icon--right">
            <img
              style="position: absolute; top: -15px"
              class="disp-inline avatar {{ hovered ? 'land' : 'land1' }}  "
              width="50px"
              src="assets/images/demo/jetsman.png"
            />
          </div>
        </div>
      </button>
    </div>
  </div>

  <div #heightElement *ngIf="!collapsed" class="chat-popup">
    <div class="chat-header jets-green">
      OrderAI Chat ( &beta;-version)
      <span
        class="fRight mR20 pointer"
        (click)="collapsed = true; sendHeightUpdateCollapsed()"
        ><i class="fa fa-chevron-down" aria-hidden="true"></i
      ></span>
    </div>
    <div></div>
    <div class="chat-body">
      <div
        class="text-center"
        style="position: relative; top: 88%"
        *ngIf="landing"
      >
        <button
          (click)="this.landing = false; log()"
          class="bord jets-green w80 mB20"
        >
          Chat with OrderAI
        </button>
      </div>
      <div *ngIf="!landing">
        <div class="message-box p-10">
          <div
            id="scroll"
            style="padding-bottom: 40%; position: relative"
            *ngIf="message_data.length > 0"
          >
            <div
              #messageContainer
              *ngFor="let msg of message_data; index as i"
              class="bub-box"
              [id]="i"
            >
              <div class="">
                <img
                  style="vertical-align: top; padding-top: 10px"
                  *ngIf="msg.msg_Cust != '*'"
                  class="disp-inline avatar"
                  width="8%"
                  height="8%"
                  src="assets/images/demo/jetsman.png"
                />
                <div
                  *ngIf="msg.msg_Cust != '*'"
                  [ngClass]="{
                    'no-bg': msg.msg_Cust.toLowerCase().includes('loading')
                  }"
                  class="talk-bubble left-in disp-inline {{
                    msg.msg_Cust.toLowerCase().includes('your name please?') ||
                    msg.msg_Cust.toLowerCase().includes('demo-card') ||
                    msg.msg_Cust.toLowerCase().includes('tip-card')
                      ? 'W75X'
                      : 'mx75'
                  }}"
                >
                  <app-demo-card
                    (paymentSubmit)="paymentSubmit()"
                    *ngIf="msg.msg_Cust.toLowerCase().includes('demo-card')"
                  ></app-demo-card>
                  <div
                    *ngIf="
                      msg.msg_Cust.toLowerCase().includes('your name please?')
                    "
                  >
                    <app-demo-summary
                      [summaryType]="'summary'"
                    ></app-demo-summary>
                  </div>
                  <div *ngIf="msg.msg_Cust.toLowerCase() == 'tip-card'">
                    <app-demo-summary
                      (tipFinished)="tipFinished()"
                      [summaryType]="'tip'"
                    ></app-demo-summary>
                  </div>
                  <div *ngIf="msg.msg_Cust.toLowerCase().includes('loading')" class="chat-bubble">
                    <div class="bubble-container">
                      <span class="bubble"></span>
                      <span class="bubble"></span>
                      <span class="bubble"></span>
                    </div>
                  </div>
                  <div
                    *ngIf="
                      !msg.msg_Cust
                        .toLowerCase()
                        .includes('your name please?') &&
                      !msg.msg_Cust.toLowerCase().includes('demo-card') &&
                      !msg.msg_Cust.toLowerCase().includes('tip-card') &&
                      !msg.msg_Cust.toLowerCase().includes('loading')
                    "
                    class="talktext"
                  >
                    <!--<p class="txt font-inherit f-13">{{ msg.msg_Cust }}</p>  your name please? -->

                    <div
                      class="{{
                        msg.isCompleted ? '' : 'typewriter'
                      }} no-caret f-16"
                    >
                      {{ formatMessages(msg.msg_Cust) }}
                    </div>
                  </div>
                </div>
              </div>

              <div
                *ngIf="msg.msg_Reply != '*'"
                class="talk-bubble right-in disp-inline"
                style="
                  max-width: 75%;
                  margin-left: auto;
                  background: #00ab4e !important;
                  color: white;
                "
              >
                <div class="talktext">
                  <p class="font-inherit f-16">{{ msg.msg_Reply }}</p>
                </div>
              </div>
            </div>
          </div>

          <div
            style="position: absolute; bottom: 0; width: 100%; right: 0"
            class=""
          >
            <div
              *ngIf="activeSuggestionData?.options?.length > 0 && !loading"
              class="mB15 pL20 w95"
            >
              <ng-container *ngFor="let button of activeSuggestionData.options">
                <button
                  type="button"
                  (click)="send(button.value, button)"
                  *ngIf="!button.clicked"
                  class="mR10 suggButton btn btn-outline-success"
                >
                  {{ button.optionText }}
                </button>
              </ng-container>
            </div>

            <div *ngIf="flow === 'new'" class="mB15 pL20 w95">
              <ng-container>
                <button
                  type="button"
                  (click)="send('Order Now', null)"
                  class="mR10 suggButton btn btn-outline-success w95"
                >
                  Let’s Build Your Cart
                </button>
              </ng-container>
            </div>

            <div class="txt-message">
              <div
                *ngIf="orderAgain"
                class="text-center"
                style="position: relative; top: 88%"
              >
                <button (click)="reload()" class="bord jets-green w80 mB20">
                  Start over with OrderAi Chat
                </button>
              </div>
              <div *ngIf="!orderAgain" class="form-group mB0">
                <div class="input w90 disp-inline">
                  <input
                    *ngIf="!showAdress && !showPhoneNumber"
                    type="text"
                    class="form-control disp-inline"
                    [(ngModel)]="userTypedText"
                    aria-describedby="userInput"
                    placeholder="Start typing your order..."
                    (keydown.enter)="send(userTypedText, null)"
                  />
                  <input
                    *ngIf="showAdress"
                    (blur)="blur()"
                    (focus)="focus()"
                    class="form-control disp-inline"
                    placeholder="Start typing your address..."
                    [(ngModel)]="userTypedText"
                    (input)="modelchanged($event.target.value)"
                    type="text"
                    #addresstext
                  />
                  <input
                    *ngIf="showPhoneNumber"
                    type="tel"
                    id="phoneNumber"
                    class="form-control disp-inline"
                    placeholder="Start typing your phone number..."
                    maxlength="14"
                    [(ngModel)]="phoneNumber"
                    name="phoneNumber"
                    (keydown.enter)="sendPhone(phoneNumber)"
                    (input)="onPhoneInput($event)"
                    (keypress)="allowOnlyNumbers($event)"
                  />
                </div>
                <div class="send-btn disp-inline w10">
                  <i
                    *ngIf="showPhoneNumber"
                    style="top: 6px; position: relative; font-size: 3.5ch"
                    (click)="sendPhone(phoneNumber)"
                    class="fa fa-paper-plane-o mLx10 mR10 green-c pointer"
                    aria-hidden="true"
                  ></i>
                  <i
                    *ngIf="!showPhoneNumber"
                    style="top: 6px; position: relative; font-size: 3.5ch"
                    (click)="send(userTypedText, null)"
                    class="fa fa-paper-plane-o mLx10 mR10 green-c pointer"
                    aria-hidden="true"
                  ></i>
                </div>
                <div class="mT10">
                  <p class="w50 disp-inline mB0 f-13 jet-man">
                    <span *ngIf="loading"
                      ><span class="bold">Jet Man</span> is typing...</span
                    >
                  </p>
                  <p class="w50 disp-inline text-right mB0 pR10 powered">
                    Powered by HungerRush
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
