export class checkmateStore {
  constructor() {}

  findStore(sid) {
    switch (sid) {
      case "cm01":
        let sidInfo = {
          discountTxt: "FOR A LIMITED TIME GET 20% OFF ON ALL PIZZAS",
          isPro: false,
          isServiceFee: false,
          successTxt: "TEXT ORDERS ARE 20% OFF ON ALL PIZZAS",
          successPro: "PRO ORDERS ARE 20% OFF ON ALL PIZZAS",
          isTxt: true,
          rid: "+18327939160",
          nickName: "1113 York Ave, New York",
          addy1: "York Ave",
          addy2: "New York, NY",
        };
        return sidInfo;
        
        case "cm02":
        sidInfo = {
          discountTxt: "FOR A LIMITED TIME GET 20% OFF ON ALL PIZZAS",
          isPro: false,
          isServiceFee: false,
          successTxt: "TEXT ORDERS ARE 20% OFF ON ALL PIZZAS",
          successPro: "PRO ORDERS ARE 20% OFF ON ALL PIZZAS",
          isTxt: true,
          rid: "+18324794965",
          nickName: "1113 York Ave, New York",
          addy1: "York Ave",
          addy2: "New York, NY",
        };
        return sidInfo;
      
       

      default:
        sidInfo = {
          discountTxt: "*",
          isPro: false,
          isServiceFee: false,
          successTxt: "Texting is the fastest way to order Jet’s!",
          successPro: "PRO ORDERS ARE 20% OFF ON ALL PIZZAS",
          isTxt: true,
          rid: "empty",
          nickName: "empty",
          addy1: "empty",
          addy2: "empty",
        };
        return sidInfo;
    }
    // console.log(cust)
  }
}
