import { Component, OnInit } from "@angular/core";
import { APICenterService } from "../../apicenter.service";
import { MatDialog } from "@angular/material/dialog";
import { Subscription } from "rxjs";
import { ProServiceService } from "./../../pro-order/pro-service.service";
@Component({
  selector: "app-demo-method",
  templateUrl: "./demo-method.component.html",
  styleUrls: ["./demo-method.component.css"],
})
export class DemoMethodComponent implements OnInit {
  addy: string;
  isApt = false;
  apt: string;
  part1: string;
  part2: string;
  finalAddy: string;
  inContactState: boolean;
  onUpdateMethod = false;
  subscription: Subscription;
  displayMode: boolean = true;
  constructor(
    public api: APICenterService,
    public dialog: MatDialog,
    public pro: ProServiceService
  ) {}

  ngOnInit() {
    
  }
  changeMethod() {
    if (this.api.method.mtype.toLowerCase() === "pickup") {
      this.displayMode = false;
    } else {
      this.change();
    }
  }
  change() {
    this.api.isFirstLoad = false;
    this.api.isSwapMethod = true;
    this.api.isMethodChanged = true;
    this.onUpdateMethod = true;
    this.api.clickValue = "Change Method";

    this.pro.onBackObj.isPickup = true;
    if (this.api.isContactFree) {
      if (this.api.isFutureOrder) {
        this.api.customer.msg_text = `${this.api.customer.msg_text} at ${this.api.method.fOrder}, ${this.api.contactLessTxt}`;
      } else {
        this.api.customer.msg_text = `${this.api.customer.msg_text}, ${this.api.contactLessTxt}`;
      }
    } else {
      if (this.api.isFutureOrder) {
        this.api.customer.msg_text = `${this.api.customer.msg_text} at ${this.api.method.fOrder}`;
      } else {
        this.api.customer.msg_text = `${this.api.customer.msg_text}`;
      }
    }
    this.api.resetItems();
    this.api.onSend = true;
    this.api.updateUserMsg();
  }
  getAddress($event) {
    this.addy = $event.formatted_address;
    this.addressbreakdown(this.addy);
    this.pro.onBackObj.custAddy = this.addy;
  }

  setApt($event) {
    this.isApt = true;
    this.apt = $event;
    this.pro.onBackObj.custAPt = this.apt;
  }

  addressbreakdown(addy) {
    const addySplit = addy.split(",");
    const part2Arr = [];
    const key = addySplit.length;
    part2Arr.push(addySplit[key - 3]);
    part2Arr.push(addySplit[key - 2]);
    part2Arr.push(addySplit[key - 1]);
    this.part2 = part2Arr.join();
    this.part1 = this.addy.replace(this.part2, "");
  }

  updateMethod() {
    this.pro.onBackObj.isPickup = false;
    if (this.isApt && this.apt.length > 0) {
      this.api.quickOrder.isAPt = true;
      this.finalAddy = `${this.part1} Apt ${this.apt}, ${this.part2}`;
    } else {
      this.api.quickOrder.isAPt = false;
      this.finalAddy = this.addy;
    }
    if (this.api.isContactFree) {
      if (this.api.isFutureOrder) {
        this.api.customer.msg_text = `${this.api.customer.msg_text} delivery to ${this.finalAddy} at ${this.api.method.fOrder}, ${this.api.contactLessTxt}`;
      } else {
        this.api.customer.msg_text = `${this.api.customer.msg_text} delivery to ${this.finalAddy}, ${this.api.contactLessTxt}`;
      }
    } else {
      if (this.api.isFutureOrder) {
        this.api.customer.msg_text = `${this.api.customer.msg_text} delivery to ${this.finalAddy} at ${this.api.method.fOrder}`;
      } else {
        this.api.customer.msg_text = `${this.api.customer.msg_text} delivery to ${this.finalAddy}`;
      }
    }
    //  }
    // console.log(this.api.customer.msg_text);
    this.api.resetItems();
    this.api.onSend = true;
    this.api.updateUserMsg();
  }
}
