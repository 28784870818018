export class SimpleSimonsStore {
  constructor() {}

  findStore(sid) {
    switch (sid) {
      case 'ss01':
                let sidInfo = {
                    discountTxt: 'GET 14% OFF ON ALL PIZZAS ON First 2 Orders',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 14% OFF ON ALL PIZZAS ON First 2 Orders',
                    successPro: 'PRO ORDERS ARE 14% OFF ON ALL PIZZAS ON First 2 Orders',
                    isTxt: true,
                    rid: '+19182418866',
                    nickName: '3 E 41st St location in Sand Springs',
                    addy1: '3 E 41st St, Sand Springs',
                    addy2: 'Sand Springs, OK'
                };
                return sidInfo;
            case 'ss02':
                sidInfo = {
                    discountTxt: 'GET 14% OFF ON ALL PIZZAS ON First 2 Orders',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 14% OFF ON ALL PIZZAS ON First 2 Orders',
                    successPro: 'PRO ORDERS ARE 14% OFF ON ALL PIZZAS ON First 2 Orders',
                    isTxt: true,
                    rid: '+19185286008',
                    nickName: '12138 Vancouver Ave location in Glenpool',
                    addy1: '12138 Vancouver Ave, Glenpool',
                    addy2: 'Glenpool, OK'
                };
                    return sidInfo;
            case 'ss03':
                sidInfo = {
                    discountTxt: 'GET 14% OFF ON ALL PIZZAS ON First 2 Orders',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 14% OFF ON ALL PIZZAS ON First 2 Orders',
                    successPro: 'PRO ORDERS ARE 14% OFF ON ALL PIZZAS ON First 2 Orders',
                    isTxt: true,
                    rid: '+19187946696',
                    nickName: '6116 W 41st S location in Berryhill',
                    addy1: '6116 W 41st S, Berryhill',
                    addy2: 'Berryhill, OK'
                };
                return sidInfo;
            case 'ss04':
                sidInfo = {
                    discountTxt: 'GET 15% OFF First 10 Orders',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'GET 15% OFF First 10 Orders',
                    successPro: 'GET 15% OFF First 10 Orders',
                    isTxt: true,
                    rid: '+15752262229',
                    nickName: '1108 W 1st St location in Portales',
                    addy1: '1108 W 1st St, Portales',
                    addy2: 'Portales, NM'
                };
                return sidInfo;
                case 'ss09':
                sidInfo = {
                    discountTxt: 'GET 14% OFF ON ALL PIZZAS ON First 2 Orders',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 14% OFF ON ALL PIZZAS ON First 2 Orders',
                    successPro: 'PRO ORDERS ARE 14% OFF ON ALL PIZZAS ON First 2 Orders',
                    isTxt: true,
                    rid: '+14173513059',
                    nickName: '1317 W Sunshine St location in Springfield',
                    addy1: '1317 W Sunshine St, Springfield',
                    addy2: 'Springfield, MO'
                };
                    return sidInfo;


                    case 'ss05':
                sidInfo = {
                    discountTxt: 'GET 14% OFF ON ALL PIZZAS ON First 2 Orders',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 14% OFF ON ALL PIZZAS ON First 2 Orders',
                    successPro: 'PRO ORDERS ARE 14% OFF ON ALL PIZZAS ON First 2 Orders',
                    isTxt: true,
                    rid: '+19404982488',
                    nickName: '6828 South Interstate 35 locztion in Corinth',
                    addy1: '6828 South Interstate 35',
                    addy2: 'Corinth, TX'
                };
                    return sidInfo;

case 'ss07':
                sidInfo = {
                    discountTxt: 'GET 14% OFF ON ALL PIZZAS ON First 2 Orders',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 14% OFF ON ALL PIZZAS ON First 2 Orders',
                    successPro: 'PRO ORDERS ARE 14% OFF ON ALL PIZZAS ON First 2 Orders',
                    isTxt: true,
                    rid: '+19188856297',
                    nickName: '401 East 1st Street locztion in Hominy',
                    addy1: '401 East 1st Street',
                    addy2: 'Hominy, OK'
                };
                    return sidInfo;

case 'ss16':
                sidInfo = {
                    discountTxt: 'GET 14% OFF ON ALL PIZZAS ON First 2 Orders',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 14% OFF ON ALL PIZZAS ON First 2 Orders',
                    successPro: 'PRO ORDERS ARE 14% OFF ON ALL PIZZAS ON First 2 Orders',
                    isTxt: true,
                    rid: '+19184048144',
                    nickName: '301 North F Street locztion in Yale',
                    addy1: '301 North F Street',
                    addy2: 'Yale, OK'
                };
                    return sidInfo;

case 'ss22':
                sidInfo = {
                    discountTxt: 'GET 14% OFF ON ALL PIZZAS ON First 2 Orders',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 14% OFF ON ALL PIZZAS ON First 2 Orders',
                    successPro: 'PRO ORDERS ARE 14% OFF ON ALL PIZZAS ON First 2 Orders',
                    isTxt: true,
                    rid: '+19185340300',
                    nickName: '414 E Don Tyler Ave locztion in Dewey',
                    addy1: '414 E Don Tyler Ave',
                    addy2: 'Dewey, OK'
                };
                    return sidInfo;

case 'ss27':
                sidInfo = {
                    discountTxt: 'GET 14% OFF ON ALL PIZZAS ON First 2 Orders',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 14% OFF ON ALL PIZZAS ON First 2 Orders',
                    successPro: 'PRO ORDERS ARE 14% OFF ON ALL PIZZAS ON First 2 Orders',
                    isTxt: true,
                    rid: '+19137244141',
                    nickName: '15302 Briar Road locztion in Basehor',
                    addy1: '15302 Briar Road',
                    addy2: 'Basehor, KS'
                };
                    return sidInfo;

case 'ss012':
                sidInfo = {
                    discountTxt: 'GET 14% OFF ON ALL PIZZAS ON First 2 Orders',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 14% OFF ON ALL PIZZAS ON First 2 Orders',
                    successPro: 'PRO ORDERS ARE 14% OFF ON ALL PIZZAS ON First 2 Orders',
                    isTxt: true,
                    rid: '+14053096014',
                    nickName: '501 S Harrah Rd location in Harrah',
                    addy1: '501 S Harrah Rd, Harrah',
                    addy2: 'Harrah, OK'
                };
                    return sidInfo;
case 'ss13':
                sidInfo = {
                    discountTxt: 'GET 14% OFF ON ALL PIZZAS ON First 2 Orders',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 14% OFF ON ALL PIZZAS ON First 2 Orders',
                    successPro: 'PRO ORDERS ARE 14% OFF ON ALL PIZZAS ON First 2 Orders',
                    isTxt: true,
                    rid: '+12813026662',
                    nickName: '13802 FM 1464 location in Richmond',
                    addy1: '13802 FM 1464, Richmond',
                    addy2: 'Richmond, TX'
                };
                    return sidInfo;
case 'ss15':
                sidInfo = {
                    discountTxt: 'GET 14% OFF ON ALL PIZZAS ON First 2 Orders',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 14% OFF ON ALL PIZZAS ON First 2 Orders',
                    successPro: 'PRO ORDERS ARE 14% OFF ON ALL PIZZAS ON First 2 Orders',
                    isTxt: true,
                    rid: '+19367440102',
                    nickName: '180 S Robb St location in Trinity',
                    addy1: '180 S Robb St, Trinity',
                    addy2: 'Trinity, TX'
                };
                    return sidInfo;

                    case 'ss10':
                sidInfo = {
                    discountTxt: 'GET 14% OFF ON ALL PIZZAS ON First 2 Orders',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 14% OFF ON ALL PIZZAS ON First 2 Orders',
                    successPro: 'PRO ORDERS ARE 14% OFF ON ALL PIZZAS ON First 2 Orders',
                    isTxt: true,
                    rid: '+18164248042',
                    nickName: '108 US-169 location in Gower',
                    addy1: '108 US-169, Gower',
                    addy2: 'Gower, MO'
                };
                    return sidInfo;
case 'ss18':
                sidInfo = {
                    discountTxt: 'GET 14% OFF ON ALL PIZZAS ON First 2 Orders',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 14% OFF ON ALL PIZZAS ON First 2 Orders',
                    successPro: 'PRO ORDERS ARE 14% OFF ON ALL PIZZAS ON First 2 Orders',
                    isTxt: true,
                    rid: '+18706424103',
                    nickName: '924 E Collin Raye Dr location in De Queen',
                    addy1: '924 E Collin Raye Dr, De Queen',
                    addy2: 'De Queen, AR'
                };
                    return sidInfo;
case 'ss20':
                sidInfo = {
                    discountTxt: 'GET 14% OFF ON ALL PIZZAS ON First 2 Orders',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 14% OFF ON ALL PIZZAS ON First 2 Orders',
                    successPro: 'PRO ORDERS ARE 14% OFF ON ALL PIZZAS ON First 2 Orders',
                    isTxt: true,
                    rid: '+14793940300',
                    nickName: '106 Morrow St N location in Mena',
                    addy1: '106 Morrow St N, Mena',
                    addy2: 'Mena, AR'
                };
                    return sidInfo;
case 'ss21':
                sidInfo = {
                    discountTxt: 'GET 14% OFF ON ALL PIZZAS ON First 2 Orders',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 14% OFF ON ALL PIZZAS ON First 2 Orders',
                    successPro: 'PRO ORDERS ARE 14% OFF ON ALL PIZZAS ON First 2 Orders',
                    isTxt: true,
                    rid: '+14177762800',
                    nickName: '904 Washington Ave location in Seneca',
                    addy1: '904 Washington Ave, Seneca',
                    addy2: 'Seneca, MO'
                };
                    return sidInfo;
case 'ss23':
    sidInfo = {
        discountTxt: 'GET 14% OFF ON ALL PIZZAS ON First 2 Orders',
        isPro: false,
        isServiceFee: false,
        successTxt: 'TEXT ORDERS ARE 14% OFF ON ALL PIZZAS ON First 2 Orders',
        successPro: 'PRO ORDERS ARE 14% OFF ON ALL PIZZAS ON First 2 Orders',
        isTxt: true,
        rid: '+19188019001',
        nickName: '2409 S Main St location in Grove',
        addy1: '2409 S Main St, Grove',
        addy2: 'Grove, OK'
    };
        return sidInfo;
 case 'ss17':
    sidInfo = {
        discountTxt: 'GET 14% OFF ON ALL PIZZAS ON First 2 Orders',
        isPro: false,
        isServiceFee: false,
        successTxt: 'TEXT ORDERS ARE 14% OFF ON ALL PIZZAS ON First 2 Orders',
        successPro: 'PRO ORDERS ARE 14% OFF ON ALL PIZZAS ON First 2 Orders',
        isTxt: true,
        rid: '+14059870533',
        nickName: '10901 NE 23rd St location in Nicoma Park',
        addy1: '10901 NE 23rd St, Nicoma Park',
        addy2: 'Nicoma Park, OK'
    };
        return sidInfo;
            case 'ss11':
                sidInfo = {
                    discountTxt: 'GET 15% OFF First 10 Orders',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'GET 15% OFF First 10 Orders',
                    successPro: 'GET 15% OFF First 10 Orders',
                    isTxt: true,
                    rid: '+18322193700',
                    nickName: '1730 Minonite Rd in Rosenberg',
                    addy1: '1730 Minonite Rd, Rosenberg',
                    addy2: 'Rosenberg, TX'
                };
                return sidInfo;
           
    }
    // console.log(cust)
  }
  // console.log(cust)
}
