
<div *ngIf="pro.mobile" appStickyHeader   class="card   {{pro.stickyHead?'sticky ':'mTn20'}}" >
   

   <mat-card  *ngIf="orderError" class="mat-pad-12">
      <h6 class="order-error-message">{{orderErrorMessage}}</h6>
      <div style = "display: flex; justify-content: center; padding: 10px;">
      <button
      mat-raised-button
      type="button"
      style = "width: 100%;"
      class="btn back-btn animated fadeInDown"
      (click) = "back()"
      > Back
     </button>
   
   </div> 
   </mat-card>
  <div  *ngIf="!orderError">
     <div  class="row">
      
        <div class="col-md-12 text-center" style = "padding-top: 10px; padding-bottom: 7px; display: flex; justify-content: space-around;">
           <span class=""> 
           
            <img [hidden]="pickupFlag"  width="40%" class="mR10 pT0" (click)="pickup()" src="/assets/images/pickup.png">
           <img [hidden]="!pickupFlag" width="40%" class="mR10 pT0" (click)="pickup()" src="/assets/images/pickup_selected.png">
           <img [hidden]="deliveryFlag" width="40%" class="mL10 pT0" (click)="delivery()" src="/assets/images/delivery.png">
           <img [hidden]="!deliveryFlag" width="40%" class="mL10 pT0" (click)="delivery()" src="/assets/images/delivery_selected.png">
           
         </span>
        </div>
     </div>
     <div class="row">
        <div class="col-12 pL0 pR0">
           <div *ngIf="deliveryFlag" class="form-group mB5 ">
              <app-pro-address-box (setAddress)="getAddress($event)" (setApt)="setApt($event)" adressType="geocode">
              </app-pro-address-box>
           </div>
           <div style="border: 1px solid lightgray;" class="{{pickupFlag ?'mTn50':''}}">
              <div *ngIf="deliveryFlag || pickupFlag" class="form-group  mB0">
               <textarea (ngModelChange)="onChange($event)" [disabled]="isLoading" (blur)="blur()" (focus)="focus();onChange($event)" style="border: white;
               box-shadow: none; " class="form-control txt-box f-17"
               id="exampleFormControlTextarea1 user-txt" placeholder="Speak or type your order here..." rows="3"
               [(ngModel)]="api.quickOrder.text"></textarea>
              </div>
              <div *ngIf="deliveryFlag || pickupFlag" class="row white-button col-12" style="padding-left: 0px;padding-right: 0px; margin-top:0px" >
                 <div class="col-12 pR0 pL0">
                  <button *ngIf="!isNext" type="button" class="btn white-buttons fRight pR20 pL20 f-20 mR12 bold"  [ngStyle]="{'background':api.primaryColor}"
                  (click)="onUpdate()">SEND <i class="fa fa-arrow-circle-up"></i>
                  </button>
                  <p  *ngIf="isLoading" class="f-18 text-center bold"><i>processing...</i></p>
                  <mat-progress-bar   *ngIf="isLoading" class="" mode="indeterminate"></mat-progress-bar>
                  <button *ngIf="isNext && !isLoading " type="button" style = "background-color: grey !important; color: white" class="btn white-buttons  fRight pR20 pL20 f-20 bold mR12"
                     >SEND <i class="fa fa-arrow-circle-up"></i>
                  </button>
                 </div>
              </div>
              <h4 style = "font-size: 16px; color: black;
              padding-top: 5px;
              padding-left: 9px;
              letter-spacing: .5px;
              word-spacing: 0.5px;
              border-top: 1px solid lightgray;"
          >You can log in to your loyalty account on the next screen.</h4>
           </div>
        </div>
     </div>
  </div>
</div>

<div *ngIf="!pro.mobile" style="align-items: center;background: black;" class="card  mTn20 pR0">
   <mat-card *ngIf="orderError" class="mat-pad-12 w40 ipad">
      <h6 class="order-error-message">{{orderErrorMessage}}</h6>
      <div style = "display: flex; justify-content: center; padding: 10px;">
      <button
      mat-raised-button
      type="button"
      style = "width: 100%;"
      class="btn back-btn animated fadeInDown"
      (click) = "back()"
      > Back
     </button>
   </div>
     </mat-card>
   <div class="mB10" *ngIf="!orderError">
  <div class="row  ipad" style="margin:auto; width:432px">
   
     <div class="col-md-12 text-center" style = "padding-top: 10px; padding-bottom: 10px; display: flex; justify-content: space-around;">
        <span class="">
           <img [hidden]="pickupFlag"  width="35%" class="mR10 pT0" (click)="pickup()" src="/assets/images/pickup.png">
           <img [hidden]="!pickupFlag" width="35%" class="mR10 pT0" (click)="pickup()" src="/assets/images/pickup_selected.png">
           <img [hidden]="deliveryFlag" width="35%" class="mL10 pT0" (click)="delivery()" src="/assets/images/delivery.png">
           <img [hidden]="!deliveryFlag" width="35%" class="mL10 pT0" (click)="delivery()" src="/assets/images/delivery_selected.png">
           
      </span>
     </div>
  </div>
  <div style="width: 432px;padding:0px;margin:auto" class="card-body  pT0 mT0">
     <div class="row ">
        <div class="col-12 {{pickupFlag?'':''}} ">
           <div *ngIf="deliveryFlag" class="form-group" style="margin-bottom:14px">
              <app-pro-address-box (setAddress)="getAddress($event)" (setApt)="setApt($event)" adressType="geocode">
              </app-pro-address-box>
           </div>
           <div *ngIf="deliveryFlag || pickupFlag" style="border: 1px solid lightgrey;" class="">
              <div *ngIf="deliveryFlag || pickupFlag" class="form-group   mB20">
               <textarea (focus)="focus();onChange($event)" [disabled]="isLoading" (ngModelChange)="onChange($event)" style="border: white;
               box-shadow: none;" class="form-control txt-box f-17"  [(ngModel)]="api.quickOrder.text"
               id="exampleFormControlTextarea1" placeholder="Type your order here.." rows="3"></textarea>
            </div>
              </div>
              <div *ngIf="deliveryFlag || pickupFlag" class="row white-button col-12" style="padding-left: 0px;padding-right: 0px;" >
               <div class="col-12 pR0 pL0">
                  <button *ngIf="!isNext" type="button" class="btn white-buttons fRight pR20 pL20 f-20 mR12  bold"  [ngStyle]="{'background':api.primaryColor}"
                  (click)="onUpdate()">SEND <i class="fa fa-arrow-circle-up"></i>
                  </button>
                  <p  *ngIf="isLoading" class="f-18 text-center bold"><i>processing...</i></p>
                  <mat-progress-bar   *ngIf="isLoading" class="" mode="indeterminate"></mat-progress-bar>
                 <button *ngIf="isNext && !isLoading" type="button" style = "background-color: grey !important; color: white" class="btn white-buttons mR12 fRight pR20 pL20 f-20 bold"
                     >SEND <i class="fa fa-arrow-circle-up"></i>
                  </button>
               </div>
            </div>
            <h4 style = "font-size: 16px; color: white; padding-top: 5px; letter-spacing: .5px; word-spacing: .5px;">You can log in to your loyalty account on the next screen.</h4>
              <!--<div *ngIf="deliveryFlag || pickupFlag" style="padding-right: 12px;padding-left: 12px;" class="row white-button col-12">
                 <div class="col-12 pL0 pR0">
                    <button type="button" *ngIf="!isNext" (click)="onUpdate()"
                    class="btn white-buttons fRight pR20 pL20 f-20 bold">SEND <i
                       class="fa fa-arrow-circle-up"></i></button>
                    <div *ngIf="isNext" style="display:flex; justify-content: flex-end ">
                       <img style=" width: 25%;"
                          src="http://cdn.lowgif.com/full/3716714f6973f4c5-loading-pay-1s-spinner-yokratom.gif">
                    </div>
                 </div>
              </div> -->
           </div>
        </div>
     </div>
  </div>
</div>

