<mat-card style = "padding: 10px;">
   
  <h3 class = "disclaimer-txt" > Did you make sure your order summary was correct?</h3>
  
 
      <div class = "wrapper-center ">
          <button type = "submit" (click) = "onNextDis()" mat-raised-button color="primary" class = "pay-option animated fadeInDown">
                   <span style = "padding-left: 20px;">I've Reviewed My Order </span> <i class="material-icons  md-light md-36 m-icon">
                        thumb_up_alt
                        </i> </button>
       </div>
       <div class = "wrapper-center">
          <mat-checkbox (change) = "isSkip($event)" style =  "font-size: medium"> For future orders, skip this message. </mat-checkbox>
       </div>

   
   
               <div class = "wrapper-center ">
                   <button
                mat-raised-button
                type="button"
                class="back-btn "
                (click) = "onBack()">
                 Back </button>
                </div>
         
    
  </mat-card>
  
  
  
 