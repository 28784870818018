import { Injectable } from '@angular/core';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { environment } from '../../environments/environment';
import { catchError, tap, switchAll } from 'rxjs/operators';
import { EMPTY, Subject } from 'rxjs';
export const WS_ENDPOINT = "wss://tutalcs.com/"
  
@Injectable({
  providedIn: 'root'
})
export class DataService {
  private socket$: any;
  private messagesSubject$ = new Subject();
  public messages$ = this.messagesSubject$.pipe(switchAll(), catchError(e => { throw e }));
  
  public connect(): void {
   console.log(WS_ENDPOINT);
    if (!this.socket$ || this.socket$.closed) {
      console.log(this.socket$);
      this.socket$ = new WebSocket("wss://tutalcs.com/");
      console.log(this.socket$);
      this.socket$.send()
    }
  }
  sendMessage(msg: any) {
    this.socket$.next(msg);
  }
  close() {
    this.socket$.complete(); }}