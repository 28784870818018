<mat-dialog-content>
    <div>
        <div style = "padding: 10px">
       <img src= "https://s3.us-east-2.amazonaws.com/ordr-assets/cropped_texts.png "alt="mob" width = "100%" >
        </div>
       
        <h3 class = "step-txt">STEP 1: Text your order to {{ api.displayNum.replace('Phone:', '')}}.</h3> 
        <h3 class = "step-txt">STEP 2: Refer a Friend </h3> 
       
       
        
      </div>
</mat-dialog-content>

<mat-dialog-actions class = "close-btn">
   
    <button mat-raised-button color="primary" (click) = "close()"> Close </button>
</mat-dialog-actions>


