<router-outlet></router-outlet>


<!-- <app-navbar></app-navbar>
<app-edit></app-edit>
<app-bt></app-bt>
<app-footer></app-footer>


<nav class="navbar sticky-top navbar-light bg-light">
    <a class="navbar-brand" href="#">Sticky top</a>
  </nav> -
<nav class="navbar fixed-bottom navbar-light bg-light">
    <a class="navbar-brand" href="#">Fixed bottom</a>
  </nav> -->
