import { RestContactService } from './../rest-contact.service';
import { OrderingStepsDialogComponent } from './../ordering-steps-dialog/ordering-steps-dialog.component';
import { Component, OnInit } from '@angular/core';
import { APICenterService } from '../apicenter.service';
import { ActivatedRoute } from '../../../node_modules/@angular/router';
import { MatDialog } from '../../../node_modules/@angular/material/dialog';
import { DeviceDetectorService } from '../../../node_modules/ngx-device-detector';
@Component({
  selector: 'app-rest-offer-page-rd',
  templateUrl: './rest-offer-page-rd.component.html',
  styleUrls: ['./rest-offer-page-rd.component.css']
})
export class RestOfferPageRdComponent implements OnInit {
  deviceInfo = null;
  restName='';
  restMenuId='';
  constructor(public api: APICenterService, private restNum: RestContactService, private route: ActivatedRoute, private deviceService: DeviceDetectorService, public dialog: MatDialog) {
    
  }
  ngOnInit() {
    this.route.url
      .subscribe(val => {
        this.restName = val[0].path;
        this.restMenuId = val[1].path;
        this.restMenuId = this.restMenuId.toLowerCase();
       
       

      });
    }
}
