import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-payment-redirect',
  templateUrl: './payment-redirect.component.html',
  styleUrls: ['./payment-redirect.component.css']
})
export class PaymentRedirectComponent implements OnInit {
loading=true;
  constructor() {
     if(window.location.href.indexOf("HostedPaymentStatus")>-1){
      parent.postMessage(window.location.href,'*');
      if(window.location.href.indexOf("Approved")>-1){
        this.loading=false;
      }
    }
   }

  ngOnInit() {
  }

}
