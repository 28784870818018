import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { jetsStore } from "./../rest-offer-page/jets/jets-store";
import { APICenterService } from "../apicenter.service";
@Component({
  selector: "app-faq",
  templateUrl: "./faq.component.html",
  styleUrls: ["./faq.component.css"],
})
export class FaqComponent implements OnInit {
  items:any;
  expandedIndex = 0;
  constructor(private route: ActivatedRoute, public api: APICenterService) {}
  restId: any = "";
  restInfo: any;
  categoryType:any;
  payload:any;
  ngOnInit() {
    this.route.url.subscribe((val) => {
      if(val.length==3){
      this.restId = val[2].path;
      const sid = new jetsStore();
      this.restInfo = sid.findStore(this.restId.toLowerCase());
      this.api.restaurant.address = this.restInfo.addy1 + this.restInfo.addy1;
      this.api.displayNum = this.api.formatPhone(this.restInfo.rid);
      this.api.restaurant.name = val[0].path;
      this.payload = { restId: val[2].path, chainName: val[0].path };
    }
    if(val.length==2){
      this.api.restaurant.name = val[0].path;
      if (val[0].path == "wayback"){
        val[0].path = "WaybackBurgers"
      }
      this.payload = { restId: "*", chainName: val[0].path };
    }
    this.api.faqInfo(this.payload);
    this.api.getFaqInfo().subscribe((val)=>{
      this.items=val.faq;
      let category=[];
      this.items.forEach(element => {
        category.push(element.category)
      });
      this.categoryType = category.filter((item, i, ar) => ar.indexOf(item) === i);
      console.log(this.categoryType);
    })
    });
  }
}
