import { APICenterService } from './../apicenter.service';
import { Component, OnInit } from '@angular/core';
import { jetsStore } from '../rest-offer-page/jets/jets-store';
import { romeoStore } from '../rest-offer-page/romeo/romeo-store';
import { blazeStore } from '../rest-offer-page/blaze/blaze-store';
import { hungerrushStore } from '../rest-offer-page/hungerrush/hungerrush-store';
import { smoothieStore } from '../rest-offer-page/SmoothieKing/smoothie-king-store';
import {freeBirdsStore} from '../rest-offer-page/free-birds/free-birds-store'


@Component({
  selector: 'app-payment-success',
  templateUrl: './payment-success.component.html',
  styleUrls: ['./payment-success.component.css']
})
export class PaymentSuccessComponent implements OnInit {
  msg: string;
  panelOpenState = false;
  isFeedback = false;
  isEmojiSelected = false;
  isZero = false;
  isOne = false;
  isTwo = false;
  isThree = false;
  isFour = false;
  isFive = false;
  isSix = false;
  isSeven = false;
  isEight = false;
  isNine = false;
  isScaleSub = false;

  scaleSelected: boolean;
  showSubmit = false;
  showSubmit1 = false;
  selectedScale;
  restInfo: any;
  successTxt: any;
  successPro: any;
  discountTxt: any
  isProOrder = true;
  isTextOrder = true;
  name: string
  sid

  constructor(public api: APICenterService) { }

  ngOnInit() {
    this.api.onChangeLink = true;
    this.api.isMethodDisplay = true;
    this.name = this.api.restaurant.name.replace("'", "").toLowerCase()

    if (this.name == 'jets') {
      this.sid = new jetsStore();
    }
    else if (this.name == 'romeos') {
      this.sid = new romeoStore();
    }
    else if (this.name == 'blaze') {
      this.sid = new blazeStore();
    } else if (this.name == 'hungerrush') {
      this.sid = new hungerrushStore()
    }
    else if (this.name == 'freebirds') {
      this.sid = new freeBirdsStore()
    }
    else if (this.name == 'smoothieking' || this.name == 'smoothie king') {
      this.sid = new smoothieStore()
    }
    this.restInfo = this.sid.findStore(this.storeCodeFormatter(this.api.restaurant.restCode));
    this.discountTxt = this.restInfo.discountTxt
    this.successTxt = this.restInfo.successTxt;
    this.successPro = this.restInfo.successPro;
    this.isProOrder = this.restInfo.isPro;
    this.isTextOrder = this.restInfo.isTxt;
  }
  // textFeedbacks()
  onFeedback() {
    this.api.clickValue = 'Text - FeedBack';
    this.api.clickAnalytics();

    // this.api.fbUserText = ''
    console.log(this.api.fbUserText);
    this.api.textFeedbacks();

    this.isFeedback = true;

  }
  verySad() {
    this.showSubmit = true;
    // this.api.clickValue = 'Sad - Emoji'
    // this.api.clickAnalytics()

    this.msg = 'It was difficult to order.';
    this.isEmojiSelected = true;
    // this.api.fbEmojiText = this.msg
    // this.api.emojiFeedbacks()
    /*
        document.getElementById('verySad').style.width = "85%"
        document.getElementById('verySad').style.height = "unset"
       // document.getElementById('sad').style.width = "60%"
        document.getElementById('ok').style.width = "40%"
        document.getElementById('ok').style.height = "30px"
       // document.getElementById('happy').style.width = "60%"
        document.getElementById('love').style.width = "40%"
        document.getElementById('love').style.height = "30px" */
  }
  /*
    sad() {
      console.log('sad is clicked');
      this.msg = 'I am disappointed with your service.';
      document.getElementById('verySad').style.width = "60%"
      document.getElementById('sad').style.width = "100%"
      document.getElementById('ok').style.width = "60%"
      document.getElementById('happy').style.width = "60%"
      document.getElementById('love').style.width = "60%"
    } */

  ok() {
    this.showSubmit = true;
    // this.api.clickValue = 'OK - Emoji'
    // this.api.clickAnalytics()
    this.msg = 'I liked it!';
    this.isEmojiSelected = true;

    // this.api.fbEmojiText = this.msg
    // this.api.emojiFeedbacks()

    /* document.getElementById('verySad').style.width = "40%"
     document.getElementById('verySad').style.height = "30px"
 
    // document.getElementById('sad').style.width = "60%"
     document.getElementById('ok').style.width = "85%"
     document.getElementById('ok').style.height = "unset"
    // document.getElementById('happy').style.width = "60%"
     document.getElementById('love').style.width = "30%"
     document.getElementById('love').style.height = "30px" */
  }

  happy() {
    this.showSubmit = true;
    // this.api.clickValue = 'happy - Emoji'
    // this.api.clickAnalytics()
    this.msg = 'I am happy.';
    this.isEmojiSelected = true;
  }

  love() {
    this.showSubmit = true;
    // this.api.clickValue = 'Love - Emoji'
    // this.api.clickAnalytics()
    this.msg = 'I absolutely loved it!';
    this.isEmojiSelected = true;

    // this.api.fbEmojiText = this.msg
    // this.api.emojiFeedbacks()
    /*document.getElementById('verySad').style.width = "40%"
    document.getElementById('verySad').style.height = "30px"
   // document.getElementById('sad').style.width = "60%"
    document.getElementById('ok').style.width = "40%"
    document.getElementById('ok').style.height = "30px"
  //  document.getElementById('happy').style.width = "60%"
    document.getElementById('love').style.width = "80%"
    document.getElementById('love').style.height = "unset" */

  }

  referFriend() {
    this.api.clickValue = 'Refer a Friend';
    this.api.clickAnalytics();
  }
  /*
  happy() {
    console.log('happy is clicked')
    this.msg = 'I am happy with your service!!!';
    document.getElementById('happy').style.width = "73%"
    document.getElementById('sad').style.width = "47%"
    document.getElementById('love').style.width = "62%"
  }

  love() {
    console.log('love is clicked');
    this.msg = 'I love your service!!!';
    document.getElementById('happy').style.width = "60%"
    document.getElementById('sad').style.width = "47%"
    document.getElementById('love').style.width = "73%"
  }

  sad() {
    console.log('sad is clicked');
    this.msg = 'I am disappointed with your service.';
    document.getElementById('happy').style.width = "60%"
    document.getElementById('love').style.width = "62%"
    document.getElementById('sad').style.width = "60%"
  }
  */

  onEmojiSubmitk() {
    this.api.fbEmojiText = this.msg;
    this.api.emojiFeedbacks();
  }

  scaleSubmit() {
    this.api.actionbutton("feedback_submit",	"SS001")
    this.api.clickValue = 'Scale - FeedBack';
    this.api.clickAnalytics();
    this.isScaleSub = true;
    this.api.fbEmojiText = this.selectedScale;
    console.log('scale');
    console.log(this.api.fbEmojiText);
    this.api.emojiFeedbacks();
  }

  storeCodeFormatter(x) {
    // console.log('storeCode')
    // console.log(x.length)
    if (x.length > 2) {
      const charx = x.split('');
      // console.log(charx)
      if (charx[2] === '0') {
        const set1 = x.substring(0, 2);
        const set2 = x.substring(3, 5);
        // console.log(set1)
        // console.log(set2)
        const newCode = set1 + set2;
        return newCode;
      } else {
        return x;
      }
    } else {
      return 'Not Live';
    }

  }



  onZero(x) {
    if (!this.isScaleSub) {


      this.showSubmit1 = true;
      if (x === '0') {
        if (!this.isZero) {

          this.isZero = true;
          this.selectedScale = '10';
          this.isOne = false;
          this.isTwo = false;
          this.isThree = false;
          this.isFour = false;
          this.isFive = false;
          this.isSix = false;
          this.isSeven = false;
          this.isEight = false;
          this.isNine = false;
          this.scaleSelected = true;
        } else {
          if (this.scaleSelected) {

            this.isZero = false;
            this.scaleSelected = false;
          } else {
            // True means selected
            this.isZero = true;
            this.selectedScale = '10';
            this.isOne = false;
            this.isTwo = false;
            this.isThree = false;
            this.isFour = false;
            this.isFive = false;
            this.isSix = false;
            this.isSeven = false;
            this.isEight = false;
            this.isNine = false;
            this.scaleSelected = true;
          }

        }

      } else if (x === '1') {
        if (!this.isOne) {
          this.isOne = true;
          this.selectedScale = '1';
          this.isZero = false;

          this.isTwo = false;
          this.isThree = false;
          this.isFour = false;
          this.isFive = false;
          this.isSix = false;
          this.isSeven = false;
          this.isEight = false;
          this.isNine = false;
          this.scaleSelected = true;
        } else {
          if (this.scaleSelected) {
            this.isOne = false;
            this.scaleSelected = false;
          } else {
            this.isOne = true;
            this.selectedScale = '1';
            this.isZero = false;

            this.isTwo = false;
            this.isThree = false;
            this.isFour = false;
            this.isFive = false;
            this.isSix = false;
            this.isSeven = false;
            this.isEight = false;
            this.isNine = false;
            this.scaleSelected = true;
          }
        }

      } else if (x === '2') {
        if (!this.isTwo) {
          this.isTwo = true;
          this.selectedScale = '2';
          this.isZero = false;
          this.isOne = false;

          this.isThree = false;
          this.isFour = false;
          this.isFive = false;
          this.isSix = false;
          this.isSeven = false;
          this.isEight = false;
          this.isNine = false;
          this.scaleSelected = true;
        } else {
          if (this.scaleSelected) {
            this.isTwo = false;
            this.scaleSelected = false;
          } else {
            this.isTwo = true;
            this.selectedScale = '2';
            this.isZero = false;
            this.isOne = false;

            this.isThree = false;
            this.isFour = false;
            this.isFive = false;
            this.isSix = false;
            this.isSeven = false;
            this.isEight = false;
            this.isNine = false;
            this.scaleSelected = true;
          }
        }
      } else if (x === '3') {
        if (!this.isThree) {
          this.isThree = true;
          this.selectedScale = '3';
          this.isZero = false;
          this.isOne = false;
          this.isTwo = false;

          this.isFour = false;
          this.isFive = false;
          this.isSix = false;
          this.isSeven = false;
          this.isEight = false;
          this.isNine = false;
          this.scaleSelected = true;
        } else {
          if (this.scaleSelected) {
            this.isThree = false;
            this.scaleSelected = false;
          } else {
            this.isThree = true;
            this.selectedScale = '3';
            this.isZero = false;
            this.isOne = false;
            this.isTwo = false;

            this.isFour = false;
            this.isFive = false;
            this.isSix = false;
            this.isSeven = false;
            this.isEight = false;
            this.isNine = false;
            this.scaleSelected = true;
          }
        }
      } else if (x === '4') {
        if (!this.isFour) {
          this.isFour = true;
          this.selectedScale = '4';
          this.isZero = false;
          this.isOne = false;
          this.isTwo = false;
          this.isThree = false;

          this.isFive = false;
          this.isSix = false;
          this.isSeven = false;
          this.isEight = false;
          this.isNine = false;
          this.scaleSelected = true;
        } else {

          if (this.scaleSelected) {
            this.isFour = false;
            this.scaleSelected = false;
          } else {
            this.isFour = true;
            this.selectedScale = '4';
            this.isZero = false;
            this.isOne = false;
            this.isTwo = false;
            this.isThree = false;

            this.isFive = false;
            this.isSix = false;
            this.isSeven = false;
            this.isEight = false;
            this.isNine = false;
            this.scaleSelected = true;
          }
        }
      } else if (x === '5') {
        if (!this.isFive) {
          this.isFive = true;
          this.selectedScale = '5';
          this.isZero = false;
          this.isOne = false;
          this.isTwo = false;
          this.isThree = false;
          this.isFour = false;

          this.isSix = false;
          this.isSeven = false;
          this.isEight = false;
          this.isNine = false;
          this.scaleSelected = true;
        } else {
          if (this.scaleSelected) {
            this.isFive = false;
            this.scaleSelected = false;
          } else {
            this.isFive = true;
            this.selectedScale = '5';
            this.isZero = false;
            this.isOne = false;
            this.isTwo = false;
            this.isThree = false;
            this.isFour = false;

            this.isSix = false;
            this.isSeven = false;
            this.isEight = false;
            this.isNine = false;
            this.scaleSelected = true;
          }
        }
      } else if (x === '6') {
        if (!this.isSix) {
          this.isSix = true;
          this.selectedScale = '6';
          this.isZero = false;
          this.isTwo = false;
          this.isThree = false;
          this.isFour = false;
          this.isFive = false;

          this.isSeven = false;
          this.isEight = false;
          this.isNine = false;
          this.scaleSelected = true;
        } else {


          if (this.scaleSelected) {
            this.isSix = false;
            this.scaleSelected = false;
          } else {
            this.isSix = true;
            this.selectedScale = '6';
            this.isZero = false;
            this.isTwo = false;
            this.isThree = false;
            this.isFour = false;
            this.isFive = false;

            this.isSeven = false;
            this.isEight = false;
            this.isNine = false;
            this.scaleSelected = true;
          }
        }
      } else if (x === '7') {
        if (!this.isSeven) {
          this.isSeven = true;
          this.selectedScale = '7';
          this.isZero = false;
          this.isOne = false;
          this.isTwo = false;
          this.isThree = false;
          this.isFour = false;
          this.isFive = false;
          this.isSix = false;

          this.isEight = false;
          this.isNine = false;
          this.scaleSelected = true;
        } else {
          if (this.scaleSelected) {
            this.isSeven = false;
            this.scaleSelected = false;
          } else {
            this.isSeven = true;
            this.selectedScale = '7';
            this.isZero = false;
            this.isOne = false;
            this.isTwo = false;
            this.isThree = false;
            this.isFour = false;
            this.isFive = false;
            this.isSix = false;

            this.isEight = false;
            this.isNine = false;
            this.scaleSelected = true;
          }
        }
      } else if (x === '8') {
        if (!this.isEight) {
          this.isEight = true;
          this.selectedScale = '8';
          this.isZero = false;
          this.isOne = false;
          this.isTwo = false;
          this.isThree = false;
          this.isFour = false;
          this.isFive = false;
          this.isSix = false;
          this.isSeven = false;

          this.isNine = false;
          this.scaleSelected = true;
        } else {
          if (this.scaleSelected) {
            this.isEight = false;
            this.scaleSelected = false;
          } else {
            this.isEight = true;
            this.selectedScale = '8';
            this.isZero = false;
            this.isOne = false;
            this.isTwo = false;
            this.isThree = false;
            this.isFour = false;
            this.isFive = false;
            this.isSix = false;
            this.isSeven = false;

            this.isNine = false;
            this.scaleSelected = true;
          }
        }
      } else if (x === '9') {
        if (this.isNine) {
          this.isNine = true;
          this.selectedScale = '9';
          this.isZero = false;
          this.isOne = false;
          this.isTwo = false;
          this.isThree = false;
          this.isFour = false;
          this.isFive = false;
          this.isSix = false;
          this.isSeven = false;
          this.isEight = false;

          this.scaleSelected = true;
        } else {

          if (this.scaleSelected) {
            this.isNine = false;
            this.scaleSelected = false;
          } else {
            this.isNine = true;
            this.selectedScale = '9';
            this.isZero = false;
            this.isOne = false;
            this.isTwo = false;
            this.isThree = false;
            this.isFour = false;
            this.isFive = false;
            this.isSix = false;
            this.isSeven = false;
            this.isEight = false;

            this.scaleSelected = true;
          }
        }
      }
    }

  }

}
