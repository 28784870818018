<nav *ngIf="!api.isMobile" class="navbar font navbar-dark white-bg">
  <table>
    <tr>
      <td style="display: flex; justify-content: center"> <img class="" height="60%" width="75%"
          src="https://www.blazepizza.com/assets/icons/blaze-logo.svg"> </td>


      <!-- <td class="pL10">
        
        <p class="white f-12 mB0"> {{api.restaurant.address}}</p>
        <p class="white f-13 mB0">{{api.displayNum}}</p>
      </td> -->
    </tr>
    <tr>
      <td>
        <p style="color: gray" class="f-13 pT5 text-center mB0">
        </p>
      </td>
    </tr>

  </table>
</nav>

<nav *ngIf="api.isMobile" class="navbar font navbar">
  <table>
    <tr>
      <td style="display: flex; justify-content: center"> <img class="" height="60%" width="75%"
          src="https://www.blazepizza.com/assets/icons/blaze-logo.svg"> </td>

    </tr>
    <tr>
      <td>
        <p style="color: gray" class="f-13 pT5 text-center mB0">
        </p>
      </td>
    </tr>

  </table>
</nav>
<div *ngIf="api.isMobile ||api.isTablet " class="body">
  <div class="head">
    <h2 class="text-center rockwell black-c pT30 f-22 mB0">INTRODUCING</h2>
<h1 class="text-center black-c rockwell-nova  text-head mB0">BLAZE &nbsp;Text <br> To Order! </h1>
    <!--<p class="text-center black-c myriad f-18">{{discountTxt}}</p> -->
   <!----> <!--<p class="limited">Thanks for helping us train the Blaze Text Ordering chatbot. Please click the below and text us
      your entire order in one message to get started. Feel free to send and submit multiple test orders. The more
      orders you send, the better we will be able to train the bot for the Blaze Menu.<br> For any questions or ideas,
      email <a class="email" href="mailto:info@ordrAi.com">info@ordrAi.com</a> and <a class="email"
        href="mailto:leon@blazepizza.com">leon@blazepizza.com</a> </p> -->
  </div>
  <div class="desc">
    <div class="step-one">
      <span class="one">1</span>
      <span class="box-pos" style="top:10px!important">
        <p class="box-t1">TEXT US YOUR ORDER</p>
        <!-- <p class="box-t2">USE THE SAME NUMBER YOU WOULD CALL</p> -->
        <p class="box-t3"> TEXT 'PICKUP' OR 'DELIVERY TO (YOUR ADDRESS)'</p>
      </span>
    </div>
    <div class="step-two" style="height: 70px;">
      <span class="two">2</span>
      <span class="box-pos">
        <p class="box-t1 black-c" style="color:black!important">RECEIVE A REPLY</p>
        <p class="box-t2" style="color:black!important">REVIEW YOUR ORDER</p>

      </span>
    </div>
    <div class="step-three">
      <span class="three">3</span>
      <span class="box-pos">
        <p class="box-t1">CONFIRM YOUR ORDER</p>

        <p class="box-t3">ENJOY YOUR PIZZA!</p>
      </span>
    </div>
  </div>
  <div class="footer">

    <div (click)="orderNowBtn()" style="height: 300px;" class="text-center pT30">
      <div class="start-order">
        <span style="font-size: 45px;" class="">START YOUR ORDER</span>

        <p style="font-size: 21px; margin-top:-12px;">At Blaze - {{restNickname}}</p>
      </div>


    </div>
    <!-- <img src="/assets/images/hand.png"> -->
  </div>
</div>

<div *ngIf="api.isDesktopDevice" class="body row">
  <div class="cust-size pR0">
    <div class="head">
      <h2 class="text-center rockwell-nova black-c pT30 f-36 mB0">INTRODUCING</h2>
<h1 style="word-wrap: break-word;" class="text-center black-c rockwell-nova  text-head-desk mB0">BLAZE TEXT <br>TO ORDER!</h1>
<!--
<p class="text-center black-c myriad f-24">{{discountTxt}}</p>  -->
     <!--  <p class="limited" style="padding-left: 30px!important;font-size: 17px!important;">Thanks for helping us train the
        Blaze Text Ordering chatbot. Please click the below and text us your entire order in one message to get started.
        Feel free to send and submit multiple test orders. The more orders you send, the better we will be able to train
        the bot for the Blaze Menu.<br> For any questions or ideas, email <a class="email"
          href="mailto:info@ordrAi.com">info@ordrAi.com</a> and <a class="email"
          href="mailto:leon@blazepizza.com">leon@blazepizza.com</a></p> -->
    </div>
    <div class="desc">
      <div class="step-one row mL0 mR0 w100 scp-align " style="display: inline-block; height: 90px;">
        <div class="col-sm-1 text-center pT20 m-aut pL20">
          <span class="one-d f-36">1</span>
        </div>
        <div class="col-sm-11 pL20">
          <span class="box-pos-d " style="top:10px!important">
            <p class="box-t1 f-36">TEXT US YOUR ORDER</p>
            <!--<p class="box-t2 f-24">USE THE SAME NUMBER YOU WOULD CALL</p> -->
            <p class="box-t3 f-24"> TEXT 'PICKUP' OR 'DELIVERY TO (YOUR ADDRESS)'</p>
          </span>
        </div>
      </div>
      <div class="step-two row mL0 mR0 w100 scp-align" style="display: inline-block;  height: 90px;">
        <div class="col-sm-1 text-center m-aut pT20 pL20 ">
          <span class="two-d f-36 t-0">2</span>
        </div>
        <div class="col-sm-11 pL20 pT0">
          <span class="box-pos-d pT10 ">
            <p class="box-t1 f-36 black-c" style="color: black!important">RECEIVE A REPLY</p>
            <p class="box-t2 f-24" style="color:black!important">REVIEW YOUR ORDER</p>
          </span>
        </div>
      </div>
      <div class="step-three-nb row mL0 mR0 w100 scp-align" style="display: inline-block; height: 90px;">
        <div class="col-sm-1 text-center m-aut pT20 pL20 ">
          <span class="three-d f-36 t-0">3</span>
        </div>
        <div class="col-sm-11 pL20 pT0">
          <span class="box-pos-d pT10">
            <p class="box-t1 f-36">CONFIRM YOUR ORDER</p>

            <p class="box-t3 f-24">ENJOY YOUR PIZZA!</p>
          </span>
        </div>
      </div>
    </div>
  </div>

  <div class="cust-size pL0 back-pic">

    <div (click)="orderNowBtn()" style="margin-top:15%" class="text-center pT30">
      <div class="start-order mL50 mR50">
        <span style="font-size: 45px;" class="">START YOUR ORDER</span>

        <p style="font-size: 21px; margin-top:-12px;">At Blaze - {{restNickname}}</p>
      </div>


    </div>
    <!-- <img src="/assets/images/hand.png"> -->
  </div>
</div>
<div class="white-bg">
  <div class="container pT5 pB5">
    <div class="row">
      <div class="col footer-txt">
        <div>
          <div class="footer-txt"> © {{year}} <b>OrdrAi</b></div>
        </div>
      </div>
      <div class="col">
        <div style="float: right">
          <a class="a-txt" target="_blank" rel="noopener noreferrer" href="/terms">Terms </a> | <a class="a-txt"
            target="_blank" rel="noopener noreferrer" href="/privacy"> Privacy</a>
        </div>

      </div>
    </div>
  </div>
</div>