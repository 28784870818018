<nav  class="navbar font navbar black-bg">
  <table>
    <tr>
      <td style="display: flex; justify-content: center"> <img class="" height="60%" width="75%"
          src="/assets/jets_new_log.png"> </td>
    </tr>
  </table>
</nav>
<div class="text-center">
  <button type="button" class="btn white-buttons mT20 pR40 pL40 bold rockwell-nova green-background" (click)="voiceorder()" style="font-size: 27px; border-radius: 0px; letter-spacing: 1px;">
    Voice Order
  </button>
</div>