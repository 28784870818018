import { Component, OnInit ,HostListener,Renderer2} from "@angular/core";

@Component({
  selector: "app-chatbot",
  templateUrl: "./chatbot.component.html",
  styleUrls: ["./chatbot.component.css"],
})
export class ChatbotComponent implements OnInit {
  isMobileView: boolean = false;
  constructor(private renderer: Renderer2) {}

  ngOnInit(): void {
    
    this.checkViewport();
    setTimeout(() => {
      const iframe = document.getElementById("dynamicIframe");
    iframe.classList.add("glowing-container");
    }, 1000);
   
  }
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.checkViewport();
  }

  private checkViewport(): void {
    this.isMobileView = window.innerWidth <= 768; // Adjust breakpoint as needed
  }
}
