<!--
<nav class="navbar bg " id = "navbar-color" >
    <a class="navbar-brand brand-txt " href="#">{{api.restaurant.name}}</a>
  </nav>
<div>
    <p class = "address-txt par" ><i>{{api.restaurant.address}}</i> </p>
</div>
<div>
  <p type = 'tel' class = "address-txt par"><i> {{api.displayNum}}</i> </p>
</div> 
-->

<!-- Jets Logo taken down-->
<!-- <mat-card style = "padding: 5px;" *ngIf = "!api.quickOrder.isQuickName" >
    <div class="container-fluid">
      <div class="row">
          <div class = "col-3 padding-zero logo-box">
              <div class="text-center img-box-outter">
                          <img src="{{api.restaurant.logo}}" class = "img-fluid rounded img-thumbnail img-box"  >
              </div>
            </div> 
            <div class = "col-8  padding-zero not-logo-box" >
                <div class = "container-fluid">
                  <div class = "row">
                    <div class = "col-12 padding-zero ">
                        <div class="col padding-zero">
                            <h4 class="card-title card-title-fs" style = "text-transform: uppercase">{{api.restaurant.name}}</h4>
                        </div>
                    </div>
                  </div>
                  <div class = "row">
                      <div class = "col-12  padding-zero ">
                          <div class="col padding-zero">
                              <h5 class="card-address card-address-fs">{{api.restaurant.address}}</h5>
                              <h5 style = "margin-top: 5px;"> <a style = "color: black; text-decoration: none" class = "card-address card-address-fs" href="tel:{{api.restaurant.rid}}">{{api.displayNum}}</a></h5>
                          </div>
                      </div>
                    </div>
                </div>
                </div>
                </div>
    </div>
    </mat-card>
    <app-pro-navbar *ngIf = "api.quickOrder.isQuickName"></app-pro-navbar>  -->
    <app-pro-navbar></app-pro-navbar>

<!--
<mat-card>
    <div class="flex-container">
        <div style = "display: inline-block"> 
                <img src="https://www.jetspizza.com/img/logo-white-text.png" alt="logo" class = "img-fluid rounded img-thumbnail img-box"  >
        </div>
        <div class = "half-box" style="flex-grow:4"> {{api.restaurant.name}}  </div>
    </div>
</mat-card> 
-->





 <!--
   <nav class="navbar navbar-dark bg-dark">
            <a class="navbar-brand brand-txt"> {{storeName}}
            </a>
            <span class="navbar-text" id = "nav-txt">
                {{storeAddress}}
              </span>
          </nav>


 <nav class="navbar navbar-expand-lg navbar-light bg-light">
              <a class="navbar-brand" href="#">Navbar w/ text</a>

              <div class="collapse navbar-collapse" id="navbarText">

                <span class="navbar-text">
                  Navbar text with an inline element
                </span>
              </div>
   </nav>

  -->
