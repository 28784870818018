export class fajitaPetesStore {
  constructor() {}
  findStore(sid) {
    switch (sid) {
      case "fp04":
        let sidInfo = {
          discountTxt: "GET $5 OFF First order",
          isPro: false,
          isServiceFee: false,
          successTxt: "GET $5 OFF First order",
          successPro: "GET $5 OFF First order",
          isTxt: true,
          rid: "+13462294671",
          nickName: "14627 Memorial Dr Suite A in Houston",
          addy1: "14627 Memorial Dr Suite A",
          addy2: "Houston, TX",
        };
        return sidInfo;

      case "fp05":
        sidInfo = {
          discountTxt: "GET $5 OFF First order",
          isPro: false,
          isServiceFee: false,
          successTxt: "GET $5 OFF First order",
          successPro: "GET $5 OFF First order",
          isTxt: true,
          rid: "+17137238100",
          nickName: "6719 Weslayan St in Houston",
          addy1: "6719 Weslayan St",
          addy2: "Houston, TX",
        };
        return sidInfo;

      case "fp06":
        sidInfo = {
          discountTxt: "GET $5 OFF First order",
          isPro: false,
          isServiceFee: false,
          successTxt: "GET $5 OFF First order",
          successPro: "GET $5 OFF First order",
          isTxt: true,
          rid: "+16823254028",
          nickName: "4712 Colleyville Blvd #140 in Houston",
          addy1: "4712 Colleyville Blvd #140",
          addy2: "Houston, TX",
        };
        return sidInfo;

      case "demo":
        sidInfo = {
          discountTxt: "*",
          isPro: false,
          isServiceFee: false,
          successTxt: "Texting is the fastest way to order Manny and olgas!",
          successPro: "PRO ORDERS ARE 20% OFF ON ALL PIZZAS",
          isTxt: true,
          rid: "+15862033838",
          nickName: "Research park dr ",
          addy1: "3917 Research Park Dr",
          addy2: "Ann Arbor, MI",
        };
        return sidInfo;
      case "xxx":
        return "+14194661111";
      case "xxx":
        return "+12159608888";
      default:
        sidInfo = {
          discountTxt: "*",
          isPro: false,
          isServiceFee: false,
          successTxt: "Texting is the fastest way to order Manny and olgas!",
          successPro: "PRO ORDERS ARE 20% OFF ON ALL PIZZAS",
          isTxt: true,
          rid: "empty",
          nickName: "empty",
          addy1: "empty",
          addy2: "empty",
        };
        return sidInfo;
    }
    // console.log(cust)
  }
}
