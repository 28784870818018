<div style="margin:auto;width:50%">
  <div class = "flex-container" style = "padding-bottom: 0px; display: flex;
  justify-content: flex-start;" routerLink = "" >
  <h3 class = "title-1"> <span style = "color: black;">Ordr</span><span style = "color: lightgray;">Ai</span> </h3> 
  </div>
  <hr style = " margin: 0px; margin-left: 20px;  margin-right: 20px; border: 0;
  border-top: 1px solid #212529; " >
  </div>
  <div class="row food-background">
  <div class="col-sm-4"></div>
  <div class="col-md-4" *ngIf="!unauthorizedflag" style="padding-top:0%; justify-content: center; display: flex;" >
      
      <div class="card mT30" style= "width: 75%">
          <h1 *ngIf="!resetpassword"  class="card-header header-login " >Sign in with your OrdrAi account </h1>
          <h1 *ngIf="resetpassword"  class="card-header header-login " >Reset your OrdrAi account </h1>
          <div class="card-body">
              <div *ngIf="errorFlag" class="alert alert-danger" role="alert">
                {{errorMessage}}
                </div>
                <div *ngIf="resetSuccessFullFlag" class="alert alert-success" role="alert">
                 A link has been sent to your registered email to reset password 
                 </div> 
                
                  <div class="form-group">
                      <label for="username" style = "color: gray; font-weight: 400">Email</label>
                      <input type="text" [(ngModel)]="username" class="form-control"  />
                     
                  </div>
                  <div *ngIf="!resetpassword" class="form-group">
                      <label for="password" style = "color: gray; font-weight: 400">Password</label>
                      <input type="password" [(ngModel)]="password" class="form-control" />
                      
                  </div>
                  <div class="text-center mT30 mB20">
                      <span *ngIf="!resetpassword">
                          <button (click)="login()" class="btn btn-primary black-bg" style = "font-size: 13px;">
                   
                              <span  >Sign In</span>
                              
                              <span *ngIf="loading"><img width="12%" src="/assets/loading.gif"></span>
                           </button>
                      </span>
                    <span *ngIf="resetpassword">
                      <button (click)="reset(username)" class="btn btn-primary black-bg" style = "font-size: 13px;">
                   
                     
                          <span >Reset</span>
                          <span *ngIf="loading"><img width="12%" src="/assets/loading.gif"></span>
                       </button>
                    </span>
                <br>
                  <label *ngIf="!resetpassword" (click)="resetPassword()" style = " margin-top:10px;font-weight: 400">Reset Password ?</label>
                  <label *ngIf="resetpassword" (click)="backToLogin()" style = " margin-top:10px;font-weight: 400">Back to Login </label>
                 
              </div>
         
          </div>
      </div>
  </div>   
  <div class="col-md-4" *ngIf="unauthorizedflag" >
      
      <div class="">
          <h1  class="card-header text-center uc ">Unauthorized access!</h1>
          <div class="card-body">
              
                
                  <div class="form-group">
                      <label for="username" style = "font-weight: 400">OOPS... Looks like you don't have access to view this page. Please login again.</label>
                      
                     
                  </div>
                  
                  <div class="text-center">
                  <button (click)="backToLogin()" class="btn btn-primary black-bg" style = "font-size: 12px;">
                      
                     Back to Login
                  </button>
                  </div>
         
          </div>
      </div>
  </div> 
  </div>  
  <div>
  <app-footer ></app-footer>    
  </div>
  