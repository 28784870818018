<div *ngIf="pro.mobile">
  <mat-card style="padding: 0px; box-shadow: none; background: black">
    <div class="menu mTn14 auto-height">
      <img
      *ngIf="restName == 'jets'"
        width="100%"
        class="pT0"
        src="/assets/images/Jets-mobile.jpg"
        alt="Pizza menu"
      />
      <img
      *ngIf="restName == 'littlecaesars'"
        width="100%"
        class="pT0"
        src="/assets/images/Jets-mobile.jpg"
        alt="Pizza menu"
      />
      <img
      *ngIf="restName == 'chowly'"
      width="100%"
      class="pT0 ipad"
      src="/assets/images/chowly-mobile-1.png"
      alt="Pizza menu"
    />
    <img
      *ngIf="restName == 'checkmate'"
      width="100%"
      class="pT0 ipad"
      src="/assets/checkmate-menu2.png"
      alt="Pizza menu"
    />

      <div class="footr">
        <div class="container">
          <div class="row mT10 mB10">
            <div class="col footer-txt">
              <div>
                <div  style="color: white" class="footer-txt">© {{ year }} <b>OrdrAi</b></div>
              </div>
            </div>
            <div class="col">
              <div style="float: right">
                <a
                style="color: white"
                  class="a-txt"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="/terms"
                  >Terms
                </a>
                |
                <a
                style="color: white"
                  class="a-txt"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="/privacy"
                >
                  Privacy</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-card>
</div>
<div *ngIf="!pro.mobile">
  
  <div class="mx-auto order-0">
    <div
      class="menu text-center auto-height"
      style="max-height: 800px; background: black; min-height: 800px"
    >
      <img
        *ngIf="restName == 'jets'"
        width="99%"
        class="pT0 ipad"
        src="/assets/images/Jets-desk.jpg"
        alt="Pizza menu"
      />
      <img
        *ngIf="restName == 'littlecaesars'"
        width="99%"
        class="pT0 ipad"
        src="/assets/images/Jets-desk.jpg"
        alt="Pizza menu"
      />
      <img
      *ngIf="restName == 'chowly'"
      width="99%"
      class="pT0 ipad"
      src="/assets/images/chowly-desk-1.png"
      alt="Pizza menu"
    />
    <img
      *ngIf="restName == 'itsacheckmate'"
      width="60%"
      class="pT0 ipad"
      src="/assets/checkmate-menu2.png"
      alt="Pizza menu"
    />

      <div class="footr">
        <div class="container">
          <div class="row mT10 mB10">
            <div class="col footer-txt">
              <div>
                <div style="color: white" class="footer-txt">
                  © {{ year }} <b>OrdrAi</b>
                </div>
              </div>
            </div>
            <div class="col">
              <div style="float: right">
                <a
                  style="color: white"
                  class="a-txt"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="/terms"
                  >Terms
                </a>
                |
                <a
                style="color: white"
                  class="a-txt"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="/privacy"
                >
                  Privacy</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class=""></div>
  </div>
</div>
