import { FaxSummaryComponent } from "./fax-summary/fax-summary.component";
import { RouterModule } from "@angular/router";
import { BrowserModule } from "@angular/platform-browser";
import { NgModule, Component } from "@angular/core";
import { FormsModule } from '@angular/forms'; 
import { CommonModule } from '@angular/common';
import { HttpClientModule } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatCardModule } from "@angular/material/card";
import { Ng4LoadingSpinnerModule } from "ng4-loading-spinner";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatDialogModule } from "@angular/material/dialog";
import { AppComponent } from "./app.component";
import { EditComponent } from "./edit/edit.component";
import { APICenterService } from "./apicenter.service";
import { CdkAccordionModule } from "@angular/cdk/accordion";
// import { RatingModule } from 'ng-starrating';
import { AudioRecordingService } from "./audio-mic/audio-recording.service";
import { AudioMicComponent } from "./audio-mic/audio-mic.component";
import { DataService } from "./audio-mic/websocket";
import { NavbarComponent } from "./navbar/navbar.component";
import { FooterComponent } from "./footer/footer.component";
import { TipComponent } from "./tip/tip.component";
import { BtComponent } from "./bt/bt.component";
import { NgxBraintreeModule } from "ngx-braintree";
import { CheckoutComponent } from "./checkout/checkout.component";
import { TermsComponent } from "./terms/terms.component";
import { PrivacyComponent } from "./privacy/privacy.component";
import { NotfoundComponent } from "./notfound/notfound.component";
import { HomeComponent } from "./home/home.component";
import { EditPageComponent } from "./edit-page/edit-page.component";
import { MethodComponent } from "./method/method.component";
import { ContactComponent } from "./contact/contact.component";
import { SummaryComponent } from "./summary/summary.component";
import { UserMsgComponent } from "./user-msg/user-msg.component";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatInputModule } from "@angular/material/input";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatIconModule } from "@angular/material/icon";
import { MatRadioModule } from "@angular/material/radio";
import { DeviceDetectorModule } from "ngx-device-detector";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatSelectModule } from "@angular/material/select";
import { MatDividerModule } from "@angular/material/divider";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatTableModule } from "@angular/material/table";
import { NgOtpInputModule } from "ng-otp-input";

import { BraintreeComponent } from "./paymentGateway/braintree/braintree.component";
import { Summary4faxComponent } from "./summary4fax/summary4fax.component";
import { PinSetComponent } from "./pin-set/pin-set.component";
import { PinPaymentComponent } from "./pin-payment/pin-payment.component";
import { PinPlateComponent } from "./pin-plate/pin-plate.component";
import { PaymentSuccessComponent } from "./payment-success/payment-success.component";
import { PinSetBComponent } from "./pin-set-b/pin-set-b.component";
import { ReceiptComponent } from "./receipt/receipt.component";
import { PaymentFailureComponent } from "./payment-failure/payment-failure.component";
import { MenuComponent } from "./menu/menu.component";
import { DisclaimerComponent } from "./disclaimer/disclaimer.component";
import { MenuDialogComponent } from "./menu-dialog/menu-dialog.component";
import { RestOfferPageComponent } from "./rest-offer-page/rest-offer-page.component";
import { OrderNowComponent } from "./Buttons/order-now/order-now.component";
import { OrderingStepsDialogComponent } from "./ordering-steps-dialog/ordering-steps-dialog.component";
import { TextMarketingCheckComponent } from "./text-marketing-check/text-marketing-check.component";
import { ReferFriendComponent } from "./Buttons/refer-friend/refer-friend.component";
import { ReferFriendDialogComponent } from "./Buttons/refer-friend-dialog/refer-friend-dialog.component";
import { FriendReferralComponent } from "./friend-referral/friend-referral.component";
import { MatNativeDateModule } from "@angular/material/core";
import { AddressBoxComponent } from "./address-box/address-box.component";
import { WorldPayComponent } from "./world-pay/world-pay.component";
import { TestComponentRenderer } from "../../node_modules/@angular/core/testing";
import { RestTextTiggerComponent } from "./rest-text-tigger/rest-text-tigger.component";
import { UIStartComponent } from "./uistart/uistart.component";
import { GetCustPhoneComponent } from "./get-cust-phone/get-cust-phone.component";
import { GetCustMsgComponent } from "./get-cust-msg/get-cust-msg.component";
import { GetMethodComponent } from "./get-method/get-method.component";
import { UpsellComponent } from "./upsell/upsell.component";
import { TestContentComponent } from "./test-content/test-content.component";
import { TestCompComponent } from "./test-comp/test-comp.component";
import { RatingStarsComponent } from "./rating-stars/rating-stars.component";
import { UpSellItemsComponent } from "./up-sell-items/up-sell-items.component";
import { ServerErrorComponent } from "./server-error/server-error.component";
import { StoreListTellFrndComponent } from "./store-list-tell-frnd/store-list-tell-frnd.component";
import { FeedbackFormComponent } from "./feedback-form/feedback-form.component";
import { InfoVideoComponent } from "./info-video/info-video.component";
import { QuickOrderComponent } from "./quick-order/quick-order.component";
import { QuickStartPageComponent } from "./quick-start-page/quick-start-page.component";
import { MenuBoxComponent } from "./pro-order/menu-box/menu-box.component";
import { OrderBoxComponent } from "./pro-order/order-box/order-box.component";
import { QuickOrderBaseComponent } from "./pro-order/quick-order-base/quick-order-base.component";
import { ProNavbarComponent } from "./pro-order/pro-navbar/pro-navbar.component";
import { ProAddressBoxComponent } from "./pro-order/pro-address-box/pro-address-box.component";
import { PhoneMaskDirective } from "./contact/phone-mask";
import { ReactiveFormsModule } from "@angular/forms";
import { SafePipe } from "./world-pay/safepipe";
import { HttpModule } from "@angular/http";
import { MethodChangeDialogComponent } from "./method-change-dialog/method-change-dialog.component";
import { StickyHeaderDirective } from "./pro-order/quick-order-base/sticky";
import { HomeNewComponent } from "./home-new/home-new.component";
import { HomeContact } from "./home-new/contact/home-contact.component";
import { NewLoginComponent } from "./new-login/new-login/new-login.component";
import { ResetNewComponent } from "./new-login/reset-new/reset-new.component";
import { JetsComponent } from "./rest-offer-page/jets/jets.component";
import { HungryhowiesComponent } from "./rest-offer-page/hungryhowies/hungryhowies.component";
import { BlazeComponent } from "./rest-offer-page/blaze/blaze.component";
import { RomeoComponent } from "./rest-offer-page/romeo/romeo.component";
import { MasterComponent } from "./rest-offer-page/master/master.component";
import { MarcosComponent } from "./rest-offer-page/marcos/marcos.component";
import { ChowlyComponent } from "./rest-offer-page/chowly/chowly.component";
import { HungerRushComponent } from "./rest-offer-page/hungerrush/hungerrush.component";
import { CenterCourtComponent } from "./rest-offer-page/centercourt/centercourt.component";
import { CoolRunningsComponent } from "./rest-offer-page/coolrunnings/coolrunnings.component";
import { EastOfChicagoComponent } from "./rest-offer-page/eastofchicago/eastofchicago.component";
import { GhattisPizzaComponent } from "./rest-offer-page/gattispizza/gattispizza.component";
import { PizzaWorldUSAComponent } from "./rest-offer-page/pizzaworldusa/pizzaworldusa.component";
import { SmoothieComponent } from "./rest-offer-page/SmoothieKing/smoothie-king.component";

import { JetsComponentShare } from "./store-list-tell-frnd/jets/jets.component";
import { HungryhowiesComponentShare } from "./store-list-tell-frnd/hungryhowies/hungryhowies.component";
import { BlazeComponentShare } from "./store-list-tell-frnd/blaze/blaze.component";
import { RomeoComponentShare } from "./store-list-tell-frnd/romeo/romeo.component";
import { MasterComponentShare } from "./store-list-tell-frnd/master/master.component";
import { SmoothieKingComponentShare } from "./store-list-tell-frnd/smoothie-king/smoothie-king.component";

import { CenterCourtComponentShare } from "./store-list-tell-frnd/centercourt/centercourt.component";
import { CoolRunningsComponentShare } from "./store-list-tell-frnd/coolrunnings/coolrunnings.component";
import { EastOfChicagoComponentShare } from "./store-list-tell-frnd/eastofchicago/eastofchicago.component";
import { GattisComponentShare } from "./store-list-tell-frnd/gattis/gattis.component";
import { PizzaWorldUSAComponentShare } from "./store-list-tell-frnd/pizzaworldusa/pizzaworldusa.component";
import { SimpleSimonsShareComponent } from "./store-list-tell-frnd/simple-simons-share/simple-simons-share.component";
import { PopoverModule } from "ngx-smart-popover";
import { StoreIamHereComponent } from "./store-iam-here/store-iam-here.component";
import { NewPhoneMaskDirective } from "./store-iam-here/phone-mask";
import { RestOfferPageRdComponent } from "./rest-offer-page-rd/rest-offer-page-rd.component";
import { JetsRdComponent } from "./rest-offer-page-rd/jets-rd/jets-rd.component";
import { FeedbackComponenentComponent } from "./feedback/feedback-componenent/feedback-componenent.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { AudioComponent } from "./audio/audio.component";
import { MarcosComponentShare } from "./store-list-tell-frnd/marcos/marcos.component";
import { PaymentRedirectComponent } from "./payment-redirect/payment-redirect.component";
import { compact } from "underscore";
import { CurbsidePopupComponent } from "./edit/curbside-popup/curbside-popup.component";
import { FaqComponent } from "./faq/faq.component";
import { SmoothieKingAllergiesComponent } from "./edit/smoothie-king-allergies/smoothie-king-allergies.component";
import { WaybackBurgerComponent } from "./rest-offer-page/wayback-burger/wayback-burger.component";
import { WaybackComponent } from "./store-list-tell-frnd/wayback/wayback.component";
import { SammysPizzaComponent } from "./rest-offer-page/sammys-pizza/sammys-pizza.component";
import { SammysPizzaShareComponent } from "./store-list-tell-frnd/sammys-pizza/sammys-pizza.component";
import { NewPaymentComponent } from "./new-payment/new-payment.component";
import { GioniosComponent } from "./rest-offer-page/gionios/gionios.component";
import { GinoninosShareComponent } from "./store-list-tell-frnd/gioninos/gioninos-share.component";
import { RewardsComponentComponent } from "./rewards-component/rewards-component.component";
import { FreeBirdsComponent } from "./rest-offer-page/free-birds/free-birds.component";
import { MannyandolgasComponent } from "./rest-offer-page/mannyandolgas/mannyandolgas.component";
import { GiovannispizzapowerComponent } from "./rest-offer-page/giovannispizzapower/giovannispizzapower.component";
import { BcPizzaComponent } from "./rest-offer-page/bc-pizza/bc-pizza.component";
import { FreebirdsShareComponent } from "./store-list-tell-frnd/freebirds-share/freebirds-share.component";
import { MannyandolgasShareComponent } from "./store-list-tell-frnd/mannyandolgas-share/mannyandolgas-share.component";
import { GiovannisShareComponent } from "./store-list-tell-frnd/giovannis-share/giovannis-share.component";
import { BcpizzaShareComponent } from "./store-list-tell-frnd/bcpizza-share/bcpizza-share.component";
import { BlackJackComponent } from "./rest-offer-page/black-jack/black-jack.component";
import { WingsToGoComponent } from "./rest-offer-page/wings-to-go/wings-to-go.component";
import { ExtremePizzaComponent } from "./rest-offer-page/extreme-pizza/extreme-pizza.component";
import { SimpleSimonsComponent } from "./rest-offer-page/simple-simsons/simple-simons.component";
import { ExtremeComponent } from "./store-list-tell-frnd/extreme/extreme.component";
import { BlackjackComponent } from "./store-list-tell-frnd/blackjack/blackjack.component";
import { WingstogoComponent } from "./store-list-tell-frnd/wingstogo/wingstogo.component";
import { LoginPopupComponent } from "./login-popup/login-popup.component";
import { LittleCaesarsComponent } from "./rest-offer-page/little-caesars/little-caesars.component";
import { FajitaPetesComponent } from "./rest-offer-page/fajita-petes/fajita-petes.component";
import { LittlecaesarsComponent } from "./store-list-tell-frnd/littlecaesars/littlecaesars.component";
import { FajitaComponent } from "./store-list-tell-frnd/fajita/fajita.component";
import { DefilicePizzaComponent } from './rest-offer-page/defilice-pizza/defilice-pizza.component';
import {PapaRomanosComponent} from './rest-offer-page/paparomanos/paparomanos.component';
import { PizzaCottageComponent } from './rest-offer-page/pizza-cottage/pizza-cottage.component';
import {JoseppisPizzaComponent} from './rest-offer-page/joseppis-pizza/joseppis-pizza.component';
import {NorthernLightsComponent} from './rest-offer-page/northern-lights/northern-lights.component';
import { DemoChatbotComponent } from './demo-chatbot/demo-chatbot.component';
import {DemoCardsComponent} from './demo-chatbot/demo-card/demo-card.component';
import {DemoSummaryComponent} from './demo-chatbot/demo-summary/demo-summary.component';
import {DemoMethodComponent} from './demo-chatbot/demo-method/demo-method.component';
import { DemoBraintreeComponent } from './demo-chatbot/demo-braintree/demo-braintree.component';
import { ChatbotComponent } from './chatbot/chatbot.component';
@NgModule({
  declarations: [ 
    DemoChatbotComponent,
    DemoMethodComponent,
    DemoSummaryComponent,
    DemoCardsComponent,
    AppComponent,
    JetsComponentShare,
    SmoothieComponent,
    PizzaWorldUSAComponentShare,
    PapaRomanosComponent,
    SimpleSimonsShareComponent,
    GattisComponentShare,
    EastOfChicagoComponentShare,
    CoolRunningsComponentShare,
    CenterCourtComponentShare,
    EastOfChicagoComponent,
    PizzaWorldUSAComponent,
    GhattisPizzaComponent,
    CenterCourtComponent,
    CoolRunningsComponent,
    HungryhowiesComponentShare,
    BlazeComponentShare,
    HungerRushComponent,
    RomeoComponentShare,
    MasterComponentShare,
    MasterComponent,
    EditComponent,
    MarcosComponentShare,
    ChowlyComponent,
    NavbarComponent,
    FooterComponent,
    TipComponent,
    BtComponent,
    CheckoutComponent,
    TermsComponent,
    PrivacyComponent,
    NotfoundComponent,
    HomeComponent,
    EditPageComponent,
    MethodComponent,
    ContactComponent,
    SummaryComponent,
    UserMsgComponent,
    FaxSummaryComponent,
    BraintreeComponent,
    DemoBraintreeComponent,
    Summary4faxComponent,
    PinSetComponent,
    PinPaymentComponent,
    PinPlateComponent,
    AudioMicComponent,
    PaymentSuccessComponent,
    PinSetBComponent,
    ReceiptComponent,
    SmoothieKingComponentShare,
    PaymentFailureComponent,
    MenuComponent,
    DisclaimerComponent,
    MenuDialogComponent,
    RestOfferPageComponent,
    OrderNowComponent,
    OrderingStepsDialogComponent,
    TextMarketingCheckComponent,
    ReferFriendComponent,
    ReferFriendDialogComponent,
    FriendReferralComponent,
    AddressBoxComponent,
    WorldPayComponent,
    FeedbackComponenentComponent,
    // AudioMicComponent,
    RestTextTiggerComponent,
    UIStartComponent,
    GetCustPhoneComponent,
    GetCustMsgComponent,
    GetMethodComponent,
    UpsellComponent,
    TestContentComponent,
    TestCompComponent,
    RatingStarsComponent,
    UpSellItemsComponent,
    ServerErrorComponent,
    StoreListTellFrndComponent,
    FeedbackFormComponent,
    InfoVideoComponent,
    QuickOrderComponent,
    QuickStartPageComponent,
    MenuBoxComponent,
    OrderBoxComponent,
    QuickOrderBaseComponent,
    ProNavbarComponent,
    ProAddressBoxComponent,
    PhoneMaskDirective,
    NewPhoneMaskDirective,
    MethodChangeDialogComponent,
    StickyHeaderDirective,
    HomeNewComponent,
    HomeContact,
    NewLoginComponent,
    ResetNewComponent,
    JetsComponent,
    MarcosComponent,
    HungryhowiesComponent,
    BlazeComponent,
    RomeoComponent,
    StoreIamHereComponent,
    RestOfferPageRdComponent,
    JetsRdComponent,
    AudioComponent,
    SafePipe,
    PaymentRedirectComponent,
    CurbsidePopupComponent,
    FaqComponent,
    SmoothieKingAllergiesComponent,
    WaybackBurgerComponent,
    WaybackComponent,
    SammysPizzaComponent,
    SammysPizzaShareComponent,
    NewPaymentComponent,
    GioniosComponent,
    GinoninosShareComponent,
    RewardsComponentComponent,
    FreeBirdsComponent,
    MannyandolgasComponent,
    GiovannispizzapowerComponent,
    BcPizzaComponent,
    FreebirdsShareComponent,
    MannyandolgasShareComponent,
    GiovannisShareComponent,
    BcpizzaShareComponent,
    BlackJackComponent,
    WingsToGoComponent,
    ExtremePizzaComponent,
    SimpleSimonsComponent,
    ExtremeComponent,
    BlackjackComponent,
    WingstogoComponent,
    LoginPopupComponent,
    LittleCaesarsComponent,
    FajitaPetesComponent,
    LittlecaesarsComponent,
    FajitaComponent,
    DefilicePizzaComponent,
    PizzaCottageComponent,
    JoseppisPizzaComponent,
    NorthernLightsComponent,
    ChatbotComponent,
   
   
    // FeedbackComponenentComponent,
  ],
  imports: [
    // ChartsModule,
    // ChartModule,
    // NgxMaterialTimepickerModule,
    // RatingModule,
    BrowserModule,
    NgOtpInputModule,
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
    HttpClientModule,
    HttpModule,
    BrowserAnimationsModule,
    CdkAccordionModule,
    MatProgressSpinnerModule,
    MatButtonToggleModule,
    MatDialogModule,
    MatButtonModule,
    MatSelectModule,
    MatTableModule,
    MatNativeDateModule,
    MatCheckboxModule,
    MatProgressBarModule,
    MatDividerModule,
    MatGridListModule,
    NgxBraintreeModule,
    Ng4LoadingSpinnerModule,
    MatDatepickerModule,
    MatCardModule,
    MatExpansionModule,
    MatInputModule,
    MatRadioModule,
    MatIconModule,
    PopoverModule,
    // SwiperModule,
    DeviceDetectorModule.forRoot(),
    RouterModule.forRoot([
      { path: "", component: HomeNewComponent },
      { path: "home-contact", component: HomeContact },
      // { path: 'papajohns', component: DemoStartComponent},
      // { path: 'papajohns7272', component: DemoComponent},
      // TXTORDR

      { path: "myordr/:ordrId", component: EditPageComponent },
      { path: "payment/:ordrId", component: NewPaymentComponent },
      // { path: 'checkout/:ordrId', component: CheckoutComponent },
      { path: "terms", component: TermsComponent },
      { path: "privacy", component: PrivacyComponent },

      { path: "jets/share", component: StoreListTellFrndComponent },
      { path: "marcos/share", component: StoreListTellFrndComponent },
      { path: "blaze/share", component: StoreListTellFrndComponent },
      { path: "romeos/share", component: StoreListTellFrndComponent },
      { path: "hungryhowies/share", component: StoreListTellFrndComponent },
      { path: "master/share", component: StoreListTellFrndComponent },
      { path: "centercourt/share", component: StoreListTellFrndComponent },
      { path: "coolrunnings/share", component: StoreListTellFrndComponent },
      { path: "eastofchicago/share", component: StoreListTellFrndComponent },
      { path: "gattis/share", component: StoreListTellFrndComponent },
      { path: "pizzaworld/share", component: StoreListTellFrndComponent },
      { path: "wayback/share", component: StoreListTellFrndComponent },
      { path: "sammys/share", component: StoreListTellFrndComponent },
      { path: "gioninos/share", component: StoreListTellFrndComponent },
      { path: "jets/textordering", component: InfoVideoComponent },
      { path: "simplesimon/share", component: StoreListTellFrndComponent },
      // { path: 'jets/feedback', component: FeedbackFormComponent },
      { path: ":chainId/help/:restId", component: FaqComponent },
      { path: ":chainId/help", component: FaqComponent },
      { path: "jets/:restId", component: RestOfferPageComponent },
      { path: "chowly/:restId", component: RestOfferPageComponent },
      { path: "simplesimon/:restId", component: RestOfferPageComponent },
      { path: "smoothieking/:restId", component: RestOfferPageComponent },
      { path: "blaze/:restId", component: RestOfferPageComponent },
      { path: "hungryhowies/:restId", component: RestOfferPageComponent },
      { path: "hungerrush/:restId", component: RestOfferPageComponent },
      { path: "romeos/:restId", component: RestOfferPageComponent },
      { path: "marcos/:restId", component: RestOfferPageComponent },
      { path: "master/:restId", component: RestOfferPageComponent },
      { path: "gioninos/:restId", component: RestOfferPageComponent },
      { path: "centercourt/:restId", component: RestOfferPageComponent },
      { path: "coolrunnings/:restId", component: RestOfferPageComponent },
      { path: "eastofchicago/:restId", component: RestOfferPageComponent },
      { path: "gattis/:restId", component: RestOfferPageComponent },
      { path: "pizzaworld/:restId", component: RestOfferPageComponent },
      { path: "wayback/:restId", component: RestOfferPageComponent },
      { path: "defelicebros/:restId", component: RestOfferPageComponent },
      { path: "pizzacottage/:restId", component: RestOfferPageComponent },
      { path: "paparomanos/:restId", component: RestOfferPageComponent },
      { path: "sammys/:restId", component: RestOfferPageComponent },
      { path: "joseppispizza/:restId", component: RestOfferPageComponent },
      { path: "northernlights/:restId", component: RestOfferPageComponent },
      { path: "freebirds/share", component: StoreListTellFrndComponent },
      { path: "mannyolgas/share", component: StoreListTellFrndComponent },
      { path: "giovannis/share", component: StoreListTellFrndComponent },
      { path: "bcpizza/share", component: StoreListTellFrndComponent },
      { path: "fajitapetes/share", component: StoreListTellFrndComponent },
      { path: "littlecaesars/share", component: StoreListTellFrndComponent },

      { path: "blackjack/share", component: StoreListTellFrndComponent },
      { path: "extreme/share", component: StoreListTellFrndComponent },
      { path: "wingstogo/share", component: StoreListTellFrndComponent },

      { path: "freebirds/:restId", component: RestOfferPageComponent },
      { path: "mannyolgas/:restId", component: RestOfferPageComponent },
      { path: "giovannis/:restId", component: RestOfferPageComponent },
      { path: "bcpizza/:restId", component: RestOfferPageComponent },
      { path: "littlecaesars/:restId", component: RestOfferPageComponent },
      { path: "fajitapetes/:restId", component: RestOfferPageComponent },

      { path: "blackjack/:restId", component: RestOfferPageComponent },
      { path: "wingstogo/:restId", component: RestOfferPageComponent },
      { path: "extreme/:restId", component: RestOfferPageComponent },

      { path: "ga1001/:restId", component: RestOfferPageRdComponent },
      { path: "ga1002/:restId", component: RestOfferPageRdComponent },
      { path: "ga1003/:restId", component: RestOfferPageRdComponent },
      { path: "ga1004/:restId", component: RestOfferPageRdComponent },
      { path: "ga1005/:restId", component: RestOfferPageRdComponent },
      // {path:'worldpay-test',component:WorldPayComponent},
      // { path: 'edit/:ordrId', component: FaxSummaryComponent },
      { path: "receipt/:recepitId", component: ReceiptComponent },
      // { path: 'hungryhowies/:restId', component: RestOfferPageComponent},
      { path: "menu/:restName", component: MenuComponent },
      { path: "refer/:referalId", component: FriendReferralComponent },
      { path: "offer/:offerId", component: RestOfferPageComponent },
      { path: "quick/:Rest/:RestId", component: QuickOrderBaseComponent },
      { path: "curb/:curbId", component: StoreIamHereComponent },
      { path: "fb/:fid", component: FeedbackComponenentComponent },
      
      { path: "paymentRedirect", component: PaymentRedirectComponent },
      {
        path: "login",
        component: NewLoginComponent,
      },
      {
        path: "reset-new/:hashValue",
        component: ResetNewComponent,
      },

      { path: "audio", component: AudioComponent },
      { path: "mic/jets/:restId", component: AudioMicComponent },
      // { path: 'kt', component: TestCompComponent },

      // { path: 'jetsmi26', component: RestTextTiggerComponent },
      // { path: ':restId/:restCode', component: UIStartComponent},
      {
        path: 'chat/jets/:user',
        component: DemoChatbotComponent
      },
      {
        path: 'chat/v1/:chain/:store',
        component: ChatbotComponent
      },
      { path: "**", component: NotfoundComponent },
     
    ]),
    NgbModule.forRoot(),
  ],
  entryComponents: [
    MenuDialogComponent,

    OrderingStepsDialogComponent,
    SmoothieKingAllergiesComponent,
    CurbsidePopupComponent,
    ReferFriendDialogComponent,
    LoginPopupComponent,
    MethodChangeDialogComponent,
  ],
  providers: [
    APICenterService,
    MatDatepickerModule,
    DataService,
    AudioRecordingService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
