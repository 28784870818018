<mat-card>
  <div class="flex-container">
          <div class = "img-box-1 animated fadeInDown"  style="margin: auto;"> <img src="/assets/images/romeologo.png"  class="img-fluid rounded w80 pB10"></div>               
      </div>
      <hr class="mT0 mB0">
      
      
     <span *ngFor="let state of stateList"  class="">
      <button type="button" (click)="selectState(state.statecode)"  class="btn bordr  btn-title ">{{state.statename}}</button>
     </span>
<span *ngIf="showState">     
<div *ngFor = "let sid of storeFinalDisplayList; index as i">
<hr>
<div class="flex-container" >
  <div >
      <div class="flex-container-col">
          <div class ="loc" style = "text-transform: capitalize"> {{sid.addy2.toLowerCase()}}</div>
          <div class ="loc-3" style = "text-transform: capitalize"> {{sid.addy1.toLowerCase()}}</div>
          <div class ="loc-2" (click) = "onMoreInfo(sid.id)"> More Info</div>
        </div>
     
  </div>
  <div class ="loc" style = "display:flex; justify-content: center; flex-direction: column;">
  <div style="padding:5px;" *ngIf = "sid.isTxt">
      <button
      mat-raised-button
      type="button"
      class="send-btn animated fadeInDown"
      (click) = "onStartText(sid.id)"
      >
      Txt Order </button>
  </div>
  
  <div style="padding:5px;" *ngIf = "sid.isPro">
      <button
      mat-raised-button
      style = "background-color: #0ba34e; border: #0ba34e"
      type="button"
      class="send-btn animated fadeInDown"
      (click) = "onProOrder(sid.id)"
      >
      Pro Order </button>
  </div>
  </div>
 <!--  <div class ="loc">
      <button
      mat-raised-button
      type="button"
      class="send-btn animated fadeInDown"
      >
      More Info </button>
  </div> -->
  
</div>

</div>
</span>
<app-footer></app-footer>
<app-order-now> </app-order-now>
</mat-card>