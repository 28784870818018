export class littleCaesarsStore {
  constructor() {}
  findStore(sid) {
    switch (sid) {
      case "lc360":
        let sidInfo = {
          discountTxt: "GET $5 OFF First order",
          isPro: false,
          isServiceFee: false,
          successTxt: "GET $5 OFF First order",
          successPro: "GET $5 OFF First order",
          isTxt: true,
          rid: "+13135138385",
          nickName: "N Barkers Landing Rd Ste",
          addy1: "15995 N Barkers Landing Rd Ste 111",
          addy2: "Houston, TX",
        };
        return sidInfo;

      case "demo":
        sidInfo = {
          discountTxt: "*",
          isPro: false,
          isServiceFee: false,
          successTxt: "Texting is the fastest way to order Little Caesars!",
          successPro: "PRO ORDERS ARE 20% OFF ON ALL PIZZAS",
          isTxt: true,
          rid: "+15862033838",
          nickName: "Research park dr ",
          addy1: "3917 Research Park Dr",
          addy2: "Ann Arbor, MI",
        };
        return sidInfo;
      case "xxx":
        return "+14194661111";
      case "xxx":
        return "+12159608888";
      default:
        sidInfo = {
          discountTxt: "*",
          isPro: false,
          isServiceFee: false,
          successTxt: "Texting is the fastest way to order Little Caesars!",
          successPro: "PRO ORDERS ARE 20% OFF ON ALL PIZZAS",
          isTxt: true,
          rid: "empty",
          nickName: "empty",
          addy1: "empty",
          addy2: "empty",
        };
        return sidInfo;
    }
    // console.log(cust)
  }
}
