import { ProServiceService } from './../pro-service.service';
import { APICenterService } from './../../apicenter.service';
import { Router,ActivatedRoute } from '../../../../node_modules/@angular/router';
import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, HostListener } from '@angular/core';
@Component({
  selector: 'app-order-box',
  templateUrl: './order-box.component.html',
  styleUrls: ['./order-box.component.css']
})
export class OrderBoxComponent implements OnInit {

  constructor(public api: APICenterService, public pro: ProServiceService, private _router: Router,  private route: ActivatedRoute) {

  }


  sticky = false;
  elementPosition: any;

  deliveryFlag = false;
  pickupFlag = false;
  isNext = true;
  i = 0;
  isApt = false;
  apt: string;
  part1;
  part2;
  finalAddy;
  addy;
  divHide = false;
  orderError = false;
  orderErrorMessage;
  isLoading=false;
  chainName=''
  focus() {
    this.pro.stickyHead = true;
    this.pro.isKeyBoard = true;
  }
  blur() {
    this.pro.stickyHead = false;
    this.pro.isKeyBoard = false;
  }
  ngOnInit() {
    this.route.url
     .subscribe(val => {
       console.log('URL Cutout');
       console.log(val);
       this.chainName =  val[1].path;
       let data= {
        restaurant:{
          name: this.chainName
        }
       }
       this.api.colourPicker(data)
     });
    if (this.pro.isProBack) {
      console.log('after back btn');
      console.log(this.pro.onBackObj);
      this.isNext = false;
      this.api.quickOrder.text = this.pro.onBackObj.custMsg;
      if (this.pro.onBackObj.isPickup) {
        this.pickupFlag = true;
        this.deliveryFlag = false;

      } else {
        this.deliveryFlag = true;
        this.pickupFlag = false;


        // console.log(this.pro.onBackObj.custAPt.length)
        if (this.api.quickOrder.isAPt) {
          if (this.pro.onBackObj.custAPt.length > 0) {
            console.log('delivery with apt');
            this.isApt = true;
            // this.addressbreakdown(this.pro.onBackObj.custAddy)
            this.part1 = this.pro.onBackObj.part1;
            this.part2 = this.pro.onBackObj.part2;
            this.apt = this.pro.onBackObj.custAPt;
          }
        } else {
          this.isApt = false;
          this.addy = this.pro.onBackObj.custAddy;
        }
      }
    } else {
      this.pickup();
    }
  }
  pickup() {
    if((this.api.quickOrder.text=='' || this.api.quickOrder.text== null)){
      this.isNext=true;
    }
    else{
      this.isNext=false;
    }
    this.pickupFlag = true;
    this.deliveryFlag = false;
    this.pro.onBackObj.isPickup = true;
    this.pro.onBackObj.isDelivery = false;

  }
  getAddress($event) {
    // console.log($event.formatted_address);
    this.addy = $event.formatted_address;
    this.pro.onBackObj.custAddy = $event.formatted_address;
    this.addressbreakdown(this.addy);
    this.api.quickOrder.address = $event.formatted_address;
    //this.onChange(this.api.quickOrder.address);
    if((this.api.quickOrder.address==''||this.api.quickOrder.address==null) || (this.api.quickOrder.text=='' || this.api.quickOrder.text== null)){
      this.isNext=true;
    }
    else{
      this.isNext=false;
    }
  }

  addressbreakdown(addy) {
    const addySplit = addy.split(',');
    const part2Arr = [];
    const key = addySplit.length;
    part2Arr.push(addySplit[key - 3]);
    part2Arr.push(addySplit[key - 2]);
    part2Arr.push(addySplit[key - 1]);
    this.part2 = part2Arr.join();
    this.pro.onBackObj.part2 = this.part2;
    this.part1 = this.addy.replace(this.part2, '');
    this.pro.onBackObj.part1 = this.part1;

  }


  setApt($event) {
    console.log($event);
    this.isApt = true;
    this.pro.onBackObj.custAPt = $event;
    this.apt = $event;
  }
  delivery() {
    this.api.quickOrder.address=null;
    if((this.api.quickOrder.address==''||this.api.quickOrder.address==null) || (this.api.quickOrder.text=='' || this.api.quickOrder.text== null)){
      this.isNext=true;
    }
    else{
      this.isNext=false;
    }
    this.deliveryFlag = true;
    this.pickupFlag = false;
    this.pro.onBackObj.isPickup = false;
    this.pro.onBackObj.isDelivery = true;
  }
  onChange($event){
    console.log($event)
    if($event.length>=3){
    if(this.pickupFlag){
    
      if($event==''|| $event==undefined|| $event==null){
        
        this.isNext=true;
      }
      else{
        this.isNext=false;
      }
    
    }
    if(this.deliveryFlag){
      if($event=='' || (this.api.quickOrder.address==''||this.api.quickOrder.address==null) ){
        
        this.isNext=true;
      }
      else{
        this.isNext=false;
      }
    }

  }
  else{
    this.isNext=true;
  }
  }
  onUpdate() {
    console.log('on-update worked');

    this.api.quickOrder.isQuickName = true;
    this.pro.isProBack = true;
    this.pro.onBackObj.custMsg = this.api.quickOrder.text;
    if (this.isApt && this.apt.length > 0 ) {
      this.api.quickOrder.isAPt = true;
      this.finalAddy = `${this.part1} Apt ${this.apt}, ${this.part2}`;
    } else {
      this.api.quickOrder.isAPt = false;
      this.finalAddy = this.addy;
    }
    if (this.deliveryFlag) {
      // Delivery true
      this.api.quickOrder.fullText = `${this.api.quickOrder.text} delivery to ${this.finalAddy}`;
    } else {
      this.api.quickOrder.fullText = this.api.quickOrder.text;
    }
    // this.api.quickOrder.restId = '+17344422075';
    if(this.chainName=='jets'){
    this.api.quickOrder.fullText= this.api.quickOrder.fullText + ' and best';
    }
    else if(this.chainName=='chowly'){
      this.api.quickOrder.fullText= this.api.quickOrder.fullText 
    }
    console.log(this.api.quickOrder);
    this.isNext = true;
    this.isLoading=true;
    this.api.resetItems();
    this.api.quickOrdr();
    this.api.getPostQuickOrdr()
      .subscribe((data) => {
        this.isNext = false;
        this.isLoading=false;
        this.api.ordrID = data.ordrId;
        this.resetState();
        if (data.ordrId.error) {
          this.orderError = true;
          this.orderErrorMessage = data.ordrId.error;
        } else {
        this.orderError = false;
        const routeId = `/myordr/${this.api.ordrID}`;
        this._router.navigate([routeId]);
        }
      });
    console.log('Onback');
    console.log(this.pro.onBackObj);
  }

back() {
  this.orderError = false;
  this.resetState();

}
  resetState() {
    this.api.isEdit = false;
    this.api.isState_0 = false;
    this.api.isContact = false;
    this.api.isCompSummary = false;
    this.api.isMethod = false;
    this.api.isPinset = false;
    this.api.isTip = false;
    this.api.isPaymentSuccess = false;
    this.api.isPinPayment = false;
    this.api.isBt = false;
    this.api.payOnline = true;
    this.api.payOption = false;
    this.api.payatStore = false;
  }

}
