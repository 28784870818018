import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-info-video',
  templateUrl: './info-video.component.html',
  styleUrls: ['./info-video.component.css']
})
export class InfoVideoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  onClick(){
    console.log('onclick')
    document.querySelector('video').load()
    //console.log(event)
  }


}
