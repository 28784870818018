<div class="image-container">
  <!-- Mobile View -->
  <img 
   class="pT0"
    *ngIf="isMobileView"
    src="assets/images/demo/mobile-bg.PNG" 
    alt="Mobile Background"
  >

  <!-- Desktop View -->
  <img 
  class="pT0"
    *ngIf="!isMobileView"
    src="assets/images/demo/background.png" 
    alt="Desktop Background"
  >
  
  <div style="margin: 0; background-color: black;">
    <div id="dynamicIframe" class="iframe-container">
      <!-- Replace with your actual iframe src -->
      <!--<iframe src="http://localhost:4200/chat/jets/JDS26"></iframe> -->
     <iframe src="https://txtord.com/chat/jets/JDS26"></iframe>  
    </div>
  </div>
</div>