<div *ngIf="!onChange" style="margin-top: -25px">
  <div *ngIf="!api.isState_0">
    <div *ngIf="!api.isMethodChanged">
      <div *ngIf="api.isContactFree">
        <div *ngIf="api.isFutureOrder">
          <mat-card
            class="text-4"
            [hidden]="api.tempFix"
            style="text-transform: uppercase"
          >
            <div class="container">
              <div class="row">
                <!-- <div class="col-2" style = "padding-top: 3px;">
                  <span class="material-icons" (click) = "openDialogMenu()" style = "border: 2px solid; border-radius: 7px; padding-left: 3px; padding-right: 3px; color: crimson">
                    menu_book
                    </span> 
                </div>-->
                <div
                  class="col-12"
                  style="
                    display: flex;
                    justify-content: center;
                    margin-top: 4px;
                  "
                >
                  <span
                    style="padding-top: 3px; font-size: 17px; font-weight: bold"
                  >
                    <span *ngIf="api.method.mtype.toLowerCase() == 'pickup'"
                      >CURBSIDE</span
                    >
                    <span *ngIf="api.method.mtype.toLowerCase() != 'pickup'"
                      >CONTACTLESS</span
                    >
                    {{ api.method.mtype }} at {{ api.method.fOrder }}
                  </span>
                  <span
                    *ngIf="!api.isMethodDisplay"
                    [ngStyle]="{ color: api.primaryColor }"
                    style="
                      padding-left: 15px;
                      text-decoration: none;
                      padding-top: 3px;
                    "
                    class="change-method"
                    (click)="changeMethod()"
                  >
                    <i>change</i>
                    <!-- <span class="material-icons swap">swap_horiz</span> -->
                  </span>
                </div>
              </div>
            </div>
          </mat-card>
        </div>
        <div *ngIf="!api.isFutureOrder">
          <mat-card
            class="text-4"
            [hidden]="api.tempFix"
            style="text-transform: uppercase"
          >
            <div class="container">
              <div class="row">
                <!--<div class="col-2" style = "padding-top: 3px;">
              <span class="material-icons" (click) = "openDialogMenu()" style = "border: 2px solid; border-radius: 7px; padding-left: 3px; padding-right: 3px; color: crimson">
                menu_book
                </span> 
            </div>-->
                <div
                  class="col-12"
                  style="
                    display: flex;
                    justify-content: center;
                    margin-top: 4px;
                  "
                >
                  <span
                    style="padding-top: 3px; font-size: 17px; font-weight: bold"
                  >
                    <span *ngIf="api.method.mtype.toLowerCase() == 'pickup'"
                      >CURBSIDE</span
                    >
                    <span *ngIf="api.method.mtype.toLowerCase() != 'pickup'"
                      >CONTACTLESS</span
                    >
                    {{ api.method.mtype }}
                  </span>
                  <span
                    *ngIf="!api.isMethodDisplay"
                    [ngStyle]="{ color: api.primaryColor }"
                    style="
                      padding-left: 15px;
                      text-decoration: none;
                      padding-top: 3px;
                    "
                    class="change-method"
                    (click)="changeMethod()"
                  >
                    <i>change</i>
                    <!-- <span class="material-icons swap">swap_horiz</span> -->
                  </span>
                </div>
              </div>
            </div>
          </mat-card>
        </div>
      </div>
      <div *ngIf="!api.isContactFree">
        <div *ngIf="api.isFutureOrder">
          <mat-card
            class="text-4"
            [hidden]="api.tempFix"
            style="text-transform: uppercase"
          >
            <div class="container">
              <div class="row">
                <!--<div class="col-2" style = "padding-top: 3px;">
                  <span class="material-icons" (click) = "openDialogMenu()" style = "border: 2px solid; border-radius: 7px; padding-left: 3px; padding-right: 3px; color: crimson">
                    menu_book
                    </span>
                </div>-->
                <div
                  class="col-12"
                  style="
                    display: flex;
                    justify-content: center;
                    margin-top: 4px;
                  "
                >
                  <span
                    style="padding-top: 3px; font-size: 17px; font-weight: bold"
                  >
                    {{ api.method.mtype }} at {{ api.method.fOrder }}
                  </span>
                  <span
                    *ngIf="!api.isMethodDisplay"
                    [ngStyle]="{ color: api.primaryColor }"
                    style="
                      padding-left: 15px;
                      text-decoration: none;
                      padding-top: 3px;
                    "
                    class="change-method"
                    (click)="changeMethod()"
                  >
                    <i>change</i>
                    <!-- <span class="material-icons swap">swap_horiz</span> -->
                  </span>
                </div>
              </div>
            </div>
          </mat-card>
        </div>
        <div *ngIf="!api.isFutureOrder">
          <mat-card
            class="text-4"
            [hidden]="api.tempFix"
            style="text-transform: uppercase"
          >
            <div class="container">
              <div class="row">
                <!-- <div class="col-2" style = "padding-top: 3px;">
              <span class="material-icons" (click) = "openDialogMenu()" style = "border: 2px solid; border-radius: 7px; padding-left: 3px; padding-right: 3px; color: crimson">
                menu_book
                </span>
            </div>-->
                <div
                  class="col-12"
                  style="
                    display: flex;
                    justify-content: center;
                    margin-top: 4px;
                  "
                >
                  <span
                    style="padding-top: 3px; font-size: 17px; font-weight: bold"
                  >
                    {{ api.method.mtype }} <span *ngIf="api.method.mtype === 'PICKUP'"> for ASAP TODAY</span>
                  </span>
                  <span
                    *ngIf="!api.isMethodDisplay"
                    [ngStyle]="{ color: api.primaryColor }"
                    style="
                      padding-left: 15px;
                      text-decoration: none;
                      padding-top: 3px;
                    "
                    class="change-method"
                    (click)="changeMethod()"
                  >
                    <i>change</i>
                    <!-- <span class="material-icons swap">swap_horiz</span> -->
                  </span>
                </div>
              </div>
            </div>
          </mat-card>
        </div>
      </div>

      <mat-card
        style="
          padding: 7px;
          padding-left: 0px;
          padding-right: 0px;
          font-size: 15px;
          line-height: 1.5;
          text-align: center;
          font-weight: bold;
        "
        [hidden]="api.method.mtype === 'PICKUP'"
        (click)="onEdit()"
      >
        {{ api.method.maddress }}
        <span
          style="font-weight: 400; text-transform: lowercase"
          [ngStyle]="{ color: api.primaryColor }"
          ><i *ngIf="!api.isMethodDisplay">Edit</i>
        </span>
      </mat-card>
    </div>
    <div *ngIf="!api.isFutureOrder && api.todayText !== '*'">
      <div *ngIf="api.method.mtype === 'PICKUP'">
        <mat-card class="text-4 txt-format" style  = "font-weight: bold;">
          {{api.todayText}}
        </mat-card>
      </div>
    </div>
    <div *ngIf="!api.isFutureOrder && api.ETAResponse.Delivery !== '*'">
      <div *ngIf="api.method.mtype === 'PICKUP'">
        <mat-card class="text-4 txt-format" [hidden]="api.tempFix">
          Estimated Pickup Time: {{ api.ETAResponse.PickUp }} Mins
        </mat-card>
      </div>
      <div *ngIf="api.method.mtype !== 'PICKUP'">
        <mat-card class="text-4 txt-format" [hidden]="api.tempFix">
          Estimated Delivery Time: {{ api.ETAResponse.Delivery }} Mins
        </mat-card>
      </div>
    </div>
   {{api.orderStatus.isProOrder}}
    <div *ngIf="(restName == 'freebirds') || (restName==='jets'&& api.isProOrder==true)"class="card no-border">
      <div
        class="card-header white-bg"
        style="padding-top: 6px; padding-bottom: 6px"
      >
        <p class="f-17 bold mB0 pT5 font-new">
          Got Rewards? 
          <span
            (click)="punchhSignIn()"
            *ngIf="punchhSignInBtn"
            style="
              position: absolute;
              right: 25px;
              font-weight: 500;
              text-decoration: underline;
            "
            [ngStyle]="{ color: api.primaryColor }"
            ><i class="siginout">Sign In</i></span
          >
          <span
            (click)="punchSignout()"
            *ngIf="!punchhSignInBtn"
            style="
              position: absolute;
              right: 25px;
              font-weight: 500;
              text-decoration: underline;
            "
            [ngStyle]="{ color: api.primaryColor }"
            ><i class="siginout">Sign Out</i></span
          >
        </p>
      </div>
    </div>
    <div class="">
      <div class="card no-border">
        <div
          class="card-header white-bg"
          style="padding-top: 6px; padding-bottom: 6px"
        >
          <p class="font-new">
            <span class="f-17 bold">Order Summary </span
            ><span class="f-15 bold"
              >({{ api.items.length }}
              <span *ngIf="api.items.length == 1">Item</span>
              <span *ngIf="api.items.length > 1">Items</span>)</span
            >
            <span *ngIf="api.isDeleteBtn">
              <button
                *ngIf="api.restaurant.rid != '+15512911234'"
                type="button"
                (click)="openDialogMenu()"
                [ngStyle]="{ 'background-color': api.primaryColor }"
                class="btn white-c mT5 fRight font-new-menu"
              >
                <i class="fa fa-list" aria-hidden="true"></i> &nbsp; Menu
              </button>
            </span>
          </p>
        </div>
      </div>
    </div>

    <div *ngIf="api.isMethodChanged">
      <mat-card class="text-4" style="text-transform: uppercase">
        LOADING...
        <span
          style="padding-left: 15px; text-decoration: none"
          class="change-method"
        ></span>
      </mat-card>
    </div>
  </div>
  <div *ngIf="api.isState_0">
    <mat-accordion>
      <mat-expansion-panel *ngIf="!api.isMethodChanged">
        <mat-expansion-panel-header style="padding-left: 2px">
          <mat-panel-title class="text-2">
            <div>
              <div
                *ngIf="!api.onChangeLink"
                [hidden]="api.pinMsg"
                class="change-method"
                style="font-size: 17px; letter-spacing: 1px; font-weight: 400"
                (click)="onMethodChange()"
              >
                <!-- edit order -->
                <!-- <span class="material-icons">
                      local_pizza
                      </span>  -->
                <span
                  [ngStyle]="{ color: api.primaryColor }"
                  style="border: 2px solid; border-radius: 4px"
                  class="material-icons"
                >
                  edit
                </span>
              </div>
              <div></div>
            </div>

            <div
              *ngIf="!api.isMethodDisplay"
              style="
                display: inline-flex !important;
                padding-top: 10px;
                text-transform: uppercase;
                font-size: 17px;
                font-family: Helvetica, sans-serif;
              "
            >
              {{ api.capFirstWord(api.method.mtype) }}
              <span
                *ngIf="!api.isMethodDisplay"
                [ngStyle]="{ color: api.primaryColor }"
                style="
                  padding-left: 15px;
                  text-decoration: none;
                  padding-top: 3px;
                "
                class="change-method"
                (click)="changeMethod()"
              >
                <i>change</i>
                <!--  <span class="material-icons swap">
                      swap_horiz
                      </span> -->
              </span>
            </div>
            <div
              *ngIf="api.isMethodDisplay"
              style="
                display: inline-flex !important;
                padding-top: 10px;
                font-size: 15px;
              "
            >
              <span *ngIf="api.method.mtype === 'PICKUP'; else delivery">
                {{ api.capFirstWord(api.method.mtype) }} Location</span
              >
              <ng-template #delivery>
                <span>{{ api.capFirstWord(api.method.mtype) }} Address</span>
              </ng-template>
            </div>
            <div></div>
          </mat-panel-title>
        </mat-expansion-panel-header>

        <div class="text-3">
          {{ api.method.maddress }}
        </div>
      </mat-expansion-panel>

      <mat-expansion-panel *ngIf="api.isMethodChanged">
        <mat-expansion-panel-header style="padding-left: 2px">
          <mat-panel-title class="text-2">
            <div></div>

            <div style="display: inline-flex !important">Loading...</div>
            <div></div>
          </mat-panel-title>
        </mat-expansion-panel-header>

        <div class="text-3"></div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>
