<h4 style="display: flex; justify-content: center; padding: 10px;">Terms and Conditions</h4>
<p class="para">THIS END USER LICENSE AGREEMENT (“EULA“) IS A BINDING AGREEMENT. BY USING THE TxtOrdr® SITE YOU
        ACKNOWLEDGE THAT YOU HAVE READ THIS EULA AND OUR PRIVACY POLICY (which is available at privacy.TxtOrdr.com) (THE
        “PRIVACY POLICY“), THAT YOU UNDERSTAND THEM, AND THAT YOU AGREE TO BE BOUND BY THE EULA AND THE PRIVACY POLICY
        (WHICH IS INCORPORATED HEREIN BY REFERENCE). IF YOU DO NOT AGREE WITH ANY OF THE TERMS AND CONDITIONS OF THIS
        EULA OR THE PRIVACY POLICY, DO NOT USE THE TxtOrdr SITE.
        By entering into the EULA you, to the extent legally permitted, hereby waive any applicable law or regulation
        requiring that the EULA be localized to meet your language, as well as any other local requirements.</p>
<p class="para">TxtOrdr reserves the right to make changes to this EULA at any time by posting the changed EULA
        at eula.TxtOrdr.com (we may also post the changed EULA within the Site, but the version
        at eula.TxtOrdr.com shall be the definitive version). Such changes will be effective ten (10) days after such
        posting, and your continued use of the Site means that you agree to be bound by the changes. Please check the
        above webpage regularly for any changes.
        Definitions
        This EULA contains a range of capitalized terms, some of which are defined in this Definitions Section, and some
        of which are defined elsewhere.
        “Content” means, but is not limited to, text, data, information, documents, images, descriptions, graphics,
        photos, sounds, videos, photographs, audio clips, advertisements, posts, comments, links, software code and
        scripts.
        “Other Content” means Content you upload or link to your Account (as defined below), such as your name, profile
        picture, profile video, email address, birthday, and telephone number.
        “Account Content” means, collectively, your Sent Messages (as defined below) and Other Content.
        Grant of License. Ordr Inc. (“Ordr”, “TxtOrdr”, “we”, “us” and “our”) provides a content messaging application
        for mobile devices (the “App“). Subject to the terms and conditions of this EULA, TxtOrdr grants you, during the
        term of this EULA, a non-exclusive, non-transferable, non-sublicensable, revocable license to use the Site
        solely for your own personal (and non-commercial) use (the “License“).
        Representation Concerning Age. You represent that you are at least thirteen (13) years old, but in any event of
        a legal age to form a binding contract. Children under the age of thirteen (13) are not permitted to use the
        Site. If you are between thirteen (13) and eighteen (18) years old, you must review this EULA with your parent
        or guardian.
        Account. In order to use the Site, you must have a telephone number (“Phone Number“), and create an account
        using that Phone Number (“Account“). You may not provide false information during the registration process. You
        acknowledge that as part of the registration process, TxtOrdr may cause an SMS message to be sent from your
        device to TxtOrdr’s servers, following which TxtOrdr may place or send a verification call or SMS message back
        to your device. You agree to immediately notify TxtOrdr (via contact info at support.TxtOrdr.com) of any
        unauthorized use of your Account. You are fully and solely responsible for the security of your computer system
        or mobile device, as well as all activity on or in your Account (even if such activities were not undertaken by
        you). TxtOrdr will not be liable for any losses or damage arising from unauthorized use of your Account, and you
        agree to indemnify and hold TxtOrdr harmless for any improper or illegal use of your Account. Please notify us
        via contact info at support.TxtOrdr.com in case your Account has been compromised to request us to block access
        to it. This includes illegal or improper use by someone to whom you have given permission to access or use your
        Account.
        Restrictions. Except to the extent expressly permitted in this EULA, you shall not, and shall not permit or
        encourage any third party to, do any of the following: (i) copy the App; (ii) sell, assign, lease, lend, rent,
        distribute, sublicense, or make available the Site to any third party, or otherwise use the Site to operate in,
        or as, a time-sharing, outsourcing, or service bureau environment; (iii) modify, alter, adapt, arrange,
        translate, decompile, disassemble, reverse engineer, or otherwise attempt to derive the source code or
        non-literal aspects (such as the underlying structure, sequence or organization) of, the App; (iv) remove,
        alter, or conceal, in whole or in part, any copyright, trademark or other intellectual property rights notices
        or legends displayed or contained on/in the App; (v) circumvent, disable or otherwise interfere with
        security-related features of the Site, or with features that prevent or restrict use of the App; (vi) make a
        derivative work of the Site, or use the Site to develop any service or product that is the same as, or
        substantially similar to, the App; (vii) publish, transmit, or link to any robot, spider, crawler, virus,
        malware, Trojan horse, spyware, or similar malicious code or item intended (or that has the potential) to
        damage, disrupt, compromise, or exploit the Site or a third party’s browser, computer, or mobile (or other)
        device (such items, “Malicious Software“); and/or (viii) use the Site in any way that is infringing, deceptive,
        harassing, or defamatory, or for any inappropriate purpose (as TxtOrdr shall determine at its sole and absolute
        discretion), or contrary to any applicable law or regulation. References in this Section _4 (Restrictions) to
        the Site shall be taken to mean (a) the Site, whether in whole or in part; and (b) any documentation (whether in
        whole or in part) related to the Site that TxtOrdr makes available to you (such documentation, “Documentation“).
        You acknowledge and agree that your full compliance with the foregoing restrictions is a condition to the
        License.
        Third Party Sources and Content.</p>

<p class="para">The Site may present, or otherwise allow you to view, access, link to, and interact with, Content from
        Third Party Sources (as those terms are defined below) that are not owned or controlled by TxtOrdr (such
        Content, “Third Party Content“). The Site may also enable you to communicate and interact with Third Party
        Sources. “Third Party Source(s)” means: (i) third party websites and service providers; and/or (ii) our partners
        and advertisers.
        TxtOrdr is not affiliated with, and has no control over, any Third Party Sources. TxtOrdr does not assume any
        responsibility for Third Party Content, or any Third PartySource’s terms of use, privacy policies, actions or
        practices. Please read the terms of use and privacy policy of any Third Party Source that you interact with
        before you engage in any such activity. TxtOrdr does not endorse any advertising, promotions,
        campaigns, products, services, or other materials contained in any Third Party Content or that is communicated
        to you from a Third Party Source.</p>
<p class="para">By using the Site you may be involuntarily exposed to Third Party Content, as well as Content from other
        Site users, that is inaccurate, offensive, indecent, unlawful or objectionable. Your interaction with a Third
        Party Source, as well as your use of and reliance upon any Third Party Content, is at your sole discretion and
        risk, and you are solely responsible and liable with respect thereto.
        TxtOrdr IS NOT RESPONSIBLE FOR, AND EXPRESSLY DISCLAIMS ANY AND ALL REPRESENTATIONS, WARRANTIES, CONDITIONS AND
        GUARANTEES OF, THIRD PARTY CONTENT OR OTHERWISE IN CONNECTION WITH THIRD PARTY SOURCES, INCLUDING WITHOUT
        LIMITATION REGARDING THEIR ACCURACY, APPROPRIATENESS, USEFULNESS, SAFETY, OR INTELLECTUAL PROPERTY RIGHTS.
        You agree to waive, and hereby do waive, any legal or equitable rights or remedies you may have against TxtOrdr,
        and release TxtOrdr from any and all liability, arising from your viewing, accessing, linking to, or interacting
        with any Third Party Content and/or Third Party Source, or from otherwise using or relying upon the foregoing.
        If you have any query or complaint regarding a Third Party Source or any Third Party Content, you agree to
        contact the Third Party Source directly.
        Ownership. Except for the License, you have no rights in or to the Site. Ownership of the Site and all
        intellectual property rights therein shall remain at all times with TxtOrdr and/or its licensors. All rights not
        granted to you herein are reserved to TxtOrdr and/or its licensors. Any references to the sale or transfer of
        the Site in this EULA or in any other communication between TxtOrdr and you, shall mean only the License. You
        have no obligation to provide us with any feedback, ideas or suggestions concerning the Site (collectively,
        “Feedback“). Nevertheless, to the extent you provide us with any Feedback, you hereby grant TxtOrdr and its
        affiliated entities a worldwide, non-exclusive, royalty-free, fully-paid, perpetual, irrevocable, sublicensable
        (through multiple tiers of sublicensees), and transferable license to use, copy, distribute, transmit, modify,
        create derivative works of, publicly display, publicly perform, and otherwise commercially exploit such
        Feedback, in any media format and through any media channels (the “Feedback License“).
        Messaging.
        Sending Messages. The Site allows you to send various types of Content to Recipients (“Sent Messages“). A
        “Recipient” can be another Site user and/or any other third party.
        Receiving Messages. The Site allows you to receive Content from other Site users (“Received Messages“) and third
        parties (including advertisers). You may also receive Content directly from TxtOrdr, including for example,
        messages about your Account, promotions of new Features, notifications (including without limitation about new
        user groups, about any of your contacts that have used the Site, and Messages that other Site users have sent to
        you), and telemarketing messages (“Administrative Messages“).</p>
<p class="para">
        By using the Site, you hereby give express consent to receive Received Messages (including without limitation,
        Inactivity Reminders, as defined below), Administrative Messages and advertisements, via any and all the
        Delivery Channels (as defined and described below).
        In this EULA, we refer to Sent Messages, Received Messages, and Administrative Messages collectively as
        “Messages“.
        Delivery Channels. The Site may utilize one or more delivery channels to send and receive Messages, such as, but
        not limited to, by way of the following: (a) TxtOrdr’s proprietary messaging protocol, (b) simple messaging
        service and multimedia messaging services (collectively, “SMS“), (c) e-mail; and (d) third party notification
        services, such as GCM, APNS, WNS, and data stream networks (each of the foregoing, a “Delivery Channel“).
        You are the Sender. Except for Administrative Messages TxtOrdr does not make or initiate any Messages; rather,
        the Site simply facilitates the sending and receiving of the Messages on your behalf. Accordingly, with respect
        to any and all Sent Messages (which, for the avoidance of doubt, also covers Invitation Reminders and Inactivity
        Reminders, each as defined below), you agree that:
        you (and not TxtOrdr) are, and will be deemed, the sender of such Messages, regardless of the Delivery Channel
        utilized, regardless of the Recipient, and regardless of any other provision in this EULA that addresses issues
        of obtaining Recipients’ prior express consent; and
        you are solely responsible and liable for such Messages.
</p>


<p class="para">
        Obtaining Recipient Consent; Indemnity. If the Recipient of a Sent Message does not have an Account, then:
        you acknowledge that the Message will be sent as an SMS, and that such SMS may be sent directly from your mobile
        device and/or via TxtOrdr’ss servers (or those of its third party service providers);
        you acknowledge that many jurisdictions have laws and regulations that require such a Recipient to give
        his/her prior express written and signed consent to receive such an SMS Message, and you agree to:
        obtain such consent prior to sending the Message,
        clearly and conspicuously disclose to the Recipient the consequences of providing such consent (such as, for
        example, the fact that he/she will also receive Invitation Reminders), and the fact that Recipient’s ability to
        use the Site is not conditioned on giving such consent, and
        provide TxtOrdr, upon request, with a record or other evidence of such consent;
        by sending the Message (including without limitation Invitations, as defined below) you are representing that
        you have obtained the Recipient’s prior express written and signed consent to receive the SMS Message and have
        complied with Subsection (v)(b) above;
        you warrant that you will immediately notify TxtOrdr if the Recipient revokes his/her consent and/or if the
        Recipient changes his/her Phone Number, by channels found at support.TxtOrdr.com
        you agree to assist, and fully cooperate with TxtOrdr, in investigating and defending any third party demand,
        claim, suit, action, or proceeding (such as, but not limited to, one brought by a regulator or other
        governmental authority) alleging that TxtOrdr has violated any consumer protection laws (including without
        limitation, the United States’ Telephone Consumer Protection Act of 1991) in connection with such a Message
        (such claims and proceedings “Messaging Claims“), as well as refrain from admitting any liability under such
        Messaging Claims; and
        you agree to indemnify and hold harmless each of TxtOrdr, its affiliated entities, and any of our respective
        directors, officers, and employees (“Message Claim Indemnitees“) for and against:
        any costs and expenses (including reasonable attorneys’ fees) incurred by the Message Claim Indemnitee in
        investigating and/or defending the Messaging Claim(s); and
        any amounts (such as, but not limited to, fines and penalties) awarded against or imposed upon the Message Claim
        Indemnitee under a Messaging Claim.
        Invitations and Auto-Reminders. If a Sent Message is an invitation to the Recipient to use the Site
        (“Invitations“), then:
        for the avoidance of doubt, because the Recipient does not have an Account and the Delivery Channel will be
        SMS, paragraphs (a) through (f) in Section 7(v) above apply, and you further and specifically represent that the
        prior express written and signed consent you obtain from the Recipient also covers the Invitation Reminders (as
        defined below);
        the Site may allow you to customize or edit some of the Content in the Invitation; and
        by sending the Invitation, you acknowledge that TxtOrdr’s servers (or those of its third party service
        providers) may automatically generate up to three (3) follow-up Messages to be sent to the Recipient via SMS,
        reminding him/her of the Invitation (“Invitation Reminders“) and you agree that you are, and are deemed to be,
        the sender of the Invitation Reminders.
        Sent Messages that are “pseudo”-invites – meaning that the Messages (although not styled as an Invitation)
        require or encourage the Recipient to use the Site in order to access the underlying Content – will be deemed
        Invitations under this EULA, and all provisions of this Section 7(vi) apply to such pseudo-invites.
        Inactivity Reminders. If a Sent Message is to a Recipient with an Account but who TxtOrdr detects has not used
        the Site for a certain period of time, or to a Recipient who previously had an Account, then:
        (A) TxtOrdr’s servers (or those of its third party service providers) may automatically cause a Message to be
        sent from your device to the Recipient via SMS, or
        (B) TxtOrdr’s servers (or those of its third party service providers) may automatically cause a Message to be
        sent from such server(s) to the Recipient via SMS and/or email,
        in each of the foregoing cases (x) to remind him/her of his/her Account, and/or (y) to alert and direct him/her
        to (or otherwise contain) the Message you sent (collectively, “Inactivity Reminders“);
        you agree that (although the Recipient has an Account) SMS is the most suitable Delivery Channel for sending the
        Inactivity Reminder in cases where TxtOrdr still has the Recipient’s Phone Number; and
        you agree that, because the Delivery Channel of the Inactivity Reminder will be SMS, paragraphs (a) through (f)
        in Section 7(v) above apply, and you further and specifically represent that the prior express consent you
        obtain from the Recipient also covers the Inactivity Reminders.
        Fees and Carriers. At select restaurant locations, TxtOrdr charges a small Text Convenience Fee to help operate
        TxtOrdr.
        At other restaurant locations, TxtOrdr does not currently charge a Text Convenience Fee to use the platform,
        although it
        may do so in the future. TxtOrdr may change the Text Convenience Feeas we deem necessary or appropriate for our
        business. Please be aware that Sent Messages and Received Messages via SMS
        (and, for the avoidance of doubt, this also covers Invitation Reminders and Inactivity Reminders) may be subject
        to carrier messaging rates, if any, and that these are payable by the senders and/or recipients, as determined
        by the carriers. TxtOrdr is not responsible for any third-party fees (including but not limited to carrier
        charges, airtime, text or data charges) incurred by you or others. If you have any questions about your text
        plan or data plan, contact your carrier. For questions about SMS, you can send an email to <a
                href="mailto:info@ordrai.com">info@ordrai.com</a> and we will try to answer your question. Messages can
        be sent through most major carriers, although TxtOrdr may not be able to support all carriers at all times. If
        you are unable to receive Messages on your carrier, feel free to let us know at <a
                href="mailto:info@ordrai.com">info@ordrai.com</a>.
        Deactivation and Content Deletion. In the event you change or deactivate your Phone Number:
        your access to your Account will be disabled;
        you acknowledge that your Sent Messages, Received Messages, and Other Content may be sent to (or otherwise
        received by or made accessible to) the person(s) who subsequently acquire(s) such Phone Number, and that such
        person(s) may end up modifying it, deleting it, or using it in other ways of which you may not approve; and
        you agree that you will not hold TxtOrdr liable, will not bring any action against TxtOrdr, and hereby release
        TxtOrdr, in connection with the situations described in the foregoing paragraph (a).
        Accordingly, we encourage you to use whatever Site tools are available within the Site to delete as much Account
        Content as possible prior to changing or deactivating your Phone Number. With respect to:
        other Account Content that cannot be deleted via Site tools, you can send a request for deletion to <a
                href="mailto:info@ordrai.com">info@ordrai.com</a>.
        Received Messages, you can send a request for deletion to <a href="mailto:info@ordrai.com">info@ordrai.com</a>.
        You acknowledge and agree that: (x) TxtOrdr shall have no obligation to respond to a request for deletion; and
        (y) Content you delete (whether directly by you or pursuant to a delete request) – whether Sent Messages,
        Received Messages, or Other Content – may not be permanently deleted by TxtOrdr, and may remain stored on our
        servers. In such cases, the Content is simply disassociated from your Account (meaning that you will not have
        access to it).We, in our sole and absolute discretion, decide whether or not to permanently delete Content from
        our servers (even after any termination of this EULA).
        Similarly to what we describe above, as an Account holder, you acknowledge that your Phone Number may have
        previously belonged to a past Account holder, and you therefore agree not to hold TxtOrdr liable for, not to
        bring any action against TxtOrdr for, and hereby release TxtOrdr regarding, any Content belonging to such past
        Account holder, which is sent, received, or made available to your device or Account. In addition, you agree
        that you will cooperate with us and follow any instructions in connection with Messages or Other Content
        belonging to prior Account holders.
        Privacy Questions. If you have any questions regarding privacy, please read the Privacy Policy (which is
        available at privacy.TxtOrdr.com), and which is incorporated herein by reference.     
        Responsibility for Content.
</p>

<p class="para">
        You are solely responsible and liable for your Account Content. (Needless to say, TxtOrdr is not responsible or
        liable for your Account Content or Received Messages.)You represent that: (a) your Account Content complies with
        all applicable laws and regulations, and does not infringe, misappropriate, or violate any third party’s
        intellectual property rights or personal rights (such as, but not limited to, moral rights, rights of privacy,
        and publicity rights); (b) your Account Content is not disparaging, threatening, offensive, harassing,
        deceptive, abusive, promoting of violence, and does not contain obscenity or pornography; (c) your Account
        Content does not contain any Malicious Software; and (d) you have obtained any and all licenses, permissions,
        consents, and authorizations required to grant TxtOrdr the Content License (defined below). You also warrant
        that the foregoing representations in (a) through (d) will continue to remain true and accurate. TxtOrdr has no
        obligation to accept, display, review or maintain any of your Account Content or Received Messages. TxtOrdr may,
        without notice, edit and/or delete your Account Content and Received Messages in our sole and absolute
        discretion, including, without limitation, if we determine that you (or the sender of the Received Message) are
        in breach of any provision of this EULA (for example, if your Account profile picture contains nudity, we may
        replace the picture with another picture we deem appropriate) or have violated (or are suspected of violating)
        any law or regulation. Without limiting the generality of Subsections (i) and (ii) above, you shall not, and
        shall not permit or encourage any third party to, do any of the following:
        send, post, transmit, upload, or make available to or on the Site any Content containing another person’s
        private or confidential information (including without limitation intimate photos or videos, or those revealing
        nudity or sexual activity) that was taken without the subject’s prior express consent or distributed without
        his/her prior express consent. If you feel that you have been a victim of such behavior please contact us at the
        following address: <a href="mailto:info@ordrai.com">info@ordrai.com</a>. After you submit such notification you
        will need to undergo identity verification to verify that you are the “individual in question” appearing in the
        Content, and that the Content was sent, posted, transmit, uploaded, or made available to or on the Site without
        consent;
        use the Site to send advertisements, promotions, spam, or other commercial communications, or for any other
        telemarketing purpose; and/or
        conduct or promote any illegal activity on or through the Site.
</p>

<p class="para">
        Site Features. The Site contains various functions, features and tools (collectively, “Features“). TxtOrdr
        reserves the right, in our sole and absolute discretion, to remove, modify, and/or add Features at any time,
        without any notice to you, and for any reason whatsoever. Some Features may in any event be limited, suspended
        or restricted by geography, volume, duration or any other criteria decided by TxtOrdr. Moreover, if TxtOrdr
        determines, in our sole and absolute discretion, that you are in breach of any provision of this EULA, we
        reserve the right to block you from certain Features, as well as block your presence, your Account Content
        and/or your Received Messages from other users. We make Features available because we believe it enhances the
        user experience of the App; but you acknowledge and agree that just because we make a Feature available, it does
        not mean we endorse, or can otherwise control, every manner in which such Feature is used. It is your sole
        responsibility and liability to ensure that you use all Features in accordance with the restrictions described
        in this EULA as well as applicable laws and regulations.
        Location Features. Certain Features may collect (or be dependent on) data related to your geographic location
        (“Location Data“). If you wish to use such Features, you agree to provide or make accessible your Location Data.
        To the extent that we do collect Location Data, we will use it in accordance with the Privacy Policy (which is
        available at privacy.TxtOrdr.com). If you do not provide or make accessible such Location Data then such
        Features may be limited or not operate.
        Search Features. The Site may include a search Feature that allows you to make generic searches of other Site
        users (for example, by searching for “David”), which search results list all relevant users that match the
        search terms, as well as some of their Other Content (such as Account name, profile picture, and profile video).
        You expressly consent to other Site users making such generic searches that may result in your Account and
        certain of your Other Content being made available to the searcher. If you do not wish to be searchable in this
        manner, you may opt-out of this Feature by turning it off via the App’s settings.
</p>

<p class="para">
        Beta Products. TxtOrdr may from time to time make available, in “beta” mode, new versions of the Site and/or new
        Features that are still undergoing internal development and testing (each, a “Beta Product“). Beta Products may
        be time-limited, feature-limited, and/or functionality-limited. TxtOrdr may also decide to only make a Beta
        Product available to closed list of Site users. If TxtOrdr makes a Beta Product available to you, you may use it
        on the following conditions: (i) you only use it for evaluation purposes; (ii) you comply with any specific
        guidelines issued by TxtOrdr in respect of the Beta Product, which may include a requirement that you provide
        Feedback and participate in surveys about the Beta Product; and (iii) you do not publicize the fact that there
        is a Beta Product, and you do not show, display, or otherwise make available the Beta Product to any other
        person (which includes, without limitation, making such disclosures to traditional or social media). For the
        avoidance of doubt, the provisions of this EULA that apply to the Site (for example
        only: the License Restrictions in Section _4, and the Warranty Disclaimer in Section _14) shall also apply to
        Beta Products.
        TxtOrdr’ss Use of Content. You hereby grant TxtOrdr and its affiliated entities a worldwide, non-exclusive,
        royalty-free, fully-paid, perpetual, irrevocable, sublicensable (through multiple tiers of sublicensees), and
        transferable license to use, copy, distribute, transmit, modify, create derivative works of, publicly display,
        publicly perform, and otherwise commercially exploit your Account Content, in any media format and through any
        media channels (the “Content License“). In using your Account Content to commercially promote the Site or
        TxtOrdr’ss business (for example, in marketing campaigns), TxtOrdr will make commercially reasonable efforts to
        remove a Content that TxtOrdr considers, in its sole and absolute discretion, to be especially private or
        confidential; but, for clarity, TxtOrdr does not consider your name or Account profile picture to be private or
        confidential for such purposes (and you acknowledge this).
        Storage. The Site is not a data storage product or service, and you shall not rely on the Site for the storage
        of any Content whatsoever. If this EULA is terminated or TxtOrdr decides to cease provision of the Site or to
        cease the length of time it currently stores any Content, you and third parties may permanently lose all access
        to Account Content and your Received Messages. Currently, you may back-up images and videos by downloading them
        directly to your device.
        Third Party Software. The Site may contain third party, including open source, software (“Third Party Software“)
        that may be subject to third party terms and conditions (“Third Party Terms“). In such case, TxtOrdr may make
        available a list of any such Third Party Software and related Third Party Terms in the Documentation, and will
        comply with any request you submit to us for exercising your rights under such Third Party Terms. To the extent
        of any conflict between any Third Party Terms and the terms or conditions of this Agreement, the Third Party
        Terms shall prevail in connection with the corresponding Third Party Software.
</p>

<p class="para">
        Advertisements, links, and commercial information in the Site. The Site may include commercial information or
        advertisements (“Ads”). The Ads may pop up or be displayed in the Site from time to time. TxtOrdr may post Ads
        on the Site but more often than not, the source of such Ads is third parties, and as such, TxtOrdr cannot and
        does not guarantee the reliability or accuracy of third party Ads. TxtOrdr does not endorse the advertisers or
        the content of third party Ads. Additionally and without derogating from the previous sentence, TxtOrdr will not
        be liable for any form of liability arising from your reliance on, or in connection with, the use of the content
        of Ads posted on the Site. It is underscored that the advertising of commercial content by TxtOrdr does not
        constitute a recommendation or encouragement to procure the goods or services advertised. Insofar as the Site
        includes links to services or applications not operated or managed by TxtOrdr, TxtOrdr will not be liable for
        any form of liability arising from your reliance on, or in connection with, the content of such services and
        applications or any information provided by them, including but not limited to its completeness, accuracy,
        correctness or it being up-to-date. TxtOrdr will not be liable for any direct or indirect damage, monetary or
        otherwise, arising from your use of or your reliance on the content of goods or services you have accessed via
        Ads or other links on the Software.
        Warranty Disclaimer. THE SITE IS PROVIDED TO YOU ON AN “AS IS” AND “AS AVAILABLE” BASIS, WITHOUT ANY
        REPRESENTATION, WARRANTY, GUARANTEE OR CONDITION OF ANY KIND WHATSOEVER (WHETHER EXPRESS, IMPLIED OR STATUTORY),
        INCLUDING WITHOUT LIMITATION ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE,
        NON-INFRINGEMENT, AND QUALITY OF SERVICE, OR THAT OTHERWISE ARISE FROM A COURSE OF PERFORMANCE OR USAGE OF
        TRADE, ALL OF WHICH ARE HEREBY DISCLAIMED BY TxtOrdr.IN ADDITION, TxtOrdr MAKES NO REPRESENTATION, WARRANTY,
        GUARANTEE OR CONDITION:
        REGARDING THE CONTENT, EFFECTIVENESS, USEFULNESS, RELIABILITY, AVAILABILITY, TIMELINESS, QUALITY, ACCURACY, OR
        COMPLETENESS OF THE APP; OR
        THAT YOUR USE OF THE SITE WILL MEET YOUR REQUIRMENTS OR EXPECTATIONS, OR WILL BE INTERRUPTED, SECURE OR
        ERROR-FREE.
        Some jurisdictions do not allow the exclusion of certain implied warranties, so to that extent some of the above
        exclusions may not apply to you.
        Indemnification. If any third party (such as, but not limited to, a regulator or governmental authority) brings
        any kind of demand, claim, suit, action or proceeding (which is not a Messaging Claim) against TxtOrdr, our
        affiliated entities, and/or any of our respective directors, officers, employees, agents, representatives,
        customers, suppliers, or licensors (each, an “Indemnitee“), and it is based upon or arises from:
        your use of the App;
        your breach of any provision in this EULA; and/or
        any of your Account Content
        (each of the foregoing, a “Claim“) then, upon request by TxtOrdr (to be decided in its sole and absolute
        discretion), you agree to assume full control of the defense and settlement of the Claim; provided,
        however, that (a) TxtOrdr reserves the right, at any time thereafter, to take over full or partial control of
        the defense and/or settlement of the Claim; and (b) you shall not settle any Claim, or admit to any liability
        thereunder, without the express prior written consent of TxtOrdr. In addition, and regardless of whether (or the
        extent to which) you participated in the defense and/or settlement of a Claim, you agree to indemnify and hold
        harmless the Indemnitee for and against:
        (x) any costs and expenses (including reasonable attorneys’ fees) incurred by the Indemnitee in the defense of
        such Claim; and
        (y) any amounts to be paid in settlement of the Claim, or awarded against the Indemnitee under such Claim (such
        as, but not limited to, damages, liabilities, and fines).
        Limitation of Liability. YOU AGREE TO THE FOLLOWING:IN NO EVENT WILL TxtOrdr OR ITS AFFILIATED ENTITIES BE
        LIABLE FOR:
        ANY CONSEQUENTIAL, INDIRECT, SPECIAL, INCIDENTAL, OR PUNITIVE DAMAGES;
        ANY LOSS OF PROFITS, LOSS OF BUSINESS, LOSS OF REVENUE, OR LOSS OF ANTICIPATED SAVINGS;
        ANY LOSS OF, OR DAMAGE TO, DATA, REPUTATION, OR GOODWILL; AND/OR
        THE COST OF PROCURING ANY SUBSTITUTE GOODS OR SERVICES.
        THE AGGREGATE COMBINED LIABILITY OF TxtOrdr AND ITS AFFILIATED ENTITIES UNDER, OR OTHERWISE IN CONNECTION WITH,
        THIS AGREEMENT SHALL NOT EXCEED THE AMOUNTS ACTUALLY PAID BY YOU TO TxtOrdr (IF ANY) DURING THE THREE (3)
        MONTHS IMMEDIATELY PRECEDING THE DATE ON WHICH YOU BRING YOUR CLAIM. ACCORDINGLY, IF YOU HAVE NOT PAID TxtOrdr
        ANY AMOUNTS IN SUCH THREE-MONTH PERIOD, TxtOrdr SHALL HAVE NO LIABILITY TO YOU WHATSOEVER.THE FOREGOING
        EXCLUSIONS AND LIMITATIONS SHALL APPLY:
        EVEN IF TxtOrdr OR ITS AFFILIATED ENTITIES HAVE BEEN ADVISED, OR SHOULD HAVE BEEN AWARE, OF THE POSSIBILITY OF
        LOSSES OR DAMAGES;
        EVEN IF ANY REMEDY IN THIS AGREEMENT FAILS OF ITS ESSENTIAL PURPOSE; AND
        REGARDLESS OF THE THEORY OR BASIS OF LIABILITY (SUCH AS, BUT NOT LIMITED TO, BREACH OF CONTRACT, TORT, AND
        STRICT LIABILITY).
        Some jurisdictions do not allow the exclusion or limitation of liability for incidental or consequential
        damages, so to that extent some of the above exclusions or limitations may not apply to you.
</p>

<p class="para">
        Termination and Survival. TxtOrdr reserves the right to immediately terminate this EULA, or otherwise modify,
        suspend or discontinue your access to and use of the Site (or any part thereof), for any reason whatsoever, at
        any time, and without notice to you, and you agree that TxtOrdr will have no liability or obligation to you for
        any such termination, modification, suspension, or discontinuance. You may terminate this EULA at any time and
        for any reason, but only by uninstalling the Site. If you object to any term or condition of this EULA or any
        subsequent changes thereto, or become dissatisfied with the Site in any way, your only recourse is to terminate
        this EULA and uninstall the Site. If you terminate the EULA by uninstalling the Site, you agree that TxtOrdr may
        at any time thereafter send you SMS messages notifying you about one of your contacts that has used the
        Site. Upon termination of this EULA: (a) the License will automatically terminate and you must immediately cease
        use of the App; and (b) your access to your Account will be disabled and TxtOrdr may permanently delete your
        Account Content. For the avoidance of doubt, termination of this EULA in no way affects the Feedback License or
        the Content License, which shall survive termination. Sections _5, _6, _7(v)-(vii), _8, _12, _14 through _17,
        and _19 through _30 shall also survive termination this EULA.
        Updates. We may from time to time provide updates or upgrades to the Site (each an “Update“), but are not under
        any obligation to do so. Such Updates will be supplied according to our then-current policies, which may include
        automatic updating or upgrading without any notice to you. You consent to any such automatic updating or
        upgrading of the Site. All references herein to the Site shall include its Updates as well, and this EULA shall
        govern any Update, unless the Update is accompanied by a separate license agreement which is explicitly stated
        to govern that Update.
        Copyright Infringement Takedowns. It is TxtOrdr’s policy to respect the legitimate rights of copyright owners,
        and we will respond to clear notices of alleged copyright infringement in accordance with our DMCA Policy
        in Annex 1
        US Government Rights. The Site is “commercial computer software” and the Documentation is “commercial computer
        software documentation,” pursuant to DFAR Section 227.7202 and FAR Section 12.212, as applicable. If you are an
        agency, department, employee or other entity of the United States Government, then your access to and use of the
        Site and/or the Documentation shall be subject solely to the terms and conditions of this EULA.
        Controlling Law and Jurisdiction. This EULA shall be governed by, and construed in accordance with, the laws of
        the State of Israel without regard to any conflicts of laws rules or principles. The United Nations Convention
        on Contracts for the International Sale of Goods does not apply to this EULA and is hereby disclaimed. Any
        claim, dispute or controversy under, or otherwise in connection with, this EULA shall be subject to the
        exclusive jurisdiction and venue of the courts in Tel Aviv, Israel, and you hereby irrevocably submit to the
        personal jurisdiction of such courts and waive any jurisdictional, improper venue, inconvenient forum, or other
        objections to such jurisdiction and venue. You also:
        agree that the Site shall be deemed solely based in the State of Israel, and shall be deemed a passive server
        that does not give rise to personal jurisdiction over TxtOrdr or its affiliated entities, either specific or
        general, in jurisdictions other than the State of Israel;
        agree that any proceedings to resolve or litigate any claim, dispute or controversy will be conducted solely on
        an individual basis (and not in any class action or class-wide proceeding), and that you may initiate such
        proceedings only on your own behalf;
        hereby waive the right to litigate such claims, disputes, or controversies in court before a jury; and
        agree not to participate in claims, disputes, or controversies brought in an attorney general or representative
        capacity, or in consolidated claims, disputes, or controversies involving another person’s Account.
        YOU AGREE THAT ANY CAUSE OF ACTION THAT YOU MAY HAVE UNDER, OR OTHERWISE IN CONNECTION WITH, THIS EULA MUST
        COMMENCE WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES. OTHERWISE, YOU AGREE THAT SUCH CAUSE OF ACTION
        SHALL BE DEEMED PERMANENTLY BARRED. Notwithstanding this Section _22, TxtOrdr may seek injunctive relief and/or
        specific performance in any court worldwide that has competent jurisdiction.
        Entire Agreement. This EULA (together with its Annexes) represents the entire agreement of the parties with
        respect to the subject matter hereof, and supersedes and replaces any and all prior and contemporaneous oral or
        written understandings and statements by the parties with respect to such subject matter. The section and
        subsection headings used in this EULA are solely for convenience of reading, and shall not be considered or
        relied upon in construing any provision of this EULA (so read this EULA fully). The language of this EULA is
        expressly agreed to be the English language.
</p>

<p class="para">
        Assignment. TxtOrdr may assign this EULA (or any of its rights and obligations hereunder) without your consent
        and without notice to you. This EULA is personal to you, and you shall not assign this EULA (or any of your
        obligations or rights hereunder) without TxtOrdr’s express prior written consent. Any prohibited assignment
        shall be null and void.
        Severability. If any provision of this EULA is held by a court of competent jurisdiction to be invalid, illegal,
        or unenforceable, then: (a) the remaining provisions of this EULA shall remain in full force and effect; and (b)
        such affected provision shall be ineffective solely as to such jurisdiction (and only to the extent and for the
        duration of such invalidity, illegality, or unenforceability), and shall be substituted (in respect of such
        jurisdiction) with a valid, legal, and enforceable provision that most closely approximates the original legal
        intent and economic impact of such provision.
        Remedies. No right or remedy conferred upon or reserved by any party under this EULA is intended to be, or shall
        be deemed, exclusive of any other right or remedy under this EULA, at law or in equity, but shall be cumulative
        of such other rights and remedies.
        Waiver. No failure or delay on the part of any party hereto in exercising any right or remedy under this EULA
        shall operate as a waiver thereof, nor shall any single or partial exercise of any such right or remedy preclude
        any other or further exercise thereof or the exercise of any other right or remedy. Any waiver granted hereunder
        must be in writing, signed by the waiving party, and shall be valid only in the specific instance in which
        given.
        Relationship of Parties. The relationship of the parties is solely that of independent contractors. Nothing in
        this EULA shall be deemed to create any employment, fiduciary, joint venture, agency or other relationship
        between the parties.
        Notices. You agree that TxtOrdr may send you notices by email, via your Account, by regular mail, and/or via
        postings on or through Site. Except as stated otherwise in this EULA or required by law, you agree to send all
        notices to TxtOrdr, to <a href="mailto:info@ordrai.com">info@ordrai.com</a>.
        No Third Party Beneficiaries. You agree that, except as otherwise expressly provided in this EULA (for example,
        Indemnitees and Message Claim Indemnitees), there shall be no third-party beneficiaries to this EULA.
        Force Majeure.  TxtOrdr shall not be responsible for any failure to perform any obligation or provide any
        service hereunder because of any (a) act of God, (b) war, riot or civil commotion, (c) governmental acts or
        directives, strikes, work stoppage, or equipment or facilities shortages, and/or (d) other similar cause beyond
        TxtOrdr’s reasonable control. For the avoidance of doubt, any problems relating to the hosting of the Site shall
        not be deemed within TxtOrdr’s reasonable control.
</p>


      <p class ="para">
        Smoothie King Text Ordering 
      </p> 
        <ol>
                <li>
                        Welcome to Smoothie King Text Ordering! The fastest and easiest way to order a smoothie on the go! Simply text your whole order in one message to 41515 and our robot will take care of the rest. Marketing and promo messages may be sent when you opt-in. Msg frequency: 4 msgs per month. 
                </li>
                <li>
                        You can cancel the SMS service at any time. Just text "STOP" to the short code. After you send the SMS message "STOP" to us, we will send you an SMS message to confirm that you have been unsubscribed. After this, you will no longer receive SMS messages from us. If you want to join again, just sign up as you did the first time and we will start sending SMS messages to you again. 
                </li>
                <li>
                        If you are experiencing issues with the messaging program you can reply with the
                        keyword HELP for more assistance, or you can get help directly at info@ordrai.com 
                        or (415) 903-4020 
                </li>
                <li>
                        Carriers are not liable for delayed or undelivered messages 
                </li>
                <li>
                        As always, message and data rates may apply for any messages sent to you from
                        us and to us from you. You will receive 4 msgs/month If you have any questions 
                        about your text plan or data plan, it is best to contact your wireless provider.
                </li>
                <li>
                        If you have any questions regarding privacy, please read our privacy policy: 
                        ordrai.com/privacy
                </li>
        </ol>












<p class = "para">
        SMS programs: 
Smoothie King Text Ordering: The fastest and easiest way to order a smoothie on the go! Simply text your whole order in one message to 41515 and our robot will take care of the rest. Marketing and promo messages may be sent when you opt-in. Msg frequency: 4 msgs per month.
</p>

<p class="para">
        Last updated: Apr 29, 2022
</p>

<p class="para">
        ANNEX 1 – DMCA POLICY
        Removal of Content. It is the policy of TxtOrdr to respect the legitimate rights of copyright owners, and we
        will respond to clear notices of alleged copyright infringement. Pursuant to the Digital Millennium Copyright
        Act (the “DMCA“), we have designated a Copyright Agent (as specified below) to receive notifications of claimed
        copyright infringement in connection with the Site. Please be advised that we enforce a policy that provides for
        the termination in appropriate circumstances of Site users who are repeat infringers. If you believe that your
        work has been copied in a way that constitutes copyright infringement, please provide the Copyright Agent with
        the following information in accordance with the DMCA:
        An electronic or physical signature of the person authorized to act on behalf of the owner of the copyright;
        A description of the copyrighted work you claim has been infringed;
        A description of where the material that you claim is infringing is located on the Site, with enough detail that
        we may find it. Providing URLs in the body of an email is the best way to contact us locate content quickly;
        Your address, telephone number, and email address;
        A statement by you that you have a good faith belief that the disputed use is not authorized by the copyright
        owner, its agent, or the law; and
        A statement by you, made under penalty of perjury, that the above information in your notice is accurate and
        that you are the copyright owner or authorized to act on the copyright owner’s behalf.
        Counter-Notification. If you believe that the material you posted was removed from the Site by mistake, and that
        you have the right to post the material, you may elect to send us a counter-notification. To be effective the
        counter-notification must be a written communication provided to our Copyright Agent that includes substantially
        the following (please consult your legal counsel or see the DMCA to confirm these requirements):
        Your physical or electronic signature;
        Identification of the material that has been removed or to which access has been disabled and the location at
        which the material appeared before it was removed or access to it was disabled. Providing URLs in the body of an
        email is the best way to contact us locate content quickly;
        A statement under penalty of perjury that you have a good faith belief that the material was removed or disabled
        as a result of mistake or misidentification of the material to be removed or disabled; and
        Your name, address, and telephone number, and a statement that you consent to the jurisdiction of the Federal
        District Court for the judicial district in which the address is located, or if your address is outside of the
        United States, for any judicial district in which the Site may be found or accessed, and that you will accept
        service of process from the person who provided notification of infringement or an agent of such person.
        Misrepresentations. Please note that under the DMCA (at 17 U.S.C. Section 512(f)) any person who knowingly
        materially misrepresents that material or activity is infringing or was removed or disabled by mistake or
        misidentification may be subject to liability. Copyright Agent. Our agent for notice of claims of copyright
        infringement (“Copyright Agent“) can be reached as follows:
        TxtOrdr Email:                 <a href="mailto:info@ordrai.com">info@ordrai.com</a>
        You represent and warrant that: (a) you are not located in a country that is subject to a U.S. Government
        embargo, or that has been designated by the U.S. Government as a “terrorist supporting” country; and (b) you are
        not listed on any U.S. Government list of prohibited or restricted parties.If you have any questions,
        complaints, or claims regarding the Site, please contact TxtOrdr at <a
                href="mailto:info@ordrai.com">info@ordrai.com</a>.
</p>

<app-footer> </app-footer>