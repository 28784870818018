<div *ngFor="let item of api.items; index as i">
  <div class="flex-container">
    <div class="qty-box">{{api.qty[i]}}</div>
    <div class="title-box" style="flex-grow: 6">{{api.sizes[i].replace('-1', '')}} {{api.cursts[i].replace('-1', '')}}
      {{api.itemNames[i]}} </div>
    <div class="price-box">
      <span *ngIf = "api.isRewardsArr[i]">$0.00</span>  <span *ngIf = "!api.isRewardsArr[i]">{{api.amounts[i]}} </span>
      </div>
  </div>
  <hr>
</div>


<div>
  <div class="price-list">
    <!--  <div class="flex-container-items">
            <div>Items total:</div>
            <div>${{api.itemsTotal}}</div> </div> -->
    <!--   <div class="flex-container-items">
            <div>Items subtotal:</div>
            <div>${{api.subtotal}}</div> </div> -->
    <div class="flex-container-items">
      <div>You Saved (<span *ngIf = "api.rewards.isRewardsSelected"> Promo: {{api.couponName}} & {{api.rewards.code}}) </span> <span *ngIf = "!api.rewards.isRewardsSelected"> Promo: {{api.couponName}})</span> </div>
      <div>-${{api.saving}}</div>
    </div>
    <div class="flex-container-items" [hidden]="api.delivery_fee === '0.00'">
      <div> Delivery fee:</div>
      <div>${{api.delivery_fee}}</div>
    </div>

    <popover-content #myPopover [closeOnClickOutside]="true">
      <div><span>Tax:</span> <span style="float: right">&nbsp;${{api.tax}}</span></div>
      <div *ngIf="api.serviceFee != '0.00'"><span>Text Convenience Fee:</span> <span
          style="float: right">${{api.serviceFee}}</span></div>
      <div *ngIf="api.serviceFee != '0.00'"><span style="color: grey">This text Convenience fee helps OrdrAi cover
          operating costs.</span></div>
    </popover-content>
    <div class="flex-container-items">
      <div>Tax <span *ngIf="api.serviceFee != '0.00'"> &amp; fees </span>: <span
          *ngIf="api.serviceFee != '0.00'">&nbsp;<i [popover]="myPopover" popoverPlacement="top"
            class="fas fa-info-circle"></i></span></div>
      <div>${{api.calcTaxnFee}}</div>
    </div>
    <div class="flex-container-items" [hidden]="api.tip === 0.00" *ngIf="api.tipDisplay">
      <div>Tip:</div>
      <div>${{api.tip}}</div>
    </div>
  </div>
</div>

<!--
      <div class="flex-container-items">
          <div class = "total-val">Total:</div>
          <div class = "total-val">{{api.total}}</div>
        </div>
      -->
<table class="table">
  <thead class="table-dark">
    <tr class="flex-container-table">
      <th scope="col" style="font-size: x-large">TOTAL</th>

      <th scope="col" style="font-size: x-large">${{(api.total  +  api.tip).toFixed(2)}}</th>
    </tr>
  </thead>
</table>