<div *ngIf="!loadingPage">
</div>
<div *ngIf="loadingPage">
  <div *ngIf="api.isMobile ||api.isTablet " class="body">
    <div class="head">
      <h2 class="text-center rockwell white-c pT30 f-22 mB0">INTRODUCING</h2>
      <h1 class="text-center white-c rockwell-nova  text-head mB0"> FAJITA PETE'S<sup>®</sup> TEXT <br> TO ORDER! </h1>
      <p class="text-center white-c myriad f-18" *ngIf="discountTxt !== '*'">{{discountTxt}}</p>
      <p class="limited" style="margin-bottom: 0px; padding-left: 2px; padding-bottom: 5px; font-size: 12px">
        <!-- <span *ngIf="discountTxt !== '*'">Limited Time Offer.</span> --> <span *ngIf="isShowService">
          Text Convenience Fee of $0.58 applies to all text orders. </span> <span> Standard message and data rates also
          apply.</span></p>

    </div>
    <div class="desc">
      <div class="step-one">
        <span class="one">1</span>
        <span class="box-pos" style="top:10px!important">
          <p class="box-t1">TEXT US YOUR ORDER</p>
          <p class="box-t3"> TEXT 'HELLO' TO {{api.formatPhone(api.menuRestId)}} [STORE PHONE]</p>
          <p class="box-t3"> TEXT 'PICKUP' OR 'DELIVERY TO (YOUR ADDRESS)'</p>
        </span>
      </div>
      <div class="step-two">
        <span class="two">2</span>
        <span class="box-pos">
          <p class="box-t1">RECEIVE A REPLY</p>
          <p class="box-t2">REVIEW YOUR ORDER</p>

        </span>
      </div>
      <div class="step-three">
        <span class="three">3</span>
        <span class="box-pos">
          <p class="box-t1">CONFIRM YOUR ORDER</p>

          <p class="box-t3">ENJOY YOUR FAJITAS!</p>
        </span>
      </div>
    </div>
    <div class="footer">

      <div *ngIf = "!storeFinder" (click)="orderNowBtn()" style="height: 300px;" class="text-center pT30">
        <div class="start-order" [ngStyle]="{'background':api.primaryColor}">
          <span style="font-size: 45px;" class="">START YOUR ORDER</span>

        <p style="font-size: 21px; margin-top:-12px;">{{restNickname}} {{restNickname1}}</p> 
        </div>


      </div>

      <div  *ngIf = "storeFinder" (click)="storeFinderBtn()" style="height: 300px;" class="text-center pT30">
        <div class="start-order" [ngStyle]="{'background':api.primaryColor}">
          <span style="font-size: 45px;" class="">FIND A STORE</span>

        <!--   <p style="font-size: 21px; margin-top:-12px;">At MANNY & OLGA'S - {{restNickname}}</p> -->
        </div>


      </div>


      <!-- <img src="/assets/images/hand.png"> -->
    </div>
  </div>

  <div *ngIf="api.isDesktopDevice" class="body row">
    <div class="cust-size pR0">
      <div class="head">
        <h2 class="text-center rockwell-nova white-c pT30 f-36 mB0">INTRODUCING</h2>
        <h1 style="word-wrap: break-word;" class="text-center white-c rockwell-nova  text-head-desk mB0">
          FAJITA PETE'S<sup>®</sup>
          TEXT <br>TO ORDER!</h1>
        <p class="text-center white-c myriad f-24" *ngIf="discountTxt !== '*'">{{discountTxt}}</p>


        <p class="limited f-12" style="padding-left: 2px; margin-bottom: 0px; padding-bottom: 5px;">
          <!-- <span *ngIf="discountTxt !== '*'">Limited Time Offer. </span> --> <span *ngIf="isShowService">
            Text convenience fee of $0.58 applies to all text orders.</span> <span> Standard message and data rates also
            apply.</span></p>


      </div>
      <div class="desc">
        <div class="step-one row mL0 mR0 w100 scp-align " style="display: inline-block; min-height: 130px;">
          <div class="col-sm-1 text-center pT30 m-aut pL20">
            <span class="one-d f-36">1</span>
          </div>
          <div class="col-sm-11 pL20">
            <span class="box-pos-d " style="top:10px!important">
              <p class="box-t1 f-36">TEXT US YOUR ORDER</p>
              <p class="box-t3 f-24"> TEXT 'HELLO' TO {{api.formatPhone(api.menuRestId)}} [STORE PHONE]</p>
              <p class="box-t3 f-24"> TEXT 'PICKUP' OR 'DELIVERY TO (YOUR ADDRESS)'</p>
            </span>
          </div>
        </div>
        <div class="step-two row mL0 mR0 w100 scp-align" style="display: inline-block;  min-height: 125px;">
          <div class="col-sm-1 text-center m-aut pT30 pL20 ">
            <span class="two-d f-36 t-0">2</span>
          </div>
          <div class="col-sm-11 pL20 pT20">
            <span class="box-pos-d pT10 ">
              <p class="box-t1 f-36">RECEIVE A REPLY</p>
              <p class="box-t2 f-24">REVIEW YOUR ORDER</p>
            </span>
          </div>
        </div>
        <div class="step-three-nb row mL0 mR0 w100 scp-align" style="display: inline-block;  min-height: 125px;">
          <div class="col-sm-1 text-center m-aut pT30 pL20 ">
            <span class="three-d f-36 t-0">3</span>
          </div>
          <div class="col-sm-11 pL20 pT20">
            <span class="box-pos-d pT10">
              <p class="box-t1 f-36">CONFIRM YOUR ORDER</p>

              <p class="box-t3 f-24">ENJOY YOUR FAJITAS!</p>
            </span>
          </div>
        </div>
      </div>
    </div>

    <div class="cust-size pL0 back-pic">

      <div *ngIf = "!storeFinder" (click)="orderNowBtn()" style="margin-top:15%" class="text-center pT30">
        <div class="start-order mL50 mR50" [ngStyle]="{'background':api.primaryColor}">
          <span style="font-size: 45px;" class="">START YOUR ORDER</span>

          <p style="font-size: 21px; margin-top:-12px;">At Fajita Pete's - {{restNickname}} {{restNickname1}}</p>
        </div>


      </div>

      <div *ngIf = "storeFinder" (click)="storeFinderBtn()" style="margin-top:15%" class="text-center pT30">
        <div class="start-order mL50 mR50" [ngStyle]="{'background':api.primaryColor}">
          <span style="font-size: 45px;" class="">FIND A STORE</span>
        </div>


      </div>
      <!-- <img src="/assets/images/hand.png"> -->
    </div>
  </div>

  <div class="black-bg">
    <div class="container pT5 pB5">
      <div class="row">
        <div class="col footer-txt">
          <div>
            <div class="footer-txt"> © {{year}} <b>OrdrAi</b></div>
          </div>
        </div>
        <div class="col">
          <div style="float: right">
            <a class="a-txt" target="_blank" rel="noopener noreferrer" href="/terms">Terms </a> | <a class="a-txt"
              target="_blank" rel="noopener noreferrer" href="/privacy"> Privacy</a>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>
