import { APICenterService } from './../apicenter.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-text-marketing-check',
  templateUrl: './text-marketing-check.component.html',
  styleUrls: ['./text-marketing-check.component.css']
})
export class TextMarketingCheckComponent implements OnInit {
  
  checked: boolean = true;
  checked1: boolean=true;
  constructor(public api: APICenterService) { }

  ngOnInit() {
    this.checked = this.api.isMktOptIn;
   
  }

}
