<div class="position-relative">
  <!-- Card Form -->
  <div class="card" style="border-radius: 5px">
    <div
      class="card-header text-white text-left"
      style="
        background-color: #00ab4e !important;
        font-weight: 500;
        word-spacing: 1px;
        letter-spacing: 0.5px;
        border-radius: 5px 5px 0px 0px;
      "
    >
      <p class="mB0 f-16">
        <i class="fa fa-lock" aria-hidden="true"></i> Payment
      </p>
    </div>
    <div class="card-body z-index-1">
     <!-- <ngx-braintree
        [getClientToken]="getClientToken"
        [createPurchase]="createPurchase"
        [chargeAmount]="55.55"
        (paymentStatus)="onPaymentStatus($event)"
        [allowChoose]="true"
        [currency]="'USD'"
      >
        <div class="ngxButtons">
          <style>
            button {
              background-color: #5cb85c;
              color: #ffffff;
              border: none;
              border-radius: 4px;
              height: 40px;
              line-height: 40px;
              font-size: 16px;
              cursor: pointer;
              margin-right: 20px;
            }

            button:disabled {
              background-color: #5cb85c;
              color: #ffffff;
              border: none;
              border-radius: 4px;
              height: 40px;
              line-height: 40px;
              font-size: 16px;
              cursor: not-allowed;
              margin-right: 20px;
            }
          </style>
          <div>
            <div></div>

            <div class="wrapper-center text-center">
              <button
                mat-raised-button
                ngxPay
                (click)="onSubmit()"
                color="primary"
                style="color: white; height: 48px"
              
                class="pay-btn animated fadeInDown jets-green"
              >
               
                Submit Your Order
              </button>
            </div>
          </div>
        </div>
      </ngx-braintree> -->

      <form (ngSubmit)="onSubmit()" #paymentForm="">
      
        <div class="mb-3">
          <div style="position: relative">
            <input
              *ngIf="show"
              [disabled]="lock"
              type="text"
              id="cardNumber"
              class="form-control"
              placeholder="1234 5678 9012 3456"
              required
              maxlength="19"
              [(ngModel)]="api.demoPaymentCard.cardNumber"
              name="cardNumber"
              (input)="formatCardNumber($event)"
            />
            <i
              style="position: absolute; top: 6px; right: 2%; font-size: 25px"
              class="fa fa-cc-visa"
              aria-hidden="true"
            ></i>
          </div>
        </div>

       
        <div class="row mb-3">
          <div class="col">
            <input
            *ngIf="show"
              [disabled]="lock"
              type="text"
              id="expMonth"
              class="form-control"
              placeholder="MM"
              required
              maxlength="2"
              [(ngModel)]="api.demoPaymentCard.expMonth"
              name="expMonth"
            />
          </div>
          <div class="col">
            <input
            *ngIf="show"
              [disabled]="lock"
              type="text"
              id="expYear"
              class="form-control"
              placeholder="YY"
              required
              maxlength="2"
              [(ngModel)]="api.demoPaymentCard.expYear"
              name="expYear"
            />
          </div>
          <div class="col">
            <input
            *ngIf="show"
              [disabled]="lock"
              type="password"
              id="cvv"
              class="form-control"
              placeholder="123"
              required
              maxlength="4"
              [(ngModel)]="api.demoPaymentCard.cvv"
              name="cvv"
            />
          </div>
        </div>

        <button
          *ngIf="!lock"
          type="submit"
          class="btn btn-success w-100 {{disabled()?'disabled':''}}"
         
        >
Submit Your Order          
        </button>
      </form> 
    </div>
  </div>
</div>
