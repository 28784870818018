import { Component, OnInit, Inject } from '@angular/core';
import { truncate } from 'fs';
import { MatDialogRef, MAT_DIALOG_DATA } from '../../../../node_modules/@angular/material/dialog';
import { APICenterService } from '../../apicenter.service';

@Component({
  selector: 'app-curbside-popup',
  templateUrl: './curbside-popup.component.html',
  styleUrls: ['./curbside-popup.component.css']
})
export class CurbsidePopupComponent implements OnInit {

  constructor(public api: APICenterService, public dialogRef: MatDialogRef<CurbsidePopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }
  userData = {
    make: '',
    color: '',
    model: ''
  }


  ngOnInit() {
    this.userData.make = this.api.curbData.make,
      this.userData.model = this.api.curbData.model,
      this.userData.color = this.api.curbData.color
  }
  save() {
    this.api.curbData.make = this.userData.make
    this.api.curbData.model = this.userData.model
    this.api.curbData.color = this.userData.color;
    let load = {
      make: this.api.curbData.make,
      model: this.api.curbData.model,
      color: this.api.curbData.color,
      isCurbside: true,
      orderId: this.api.ordrID,
      custId: this.api.customer.cid
    }
    this.api.curbInfo(load);
    this.api.getcurbInfo()
      .subscribe((val) => {
        let payload = {
          make: this.api.curbData.make,
          color: this.api.curbData.color,
          model: this.api.curbData.model
        }
        this.api.curbside = true;
        this.api.curbData = payload;
        this.dialogRef.close();
      })
    //put this inisde after call


  }
  enableSave() {
    if (this.userData.make != '' && this.userData.make != undefined &&
      this.userData.model != '' && this.userData.model != undefined &&
      this.userData.color != '' && this.userData.color != undefined) {
      return true;
    }
    else {
      return false;
    }
  }
  close() {
    this.dialogRef.close();
  }



}
