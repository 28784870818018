export class pizzaworldusaStore {

    constructor() { }

    findStore(sid) {
        switch (sid) {
            case 'pw01':
               let  sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 20% OFF ON ALL PIZZAS',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 20% OFF ON ALL PIZZAS',
                    successPro: 'PRO ORDERS ARE 20% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+16184511111',
                    nickName: '1535 Johnson Rd location in Granite City',
                    addy1: '1535 Johnson Rd',
                    addy2: 'Granite City, IL'
                };
                return sidInfo;
                
            case 'pw02':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 20% OFF ON ALL PIZZAS',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 20% OFF ON ALL PIZZAS',
                    successPro: 'PRO ORDERS ARE 20% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+13145760000',
                    nickName: '12305 Olive Blvd location in St. Louis',
                    addy1: '12305 Olive Blvd',
                    addy2: 'St. Louis, MO'
                };
                return sidInfo;

            default:
                sidInfo = {
                    discountTxt: '*',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'Texting is the fastest way to order Jet’s!',
                    successPro: 'PRO ORDERS ARE 20% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: 'empty',
                    nickName: 'empty',
                    addy1: 'empty',
                    addy2: 'empty'
                };
                return sidInfo;
        }
        // console.log(cust)
    }
}
