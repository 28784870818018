

<mat-dialog-content>
    <app-pro-address-box (setAddress)="getAddress($event)" (setApt)="setApt($event)" adressType="geocode"> </app-pro-address-box>
   <!-- <button mat-raised-button color="primary" (click) = "close()"> Close </button> -->
   <div style = "display: flex; justify-content: space-evenly ; padding: 18px 0px 0px;">
    <div style = "padding: 5px;">
        <button class="btn btn-success"   (click) = "updateMethod()" type="button"  [ngStyle]="{'background':api.primaryColor}" style = "font-size: 16px; padding: 7px 25px 7px;"> Update</button>  
    </div>
    <div style = "padding: 5px;">
     <button class="btn btn-success"   (click) = "close()" type="button" style = "border: black; font-size: 16px; padding: 7px 25px 7px; background-color: black"> Cancel </button>  
    </div>
  </div>
</mat-dialog-content>

<!-- <mat-dialog-actions class = "close-btn">
 
</mat-dialog-actions>  -->