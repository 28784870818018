<div style="display: flex; justify-content: center" *ngIf="!api.isOrdrSummary">
    <img src={{api.loadingGif}} class="rounded img-fluid" alt="...">
</div>
<div *ngIf="api.isOrdrSummary">
    <!--PIN CUSTOMER-->

    <form *ngIf="!showPasswordForm" #f="ngForm" (submit)="submit(f)">
        <label class="label-txt"> Enter your Text-PIN to pay with {{api.customer.cardType}} Card ending in
            {{api.customer.lastFour}}</label>
        <div class="input-group input-group-lg input-txt">
            <!-- <div class="input-group-addon" style = "background: white; ">
                       PIN
                    </div> -->
            <input matInput autofocus autocomplete="off" minlength="4" maxlength="4" id="pin-text" type="tel"
                class="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default"
                [(ngModel)]="api.paymentPin" name="pinText" #name="ngModel" (keypress)="onChange($event)">
        </div>

        <div class="alert alert-danger" *ngIf="name.touched && !name.valid">
            <!-- <div *ngIf = "name?.errors.required"> Please enter your PIN</div> -->
            <div *ngIf="name?.errors.minlength"> 4 digit is required </div>
            <div *ngIf="name?.errors.pattern"> Number is expected </div>
        </div>


        <div class="alert alert-danger" *ngIf="api.isFirstWrong"> The entered PIN is wrong. Re-enter the PIN</div>
        <div class="alert alert-danger" *ngIf="api.isSecondWrong"> The entered PIN isn't right for the second time.
            Re-enter the PIN</div>
        <div class="alert alert-danger" *ngIf="api.isThridWrong"> On returning a wrong PIN for the third time will
            deactivate your PIN. You can reset your PIN again and complete this transaction using Credit Card. </div>
        <app-text-marketing-check> </app-text-marketing-check>
        <div class="wrapper-center ">
            <div *ngIf="!api.pinMsg" class="wrapper-center ">
                <mat-progress-bar mode="indeterminate" *ngIf="api.pinMsg"></mat-progress-bar>
                <button
                    [ngStyle]="{'background':api.paymentPin.length == 4 ? api.primaryColor : '#d3d3d3', 'color' : api.paymentPin.length == 4 ? '#ffffff' : '#0000006b' }"
                    [disabled]="!f.valid" type="submit" mat-raised-button color="primary"
                    class="pay-btn animated fadeInDown">
                    <i class="material-icons md-light md-36 m-icon">
                        directions_run</i>
                    <b *ngIf="!api.pinMsg">Submit Your Order </b> <b *ngIf="api.pinMsg"> Processing Your Order </b> <img
                        *ngIf="api.pinMsg" style=" width: 12%; padding-left: 10px;"
                        src="http://indismart.in/wp-content/uploads/2018/09/loaderimg.gif"> </button>
            </div>
            <div *ngIf="api.pinMsg" class="alert alert-success wrapper-center " role="alert">
                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                <b class="payment-box">Processing Your Order!</b>
                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                <!-- <img  style = " width: 12%; padding-left: 10px;" src="http://indismart.in/wp-content/uploads/2018/09/loaderimg.gif" > -->
            </div>
        </div>
        <div class="wrapper-center-back " [hidden]="api.pinMsg">
            <button mat-raised-button type="button"
                [ngStyle]="{'color':api.primaryColor, 'border-color' : api.primaryColor}" style="color: white"
                class="back-btn " (click)="onBack()">
                Back </button>
        </div>

        <div class="flex-container">
            <div style="padding: 0px; margin: 0px; " (click)="reset()" [hidden]="api.pinMsg">
                <p class="pay-with-cc" [ngStyle]="{'color':api.primaryColor}"> Reset PIN</p>
            </div>
            <div style="padding: 0px; margin: 0px; " (click)="payCC()" [hidden]="api.pinMsg">
                <p class="pay-with-cc" [ngStyle]="{'color':api.primaryColor}">Pay with a different card</p>
            </div>

        </div>

    </form>


    <!--PASSWORD CUSTOMER-->
    <form *ngIf="showPasswordForm" #f="ngForm" (submit)="submitPwd(f)">
        <label class="label-txt" style="font-size: 18px; "> Enter your Text-Password to pay with
            {{api.customer.cardType}} Card ending in {{api.customer.lastFour}}</label>
        <div class="input-group input-group-lg input-txt">
            <!-- <div class="input-group-addon" style = "background: white; ">
                               PIN
                            </div> -->
            <input required matInput autofocus autocomplete="off" style="letter-spacing: 2px; text-align: center;"
                id="pin-text" type="password" class="form-control" aria-label="Default"
                aria-describedby="inputGroup-sizing-default" [(ngModel)]="api.paymentPwd" name="pinText" #name="ngModel"
                (keypress)="onChange($event)">
        </div>

        <div class="alert alert-danger" *ngIf="name.touched && !name.valid">
            <div *ngIf="name?.errors.required"> Please enter your password</div>
            <!-- <div *ngIf = "name?.errors.minlength"> 4 digit is required </div> -->
            <!--   <div *ngIf = "name?.errors.pattern"> Number is expected  </div> -->
        </div>


        <div class="alert alert-danger" *ngIf="api.isFirstWrong"> The entered password is wrong. Re-enter the password
        </div>
        <div class="alert alert-danger" *ngIf="api.isSecondWrong"> The entered password isn't right for the second time.
            Re-enter the password</div>
        <div class="alert alert-danger" *ngIf="api.isThridWrong"> On returning a wrong password for the third time will
            deactivate your password. You can reset your password again and complete this transaction using Credit Card.
        </div>
        <app-text-marketing-check> </app-text-marketing-check>
        <div class="wrapper-center ">
            <div *ngIf="!api.pinMsg" class="wrapper-center ">
                <mat-progress-bar mode="indeterminate" *ngIf="api.pinMsg"></mat-progress-bar>
                <button [disabled]="!f.valid" type="submit" mat-raised-button color="primary"
                    class="pay-btn animated fadeInDown" style="color: white"
                    [ngStyle]="{'background':api.primaryColor}">
                    <i class="material-icons md-light md-36 m-icon">
                        directions_run</i>
                    <b *ngIf="!api.pinMsg">Submit Your Order </b> <b *ngIf="api.pinMsg"> Processing Your Order </b> <img
                        *ngIf="api.pinMsg" style=" width: 12%; padding-left: 10px;"
                        src="http://indismart.in/wp-content/uploads/2018/09/loaderimg.gif"> </button>
            </div>
            <div *ngIf="api.pinMsg" class="alert alert-success wrapper-center " role="alert">
                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                <b class="payment-box">Processing Your Order!</b>
                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                <!-- <img  style = " width: 12%; padding-left: 10px;" src="http://indismart.in/wp-content/uploads/2018/09/loaderimg.gif" > -->
            </div>
        </div>
        <div class="wrapper-center-back " [hidden]="api.pinMsg">
            <button mat-raised-button type="button" class="back-btn "
                [ngStyle]="{'color':api.primaryColor, 'border-color' : api.primaryColor}" (click)="onBack()">
                Back </button>
        </div>

        <div class="flex-container">
            <div style="padding: 0px; margin: 0px; " (click)="reset()" [hidden]="api.pinMsg">
                <p style="font-size:18px;" [ngStyle]="{'color':api.primaryColor}" class="pay-with-cc"> Reset PIN</p>
            </div>
            <div style="padding: 0px; margin: 0px" (click)="payCC()" [hidden]="api.pinMsg">
                <p style="font-size:18px;" [ngStyle]="{'color':api.primaryColor}" class="pay-with-cc">Pay with a
                    different card</p>
            </div>

        </div>

    </form>

</div>