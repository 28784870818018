import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '../../../node_modules/@angular/router';
@Component({
  selector: 'app-store-list-tell-frnd',
  templateUrl: './store-list-tell-frnd.component.html',
  styleUrls: ['./store-list-tell-frnd.component.css']
})
export class StoreListTellFrndComponent implements OnInit {
  restName = '';
  constructor(private route: ActivatedRoute) {

  }
  ngOnInit() {
    this.route.url
      .subscribe(val => {
        this.restName = val[0].path;
      });
  }
}
