

<div
  *ngIf = "restMenuId == 'sammy'">
  <div style = "padding: 10px">
 <img src="https://s3.us-east-2.amazonaws.com/ordr-assets/menu/JPG+menu.jpg" alt="Sammy" width = "100%" >
  </div>
</div>
<div
  *ngIf = "restMenuId == 'sfpslices'">
  <div style = "padding: 10px">
 <img src="https://s3.us-east-2.amazonaws.com/ordr-assets/menu/SUPERFINE+SLICE+MENU.jpg" alt="sfpslices" width = "100%" >
  </div>
</div>

<div
  *ngIf = "restMenuId == 'superfine'">
  <div style = "padding: 10px">
 <img src="https://s3.us-east-2.amazonaws.com/ordr-assets/menu/Superfine+Menu.jpg" alt="sfpslices" width = "100%" >
  </div>
</div>

<div
  *ngIf = "restMenuId == 'jets'">
  <div class="flex-container">
      <div class = "img-box-1 animated fadeInDown"> <img src="https://ordr-assets.s3.us-east-2.amazonaws.com/logos/logo_01.png"  class="img-fluid rounded"></div>               
  </div>
  <div style = "padding: 10px">
    <img src="https://s3.us-east-2.amazonaws.com/ordr-assets/menu/jets_menu.jpg" alt="jets" width = "100%" >
     </div>
</div>

<div
  *ngIf = "restMenuId == 'marcos'">
  <div class="flex-container ">
      <div class = "img-box-1 animated fadeInDown"> <img class="mT10" width="40%" src="assets/images/Marco.svg"  class="img-fluid rounded"></div>               
  </div>
  <div style = "padding: 10px">
    <img src="https://s3.us-east-2.amazonaws.com/ordr-assets/menu/jets_menu.jpg" alt="jets" width = "100%" >
     </div>
</div>

<div
  *ngIf = "restMenuId == 'romeos'">
  
  <div style = "padding: 10px">
    <img src="https://ordr-assets.s3.us-east-2.amazonaws.com/menu/Romeos+Menu+Image.png" alt="jets" width = "100%" >
     </div>
</div>

<div
  *ngIf = "restMenuId == 'tree'">
<!-- <div class="flex-container">
      <div class = "img-box-1 animated fadeInDown"> <img src="https://ordr-assets.s3.us-east-2.amazonaws.com/logos/logo_01.png"  class="img-fluid rounded"></div>               
  </div> -->
  <div style = "padding: 10px">
    <img src="https://ordr-assets.s3.us-east-2.amazonaws.com/menu/PATIENT+STAION+PIC+MENU.png" alt="jets" width = "100%" >
     </div>
</div>

<div
  *ngIf = "restMenuId == 'jets_tn'">
  <div style = "padding: 10px">
    <img src="https://s3.us-east-2.amazonaws.com/ordr-assets/menu/Jets_TN+Menu.jpg" alt="jets" width = "100%" >
     </div>
</div>

<div
  *ngIf = "restMenuId == 'faz'">
  <div style = "padding: 10px">
    <img src="https://ordr-assets.s3.us-east-2.amazonaws.com/menu/Patient+Station+Menu+2.pdf" alt="faz" width = "100%" >
     </div>
</div>

<div
  *ngIf = "restMenuId == 'jetsmi73'">
  <div style = "padding: 10px">
    <img src="https://s3.us-east-2.amazonaws.com/ordr-assets/menu/jets_menu.jpg" alt="faz" width = "100%" >
     </div>
</div>

<div
  *ngIf = "restMenuId == 'jetsmi26'">
  <div style = "padding: 10px">
    <img src="https://s3.us-east-2.amazonaws.com/ordr-assets/menu/jets_menu.jpg" alt="faz" width = "100%" >
     </div>
</div>

<div
  *ngIf = "restMenuId == 'hh72'">
  <div style = "padding: 10px">
    <img src="https://ordr-assets.s3.us-east-2.amazonaws.com/menu/Hungry+Howies.png" alt="faz" width = "100%" >
     </div>
</div>
<app-footer></app-footer>
