import { Component, OnInit } from '@angular/core';
import { APICenterService } from '../apicenter.service';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.css']
})
export class TermsComponent implements OnInit {

  constructor(public api: APICenterService) { }

  ngOnInit() {
    this.api.isFullFooter = false;
  }

}
