
<!-- Tunnel Upsell
    <mat-card style = "padding: 10px;">
<p class = "text-5">Can We Tempt You?</p> 

<mat-card *ngIf = "isItem1Added || isItem2Added || isItem3Added || isItem4Added " style="padding-left: 7px; padding-right: 7px;padding-top: 15px;">
--><!--  <p class = "text-5" style = "font-size: 18px;"   >Added to your Cart</p>   --><!--
        <div class  = "add-item-title" *ngIf = "isItem1Added">
                <span class = "added-txt" style = "padding-left: 10px;">{{api.upSellItemName.item1}}</span>
                <span style = "padding: 2px 18px; font-weight: bold; font-size: 18px; color: white">|</span>
                <span class = "added-txt" (click) = "undoItem1()">  <i class="material-icons">  close </i> </span>
        </div>
        <div class  = "add-item-title"  *ngIf = "isItem2Added">
                <span class = "added-txt" style = "padding-left: 10px;">{{api.upSellItemName.item2}}</span> 
                <span style = "padding: 0px 18px; font-weight: bold; font-size: 18px; color: white">|</span>
                <span class = "added-txt" (click) = "undoItem2()"> <i class="material-icons">  close </i> </span>
        </div>
        <div class  = "add-item-title"  *ngIf = "isItem3Added">
                <span class = "added-txt" style = "padding-left: 10px;">{{api.upSellItemName.item3}}</span> 
                <span style = "padding: 0px 18px; font-weight: bold; font-size: 18px; color: white">|</span>
                <span class = "added-txt" (click) = "undoItem3()"> <i class="material-icons">  close </i> </span>
        </div>
        <div class  = "add-item-title"  *ngIf = "isItem4Added">
                <span class = "added-txt" style = "padding-left: 10px;">{{api.upSellItemName.item4}}</span> 
                <span style = "padding: 0px 18px; font-weight: bold; font-size: 18px; color: white">|</span>
                <span class = "added-txt" (click) = "undoItem4()"><i class="material-icons">  close </i> </span>
        </div>
</mat-card>  

      

<div class="flex-container" *ngIf = "api.upSell.isItem1 || api.upSell.isItem2 " >
    <div *ngIf = "api.isBread">
         <div *ngIf = "api.upSell.isItem1" class = "img-box-1 " (click) = "item1()"  ><img src="{{api.upSellItemUrl.item1}}" class="img-thumbnail"></div> 

    </div>
    

   

    <div *ngIf = "api.isDessert">
        <div *ngIf = "api.upSell.isItem2" class = "img-box-1 " (click) =  "item2()"  > <img src="{{api.upSellItemUrl.item2}}" class="img-thumbnail"></div>

    </div>
 

   
</div> 
<div class="flex-container" *ngIf = "api.upSell.isItem3 || api.upSell.isItem4 "> 
<div *ngIf = "api.isPop">
    <div *ngIf = "api.upSell.isItem3" class = "img-box-1 " (click) = "item3()"  > <img src="{{api.upSellItemUrl.item3}}"  class="img-thumbnail"></div>
</div>

<div *ngIf = "api.isPoppers">
    <div *ngIf = "api.upSell.isItem4" class = "img-box-1 " (click) =  "item4()"  > <img src="{{api.upSellItemUrl.item4}}"  class="img-thumbnail"></div> 
</div>

   
</div> 

<div class = "container wrapper animated fadeInDown ">
    <button
        mat-raised-butto
        type="button"
        class="back-btn"
        (click) = "onBack()" >  Back </button>

    <button
      mat-raised-button
      type = "submit"
      class="send-btn animated fadeInDown "
      (click) = "onNext()"
       >  Next </button>


  </div>



</mat-card> -->

<p class = "text-5">Add Item</p> 
<!-- <div class="flex-container" style = "padding: 7px 0px;">
            <div *ngIf = "api.isBread" class = "img-box-1 animated fadeInDown" (click) = "item1vs()"  ><img src="{{api.upSellItemUrl.item1}}" class="img-thumbnail"></div>
            <div *ngIf = "api.isDessert"  class = "img-box-1 animated fadeInDown" (click) = "item2vs()"  > <img src="{{api.upSellItemUrl.item2}}" class="img-thumbnail"></div>
            <div *ngIf = "api.isPop" class = "img-box-1 animated fadeInDown" (click) = "item3vs()"  > <img src="{{api.upSellItemUrl.item3}}"  class="img-thumbnail"></div>
</div> -->

<div  class="flex-container" style = "padding: 7px 0px;">
        <div *ngFor = "let item of imgList; index as i"  class = "img-box-1 animated fadeInDown"  (click) = "items(i)"  ><img src="{{api.imgUrl[i]}}" class="img-thumbnail"></div>
</div>