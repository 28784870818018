export class blazeStore {

    constructor() { }

    findStore(sid) {
        switch (sid) {
            case 'demo':
                let sidInfo = {
                    discountTxt: '*',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'Texting is the fastest way to order Blaze!',
                    successPro: 'Texting is the fastest way to order Blaze!',
                    isTxt: true,
                    rid: '+13037071100',
                    nickName: 'S. Broadway & W. Mineral Ave. location in Littleton',
                    addy1: 'S. Broadway & W. Mineral Ave.',
                    addy2: 'Littleton, CO'
                };
                return sidInfo;
                case 'b1002':
                    sidInfo = {
                        discountTxt: '*',
                        isPro: false,
                        isServiceFee: false,
                        successTxt: 'Texting is the fastest way to order Blaze!',
                        successPro: 'Texting is the fastest way to order Blaze!',
                        isTxt: true,
                        rid: '+16268025352',
                        nickName: '667 E Colorado Blvd location in Pasadena',
                        addy1: '667 E Colorado Blvd',
                        addy2: 'Pasadena, CA'
                    };
                    return sidInfo;
                case 'b1018':
                    sidInfo = {
                        discountTxt: '*',
                        isPro: false,
                        isServiceFee: false,
                        successTxt: 'Texting is the fastest way to order Blaze!',
                        successPro: 'Texting is the fastest way to order Blaze!',
                        isTxt: true,
                        rid: '+18184950573',
                        nickName: '100 W Broadway location in Glendale',
                        addy1: '100 W Broadway',
                        addy2: 'Glendale, CA'
                    };
                    return sidInfo;
            case 'demo':
                sidInfo = {
                    discountTxt: '*',
                    isPro: true,
                    isServiceFee: false,
                    successTxt: 'Texting is the fastest way to order Blaze!',
                    successPro: 'Texting is the fastest way to order Blaze!',
                    isTxt: false,
                    rid: '+15862033838',
                    nickName: 'Research park dr ',
                    addy1: '3917 Research Park Dr',
                    addy2: 'Ann Arbor, MI'
                };
                return sidInfo;
            default:
                sidInfo = {
                    discountTxt: '*',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'Texting is the fastest way to order Blaze!',
                    successPro: 'Texting is the fastest way to order Blaze!',
                    isTxt: true,
                    rid: 'empty',
                    nickName: 'empty',
                    addy1: 'empty',
                    addy2: 'empty'
                };
                return sidInfo;
        }
        // console.log(cust)
    }
}
