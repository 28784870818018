<!-- <div class = "spinner"  >
  <mat-spinner ></mat-spinner> -->
<ng4-loading-spinner *ngIf="isLoading" [timeout]="15000" [loadingText]="'Loading... Please wait'" [zIndex]="9999">
</ng4-loading-spinner>
<!-- <img src='https://i.imgur.com/JAXDLi6.png'>
</div> -->
<div *ngIf="!isLoading">
    <div *ngIf="api.isErrorTimer"
    style="position: absolute;
    width: 95%;
    top: 19%;
    z-index: 999;" class="alert alert-danger f-17" role="alert">
       {{api.isErrorTimerMsg}}
      </div>
    <app-user-msg style=" padding: 10px" *ngIf="api.isEdit"></app-user-msg>
    <!--
    <button mat-raised-button class = "send-btn animated fadeInDown"> <span class="glyphicon glyphicon-send send-gl"></span> send </button>
        <div class="container btn-update-clear">
            <div class="row wrapper">
              <div class="col-6 center-div wrapper" >

               <button
                  type="button"
                  class="btn btn-lg btn-success animated fadeInDown"
                  (click) = "onUpdate()"
                  *ngIf = "isActive"> Send </button>

                </div>
            </div>
        </div>

-->
    <!--<mat-card *ngIf = "!api.isEdit && !api.isState_0"  class = "is-correct">Is this correct?</mat-card> -->
    <span *ngIf="api.isMethodBox">
        <app-method *ngIf="!api.isEdit && !api.isState_0 && !api.onSend" [hidden]="api.homePageDis"></app-method>
    </span>
    <mat-card *ngIf="!api.isEdit && !api.isState_0" style="padding: 0px !important;">
        <!--<app-method *ngIf = "!api.isEdit && !api.isState_0 && !api.onSend"></app-method> -->
        <app-summary></app-summary>

    </mat-card>



    <app-footer *ngIf="!api.isState_0"></app-footer>
    <div *ngIf="!api.isSticky">
        <div class="container btn-update-clear bottom" *ngIf="!api.isEdit && !api.isState_0 && !api.onSend">
            <div class="row sticky-button flex-c">
                <div class="wrapper w100" *ngIf="!api.isPaid && api.isStoreOpen">

                    <div class="col center-div wrapper"
                        *ngIf="api.keywordText !== '-1' || api.errorText !== '-1' || api.irrelevantText !== '-1' || api.quickOrder.isQuickName">
                        <!-- <button
              mat-raised-button
              *ngIf = "!api.quickOrder.isQuickName"
              type="button"
              class=" back-btn animated fadeInDown "
              (click) = "onEdit()" > Edit <span class="glyphicon glyphicon-pencil send-gl"></span> </button> -->
                        <button mat-raised-button
                            *ngIf="api.keywordText !== '-1' || api.errorText !== '-1' || api.irrelevantText !== '-1'"
                            type="button" class=" back-btn-pro animated fadeInDown " (click)="onBackViewState()"> Back
                        </button>
                        <button mat-raised-button type="button" *ngIf="api.quickOrder.isQuickName"
                            class="back-btn-pro animated fadeInDown " (click)="onBack()"> Back </button>
                    </div>
                    <div class="col center-div wrapper" [hidden]="api.infoMsg">
                        <button mat-raised-button type="button" [ngStyle]="{background:api.primaryColor}"
                            class="send-btn animated fadeInDown w100" style="border-radius: 5px;color: white;"
                            (click)="onCheckOut()">
                            <!-- <span class="glyphicon glyphicon-shopping-cart send-gl"></span> --> Checkout </button>
                    </div>
                </div>
                <div *ngIf="api.isPaid && api.isStoreOpen">
                    <div class="flex-container-paid">
                        <div class=" wrapper paid-msg"><b> Order Already Accepted.</b></div>
                        <div class=" wrapper paid-msg-1 "> Thank you! </div>
                    </div>
                    <!--If you are having issues with this order, please call the store.-->
                </div>
                <div *ngIf="!api.isStoreOpen">
                    <div class="flex-container-paid">
                        <div class=" wrapper paid-close" style="font-size: 13px;"><b> Sorry, we are not taking new
                                orders at the moment.</b></div>
                        <div class=" wrapper paid-close-1 " style="font-size: 14px;"> For more information, please call
                            us at &nbsp; <a href="tel:{{api.restaurant.rid}}"
                                style="text-decoration: none; color: white;"> {{(api.displayNum)}} </a></div>
                    </div>
                </div>
            </div>

        </div>
    </div>

</div>


<div *ngIf="api.isState_0" style = "padding: 2px;">
    <app-disclaimer *ngIf="api.isDisclaimer"> </app-disclaimer>
    <app-contact *ngIf="api.isContact"></app-contact>
    <app-tip *ngIf="api.isTip"></app-tip>
    <app-bt *ngIf="api.isBt"></app-bt>
    <app-pin-set *ngIf="api.pinFailure"> </app-pin-set>
    <app-payment-success *ngIf="api.isSuccess"></app-payment-success>
    <app-payment-failure *ngIf="api.isFailure"></app-payment-failure>


    <!--
    <app-pin-set *ngIf = "api.isPinset" > </app-pin-set>
    <mat-card style = "padding: 1px; padding-bottom: 15px" *ngIf = "api.isTip">
        <app-tip ></app-tip>
    </mat-card>
    <app-bt *ngIf = "api.isBt"></app-bt> -->
    <!--
    <app-checkout *ngIf = "!api.isSuccess"></app-checkout>
    <app-method *ngIf = "!api.isSuccess" ></app-method>
    <app-contact *ngIf = "!api.isState_1"></app-contact>
    <mat-card style = "padding: 1px; padding-bottom: 15px" *ngIf = "api.isState_1 && !api.isState_2 && !api.isSuccess && !api.isUserPin">
        <app-tip ></app-tip>
    </mat-card>
    <app-payment-success *ngIf = "api.isSuccess"></app-payment-success>
    <app-pin-payment *ngIf = "api.isUserPin && !api.isSuccess"></app-pin-payment>
    <app-bt *ngIf = "api.isState_2 "></app-bt>
    <app-pin-set *ngIf = "api.setPIN && !api.isSuccess" > </app-pin-set>-->
    <app-footer></app-footer>
</div>

<!-- States FLow Karthi n ALi design
<div *ngIf = "api.isState_0">

<app-checkout *ngIf = "!api.isSuccess"></app-checkout>
<app-method *ngIf = "!api.isSuccess" ></app-method>
<app-contact *ngIf = "!api.isState_1"></app-contact>
<mat-card style = "padding: 1px; padding-bottom: 15px" *ngIf = "api.isState_1 && !api.isState_2 && !api.isSuccess && !api.isUserPin">
    <app-tip ></app-tip>
</mat-card>
<app-payment-success *ngIf = "api.isSuccess"></app-payment-success>
<app-pin-payment *ngIf = "api.isUserPin && !api.isSuccess"></app-pin-payment>
<app-bt *ngIf = "api.isState_2 "></app-bt>
<app-pin-set *ngIf = "api.setPIN && !api.isSuccess" > </app-pin-set>
<app-footer></app-footer>
</div>  -->