<div style="margin: auto; width: 50%;">
  <div
    class="flex-container"
    style="padding-bottom: 0px; display: flex; justify-content: flex-start;"
    routerLink=""
  >
    <h3 class="title-1">
      <span style="color: black;">Ordr</span
      ><span style="color: lightgray;">Ai</span>
    </h3>
  </div>
  <hr
    style="
      margin: 0px;
      margin-left: 20px;
      margin-right: 20px;
      border: 0;
      border-top: 1px solid #212529;
    "
  />
</div>
<div class="row food-background">
  <div class="col-sm-4"></div>
  <div
    class="col-md-4"
    style="padding-top: 0%; justify-content: center; display: flex;"
  >
    <div class="card mT30" style="width: 75%;">
      <h1 class="card-header header-login">Reset OrdrAi account password</h1>
      <div class="card-body">
        <div *ngIf="errorFlag" class="alert alert-danger" role="alert">
          {{ errorMessage }}
        </div>

        <div>
          <!--<div class="form-group">
                      <label for="username" style = "color: gray; font-weight: 400">Username</label>
                      <input type="text" disabled [(ngModel)]="userName" class="form-control"  />
                     
                  </div> -->
          <div class="form-group">
            <label for="password" style="color: gray; font-weight: 400;">
              New Password</label
            >
            <input
              type="password"
              id="newpassword"
              (ngModelChange)="validation($event)"
              [(ngModel)]="newPassword"
              class="form-control"
            />
          </div>
          <div class="form-group">
            <label for="password" style="color: gray; font-weight: 400;"
              >Retype Password</label
            >
            <input
              type="password"
              [(ngModel)]="newPasswordConfirm"
              class="form-control"
            />
            <div class="mT10">
              <p>
                <i
                  class="fa {{
                    passwordLength == true
                      ? 'fa-check-circle green'
                      : 'fa-times-circle red'
                  }} "
                  aria-hidden="true"
                ></i>
                Min 8 Characters
              </p>
              <p>
                <i
                  class="fa {{
                    passwordAlphabet == true
                      ? 'fa-check-circle green'
                      : 'fa-times-circle red'
                  }}"
                  aria-hidden="true"
                ></i>
                Should Contain Alphabet's
              </p>
              <p>
                <i
                  class="fa {{
                    passwordNumeric == true
                      ? 'fa-check-circle green'
                      : 'fa-times-circle red'
                  }}"
                  aria-hidden="true"
                ></i>
                Should Contain Numbers
              </p>
              <p>
                <i
                  class="fa {{
                    passwordSpecialCharacter == true
                      ? 'fa-check-circle green'
                      : 'fa-times-circle red'
                  }}"
                  aria-hidden="true"
                ></i>
                Should Contain Special Characters
              </p>
            </div>
            <div class="text-center mT30 mB20">
              <button
                (click)="reset()"
                class="btn btn-primary black-bg pL30 pR30 {{
                  passworddisable() ? 'disabled' : ''
                }} "
                style="font-size: 13px;"
              >
                <span>Reset Password</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-sm-4"></div>
  <div class="clearfix"></div>

  <app-footer class="w100"></app-footer>
</div>
