<nav
  class="navbar navbar-expand-md navbar-dark fixed-top black-bg"
  style="border-radius: unset; position: fixed; border-bottom:3px solid #337ab7 ;"
>
<div class="col-sm-4 pR0">
  <a class="navbar-brand f-30" style="font-family: Arial, Helvetica, sans-serif;" href="#"><b>OrdrAi</b></a>
  <span class="text-center pT20 navbar-brand desk-hide" style="float: right;padding-right: 0px;margin-right: 0px;">
   <!-- <span routerLink="/demo" class="f-15">Demo</span> --> &nbsp;&nbsp;
    <span  routerLink="/home-contact"  class="f-15">Contact</span> 
    <!--<button
      type="button"
      class="btn btn-primary mR20 f-17 black-bg but-2  mB20 robo"
      routerLink="/demo" style="border-radius: 0px; border:none; color: white;margin-right: 10px!important;"
    >
      <b>Demo</b>
    </button>
    <button
      type="button"
      class="btn btn-primary mL20 f-17 black-bg but-2  mB20 robo"
      routerLink="/home-contact" style="border-radius: 0px; border:none; color: white;"
    >
     <b> Contact </b>
    </button> -->
  </span>
</div>
 <div class="col-sm-8 mob-hide" style="text-align:right">
  <span class="text-center pT20 navbar-brand" style="float: unset;">
  <!--  <span routerLink="/demo" class="f-16">Demo</span> --> &nbsp;&nbsp;
    <span routerLink="/home-contact"  class="f-16">Contact</span>
    <!--<button
      type="button"
      class="btn btn-primary mR20 f-17 black-bg but-2  mB20 robo"
      routerLink="/demo" style="border-radius: 0px; border:none; color: white;"
    >
      <b>Demo</b>
    </button>
    <button
      type="button"
      class="btn btn-primary mL20 f-17 black-bg but-2  mB20 robo"
      routerLink="/home-contact" style="border-radius: 0px; border:none; color: white;"
    > 
     <b> Contact </b>
    </button>-->
  </span>
  </div>
  <!--<button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" id="navbarCollapse">
    <ul class="navbar-nav mr-auto">
      <li class="nav-item active">
        <a class="nav-link" href="#">Home <span class="sr-only">(current)</span></a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="#">Link</a>
      </li>
      <li class="nav-item">
        <a class="nav-link disabled" href="#">Disabled</a>
      </li>
    </ul>
   
  </div> -->
</nav>
<div class="auto-height">
  <div class="background">
    <div class="layer">
      <div class="heading">
        
       <!-- <p class="text-center f-35 white-c ant " style="margin-top:150px">
          <b>Keep Your Customers Coming Back With The</b>
        </p> -->
       <!-- <p class="text-center f-50 white-c " style="padding: 10px;">
          <b>Fastest & Easiest Way to Order a Pizza</b>
        </p> -->
        <p class="text-center f-50 white-c  head-font "  style="text-transform: uppercase;padding: 10px;margin-top:30px; margin-left: 20%;
        margin-right: 20%;">
          <b>The Fastest Way to Order Food</b>
        </p>
      </div>
      <div class="">
        <p class="text-center pT20 f-25 white-c robo" style="margin-left: 17%;
        margin-right: 17%;">
       OrdrAi provides Text and Voice ordering for leading restaurant chains. Sign up today for a free trial.
      </p>
       <!--<p class="text-center f-25 white-c robo">
          No app. No complications. Just one text and an order is placed.
        </p> -->
      </div>
      <div class="text-center mT60" style="margin-top:80px" >
       <!-- <button
          type="button"
          class="btn btn-primary mR20 f-17 black-bg wid-200 mB20 robo"
          routerLink="/demo" style="border-radius: 0px; border:none;"
        >
          <b>SEE IT IN ACTION </b>
        </button> -->
        <button
          type="button"
          class="btn btn-primary mR20 mL20 f-17 black-bg but-2 wid-200 mB20 robo"
          routerLink="/home-contact" style="border-radius: 0px; border:none; color: white;"
        >
         <b> GET STARTED </b>
        </button> 
      </div> 
    </div>
  </div>
</div>
<!--<div class="mT50">
  <div class="">
    <p class="mT50 text-center f-35 orange-c ant"><b>The Future is Easy</b></p>
    <p class="text-center f-18 robo">
      Want your customers to order more often? Give them easier ways to order.
    </p>
  </div>
  <div class="col-sm-12 mT40">
    <div class="margin-auto">
      <div style="width: 32%; display: inline-block;" class="">
        <img width="50px" src='/assets/Fast.png' />
       
        <p class="text-center f-16 robo mT20">
          Ordering with OrdrAi is officially faster than any app, site or chatbot on the market. Reach out to find out how we do it.
        </p>
      </div>
      <div  style="width: 32%; display: inline-block;"class="">
        <img  width="50px" src='/assets/Accessible.jpg' />
        <p class="text-center f-16 robo mT20">
          Forcing customers to order on apps and sites excludes many potential customers. Text and Voice with OrdrAi makes ordering accessible to every potential client. Reach out to find out how we are ADA compliant and how we can help you reach all demographics.
        </p>
      </div>
      <div  style="width: 32%; display: inline-block;" class="">
        <img  width="50px" src='/assets/Proven.png' />
        <p class="text-center f-16 robo mT20">
          OrdrAi has processed 450,000 real life text orders and has a 4.92 customer satisfaction rating. 85% of customers who begin ordering complete their order. Customers who order with OrdrAi order 6% faster.
        </p>
      </div>
    </div>
  </div>
  <div class="col-sm-12 mT40">
    <div class="w80 margin-auto">
      <div class="col-sm-4 text-center">
        <img width="150px" src='/assets/Fast.png' />
       
        <p class="text-center f-16 robo mT20">
          Ordering with OrdrAi is officially faster than any app, site or chatbot on the market. Reach out to find out how we do it.
        </p>
      </div>
      <div class="col-sm-4 text-center">
        <img  width="150px" src='/assets/Accessible.jpg' />
        <p class="text-center f-16 robo mT20">
          Forcing customers to order on apps and sites excludes many potential customers. Text and Voice with OrdrAi makes ordering accessible to every potential client. Reach out to find out how we are ADA compliant and how we can help you reach all demographics.
        </p>
      </div>
      <div class="col-sm-4 text-center">
        <img  width="150px" src='/assets/Proven.png' />
        <p class="text-center f-16 robo mT20">
          OrdrAi has processed 450,000 real life text orders and has a 4.92 customer satisfaction rating. 85% of customers who begin ordering complete their order. Customers who order with OrdrAi order 6% faster.
        </p>
      </div>
    </div>
  </div>
</div>  -->
<style type="text/css">
  div.new_html_code {
    width:150px;
    height:300px;
    min-width:150px;
    min-height:100px;
    max-width:200px;
   
    overflow:hidden;
    display:block;
    
  }
</style>
<!--<div class="col-sm-12 ">
  <h1 class="text-center bold ant">Client Testimonials</h1>
  <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
    <ol class="carousel-indicators">
      <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
      <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
      <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
      <li data-target="#carouselExampleIndicators" data-slide-to="3"></li>
    </ol>
    <div class="carousel-inner slider-bg">
      <div class="carousel-item active">
        <div class="new_html_code"></div>
        <div class="carousel-caption  black-c">
          <p _ngcontent-c1="" class="text-center f-35 bold ant ">
            <i>“I just text ordered pizza!! The future is here!”</i></p>
            <p class="f-18 text-center pB25">- Customer, Ann Arbor, MI</p>
      </div>
      </div>
      <div class="carousel-item slider-bg">
        <div class="new_html_code"></div>
        <div class="carousel-caption black-c">
          <p _ngcontent-c1="" class="text-center f-35 bold ant ">
            <i>“I am never going to order any other way again. My repeat orders take 10 seconds tops!”</i></p>
            <p class="f-18 text-center pB25">- Customer, Louisville, KY</p>
      </div>
      </div>
      <div class="carousel-item slider-bg">
        <div class="new_html_code"></div>
        <div class="carousel-caption  black-c">
          <p _ngcontent-c1="" class="text-center f-35 bold ant ">
            <i>“Text ordering has been such a huge savior during the lockdown. We encourage all of our phone customers to text their order.”</i></p>
            <p class="f-18 text-center pB25">- Pizza Franchisee, Chicago, IL</p>
      </div>
      </div>
      <div class="carousel-item slider-bg">
        <div class="new_html_code"></div>
        <div class="carousel-caption   black-c">
          <p _ngcontent-c1="" class="text-center f-35 bold ant ">
            <i>“This just might be the best food ordering tech on the planet”</i></p>
            <p class="f-18 text-center pB25">- CIO, Jet’s Pizza</p>
      </div>
      </div>
    </div>
    <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
      <i class="fa fa-chevron-left" aria-hidden="true"></i>
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="sr-only">Previous</span>
    </a>
    <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="sr-only">Next</span>
    </a>
  </div>

</div>
<div class="col-sm-12 mT30">
  <p class="f-18 text-center">Integration Partners</p>
  <div class="text-center">
    <img width="25%" src="/assets/images/logo1.jpg" /> &nbsp;
    <img width="16%" src="/assets/images/logo2.png" /> &nbsp;
    <img width="14%" src="/assets/images/logo3.jpg" />
  </div>
</div> -->
<div class="col-sm-12 con-bag" style="background-color: #e0e0e0;">
  <div >
  <p style="
  text-transform: uppercase;
  margin-left: 20%;
  margin-right: 20%;" class="pT30 text-center f-35 bold white-c head-font">
    THE ONLY CHATBOT THAT ACTUALLY GETS PEOPLE ORDERING
  </p>
  
 <!-- <p class="text-center w80 f-16 margin-auto white-c robo">
    Are you text obsessed? If not, it’s time to be. Jump on board to a platform
    that gets bigger, smarter, and stronger every year, and unlock your largest
    market of consumers. This is the simplest way to order. And it’s what
    ordering should have been all long.
  </p>-->
  <div class="w80 margin-auto white-c" style="margin-top: 40px !important;">
    <div class="col-sm-4 text-center">
      <img height="120px" src='/assets/images/cometitive.png' />
      <br>
      <p class="f-18 text-center bold robo mT20">Give Customers One Number to Text</p>
      <p class="text-center f-16 robo">
        Streamline marketing and adoption by advertising a single text number for all of locations.
      </p>
    </div>

    <div class="col-sm-4 text-center">
      <img height="120px" src='/assets/images/marketing.png' />
      <br>
      <p class="f-18 text-center bold robo mT20">
        Be Accessible To All Market Segments
      </p>
      <p class="text-center f-16 robo">
        Not all customers are tech savvy, but everyone can text. Reach a new digital audience with text message ordering. 
      </p>
    </div>
    <div class="col-sm-4 text-center">
      <img height="120px" src='/assets/images/audiance.png' />
      <br>
      <p class="f-18 text-center bold robo mT20">Drive New and Repeat Business</p>
      <p class="text-center f-16 robo " >
        Watch new and repeat orders rise immediately. Expect sales to grow by 5-8% in the first 6 months after launch. 
      </p>
    </div>
  </div>
  <div class="clearfix"></div>
  <div class="text-center mT50 mB50 pB30">
   <!-- <button
      type="button"
      class="btn btn-primary mR20 f-17 black-bg wid-200 mB20 robo"
      routerLink="/demo" style="border-radius: 0px; border:none;"
    >
      <b>SEE IT IN ACTION</b>
    </button>
    <button
      type="button"
      class="btn btn-primary mL20 mR20 f-17 black-bg but-2 wid-200 mB20 robo"
      routerLink="/home-contact" style="border-radius: 0px; border:none; color: white;"
    >
      <b>GET IN TOUCH</b>
    </button> -->
  </div>
</div>
</div>
<div class="col-sm-12 mT40 mB40">
  <p class="text-center f-35 bold head-font">Nothing is Easier than OrdrAi</p>
  
  <div class="w70 margin-auto mT50 pB50">
  <!--   <app-demo-video></app-demo-video> -->
  </div>
</div>


<div class="col-sm-12 ">
  <h1 style="text-transform: uppercase; margin-left:20px;margin-right: 20px;" class="text-center f-35 bold head-font">Client Testimonials</h1>
  <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
    <ol class="carousel-indicators">
      <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
      <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
      <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
      <li data-target="#carouselExampleIndicators" data-slide-to="3"></li>
    </ol>
    <div class="carousel-inner slider-bg">
      <div class="carousel-item active">
        <div class="new_html_code" style="height:200px!important;"></div>
        <div class="carousel-caption  black-c robo" style="margin-top:-150px">
          <p _ngcontent-c1="" class="text-center f-35">
            <i>“I just text ordered pizza!! The future is here!”</i></p>
            <p class="f-18 text-center pB25">- Customer, Ann Arbor, MI</p>
      </div>
      </div>
      <div class="carousel-item slider-bg">
        <div class="new_html_code"  style="height:250px!important;" ></div>
        <div class="carousel-caption black-c robo" style="margin-top:-100px">
          <p _ngcontent-c1="" class="text-center f-35   ">
            <i>“I am never going to order any other way again. My repeat orders take 10 seconds tops!”</i></p>
            <p class="f-18 text-center pB25">- Customer, Louisville, KY</p>
      </div>
      </div>
      <div class="carousel-item slider-bg">
        <div class="new_html_code" ></div>
        <div class="carousel-caption  black-c robo">
          <p _ngcontent-c1="" class="text-center f-35 ">
            <i>“Text ordering has been such a huge savior during the lockdown. We encourage all of our phone customers to text their order.”</i></p>
            <p class="f-18 text-center pB25">- Pizza Franchisee, Chicago, IL</p>
      </div>
      </div>
      <div class="carousel-item slider-bg">
        <div class="new_html_code" style="height:250px!important;"></div>
        <div class="carousel-caption   black-c robo" style="margin-top:-100px">
          <p _ngcontent-c1="" class="text-center f-35 ">
            <i>“This just might be the best food ordering tech on the planet”</i></p>
            <p class="f-18 text-center pB25">- CIO, Jet’s Pizza</p>
      </div>
      </div>
    </div>
    <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
      <i class="fa fa-chevron-left" aria-hidden="true"></i>
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="sr-only">Previous</span>
    </a>
    <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="sr-only">Next</span>
    </a>
  </div>

</div>

<div class="col-sm-12 simpler ">
  <p class="mT50 text-center f-35 bold white-c head-font pT20">How We Get You Onboarded</p>
  <p class="text-center f-25 bold white-c robo">
    Here’s how to get started with text ordering.
  </p>

  <div class="w80 margin-auto" style="margin-top: 40px !important;">
    <div class="col-sm-4 white-c text-center">
      <img height="120px" src='/assets/images/one.png' />
     
      <p class="f-20 text-center bold robo">Pilot</p>
      <p class="text-center f-16 robo">
        We will run a 1-2 month, up to 30 store pilot without any POS integration. No setup, no investment, no development. As Text and Voice orders are placed, our team will key orders into your existing site or app in real-time.
      </p>
    </div>
    <div class="col-sm-4 white-c text-center">
      <img height="120px" src='/assets/images/two.png' />
     
      <p class="f-20 text-center bold robo">Integration</p>
      <p class="text-center f-16 robo">
        After a successful pilot, our dedicated integration team will work with your Point of Sale team to fully integrate Text and Voice ordering for a seamless customer and employee experience. Most integrations take less than 6 weeks.
      </p>
    </div>
    <div class="col-sm-4 white-c text-center">
      <img height="120px" src='/assets/images/three.png' />
     
      <p class="f-20 text-center bold robo">Roll-Out</p>
      <p class="text-center f-16 robo">
        Roll out your new platform chain wide and begin to advertise your new, innovative, accessible, awesome ordering channel. Expect a 5-8% increase in sales in the first 6 months after launch.
      </p>
    </div>
  </div>
  <div class="clearfix"></div>
  <!--<div class="text-center mT30 mB30 pB30">
    <button
      type="button"
      class="btn btn-primary mL20 f-17 black-bg but-2"
      routerLink="/demo"
    >
      <b>GET IN TOUCH</b>
    </button>
  </div> -->
</div>

<div class="col-sm-12 mT30">
  <p class="f-18 text-center">Integration Partners</p>
  <div class="text-center">
    <img width="25%" src="/assets/images/logo1.jpg" /> &nbsp;
    <img width="16%" src="/assets/images/logo2.png" /> &nbsp;
    <img width="14%" src="/assets/images/logo3.jpg" />
  </div>
</div>
<div class="col-sm-12 pre-footer pL0" style="background-color: #e0e0e0;">
  <div class="layer">
  <p class="pT90 f-45 text-center bold white-c head-font" style="    font-size: 45px;margin-left: 20%;margin-right: 20%;">
    It’s time to unlock your digital ordering potential
  </p>
  <p class="text-center f-20 white-c robo">Your customers are waiting.</p>
  <div class="text-center mT30 mB30 pB30">
    <!--<button
      type="button"
      class="btn btn-primary mR20 f-17 black-bg wid-200 mB20 robo"
      routerLink="/demo" style="border-radius: 0px; border:none;"
    >
      <b>SEE IT IN ACTION</b>
    </button> -->
    <button
      type="button"
      class="btn btn-primary mL20 f-17 mR20 black-bg but-2 wid-200 mB20 robo"
      routerLink="/home-contact" style="border-radius: 0px; border:none; color: white;"
    >
      <b>GET STARTED</b>
    </button>
  </div>
  </div>
</div>

<nav
  class="col-sm-12 black-bg"
  style="border-radius: unset;margin-bottom: 0px; border-top: 3px solid #337ab7;"
>
<div class="col-sm-4 mT20">
  <!--<a class="navbar-brand f-16" style="font-family: Arial, Helvetica, sans-serif;"  href="#"><b>OrdrAi</b></a> -->
  <p class=" f-16 white-c robo" style="color: white!important;" (click)="tryit()"><u>Text Ordering - Live</u></p>
  <p class=" f-16 robo" style="color: white!important;"  (click)="tryquick()" > <u>Quick Ordering - Live</u></p>
</div>
<div class="col-sm-4 mT20">
  <p class=" f-16 white-c robo text-center" style="color:white">3917 Research Park Drive Ste B-7 Ann Arbor, MI 48108</p>
 <p class=" f-16 white-c robo text-center" style="color:white">info@ordrai.com   (734) 717-2769</p>
</div>

<div class="col-sm-4 fRight mT30" style="text-align: right;">
  <a class="f-16" href="">
    © 2020 OrdrAi</a>
</div>
<br>
<!--<div class="col-sm-12 text-center">
 <p style="color:white">3917 Research Park Drive Ste B-7 Ann Arbor, MI 48108</p>
 <p style="color:white">info@ordrai.com   (734) 717-2769</p>
</div> -->
 
  
</nav>