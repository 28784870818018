<mat-dialog-content>
    <div>
        <h2 class = "step-txt">Text "Hello" to {{api.displayNum}}.</h2> 
    </div>
</mat-dialog-content>

<mat-dialog-actions class = "close-btn">
   
    <button mat-raised-button color="primary" (click) = "close()"> Close </button>
</mat-dialog-actions>
