<nav class="navbar font navbar  {{ name == 'jets' ? 'black-bg' : '' }} {{name==='northernlights'?'nl-bg':''}}">
  <table class="{{ pro.mobile ? 'w100 ' : 'w40 margin-auto' }}">
    <tr>
      <!---->
      <td *ngIf="name == 'jets'" style="display: flex; justify-content: center">
        <img class="" height="60%" width="75%" src="/assets/jets_new_log.png" />
      </td>

      <td
        *ngIf="name == 'demo restaurant'"
        style="display: flex; justify-content: center"
      >
        <img class="" height="60%" width="75%" src="/assets/jets_new_log.png" />
      </td>
      
      <td
        *ngIf="name == 'defelicepizza' || name == 'defelicebros'"
        style="display: flex; justify-content: center"
      >
        <img
          class=""
          height="30%"
          width="30%"
          src="/assets/images/df-logo.png"
        />
      </td>
      <td
        *ngIf="name == 'romeos'"
        style="display: flex; justify-content: center"
      >
        <img
          class=""
          height="73px"
          width="91%"
          src="/assets/images/romeosLogo.png"
        />
      </td>
      <td
        *ngIf="name == 'marcos'"
        style="display: flex; justify-content: center"
      >
        <img class="" height="73px" width="91%" src="assets/images/Marco.svg" />
      </td>
      <td
        *ngIf="name == 'howies'"
        style="display: flex; justify-content: center"
      >
        <img
          class=""
          height="73px"
          width="91%"
          src="assets/images/hungry.png"
        />
      </td>
      <td
        *ngIf="name == 'master'"
        style="display: flex; justify-content: center"
      >
        <img
          class=""
          height="73px"
          width="40%"
          height="40%"
          src="assets/images/master.jpg"
        />
      </td>
      <td
        *ngIf="name == 'blaze'"
        style="display: flex; justify-content: center"
      >
        <img
          class=""
          height="73px"
          width="40%"
          height="40%"
          src="/assets/images/blaze-logo.png"
        />
      </td>
      <td
        *ngIf="name == 'pizzaworld'"
        style="display: flex; justify-content: center"
      >
        <img
          class=""
          height="73px"
          width="40%"
          height="40%"
          src="assets/images/pizza-world-logo.jpg"
        />
      </td>
      <td
        *ngIf="name == 'eastofchicago'"
        style="display: flex; justify-content: center"
      >
        <img
          class=""
          height="73px"
          width="30%"
          height="40%"
          src="assets/images/east-of-chicago-logo.jpg"
        />
      </td>
      <td
        *ngIf="name == 'coolrunnings'"
        style="display: flex; justify-content: center"
      >
        <img
          class=""
          height="73px"
          width="30%"
          height="40%"
          src="assets/images/cool-runnings.jpg"
        />
      </td>
      <td
        *ngIf="name == 'centercourt'"
        style="display: flex; justify-content: center"
      >
        <img
          class=""
          height="73px"
          width="60%"
          height="40%"
          src="assets/images/ccbrew-logo.png"
        />
      </td>
      <td
        *ngIf="name == 'gattis'"
        style="display: flex; justify-content: center"
      >
        <img
          class=""
          height="73px"
          width="30%"
          height="40%"
          src="/assets/images/gattis-logo.png"
        />
      </td>
      <td
        *ngIf="name == 'hungerrush'"
        style="display: flex; justify-content: center"
      >
        <img
          class=""
          height="73px"
          width="75%"
          height="30%"
          src="/assets/images/hungerush.png"
        />
      </td>
      <td
        *ngIf="name == 'smoothie king' || name == 'smoothieking'"
        style="display: flex; justify-content: center"
      >
        <img
          class=""
          height="73px"
          width="40%"
          height="30%"
          src="/assets/images/smoothie-logo.png"
        />
      </td>
      <td
        *ngIf="name == 'wayback' && !this.pro.mobile"
        style="display: flex; justify-content: center"
      >
        <img
          class=""
          height="73px"
          width="50%"
          height="75%"
          src="/assets/images/wayback-logo.png"
        />
      </td>
      <td
        *ngIf="name == 'wayback' && this.pro.mobile"
        style="display: flex; justify-content: center"
      >
        <img
          class=""
          height="73px"
          width="75%"
          height="75%"
          src="/assets/images/wayback-logo.png"
        />
      </td>
      <td
        *ngIf="name == 'sammys'"
        style="display: flex; justify-content: center"
      >
        <img
          class=""
          height="73px"
          width="40%"
          height="30%"
          src="/assets/images/sammys-logo.png"
        />
      </td>
      <td
        *ngIf="name == 'gioninos'"
        style="display: flex; justify-content: center"
      >
        <img
          class=""
          height="73px"
          width="50%"
          height="50%"
          src="/assets/images/gioninos-logo.png"
        />
      </td>
      <td
        *ngIf="name == 'giovanni'"
        style="display: flex; justify-content: center"
      >
        <img
          class=""
          height="73px"
          width="30%"
          height="50%"
          src="/assets/images/giovannis-logo.png"
        />
      </td>
      <td
        *ngIf="name == 'mannyolgas'"
        style="display: flex; justify-content: center"
      >
        <img
          class=""
          height="73px"
          width="35%"
          height="50%"
          src="/assets/images/manny-logo.png"
        />
      </td>
      <td
        *ngIf="name == 'freebirds'"
        style="display: flex; justify-content: center"
      >
        <img
          class=""
          height="73px"
          width="35%"
          height="50%"
          src="/assets/images/free-birds-logo.png"
        />
      </td>
      <td
        *ngIf="
          name == 'bcpizza' ||
          name == 'b.c.pizza' ||
          name == 'bc' ||
          name == 'bc pizza'
        "
        style="display: flex; justify-content: center"
      >
        <img
          class=""
          height="73px"
          width="50%"
          height="50%"
          src="/assets/images/bc-pizza-logo.png"
        />
      </td>

      <td
        *ngIf="name == 'wingstogo'"
        style="display: flex; justify-content: center"
      >
        <img
          class=""
          height="73px"
          width="50%"
          height="50%"
          src="/assets/images/wings-to-go-logo.png"
        />
      </td>

      <td
        *ngIf="name == 'extreme'"
        style="display: flex; justify-content: center"
      >
        <img
          class=""
          height="73px"
          width="25%"
          height="50%"
          src="/assets/images/extreme-logo.png"
        />
      </td>

      <td
        *ngIf="name == 'blackjack'"
        style="display: flex; justify-content: center"
      >
        <img
          class=""
          height="73px"
          width="55%"
          height="50%"
          src="/assets/images/black-jack-logo.png"
        />
      </td>
      <td
        *ngIf="name == 'chowly'"
        style="display: flex; justify-content: center"
      >
        <img
          class=""
          height="73px"
          width="20%"
          height="50%"
          src="/assets/images/chowly.png"
        />
      </td>
      <td
        *ngIf="name == 'checkmate'"
        style="display: flex; justify-content: center"
      >
        <img
          class=""
          height="73px"
          width="60%"
          height="100%"
          src="/assets/checkmate-logo.png"
        />
      </td>
      <td
        *ngIf="name == 'simplesimon'"
        style="display: flex; justify-content: center"
      >
        <img
          class=""
          height="73px"
          width="30%"
          height="100%"
          src="/assets/images/ssp-logo.png"
        />
      </td>
      <td
        *ngIf="name == 'littlecaesars'"
        style="display: flex; justify-content: center"
      >
        <img
          class=""
        
          width="30%"
          height="100%"
          src="/assets/images/lc-logo.png"
        />
      </td>
      <td
        *ngIf="name == 'fajitapetes'"
        style="display: flex; justify-content: center"
      >
        <img
          class=""
        
          width="30%"
          height="100%"
          src="/assets/images/fp-logo-1.png"
        />
      </td>
      <td
      *ngIf="name == 'paparomanos'"
      style="display: flex; justify-content: center"
    >
      <img
        class=""
      
        width="25%"
        height="100%"
        src="/assets/images/pr-logo.jpg"
      />
    </td>
    <td
    *ngIf="name == 'pizzacottage'"
    style="display: flex; justify-content: center"
  >
    <img
      class=""
    
      width="30%"
      height="100%"
      src="/assets/images/pc-logo.jpg"
    />
  </td>

  <td
  *ngIf="name == 'joseppispizza'"
  style="display: flex; justify-content: center"
>
  <img
    class=""
  
    width="20%"
    height="100%"
    src="/assets/images/jsp-logo.jpg"
  />
</td>


<td
*ngIf="name == 'northernlights'"
style="display: flex; justify-content: center"
>
<img
  class=""

  width="35%"
  height="100%"
  src="/assets/images/nl-logo.png"
/>
</td>
      

      <!-- <td class="pL10">
        
        <p class="white f-12 mB0"> {{api.restaurant.address}}</p>
        <p class="white f-13 mB0">{{api.displayNum}}</p>
      </td> -->
    </tr>
    <tr>
      <td>
        <p style="color: gray" class="f-13 pT5 text-center mB0">
          {{ removeStateAndZip(api.restaurant.address) }} {{ api.displayNum }}
        </p>
      </td>
    </tr>
  </table>
</nav>
