<div>
  <div *ngIf="displayMode">
    <p class="text-center bold mB5 f-16">
      {{ api.method.mtype }}
      <span
        *ngIf="true && api.chatBotPaymentMade"
        [ngStyle]="{ color: api.primaryColor }"
        style="
          font-weight: 400;
          text-transform: lowercase;
          padding: 0px 4px;
          display: inline-block;
          cursor: pointer;
        "
        class="pointer"
        (click)="changeMethod()"
      >
        <i>change</i>
      </span>
    </p>
    <p
      *ngIf="api.method.mtype.toLowerCase() == 'delivery'"
      class="text-center bold mB5 f-16"
    >
      {{ api.method.maddress }}
    </p>
  </div>
  <div style="position: relative;" *ngIf="!displayMode">
    <app-pro-address-box
      (setAddress)="getAddress($event)"
      (setApt)="setApt($event)"
    >
    </app-pro-address-box>
    <div class="text-center">
      <div class="" style="padding: 0px">
        <button
          [ngStyle]="{ background: api.primaryColor }"
          style="border-radius: 5px"
          class="btn btn-success add-btn mT10"
          (click)="updateMethod()"
          type="button"
        >
          Update
        </button>
        &nbsp;&nbsp;
        <button
       
        style="border-radius: 5px"
        class="btn btn-dark add-btn mT10"
        (click)="displayMode=true"
        type="button"
      >
        Cancel
      </button>
      </div>
   
    </div>
  </div>
  <hr style="margin-top: 5px; margin-bottom: 5px" />
</div>
