import { Component, OnInit } from '@angular/core';
import { RestContactService } from './../../rest-contact.service';
import { APICenterService } from './../../apicenter.service';
import { jetsStore } from '../../rest-offer-page/jets/jets-store';
import { Router } from '../../../../node_modules/@angular/router';
import { MatDialog } from '../../../../node_modules/@angular/material/dialog';
import { OrderingStepsDialogComponent } from '../../ordering-steps-dialog/ordering-steps-dialog.component';
import { ClassGetter } from '../../../../node_modules/@angular/compiler/src/output/output_ast';

@Component({
  selector: 'app-jets-share',
  templateUrl: './jets.component.html',
  styleUrls: ['./jets.component.css']
})
export class JetsComponentShare implements OnInit {

  stateObject = {
    'IN': ['in14','in02','in04', 'in03', 'in05', 'in10','in01', 'in12', 'in11','in09', 'in16'],
    'PA': ['pa01'],
    'GA': ['ga01', 'ga02', 'ga03', 'ga04', 'ga06', 'ga10', 'ga17'],
    'CO': ['co01', 'co02','co03', 'co07', 'co06', 'co09', 'co05', 'co10', 'co04', 'co11'],
    'AZ': ['az01', 'az02', 'az03'],
    'MO': ['mo01', 'mo02', 'mo06', 'mo07'],
    // tslint:disable-next-line:max-line-length
    'FL': ['fl05', 'fl43', 'fl41','fl37', 'fl19', 'fl09', 'fl12', 'fl34', 'fl40', 'fl26', 'fl33', 'fl15', 'fl21', 'fl22', 'fl25', 'fl31', 'fl38', 'fl39', 'fl08', 'fl24', 'fl10', 'fl13', 'fl14', 'fl16', 'fl17', 'fl18', 'fl23', 'fl30', 'fl29', 'fl32', 'fl35'],
    // tslint:disable-next-line:max-line-length
    'IL': ['il01', 'il35', 'il30','il03', 'il14','il27', 'il28', 'il31', 'il32', 'il08', 'il17', 'il06', 'il02', 'il02', 'il02', 'il04', 'il05', 'il07', 'il11', 'il12', 'il15', 'il16', 'il18', 'il19', 'il21', 'il22', 'il23', 'il24', 'il25'],
    'KY': ['ky02', 'ky04', 'ky09', 'ky03', 'ky05', 'ky06', 'ky07', 'ky10', 'ky11', 'ky12', 'ky13', 'ky14', 'ky15'],
    'NC': ['nc01', 'nc03','nc14', 'nc09', 'nc17', 'nc05', 'nc06', 'nc08', 'nc10', 'nc11', 'nc13', 'nc16'],
    'SC': ['sc02', 'sc03', 'sc04'],
    'TX': ['tx01', 'tx23', 'tx25', 'tx21', 'tx22', 'tx13', 'tx05', 'tx17', 'tx06', 'tx08', 'tx10', 'tx11', 'tx12', 'tx04', 'tx19', 'tx20'],
    'TN': ['tn02','tn01','tn39', 'tn42', 'tn12', 'tn24', 'tn09', 'tn17', 'tn26', 'tn04', 'tn15', 'tn05', 'tn06', 'tn07', 'tn19', 'tn22', 'tn23', 'tn14', 'tn30', 'tn21', 'tn32', 'tn37', 'tn03', 'tn18', 'tn27', 'tn10', 'tn20', 'tn16', 'tn28', 'tn13', 'tn41'],
    // tslint:disable-next-line:max-line-length
    'MI': ['mi07', 'mi31', 'mi27', 'mi71', 'mi22', 'mi33', 'mi54', 'mi102', 'mi110', 'mi47', 'mi117', 'mi59', 'mi172', 'mi61', 'mi09', 'mi101','mi67','mi49','mi21','mi112','mi125','mi76','mi122', 'mi138','mi145','mi64', 'mi170', 'mi20','mi81', 'mi38', 'mi127', 'mi32', 'mi35', 'mi105', 'mi70', 'mi89', 'mi11', 'mi37', 'mi150', 'mi46', 'mi15', 'mi159', 'mi111', 'mi113', 'mi28', 'mi74', 'mi85', 'mi90', 'mi124', 'mi55', 'mi100', 'mi71', 'mi108', 'mi22', 'mi165', 'mi14', 'mi133', 'mi18', 'mi53', 'mi58', 'mi94', 'mi99', 'mi154', 'mi66', 'mi120', 'mi121', 'mi36', 'mi43', 'mi48', 'mi139', 'mi148', 'mi34', 'mi86', 'mi19', 'mi128', 'mi79', 'mi75', 'mi149', 'mi166', 'mi156', 'mi91', 'mi109', 'mi01', 'mi02', 'mi03', 'mi04', 'mi05', 'mi06', 'mi08', 'mi10', 'mi39', 'mi41', 'mi63', 'mi68', 'mi87', 'mi104', 'mi106', 'mi107', 'mi114', 'mi115', 'mi116', 'mi12', 'mi12', 'mi126', 'mi13', 'mi130', 'mi132', 'mi135', 'mi140', 'mi141', 'mi143', 'mi146', 'mi151', 'mi157', 'mi16', 'mi160', 'mi161', 'mi162', 'mi162', 'mi164', 'mi169', 'mi17', 'mi23', 'mi24', 'mi25', 'mi26', 'mi29', 'mi30', 'mi42', 'mi50', 'mi51', 'mi57', 'mi60', 'mi73', 'mi77', 'mi78', 'mi82', 'mi83', 'mi84', 'mi88', 'mi92', 'mi93', 'mi95', 'mi97', 'mi98'],
    // tslint:disable-next-line:max-line-length
    'OH': ['oh40', 'oh09', 'oh27', 'oh46', 'oh47','oh13','oh03','oh07','oh04', 'oh35', 'oh15','oh34','oh44', 'oh42', 'oh29', 'oh01', 'oh05', 'oh17', 'oh20', 'oh24', 'oh29', 'oh32', 'oh45', 'oh09', 'oh16', 'oh27', 'oh36', 'oh33', 'oh06', 'oh08', 'oh10', 'oh12', 'oh14', 'oh18', 'oh19', 'oh21', 'oh22', 'oh23', 'oh25', 'oh26', 'oh30', 'oh31', 'oh37', 'oh38', 'oh39', 'oh41', 'oh43', 'ohh11'],
    'NY': ['ny01', 'ny03', 'ny04', 'ny06'],
    'NV' : ['nv02'],
    'MN' : ['mn05'],
    'UT' : ['ut01'],
    'WI': ['wi01','wi02', 'wi04', 'wi06', 'wi07', 'wi09']
  };
  storeList = [];
  storeFinalDisplayList = [];
  // storeList = ['mi26', 'mi73', 'mi51']
  restInfo;
  stateList = [
    { 'statename': 'Arizona', 'statecode': 'AZ' },
    { 'statename': 'Colorado', 'statecode': 'CO' },
    { 'statename': 'Utah', 'statecode': 'UT' },
    { 'statename': 'Florida', 'statecode': 'FL' },
    { 'statename': 'Georgia', 'statecode': 'GA' },
    { 'statename': 'Illinois', 'statecode': 'IL' },
    { 'statename': 'Indiana', 'statecode': 'IN' },
    { 'statename': 'Kentucky', 'statecode': 'KY' },
    { 'statename': 'Michigan', 'statecode': 'MI' },
    { 'statename': 'Minnesota', 'statecode': 'MN' },
    { 'statename': 'Missouri', 'statecode': 'MO' },
    { 'statename': 'New York', 'statecode': 'NY' },
    { 'statename': 'North Carolina', 'statecode': 'NC' },
    { 'statename': 'Ohio', 'statecode': 'OH' },
    { 'statename': 'Pennsylvania', 'statecode': 'PA' },
    { 'statename': 'South Carolina', 'statecode': 'SC' },
    { 'statename': 'Tennessee', 'statecode': 'TN' },
    { 'statename': 'Texas', 'statecode': 'TX' },
    { 'statename': 'Nevada', 'statecode': 'NV' },
    { 'statename': 'Wisconsin', 'statecode': 'WI' }
  ];

  showState = false;
  smsNumber;
  // addy1 = [];
  // addy2 = [];

  text = 'Hi Jets! I want...';
  constructor(private api: APICenterService, private _router: Router, public dialog: MatDialog) { }

  ngOnInit() {

    // tslint:disable-next-line:max-line-length
    /* this.storeList = this.storeList.concat(this.NY, this.SC, this.TX, this.PA, this.IN, this.CO, this.FL, this.IL, this.KY, this.NC, this.TN, this.MI, this.OH)
     console.log(this.storeList)
     console.log(this.storeList.length)
     this.api.isdemo = false;
     this.storeList.map((x) => {
       let sid  =  new jetsStore()
       this.restInfo = sid.findStore(x)
       this.addy1.push(this.restInfo.addy1)
       this.addy2.push(this.restInfo.addy2)
 
     })*/

    this.api.isdemo = true;
  }
  selectState(stateCode) {
    this.showState = true;
    this.storeList = [];
    this.storeFinalDisplayList = [];
    // this.addy1=[];
    // this.addy2=[];
    // var store;

    this.storeList = this.storeList.concat(this.stateObject[stateCode]);
    console.log(this.storeList);
    this.api.isdemo = false;

    this.storeList.map((x) => {
      const sid = new jetsStore();
      this.restInfo = sid.findStore(x);

      this.restInfo.id = x;
      console.log(this.restInfo);
      this.storeFinalDisplayList.push(this.restInfo);
      // this.addy1.push(this.restInfo.addy1);
      // this.addy2.push(this.restInfo.addy2);
    });

    console.log(this.storeFinalDisplayList);
    this.storeFinalDisplayList.sort(function (a, b) {
      const nameA = a.addy2.toLowerCase(), nameB = b.addy2.toLowerCase();
      if (nameA < nameB) { // sort string ascending
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
    console.log(this.storeFinalDisplayList);

    this.api.isdemo = true;

  }

  onMoreInfo(x) {
    // console.log(x)
    const routeId = `/jets/${x}`;
    this._router.navigate([routeId]);
  }

  onStartText(x) {
    console.log(x);
    const sid = new jetsStore();
    this.restInfo = sid.findStore(x);
    this.smsNumber = this.restInfo.rid;
    console.log(this.smsNumber);
    this.api.displayNum = this.api.formatPhone(this.smsNumber);
    this.orderNowBtn();
  }

  onProOrder(x) {
    const routeId = `quick/jets/${x}`;
    this._router.navigate([routeId]);
  }

  orderNowBtn() {

    if (this.api.isMobile) {
      if (this.api.deviceName === 'iPhone') {
        console.log('IPHONE');
        window.location.href = `sms:${this.smsNumber}&body=` + encodeURIComponent(this.text);
      } else {
        console.log('MAY BE andriod');
        try {
          window.location.href = `sms:${this.smsNumber}?body=` + encodeURIComponent(this.text);
        } catch {
          window.location.href = `sms:${this.smsNumber};body=` + encodeURIComponent(this.text);
        }
        finally {
          window.location.href = `sms:${this.smsNumber};?&body=` + encodeURIComponent(this.text);
        }
      }
    } else if (this.api.isDesktopDevice) {

      const dialogRef = this.dialog.open(OrderingStepsDialogComponent, {
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');
      });

    } else if (this.api.isTablet) {
      console.log('TABLET');
      const dialogRef = this.dialog.open(OrderingStepsDialogComponent, {
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');
      });
    }

  }
}
