<app-navbar *ngIf = "!isFraudActive"></app-navbar>
<div class = "out-box" >
  <div>
  <div class="container" style = "padding: 0px;">
    <span *ngIf = "!isFraudActive"> <app-edit></app-edit> </span>
    <span *ngIf = "isFraudActive"> <app-payment-failure></app-payment-failure></span>
  </div>
</div>
</div>



