import { APICenterService } from './../../apicenter.service';
import { Component, OnInit } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-order-now',
  templateUrl: './order-now.component.html',
  styleUrls: ['./order-now.component.css']
})
export class OrderNowComponent implements OnInit {
  deviceInfo = null;

  constructor(public api: APICenterService,  private deviceService: DeviceDetectorService) { 
    this.deviceDetect()
  }
  deviceDetect() {
      // // console.log(this.deviceService)
      this.deviceInfo = this.deviceService.getDeviceInfo();
      // console.log('device  Infomation')
      // console.log(this.deviceInfo)
      this.api.browserVal = this.deviceInfo.browser
      this.api.deviceVal = this.deviceInfo.device
      var d = new Date();
      this.api.timeVal = d
   
      this.api.isMobile = this.deviceService.isMobile();
      this.api.isTablet = this.deviceService.isTablet();
      this.api.isDesktopDevice = this.deviceService.isDesktop();
      // console.log(this.deviceInfo);
      this.api.deviceName = this.deviceInfo.userAgent.split('(')[1].split(';')[0]
      this.api.deviceOs = this.deviceInfo.userAgent.split('(')[1].split(';')[1]

   }
  ngOnInit() {
  }

  orderNow() {

  }

}
