import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { APICenterService } from "./../apicenter.service";
import { DeviceDetectorService } from '../../../node_modules/ngx-device-detector';
@Component({
  selector: "app-rewards-component",
  templateUrl: "./rewards-component.component.html",
  styleUrls: ["./rewards-component.component.css"],
})
export class RewardsComponentComponent implements OnInit {
  @Output() item: EventEmitter<any> = new EventEmitter();
  constructor(public api: APICenterService,private deviceService: DeviceDetectorService,) {}
  rewards: any;
  deviceInfo = null;
  ngOnInit() {

/*fetch('/assets/json/rewardsData.json').then(res => res.json())
    .then(jsonData => {
      this.api.rewards.items = jsonData.punch_reward_list;
      
    }); */

    for (var i = 0; i < this.api.rewards.items.length; i++) {
      this.api.rewards.items[i]["selected"] = false;
      if(this.api.rewards.items[i]["name"].indexOf(":")>-1){
      this.api.rewards.items[i]["name"] = this.api.rewards.items[i]["name"].split(":")[1]
      }
      else if(this.api.rewards.items[i]["name"].indexOf("-")>-1){
        this.api.rewards.items[i]["name"] = this.api.rewards.items[i]["name"].split("-")[1]
        }
        else {
          this.api.rewards.items[i]["name"] = this.api.rewards.items[i]["name"]
          }
        
    }

    console.log(this.api.rewards)
    let payload = {};
    this.deviceDetect();
   
    /*this.api.rewardsData(payload);
    this.api.getrewardsData().subscribe((data: any) => {
      console.log("rewardsData" + data);
      this.api.rewards = data.punch_reward_list;
      for (var i = 0; i < this.api.rewards.length; i++) {
        this.api.rewards[i]["selected"] = false;
      }
    });*/

  }
  click(name, reward) {

    for(var i=0;i<this.api.rewards.items.length;i++){
      if(this.api.rewards.items[i]==reward){
        this.api.rewards.items[i].selected = !this.api.rewards.items[i].selected;
        if (reward.selected) {
          // let finalName = name.replaceAll("[^a-zA-Z0-9]", " ");
          this.item.emit(name);
        }
      }
      else{
        this.api.rewards.items[i].selected=false;
      }
    }
   
  } 

  deviceDetect() {
    // console.log(this.deviceService);
    this.deviceInfo = this.deviceService.getDeviceInfo();
    this.api.browserVal = this.deviceInfo.browser;
    this.api.deviceVal = this.deviceInfo.device;
    const d = new Date();
    this.api.timeVal = d;

    this.api.isMobile = this.deviceService.isMobile();
    this.api.isTablet = this.deviceService.isTablet();
    this.api.isDesktopDevice = this.deviceService.isDesktop();
   //  console.log(this.deviceInfo);
    this.api.deviceName = this.deviceInfo.userAgent.split('(')[1].split(';')[0];
    this.api.deviceOs = this.deviceInfo.userAgent.split('(')[1].split(';')[1];

  }
}
