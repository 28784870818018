<div *ngIf="loading" class="text-center">
  <img src={{api.loadingGif}} class="rounded img-fluid" alt="...">
</div>
<div *ngIf="!loading">
<!--Login screen Starts-->
<div *ngIf="showLogin">
  <mat-dialog-content>
    <div>
      <i
        (click)="close()"
        class="fa fa-times-circle close-circle"
       
        aria-hidden="true"
      ></i>
      <p
       
        class="text-center pB10 head-txt"
      >
        Sign In & Earn Rewards
      </p>
      <div *ngIf="loginError" class="alert alert-danger" role="alert">
        {{ loginErrorMessage }}
      </div>
      <div class="form-group mT20">
        <input
          type="email"
          [(ngModel)]="username"
          class="form-control"
          placeholder="Email"
        />
      </div>
      <div class="form-group">
        <input
          type="password"
          [(ngModel)]="password"
          class="form-control"
          placeholder="Password"
        />
      </div>
     <!-- <div class="pT20 text-center">
      <span (click)="forgotPassword()" class="forgotpass">Forgot Password?</span>
      </div>-->
    </div>
    
  
    
  </mat-dialog-content>
  <mat-dialog-actions class="close-btn">
    <div class="text-center w100 pT20 " >
      <button
        type="button"
        class="btn btn-outline-success freebirdBtn"
        [ngStyle]="{ background: api.primaryColor }"
      
        (click)="signin()"
      >
        Sign In
      </button>
    </div>
  </mat-dialog-actions>
</div>
<!--Login screen ends-->
<!--Forgot Password email screen Begins-->
<div *ngIf="forgotPasswordEmail">
  <mat-dialog-content>
    <div>
      <i
        (click)="close()"
        class="fa fa-times-circle close-circle"
        aria-hidden="true"
      ></i>
      <i
      (click)="backToLoginScreen()"
      class="fa fa fa-angle-left backBtn"
      aria-hidden="true"
    >
    <span  class="f-18 bk">Back</span>
  </i>
      <p class="text-center pB10 head-txt">RESET PASSWORD</p>
      <p class="text-center f-18">
        Enter your email address and submit for your one time verification code.
      </p>
      <div *ngIf="emailError" class="alert alert-danger" role="alert">
        {{ emailErrorMessage }}
      </div>
      <div class="form-group mT20">
        <input
          type="email"
          [(ngModel)]="emailForReset"
          class="form-control"
          placeholder="Email"
        />
      </div>
    </div>
    <div></div>
  </mat-dialog-content>
  <mat-dialog-actions class="close-btn">
    <div class="text-center w100 pT20 bod" >
      <button
        type="button"
        class="btn btn-outline-success freebirdBtn  {{emailValidation()?'':'disabled'}}"
        [ngStyle]="{ background: api.primaryColor }"
        (click)="submitEmail()"
      >
        Submit
      </button>
    </div>
  </mat-dialog-actions>
</div>

<div *ngIf="otpScreen">
  <mat-dialog-content>
    <div>
      <i
        (click)="close()"
        class="fa fa-times-circle close-circle"
        aria-hidden="true"
      ></i>
      <i
      (click)="backToEmailScreen()"
      class="fa fa fa-angle-left backBtn"
      aria-hidden="true"
    >
    <span  class="f-18 bk">Back</span>
  </i>
      <p class="text-center pB10 head-txt">Verification code</p>
      <p class="text-center f-18">
        Input the code we sent to (xxx) xxxx-{{lastdigits}}
      </p>
      <div *ngIf="otpError" class="alert alert-danger" role="alert">
        {{ otpErrorMessage }}
      </div>
      <div class="form-group mT20 text-center">
        <div class="col-auto w100 pL0 margin-autos pT10 pR0">

          <div class="input-group mb-2">
            <ng-otp-input  (onInputChange)="onOtpChange($event)"  [config]="{length:4,allowNumbersOnly:true}"></ng-otp-input>
              
    
          </div>
      </div>
      </div>
      <p *ngIf="newCodeText" class="text-center f-18 pT20 mB0 green">
        <i class="fa fa-check-circle" aria-hidden="true"></i>  New verification code sent
      </p>
      <p class="text-center f-18 pT20 mB0">
        Still haven't received a text?
      </p>
      <p (click)="requestNewCode()" class="text-center f-18"
      [ngStyle]="{ color: api.primaryColor }" style="text-decoration:underline" >
        Request a new code.
      </p>
    </div>
    <div></div>
  </mat-dialog-content>
  <mat-dialog-actions class="close-btn">
    <div class="text-center w100 pT20 bod" >
      <button
        type="button"
        class="btn btn-outline-success freebirdBtn {{otp.length<4?'disabled':''}} "
        [ngStyle]="{ background: api.primaryColor }"
        (click)="validateOtp()"
      >
        Verify
      </button>
    </div>
  </mat-dialog-actions>
</div>

<div *ngIf="passwordScreen">
  <mat-dialog-content>
    <div>
      <i
        (click)="close()"
        class="fa fa-times-circle close-circle"
        aria-hidden="true"
      ></i>
      
      <p class="text-center pB10 head-txt">SET NEW PASSWORD</p>
      <div *ngIf="passwordError" class="alert alert-danger" role="alert">
        {{ passwordErrorMessage }}
      </div>
      <div class="form-group">
        <label for="password" style="color: gray; font-weight: 400;">
          New Password</label
        >
        <input
          type="password"
          id="newpassword"
          (ngModelChange)="validation($event)"
          [(ngModel)]="newPassword"
          class="form-control"
        />
      </div>
      <div class="form-group">
        <label for="password" style="color: gray; font-weight: 400;"
          >Retype Password</label
        >
        <input
          type="password"
          [(ngModel)]="newPasswordConfirm"
          class="form-control"
        />
        <div class="mT30 f-17">
          <p>
            <i
              class="fa {{
                passwordLength == true
                  ? 'fa-check-circle green'
                  : 'fa-times-circle red'
              }} "
              aria-hidden="true"
            ></i>
            Min 8 Characters
          </p>
          <p>
            <i
              class="fa {{
                passwordAlphabet == true
                  ? 'fa-check-circle green'
                  : 'fa-times-circle red'
              }}"
              aria-hidden="true"
            ></i>
            Should Contain Alphabet's
          </p>
          <p>
            <i
              class="fa {{
                passwordNumeric == true
                  ? 'fa-check-circle green'
                  : 'fa-times-circle red'
              }}"
              aria-hidden="true"
            ></i>
            Should Contain Numbers
          </p>
          <p>
            <i
              class="fa {{
                passwordSpecialCharacter == true
                  ? 'fa-check-circle green'
                  : 'fa-times-circle red'
              }}"
              aria-hidden="true"
            ></i>
            Should Contain Special Characters
          </p>
        </div>
       
      </div>
    </div>
    <div></div>
  </mat-dialog-content>
  <mat-dialog-actions class="close-btn">
    <div class="text-center w100 pT20 bod" >
      <button
        type="button"
        class="btn btn-outline-success freebirdBtn  {{
          passworddisable() ? 'disabled' : ''
        }}"
        [ngStyle]="{ background: api.primaryColor }"
        (click)="submitPassword()"
      >
        Reset Password
      </button>
    </div>
  </mat-dialog-actions>
</div>
</div>
