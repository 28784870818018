import { ProServiceService } from './../pro-order/pro-service.service';
import { Component, OnInit, Inject } from '@angular/core';
import { APICenterService } from '../apicenter.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-method-change-dialog',
  templateUrl: './method-change-dialog.component.html',
  styleUrls: ['./method-change-dialog.component.css']
})
export class MethodChangeDialogComponent implements OnInit {

  constructor( public api: APICenterService,
               private pro: ProServiceService,
               public dialogRef: MatDialogRef<MethodChangeDialogComponent>,
               @Inject(MAT_DIALOG_DATA) public data: any ) { }

  addy: string;
  isApt = false;
  apt: string;
  part1: string;
  part2: string;
  finalAddy: string;
  inContactState: boolean;
  onUpdateMethod =  false;
  subscription: Subscription;


  ngOnInit() {
    // console.log('@method-change-dailog');
  }


  getAddress($event) {
    // console.log($event.formatted_address);
    this.addy = $event.formatted_address;
    this.addressbreakdown(this.addy);
    this.pro.onBackObj.custAddy =  this.addy;

    // this.api.quickOrder.address = $event.formatted_address;
  }

  setApt($event) {
    // console.log($event);
    this.isApt = true;
    this.apt = $event;
    this.pro.onBackObj.custAPt = this.apt;
  }

  addressbreakdown(addy) {
    const addySplit = addy.split(',');
    const part2Arr = [];
    const key = addySplit.length;
    part2Arr.push(addySplit[key - 3]);
    part2Arr.push(addySplit[key - 2]);
    part2Arr.push(addySplit[key - 1]);
    this.part2 = part2Arr.join();
    this.part1 = this.addy.replace(this.part2, '');
  }

  updateMethod() {
    this.api.actionbutton("delivery_method_update",	"MS002")

     // console.log('method-change-dialog');
     this.pro.onBackObj.isPickup = false;
    if (this.isApt && this.apt.length > 0 ) {
      this.api.quickOrder.isAPt = true;
      this.finalAddy = `${this.part1} Apt ${this.apt}, ${this.part2}`;
    } else {
      this.api.quickOrder.isAPt = false;
      this.finalAddy = this.addy;
    }
    if (this.api.isContactFree) {
      if (this.api.isFutureOrder) {
        this.api.customer.msg_text = `${this.api.customer.msg_text} delivery to ${this.finalAddy} at ${this.api.method.fOrder}, ${this.api.contactLessTxt}`;
      } else {
        this.api.customer.msg_text = `${this.api.customer.msg_text} delivery to ${this.finalAddy}, ${this.api.contactLessTxt}`;
      }
    } else {
      if (this.api.isFutureOrder) {
        this.api.customer.msg_text = `${this.api.customer.msg_text} delivery to ${this.finalAddy} at ${this.api.method.fOrder}`;
      } else {
        this.api.customer.msg_text = `${this.api.customer.msg_text} delivery to ${this.finalAddy}`;
      }
    }
    //  }
    // console.log(this.api.customer.msg_text);
    this.api.resetItems();
    this.api.onSend = true;
    this.api.updateUserMsg();
    this.dialogRef.close();
  }

  close() {
    this.api.actionbutton("delivery_method_cancel",	"MS003")
    this.api.isMethodswap = false;
    this.api.isMethodChanged = false;
    this.dialogRef.close();
  }


}
