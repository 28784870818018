<mat-dialog-actions class="close-btn w100 text-center" style="padding-top: 0px">

  <h4 class="media-css w100"
      style="font-size: 25px;margin-top: 0px; font-family: Arial, Helvetica, sans-serif; font-weight: bold; letter-spacing: .75px; word-spacing: .5px; text-transform: uppercase;">
     CURBSIDE PICKUP <span  (click)="close()" class="fRight">X</span></h4>
</mat-dialog-actions>

<mat-dialog-content>
  <div>
    <p class="f-17 pT20">Provide the following details about your car so we can find you.</p>
    <div class="form-group pT20">
      <input type="text" [(ngModel)]="userData.color" class="form-control w80 margin-auto f-17 h40"  placeholder="Color">
    </div>
    <div class="form-group ">
      <input type="text" [(ngModel)]="userData.make" class="form-control w80 margin-auto f-17 h40"  placeholder="Make">
    </div>
    <div class="form-group">
      <input type="text" [(ngModel)]="userData.model" class="form-control w80 margin-auto f-17 h40"  placeholder="Model">
    </div>
     
  </div>
</mat-dialog-content>

<mat-dialog-actions class="close-btn text-center">
<div class="text-center w100">
  <button mat-raised-button [ngStyle]="{background:api.primaryColor}" class="primary f-18 {{enableSave()?'true':'false'}}"
      style="color: white;border-radius: 5px;width: 150px;height: 35px; border: none;"  (click)="save()"> Save </button>
      </div>
</mat-dialog-actions>