<div style="display: flex;" *ngIf="loading">
  <img src={{api.loadingGif}} class="rounded img-fluid" alt="...">
</div>
<span *ngIf="!loading">
  <div *ngIf="showIframe=='iframe'" style="width: 100%;" class="text-center">
   <i class="fab fa-cc-visa f-40 pR20 pL15" aria-hidden="true"></i>
   <i class="fab fa-cc-mastercard f-40 pR20" aria-hidden="true"></i>
   <i class="fab fa-cc-amex f-40 pR20" aria-hidden="true"></i>
   <i class="fab fa-cc-discover f-40 pR20" aria-hidden="true"></i>
  </div>
  <div class="alert alert-danger" *ngIf="errorFlag" role="alert">
    {{errorMessage}}
  </div>
  <iframe #iframe [src]="paymentUrl | safe" *ngIf="showIframe=='iframe'" scrolling="no" name="iframe1" id="iframe1"
    style="width:110%;min-height:300px;border: none;position:relative; left:-30px;"></iframe>
  <p *ngIf="showIframe=='success'" class="text-center bold f-22 pT40">
    <img src={{api.loadingGif}} class="rounded img-fluid" alt="...">
  </p>
  <div *ngIf="showIframe=='cards'" class="">
    <p class="f-18 pL10">Paying with Card</p>
    <div class="card sel-card mB20" [ngStyle]="{'border-color':card.userselected? api.primaryColor:''}"
      *ngFor="let card of userCards" (click)="selectCard(card)" style="padding:10px">
      <img [src]="card.cardLogo" style="vertical-align: top;height: 40px;position: relative;top: 5px;" width="60px">
      <div class="disp-inline pos-relative t10">
        <p class="pL10 bold f-17 mB0">Ending in {{card.cardEnding}}</p>
        <p class="pL10 f-17 mB0">{{card.cardName}} {{card.exp}} </p>
      </div>
      <i *ngIf="card.userselected" class="fa fa-check-circle green-check"
        [ngStyle]="{'color':card.userselected? api.primaryColor:''}" aria-hidden="true"></i>
    </div>
    <p (click)="differentCard()"  [ngStyle]="{'color':api.primaryColor}" class="f-20 pT15 pB15 text-center pL10 text-underline"><i>Pay with another card</i></p>
    <app-text-marketing-check> </app-text-marketing-check>
    <div *ngIf="submitcheck()" class="text-center">
      <button mat-raised-button (click)="submitOrder()" color="primary" [ngStyle]="{'background':api.primaryColor}"
        style="color: white" class="pay-btn animated fadeInDown mT15 mB15 ">
        <span>
          Submit Your Order
        </span>
      </button>
    </div>
  <!--  <div class="text-center pT20 " [hidden]="paymentSubmitted">
      <button mat-raised-button type="button" [ngStyle]="{'color':api.primaryColor, 'border-color' : api.primaryColor}"
        class="back-btn  btn-payment" (click)="back()">
        Back </button>
    </div> -->
  </div>
</span>