<nav
  class="navbar navbar-expand-md navbar-dark fixed-top black-bg"
  style="border-radius: unset; position: fixed; border-bottom:3px solid #337ab7 ;"
>
<div class="col-sm-4">
  <a class="navbar-brand f-30" style="font-family: Arial, Helvetica, sans-serif;" href="#"><b>OrdrAi</b></a>
</div>

  <!--<button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" id="navbarCollapse">
    <ul class="navbar-nav mr-auto">
      <li class="nav-item active">
        <a class="nav-link" href="#">Home <span class="sr-only">(current)</span></a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="#">Link</a>
      </li>
      <li class="nav-item">
        <a class="nav-link disabled" href="#">Disabled</a>
      </li>
    </ul>
   
  </div> -->
</nav>
<div class="m-box" style="margin-top: 150px; margin-bottom: 300px;" >
  <hr>
<div *ngIf="!messageBox" class="" style="margin-top: 100px;">
<p class="m-contact-text contact-text">Please fill out this form, and we will be in touch shortly. We are excited to help you unlock your digital ordering potential.</p>
<div class="contact-form pL15 pR15">
  <div class="form-group">
    
    <input type="text" class="form-control" [(ngModel)] = "name" id="name" aria-describedby="namehelp" placeholder="Name">
   
  </div>
  <div class="form-group">
   
    <input type="text" class="form-control" id="business-name" [(ngModel)] = "businessName" aria-describedby="business-name" placeholder="Business Name">
    
  </div>
<div class="form-group">
  
  <input type="email" class="form-control" id="email" aria-describedby="emailHelp" [(ngModel)] = "email" placeholder="Email">
  
</div>
<div class="form-group">
 <textarea rows="3" class="form-control" [(ngModel)] = "schedule" placeholder="Additional Comments"></textarea> 
 
</div>
<div class="text-center mB30">
<button type="button" (click)="contactSubmit()" class="btn btn-primary contact-submit mR20 {{disabled()==true?'disabled':''}} "><i>Submit</i></button>
<button type="button" (click)="home()" class="btn btn-primary contact-cancel "><i>Cancel</i></button>

</div>

</div>
</div>
<div *ngIf="messageBox" class="messageBox ">
  <div *ngIf="responseFlag" class="success-alert-box">
    <i class="fa fa-check-circle circle-check"></i>
    <br>
    <p>Thank You. We will get back to you soon.</p>
  </div>
  <div *ngIf="!responseFlag" class="failure-alert-box">
    <i class="fa fa-times-circle times-check"></i>
    <br>
    <p>Opps Something went wrong. Please try again.</p>
  </div>
  <div class="text-center mT20">
  <button type="button"  routerLink = "/" class="btn btn-primary contact-submit"><i>Home</i></button>
</div>
</div>
</div>

<nav
  class="col-sm-12 black-bg"
  style="border-radius: unset;margin-bottom: 0px; border-top: 3px solid #337ab7;"
>
<div class="col-sm-4 mT20">
  <!--<a class="navbar-brand f-16" style="font-family: Arial, Helvetica, sans-serif;"  href="#"><b>OrdrAi</b></a> -->
  <p class=" f-16 white-c robo" style="color: white!important;" (click)="tryit()"><u>Text Ordering - Live</u></p>
  <p class=" f-16 robo" style="color: white!important;"  (click)="tryquick()" > <u>Quick Ordering - Live</u></p>
</div>
<div class="col-sm-4 mT20">
  <p class=" f-16 white-c robo" style="color:white">3917 Research Park Drive Ste B-7 Ann Arbor, MI 48108</p>
 <p class=" f-16 white-c robo text-center" style="color:white">info@ordrai.com   (734) 717-2769</p>
</div>

<div class="col-sm-4 fRight mT30" style="text-align: right;">
  <a class="f-16" href="">
    © 2020 OrdrAi</a>
</div>
<br>
<!--<div class="col-sm-12 text-center">
 <p style="color:white">3917 Research Park Drive Ste B-7 Ann Arbor, MI 48108</p>
 <p style="color:white">info@ordrai.com   (734) 717-2769</p>
</div> -->
 
  
</nav>