export class romeoStore {

    constructor() { }

    findStore(sid) {
        switch (sid) {
            case 'rm01':
                let sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 20% OFF ON TEXT ORDERS',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 20% OFF',
                    successPro: 'PRO ORDERS ARE 20% OFF',
                    isTxt: true,
                    rid: '+16147890014',
                    nickName: '1992 Hard Rd location in Columbus',
                    addy1: '1992 Hard Rd',
                    addy2: 'Columbus, OH'
                };
                return sidInfo;
            case 'rm02':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 20% OFF ON TEXT ORDERS',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 20% OFF',
                    successPro: 'PRO ORDERS ARE 20% OFF',
                    isTxt: true,
                    rid: '+16146024600',
                    nickName: '343 W Bridge St Location in Dublin',
                    addy1: '343 W Bridge St',
                    addy2: 'Dublin, OH'
                };
                return sidInfo;
            case 'rmXX':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 20% OFF ON TEXT ORDERS',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 20% OFF',
                    successPro: 'PRO ORDERS ARE 20% OFF',
                    isTxt: true,
                    rid: '+12164761111',
                    nickName: '3768 Rocky River Dr location in Cleveland',
                    addy1: '3768 Rocky River Dr',
                    addy2: 'Cleveland, OH'
                };
                return sidInfo;
            case 'rm06':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 20% OFF ON TEXT ORDERS',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 20% OFF',
                    successPro: 'PRO ORDERS ARE 20% OFF',
                    isTxt: true,
                    rid: '+16144706000',
                    nickName: '1119 N Hamilton Rd location in Gahanna',
                    addy1: '1119 N Hamilton Rd',
                    addy2: 'Gahanna, OH'
                };
                return sidInfo;
            case 'rm07':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 20% OFF ON TEXT ORDERS',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 20% OFF',
                    successPro: 'PRO ORDERS ARE 20% OFF',
                    isTxt: true,
                    rid: '+16142974000',
                    nickName: '955 W 5th Ave location in Grandview',
                    addy1: '955 W 5th Ave',
                    addy2: 'Grandview, OH'
                };
                return sidInfo;
            case 'rm04':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 20% OFF ON TEXT ORDERS',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 20% OFF',
                    successPro: 'PRO ORDERS ARE 20% OFF',
                    isTxt: true,
                    rid: '+16145270044',
                    nickName: '3189 Hilliard Rome Rd location in Hilliard',
                    addy1: '3189 Hilliard Rome Rd',
                    addy2: 'Hilliard, OH'
                };
                return sidInfo;
                case 'rm14':
                    sidInfo = {
                        discountTxt: 'FOR A LIMITED TIME GET 20% OFF ON ALL TEXT ORDERS',
                        isPro: false,
                        isServiceFee: false,
                        successTxt: 'TEXT ORDERS ARE 20% OFF ON ALL TEXT ORDERS',
                        successPro: 'PRO ORDERS ARE 20% OFF ON ALL TEXT ORDERS',
                        isTxt: true,
                        rid: '+13306237200',
                        nickName: '124 W Liberty St location in Hubbard',
                        addy1: '124 W Liberty St',
                        addy2: 'Hubbard, OH'
                    };
                    return sidInfo;
                case 'rm15':
                    sidInfo = {
                        discountTxt: 'FOR A LIMITED TIME GET 20% OFF ON ALL TEXT ORDERS',
                        isPro: false,
                        isServiceFee: false,
                        successTxt: 'TEXT ORDERS ARE 20% OFF ON ALL TEXT ORDERS',
                        successPro: 'PRO ORDERS ARE 20% OFF ON ALL TEXT ORDERS',
                        isTxt: true,
                        rid: '+13309324999',
                        nickName: '15549 State Route 170 location in New Liverpool',
                        addy1: '15549 State Route 170',
                        addy2: 'New Liverpool, MI'
                    };
                    return sidInfo;
                case 'rm16':
                    sidInfo = {
                        discountTxt: 'FOR A LIMITED TIME GET 20% OFF ON ALL TEXT ORDERS',
                        isPro: false,
                        isServiceFee: false,
                        successTxt: 'TEXT ORDERS ARE 20% OFF ON ALL TEXT ORDERS',
                        successPro: 'PRO ORDERS ARE 20% OFF ON ALL TEXT ORDERS',
                        isTxt: true,
                        rid: '+13309681700',
                        nickName: '9336 State Route 43 location in Streetsboro',
                        addy1: '9336 State Route 43',
                        addy2: 'Streetsboro, MI'
                    };
                    return sidInfo;
            case 'rm05':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 20% OFF ON TEXT ORDERS',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 20% OFF',
                    successPro: 'PRO ORDERS ARE 20% OFF',
                    isTxt: true,
                    rid: '+16148631000',
                    nickName: '499 Lancaster Ave location in Reynoldsburg',
                    addy1: '499 Lancaster Ave',
                    addy2: 'Reynoldsburg, OH'
                };
                return sidInfo;
            case 'rmxx':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 20% OFF ON TEXT ORDERS',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 20% OFF',
                    successPro: 'PRO ORDERS ARE 20% OFF',
                    isTxt: true,
                    rid: '+13305580058',
                    nickName: '3816 Center Rd location in Brunswick',
                    addy1: '3816 Center Rd',
                    addy2: 'Brunswick, OH'
                };
                return sidInfo;
            case 'xx':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 20% OFF ON TEXT ORDERS',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 20% OFF',
                    successPro: 'PRO ORDERS ARE 20% OFF',
                    isTxt: true,
                    rid: '+12164337373',
                    nickName: '13349 Snow Rd location in Brook Park',
                    addy1: '13349 Snow Rd',
                    addy2: 'Brook Park, OH'
                };
                return sidInfo;
            case 'xxxii':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 20% OFF ON TEXT ORDERS',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 20% OFF',
                    successPro: 'PRO ORDERS ARE 20% OFF',
                    isTxt: true,
                    rid: '+14406631000',
                    nickName: '7452 Ridge Rd location in Parma',
                    addy1: '7452 Ridge Rd',
                    addy2: 'Parma, OH'
                };
                return sidInfo;
            case 'rm09':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 20% OFF ON TEXT ORDERS',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 20% OFF',
                    successPro: 'PRO ORDERS ARE 20% OFF',
                    isTxt: true,
                    rid: '+16148348315',
                    nickName: '480 W Church St location in Pickerington',
                    addy1: '80 W Church St',
                    addy2: 'Pickerington, OH'
                };
                return sidInfo;
            case 'rm08':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 20% OFF ON TEXT ORDERS',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 20% OFF',
                    successPro: 'PRO ORDERS ARE 20% OFF',
                    isTxt: true,
                    rid: '+16145393800',
                    nickName: '2207 Stringtown Rd location in Grove City',
                    addy1: '2207 Stringtown Rd',
                    addy2: 'Grove City, OH'
                };
                return sidInfo;
            case 'rm03':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 20% OFF ON TEXT ORDERS',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 20% OFF',
                    successPro: 'PRO ORDERS ARE 20% OFF',
                    isTxt: true,
                    rid: '+16148693200',
                    nickName: '3530 N High St location in Columbus',
                    addy1: '3530 N High St',
                    addy2: 'Columbus, OH'
                };
                return sidInfo;
            case 'rm06':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 20% OFF ON TEXT ORDERS',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 20% OFF',
                    successPro: 'PRO ORDERS ARE 20% OFF',
                    isTxt: true,
                    rid: '+16144706000',
                    nickName: '1119 N Hamilton Rd',
                    addy1: '1119 N Hamilton Rd',
                    addy2: 'Gahanna, OH'
                };
                return sidInfo;
            case 'rm10':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 20% OFF ON TEXT ORDERS',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 20% OFF',
                    successPro: 'PRO ORDERS ARE 20% OFF',
                    isTxt: true,
                    rid: '+16146187700',
                    nickName: '2052 Crown Plaza Dr',
                    addy1: '2052 Crown Plaza Dr',
                    addy2: 'Columbus, OH'
                };
                return sidInfo;

                case 'rm11':
                    sidInfo = {
                        discountTxt: 'FOR A LIMITED TIME GET 20% OFF ON TEXT ORDERS',
                        isPro: false,
                        isServiceFee: false,
                        successTxt: 'TEXT ORDERS ARE 20% OFF',
                        successPro: 'PRO ORDERS ARE 20% OFF',
                        isTxt: true,
                        rid: '+16148955555',
                        nickName: '5733 Maxtown Rd location in Westerville',
                        addy1: '5733 Maxtown Rd',
                        addy2: 'Westerville, OH'
                    };
                    return sidInfo;
                case 'rm12':
                    sidInfo = {
                        discountTxt: 'FOR A LIMITED TIME GET 20% OFF ON TEXT ORDERS',
                        isPro: false,
                        isServiceFee: false,
                        successTxt: 'TEXT ORDERS ARE 20% OFF',
                        successPro: 'PRO ORDERS ARE 20% OFF',
                        isTxt: true,
                        rid: '+16149853355',
                        nickName: '8929 S Old State Rd location in Lewis Center',
                        addy1: '8929 S Old State Rd',
                        addy2: 'Lewis Center, OH'
                    };
                    return sidInfo;
                case 'rm13':
                    sidInfo = {
                        discountTxt: 'FOR A LIMITED TIME GET 20% OFF ON TEXT ORDERS',
                        isPro: false,
                        isServiceFee: false,
                        successTxt: 'TEXT ORDERS ARE 20% OFF',
                        successPro: 'PRO ORDERS ARE 20% OFF',
                        isTxt: true,
                        rid: '+17405133035',
                        nickName: '1832 Columbus Pike location in Delaware',
                        addy1: '1832 Columbus Pike',
                        addy2: 'Delaware, OH'
                    };
                    return sidInfo;




            case 'demo':
                sidInfo = {
                    discountTxt: '*',
                    isPro: true,
                    isServiceFee: false,
                    successTxt: 'Texting is the fastest way to order Jet’s!',
                    successPro: 'PRO ORDERS ARE 20% OFF',
                    isTxt: false,
                    rid: '+15862033838',
                    nickName: 'Research park dr ',
                    addy1: '3917 Research Park Dr',
                    addy2: 'Ann Arbor, MI'
                };
                return sidInfo;
            default:
                sidInfo = {
                    discountTxt: '*',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'Texting is the fastest way to order Jet’s!',
                    successPro: 'PRO ORDERS ARE 20% OFF',
                    isTxt: true,
                    rid: 'empty',
                    nickName: 'empty',
                    addy1: 'empty',
                    addy2: 'empty'
                };
                return sidInfo;
        }
        // console.log(cust)
    }
}