export class waybackStore {

    constructor() { }

    findStore(sid) {
        switch (sid) {
            case 'wb00':
                    let sidInfo = {
                        discountTxt: 'GET 15% OFF YOUR FIRST ORDER OF $20 OR MORE',
                        isPro: false,
                        isServiceFee: false,
                        successTxt: 'FOR TEXT ORDERS GET 15% OFF YOUR FIRST ORDER OF $20 OR MORE',
                        successPro: 'FOR TEXT ORDERS GET 15% OFF YOUR FIRST ORDER OF $20 OR MORE',
                        isTxt: true,
                        rid: '+13159292225',
                        nickName: '1427 Airport Way location in Kodiak',
                        addy1: '1427 Airport Way',
                        addy2: 'Kodiak, AK'
                    };
                return sidInfo;

            default:
                sidInfo = {
                    discountTxt: '*',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'Texting is the fastest way to order at Wayback Burger!',
                    successPro: 'Texting is the fastest way to order at Wayback Burger!',
                    isTxt: true,
                    rid: 'empty',
                    nickName: 'empty',
                    addy1: 'empty',
                    addy2: 'empty'
                };
                return sidInfo;
        }
        // console.log(cust)
    }
}
