import { ProServiceService } from './../pro-service.service';
import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from "../../../../node_modules/@angular/router";
@Component({
  selector: 'app-menu-box',
  templateUrl: './menu-box.component.html',
  styleUrls: ['./menu-box.component.css']
})
export class MenuBoxComponent implements OnInit {
  @ViewChild('navspecialtypizzatab') myDiv: ElementRef<HTMLElement>;

  constructor(public pro : ProServiceService, private route: ActivatedRoute) { 
    this.buildPizza = true;
  }
  buildPizza: boolean = false;
  specialityPizza: boolean = false;
  salad: boolean = false;
  sidekick: boolean = false;
  other: boolean = false;
  restName:string=''
  ngOnInit() {
    this.route.url.subscribe((val) => {
      console.log("URL Cutout");
      console.log(val);
      this.restName = val[1].path;
      })
  }

  swipe(type, event) {
    if (type == 'buildpizza') {
      if (event == 'swipeleft') {
        this.menuclick('specialityPizza');
        this.myDiv.nativeElement.click();

      }
      if (event == 'swiperight') {
        //do nothing
      }
    }
    if (type == 'specialityPizza') {
      if (event == 'swipeleft') {
        this.menuclick('salad');
      }
      if (event == 'swiperight') {
        this.menuclick('buildPizza')
      }
    }

    if (type == 'salad') {
      if (event == 'swipeleft') {
        this.menuclick('sidekick');
      }
      if (event == 'swiperight') {
        this.menuclick('specialityPizza');
      }
    }

    if (type == 'sidekick') {
      if (event == 'swipeleft') {
        this.menuclick('other');
      }
      if (event == 'swiperight') {
        this.menuclick('salad');
      }
    }
    if (type == 'other') {
      if (event == 'swipeleft') {
        //this.menuclick('sidekick');
      }
      if (event == 'swiperight') {
        this.menuclick('sidekick');
      }
    }





    console.log(event);
  }
  menuclick(option) {
    this.buildPizza = false;
    this.specialityPizza = false;
    this.salad = false;
    this.sidekick = false;
    this.other = false;
    if (option == 'buildPizza') {
      this.buildPizza = true;
    }
    if (option == 'specialityPizza') {
      this.specialityPizza = true;
    }
    if (option == 'salad') {
      this.salad = true;
    }
    if (option == 'sidekick') {
      this.sidekick = true;
    }
    if (option == 'other') {
      this.other = true;
    }
  }



}
