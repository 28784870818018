<app-blaze-share    *ngIf="restName=='blaze'"></app-blaze-share>
<app-hungryhowies-share   *ngIf="restName=='hungryhowies'"></app-hungryhowies-share>
<app-jets-share  *ngIf="restName=='jets'"></app-jets-share>
<app-romeo-share  *ngIf="restName=='romeos'"></app-romeo-share>
<app-marcos-share *ngIf="restName=='marcos'"></app-marcos-share>
<app-master-share *ngIf="restName=='master'"></app-master-share>
<app-centercourt-share *ngIf="restName=='centercourt'"></app-centercourt-share>
<app-coolrunnings-share *ngIf="restName=='coolrunnings'"></app-coolrunnings-share>
<app-eastofchicago-share *ngIf="restName=='eastofchicago'"></app-eastofchicago-share>
<app-gattis-share *ngIf="restName=='gattis'"></app-gattis-share>
<app-pizzaworldusa-share *ngIf="restName=='pizzaworld'"></app-pizzaworldusa-share>
<app-smoothie-king-share  *ngIf="restName=='smoothie king'"></app-smoothie-king-share>
<app-wayback-share *ngIf="restName=='wayback'"></app-wayback-share>
<app-sammys-pizza-share *ngIf="restName=='sammys'"></app-sammys-pizza-share>
<app-gioninos-share *ngIf="restName=='gioninos'"></app-gioninos-share>
<app-bcpizza-share *ngIf="restName=='bcpizza'"></app-bcpizza-share>
<app-freebirds-share *ngIf="restName=='freebirds'"></app-freebirds-share>
<app-giovannis-share *ngIf="restName=='giovannis'"></app-giovannis-share>
<app-mannyandolgas-share *ngIf="restName=='MannyOlgas'"></app-mannyandolgas-share>
<app-blackjack *ngIf="restName=='blackjack'"></app-blackjack>
<app-extreme *ngIf="restName=='extreme'"></app-extreme>
<app-wingstogo *ngIf="restName=='wingstogo'"></app-wingstogo>
<app-simple-simons-share *ngIf="restName=='simplesimon'"></app-simple-simons-share>
<app-littlecaesars-share *ngIf="restName=='littlecaesars'"></app-littlecaesars-share>
<app-fajita-share *ngIf="restName=='fajitapetes'"></app-fajita-share>