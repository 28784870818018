<!-- <div class = "check-box">
  <mat-checkbox  [(ngModel)]="checked"></mat-checkbox>
</div>
-->
<div *ngIf="api.showMktOptIn">
  <div class="check-box">
    <mat-checkbox color="primary" [(ngModel)]="api.isMktOptIn"></mat-checkbox>
    <!-- your content goes right below -->
    <!--  <span class = "check-txt"> Yes, I would like to receive special offers via SMS.</span> -->
    <span class="check-txt">
      Sign me up to receive exclusive text deals and promotions. Receive 2-4
      text msgs/month. Standard msg and data rates apply.
    </span>
  </div>
  <!--
<div class = "check-box"  *ngIf = "!api.isMktOptIn">
  <span class = "check-note-txt"> You will no longer be eligible for exclusive TEXT order discounts. </span>
</div> -->
</div>
