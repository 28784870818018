
import { APICenterService } from './../apicenter.service';
import { Component, OnInit } from '@angular/core';
import { trigger, transition, animate, style } from '../../../node_modules/@angular/animations';
import { MatDialog } from '../../../node_modules/@angular/material/dialog';
import { MenuDialogComponent } from '../menu-dialog/menu-dialog.component';


@Component({
  selector: 'app-user-msg',
  templateUrl: './user-msg.component.html',
  styleUrls: ['./user-msg.component.css'],
  animations: [
    trigger('fade-out', [
      transition ('* => void', [
        animate(1500, style({ opacity: 0}))
      ] )
    ])
  ]
})
export class UserMsgComponent implements OnInit {


  isDelivery;
  isMicActive = false;
  msgWithAddress: string;
  isPickup = true;
  msgList = [];
  isWing = false;
  isBread = false;
  isSoda = false;
  hidePickupDel = false;

  constructor(public api: APICenterService, public dialog: MatDialog) { }

  ngOnInit() {
    this.api.ispickupValidator();
    // console.log('PICKUP API ')
    // console.log('PickupVal' + this.api.isPickupVal)
    // console.log('IsDel' + this.api.isDelivery)

  if ((this.api.isPayOnline) && (this.api.isPayAtDoor) && (this.api.isPayAtStore)) {
      console.log('all Are true');
      this.hidePickupDel = false;

  } else if ((this.api.isPayAtDoor) && (this.api.isPayAtStore)) {
          console.log ('pay at door and pay at store is true');
          this.hidePickupDel = false;
       } else if ((this.api.isPayOnline) && (this.api.isPayAtDoor) ) {
             console.log ('pay online  and pay at door ');
             this.hidePickupDel = false;
           } else if ((this.api.isPayOnline) && (this.api.isPayAtStore) ) {
                  console.log('pay online and pay at store');
                  this.hidePickupDel = false;
               } else {
                    if (!(this.api.isPayOnline)) {

                      // PayOnline is False
                      if (!(this.api.isPayAtDoor)) {

                        // Pay at door is False
                        if (this.api.isPayAtStore) {
                          // Pay at store is TRUE
                          this.hidePickupDel = true;
                        }
                      }  else {
                        // Pay at door is true
                            this.hidePickupDel = false;

                       }
                    } else {
                              console.log('online is true');
                              // online is true
                              this.hidePickupDel = false;

                   }
                  }



    this.api.resetItems();
    this.api.isAddItemBox = false;

    this.api.showTotal = true;

   if (this.api.method.mtype.toLowerCase() === 'pickup') {
    this.isDelivery = false;
    console.log('this s not a  delivery');
   }

   /* if(this.api.customer.msg_text !== null) {
        if(this.api.customer.msg_type === 'order') {
          this.msgList = this.api.customer.msg_text.split(' ');
          // console.log(this.msgList);
          this.msgList.map((val) => {
            if((val.toLowerCase()) === "wings" ||
                (val.toLowerCase()) === "wing" ||
                (val.toLowerCase()) === "wings," || (val.toLowerCase()) === "wing," || (val.toLowerCase()) === "jet's wing," ){
             //  console.log("chicken wings there");
              this.isWing = true;
            } else if ((val.toLowerCase())==="bread" ||
                        (val.toLowerCase())==="breads" || (val.toLowerCase())==="bread," || (val.toLowerCase())==="breads,") {
              this.isBread = true;
              // console.log("cheese bread is there")
            } else if (
                (val.toLowerCase()) === "pepsi" ||
                (val.toLowerCase()) === "pepsi," ||
                (val.toLowerCase()) === "coke" ||
                (val.toLowerCase()) === "coke," ||
                (val.toLowerCase()) === "dew" ||
                (val.toLowerCase()) === "dew," ||
                (val.toLowerCase()) === "soda" ||
                (val.toLowerCase()) === "soda," ||
                (val.toLowerCase()) === "pop"||
                (val.toLowerCase()) === "pop,"||
                (val.toLowerCase()) ==="drpepper") {
              console.log("there is Sodsa");
              this.isSoda = true;
            } else {
              //console.log(val)
            }
          })
        }
     } */
    // console.log('last')
    // console.log('IsDel' + this.api.isDelivery)
    // console.log('PickupVal'+ this.api.isPickupVal)

  }


  getAddress(event) {
    console.log(event);
    this.api.editStateAddress = event.formatted_address;
  }

  delivery() {
    this.api.clickValue = 'EditState - Delivery';
    this.api.clickAnalytics();
    // console.log("delivery is clicked");
    document.getElementById('del-btn').style.fontSize = 'x-large';
    document.getElementById('pickup-btn').style.fontSize = 'large';
    this.api.isDelivery = true;
  }

  pickup() {
    this.api.clickValue = 'EditState - Pickup';
    this.api.clickAnalytics();
    // console.log("pickup is clicked");
    document.getElementById('del-btn').style.fontSize = 'large';
    document.getElementById('pickup-btn').style.fontSize = 'x-large';
    this.api.isDelivery = false;
    // console.log(this.api.pickupText)

  }

  onMic() {
    console.log('mic is clicked');
    this.isMicActive = true;
  }

  onBack() {
    this.isMicActive = false;
  }

  onUpdate() {
    this.api.currentState = {
      viewState: true,
      isContact: false,
      isTip: false,
      payatStore: false,
      payOption: false,
      payOnline: false,
      isPinStored: false,
      isBt: false,
      isBraintree: false
    };
    this.api.clickValue = 'EditState - Send';
    this.api.clickAnalytics();
    console.log('isDelivery @ update route');
    console.log(this.api.isDelivery);
    this.api.onSend = true;
    this.api.isAddItemBox = false;
    this.api.isSummaryTitle = false;
    if (this.api.isDelivery) {
    this.msgWithAddress = `${this.api.customer.msg_text} delivery to ${this.api.editStateAddress} `;
    console.log('msg with address');
    console.log(this.msgWithAddress);
    this.api.customer.msg_text =  this.msgWithAddress;
    this.api.updateUserMsg();
    this.api.isEdit = false;
    } else {
     // console.log("inside else")
      // this.api.customer.msg_text;
      console.log('msg without address');
      console.log(this.api.customer.msg_text);
      this.api.updateUserMsg();
      this.api.isEdit = false;
    }

  }

  openDialog(): void {
    this.api.clickValue = 'Menu';
    this.api.clickAnalytics();
    const dialogRef = this.dialog.open(MenuDialogComponent, {
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

}
