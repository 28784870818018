export class bcPizzaStore {
  constructor() {}
  findStore(sid) {
    switch (sid) {
      case "stores":
        let sidInfo = {
          discountTxt: "FOR A LIMITED TIME GET 20% OFF ON YOUR FIRST ORDER",
          isPro: false,
          isServiceFee: false,
          successTxt: "TEXT ORDERS ARE 20% OFF ON YOUR FIRST ORDER",
          successPro: "PRO ORDERS ARE 20% OFF ON YOUR FIRST ORDER",
          isTxt: true,
          rid: "+1941729538799",
          nickName: "",
          addy1: "",
          addy2: "",
        };
        return sidInfo;

        case 'bc01':
                sidInfo = {
                    discountTxt: 'GET 20% OFF ON FIRST ORDER, 10% OFF ON SECOND ORDER',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 20% OFF ON FIRST ORDER, 10% OFF ON SECOND ORDER',
                    successPro: 'PRO ORDERS ARE 20% OFF ON FIRST ORDER, 10% OFF ON SECOND ORDER',
                    isTxt: true,
                    rid: '+12315365555',
                    nickName: '101 N Lake St location in East Jordan',
                    addy1: '101 N Lake St',
                    addy2: 'East Jordan, MI'
                };
                return sidInfo;
            case 'bc02':
                sidInfo = {
                    discountTxt: 'GET 20% OFF ON FIRST ORDER, 10% OFF ON SECOND ORDER',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 20% OFF ON FIRST ORDER, 10% OFF ON SECOND ORDER',
                    successPro: 'PRO ORDERS ARE 20% OFF ON FIRST ORDER, 10% OFF ON SECOND ORDER',
                    isTxt: true,
                    rid: '+12313471212',
                    nickName: '910 Spring St location in Petoskey',
                    addy1: '910 Spring St',
                    addy2: 'Petoskey, MI'
                };
                return sidInfo;
             case 'bc03':
                sidInfo = {
                    discountTxt: 'GET 20% OFF ON FIRST ORDER, 10% OFF ON SECOND ORDER',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 20% OFF ON FIRST ORDER, 10% OFF ON SECOND ORDER',
                    successPro: 'PRO ORDERS ARE 20% OFF ON FIRST ORDER, 10% OFF ON SECOND ORDER',
                    isTxt: true,
                    rid: '+12315483636',
                    nickName: '7660 US-31 location in Alanson',
                    addy1: '7660 US-31',
                    addy2: 'Alanson, MI'
                };
                return sidInfo;
             case 'bc04':
                sidInfo = {
                    discountTxt: 'GET 20% OFF ON FIRST ORDER, 10% OFF ON SECOND ORDER',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 20% OFF ON FIRST ORDER, 10% OFF ON SECOND ORDER',
                    successPro: 'PRO ORDERS ARE 20% OFF ON FIRST ORDER, 10% OFF ON SECOND ORDER',
                    isTxt: true,
                    rid: '+12312380055',
                    nickName: '3695 S Straits Hwy location in Indian River, MI',
                    addy1: '3695 S Straits Hwy',
                    addy2: 'Indian River, MI'
                };
                return sidInfo;
            case 'bc05':
                sidInfo = {
                    discountTxt: 'GET 20% OFF ON FIRST ORDER, 10% OFF ON SECOND ORDER',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 20% OFF ON FIRST ORDER, 10% OFF ON SECOND ORDER',
                    successPro: 'PRO ORDERS ARE 20% OFF ON FIRST ORDER, 10% OFF ON SECOND ORDER',
                    isTxt: true,
                    rid: '+12315970055',
                    nickName: '523 Mackinaw Ave location in Cheboygan',
                    addy1: '523 Mackinaw Ave',
                    addy2: 'Cheboygan, MI'
                };
                return sidInfo;
            case 'bc06':
                sidInfo = {
                    discountTxt: 'GET 20% OFF ON FIRST ORDER, 10% OFF ON SECOND ORDER',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 20% OFF ON FIRST ORDER, 10% OFF ON SECOND ORDER',
                    successPro: 'PRO ORDERS ARE 20% OFF ON FIRST ORDER, 10% OFF ON SECOND ORDER',
                    isTxt: true,
                    rid: '+12317791337',
                    nickName: '6184 E M-55 location in Cadillac',
                    addy1: '6184 E M-55',
                    addy2: 'Cadillac, MI'
                };
                return sidInfo;  
              
                case 'bc07':
                sidInfo = {
                    discountTxt: 'GET 20% OFF ON FIRST ORDER, 10% OFF SECOND ORDER',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 20% OFF ON FIRST ORDER, 10% OFF SECOND ORDER',
                    successPro: 'PRO ORDERS ARE 20% OFF ON FIRST ORDER, 10% OFF SECOND ORDER',
                    isTxt: true,
                    rid: '+12315822288',
                    nickName: '1191 South M-75 location in Boyne City',
                    addy1: '1191 South M-75',
                    addy2: 'Boyne City, MI'
                };
                return sidInfo;
            case 'bc08':
                sidInfo = {
                    discountTxt: 'GET 20% OFF ON FIRST ORDER, 10% OFF SECOND ORDER',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 20% OFF ON FIRST ORDER, 10% OFF SECOND ORDER',
                    successPro: 'PRO ORDERS ARE 20% OFF ON FIRST ORDER, 10% OFF SECOND ORDER',
                    isTxt: true,
                    rid: '+19892751500',
                    nickName: '800 Lake St location in Roscommon',
                    addy1: '800 Lake St',
                    addy2: 'Roscommon, MI'
                };
                return sidInfo;

              case 'bc10':
                  sidInfo = {
                      discountTxt: 'GET 20% OFF ON FIRST ORDER, 10% OFF SECOND ORDER',
                      isPro: false,
                      isServiceFee: false,
                      successTxt: 'TEXT ORDERS ARE 20% OFF ON FIRST ORDER, 10% OFF SECOND ORDER',
                      successPro: 'PRO ORDERS ARE 20% OFF ON FIRST ORDER, 10% OFF SECOND ORDER',
                      isTxt: true,
                      rid: '+12315826050',
                      nickName: '472 N Lake St location in Boyne City',
                      addy1: '472 N Lake St',
                      addy2: 'Boyne City, MI'
                  };
                  return sidInfo;
                case 'bc12':
                  sidInfo = {
                      discountTxt: 'GET 20% OFF ON FIRST ORDER, 10% OFF SECOND ORDER',
                      isPro: false,
                      isServiceFee: false,
                      successTxt: 'TEXT ORDERS ARE 20% OFF ON FIRST ORDER, 10% OFF SECOND ORDER',
                      successPro: 'PRO ORDERS ARE 20% OFF ON FIRST ORDER, 10% OFF SECOND ORDER',
                      isTxt: true,
                      rid: '+12315479999',
                      nickName: '149 M-66 location in Charlevoix',
                      addy1: '149 M-66',
                      addy2: 'Charlevoix, MI'
                  };
                  return sidInfo;
               case 'bc13':
                  sidInfo = {
                      discountTxt: 'GET 20% OFF ON FIRST ORDER, 10% OFF SECOND ORDER',
                      isPro: false,
                      isServiceFee: false,
                      successTxt: 'TEXT ORDERS ARE 20% OFF ON FIRST ORDER, 10% OFF SECOND ORDER',
                      successPro: 'PRO ORDERS ARE 20% OFF ON FIRST ORDER, 10% OFF SECOND ORDER',
                      isTxt: true,
                      rid: '+12693505774',
                      nickName: '1710 W Main St location in Kalamazoo',
                      addy1: '1710 W Main St',
                      addy2: 'Kalamazoo, MI'
                  };
                  return sidInfo;
                
                case 'bc14':
                    sidInfo = {
                        discountTxt: 'GET 20% OFF ON FIRST ORDER, 10% OFF SECOND ORDER',
                        isPro: false,
                        isServiceFee: false,
                        successTxt: 'TEXT ORDERS ARE 20% OFF ON FIRST ORDER, 10% OFF SECOND ORDER',
                        successPro: 'PRO ORDERS ARE 20% OFF ON FIRST ORDER, 10% OFF SECOND ORDER',
                        isTxt: true,
                        rid: '+12319959900',
                        nickName: '3186 LaFranier Rd location in Traverse City',
                        addy1: '3186 LaFranier Rd, Traverse City',
                        addy2: 'Traverse City, MI'
                    };
                    return sidInfo;

              case 'bc11':
                    sidInfo = {
                        discountTxt: 'GET 20% OFF ON FIRST ORDER, 10% OFF SECOND ORDER',
                        isPro: false,
                        isServiceFee: false,
                        successTxt: 'TEXT ORDERS ARE 20% OFF ON FIRST ORDER, 10% OFF SECOND ORDER',
                        successPro: 'PRO ORDERS ARE 20% OFF ON FIRST ORDER, 10% OFF SECOND ORDER',
                        isTxt: true,
                        rid: '+19893442222',
                        nickName: '307 S James St location in Grayling',
                        addy1: '307 S James St',
                        addy2: 'Grayling, MI'
                    };
                    return sidInfo;

             case 'bc09':
                sidInfo = {
                    discountTxt: 'GET 20% OFF ON FIRST ORDER, 10% OFF SECOND ORDER',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 20% OFF ON FIRST ORDER, 10% OFF SECOND ORDER',
                    successPro: 'PRO ORDERS ARE 20% OFF ON FIRST ORDER, 10% OFF SECOND ORDER',
                    isTxt: true,
                    rid: '+12318391177',
                    nickName: '45 Morey Rd location in Lake City',
                    addy1: '45 Morey Rd',
                    addy2: 'Lake City, MI'
                };
                return sidInfo;

      case "demo":
        sidInfo = {
          discountTxt: "*",
          isPro: false,

          isServiceFee: false,
          successTxt: "Texting is the fastest way to order B.C.  PIZZA!",
          successPro: "PRO ORDERS ARE 20% OFF ON ALL PIZZAS",
          isTxt: true,
          rid: "+15862033838",
          nickName: "Research park dr ",
          addy1: "3917 Research Park Dr",
          addy2: "Ann Arbor, MI",
        };
        return sidInfo;
      case "xxx":
        return "+14194661111";
      case "xxx":
        return "+12159608888";
      default:
        sidInfo = {
          discountTxt: "*",
          isPro: false,
          isServiceFee: false,
          successTxt: "Texting is the fastest way to order B.C.  PIZZA!",
          successPro: "PRO ORDERS ARE 20% OFF ON ALL PIZZAS",
          isTxt: true,
          rid: "empty",
          nickName: "empty",
          addy1: "empty",
          addy2: "empty",
        };
        return sidInfo;
    }
    // console.log(cust)
  }
}
