export class blackJackStore {
  constructor() {}
  findStore(sid) {
    switch (sid) {
      case "stores":
        let sidInfo = {
          discountTxt: "FOR A LIMITED TIME GET 20% OFF ON YOUR FIRST ORDER",
          isPro: false,
          isServiceFee: false,
          successTxt: "TEXT ORDERS ARE 20% OFF ON YOUR FIRST ORDER",
          successPro: "PRO ORDERS ARE 20% OFF ON YOUR FIRST ORDER",
          isTxt: true,
          rid: "+1941729538799",
          nickName: "",
          addy1: "",
          addy2: "",
        };
        return sidInfo;

      case "demo":
        sidInfo = {
          discountTxt: "*",
          isPro: false,

          isServiceFee: false,
          successTxt: "Texting is the fastest way to order B.C.  PIZZA!",
          successPro: "PRO ORDERS ARE 20% OFF ON ALL PIZZAS",
          isTxt: true,
          rid: "+15862033838",
          nickName: "Research park dr ",
          addy1: "3917 Research Park Dr",
          addy2: "Ann Arbor, MI",
        };
        return sidInfo;
      case "xxx":
        return "+14194661111";
      case "xxx":
        return "+12159608888";
      default:
        sidInfo = {
          discountTxt: "*",
          isPro: false,
          isServiceFee: false,
          successTxt: "Texting is the fastest way to order B.C.  PIZZA!",
          successPro: "PRO ORDERS ARE 20% OFF ON ALL PIZZAS",
          isTxt: true,
          rid: "empty",
          nickName: "empty",
          addy1: "empty",
          addy2: "empty",
        };
        return sidInfo;
    }
    // console.log(cust)
  }
}
