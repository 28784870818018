<!--!api.quickOrder.isQuickName -->
<div *ngIf="!api.quickOrder.isQuickName">
  <form #f="ngForm" (submit)="submit(f)" *ngIf="!isPinSaved">
    <!--  <div class = "wrapper-center">
          <p class= "text-2" style = "font-weight: bold; font-size: 19px; color: green; padding-top: 15px; padding-right: 10px;"> Your order has been received!</p>
        <i class="material-icons m-icon" style = " color: green; font-size: 50px;">
            check_circle
            </i>
        </div> -->
    <div class="wrapper-center">
      <label
        *ngIf="!api.isOnlyPayment"
        class="label-txt"
        for="pinA"
        style="
          color: black;
          font-weight: bold;
          font-size: 30px;
          text-align: center;
        "
      >
        Set up a TEXT-PIN
      </label>
      <label
        *ngIf="api.isOnlyPayment"
        class="label-txt"
        for="pinA"
        style="
          color: black;
          font-weight: bold;
          font-size: 30px;
          text-align: center;
        "
        >Set up a PIN <br />
        
        </label
      >
    </div>
    <div class="wrapper-center">
      <p
        *ngIf="!api.isOnlyPayment"
        for="pinA"
        style="color: black; font-size: 18px; padding: 0px; text-align: left"
      >
        Create an easy to remember 4 digit PIN to save your card on file for
        faster checkout.
      </p>
      <p
        *ngIf="api.isOnlyPayment"
        for="pinA"
        style="color: black; font-size: 18px; padding: 0px; text-align: left"
      >
        Create an easy-to-remember 4-digit PIN to save your card on file.
      </p>
    </div>
    <!--
        <div style = "padding-top: 10px" >
            <p class = " alert alert-info note">Your 4-Digit PIN will allow you to pay for future orders directly in SMS. </p>
          </div> -->
    <!--  <label class = "label-txt"for="pinA"> Enter your Text PIN</label>
       <div class="input-group input-group-lg input-txt">
       -->
    <!-- <div class="input-group-addon" style = "background: white; ">
               PIN
            </div> -->
    <!--
                  <input
                    required
                    matInput
                    autofocus
                    autocomplete="off"
                    minlength="4"
                    maxlength="4"
                    pattern="^[0-9]+$"
                    id = "pin-text"
                    type = "tel"
                    class = "form-control"
                    aria-label="Default"
                    aria-describedby="inputGroup-sizing-default"
                    ngModel
                    name = "pinText-1"
                    #name = "ngModel"
                    >
                  </div> 
                  <div class = "alert alert-danger" *ngIf = "name.touched && !name.valid">
                      <div *ngIf = "name?.errors.required"> Please enter your PIN</div>
                      <div *ngIf = "name?.errors.minlength"> 4 digit is required </div>
                      <div *ngIf = "name?.errors.pattern"> Number is expected  </div>
                  </div> -->
    <!-- <label for="pinA" class = "label-txt"> Confirm PIN </label>-->
    <div class="input-group input-group-lg input-txt">
      <!-- <div class="input-group-addon" style = "background: white; ">
                         PIN
                      </div> -->
      <input
        required
        matInput
        autocomplete="off"
        minlength="4"
        maxlength="4"
        style="text-align: center; letter-spacing: 25px; font-size: 28px"
        pattern="^[0-9]+$"
        id="pin-text"
        type="tel"
        class="form-control"
        aria-label="Default"
        aria-describedby="inputGroup-sizing-default"
        ngModel
        name="pinText-2"
        #nameB="ngModel"
      />
    </div>
    <div class="alert alert-danger" *ngIf="nameB.touched && !nameB.valid">
      <div *ngIf="nameB?.errors.required">Please enter your PIN</div>
      <div *ngIf="nameB?.errors.minlength">4 digit is required</div>
      <div *ngIf="nameB?.errors.pattern">Number is expected</div>
    </div>

    <div id="alert-number" class="alert alert-danger" *ngIf="!wrongPin">
      <strong>PIN does not match</strong>
    </div>
    <div id="alert-number" class="alert alert-success" *ngIf="!pinSet">
      <strong
        >Congratulations! Text ORDR-PIN to get one topping large pizza @ $3.99
      </strong>
    </div>

    <div class="container wrapper">
      <button
        mat-raised-button
        type="submit"
        class="send-btn animated fadeInDown"
        [ngStyle]="{ background: api.primaryColor }"
        style="font-size: x-large; color: white"
        [disabled]="!f.valid"
      >
        Save PIN
        <i class="material-icons md-light md-36 m-icon"> fast_forward </i>
      </button>
    </div>
  </form>

  <div *ngIf="isPinSaved">
    <p
      *ngIf="api.isOnlyPayment"
      class="label-txt"
      style="
        color: black;
        font-weight: bold;
        font-size: 30px;
        text-align: center;
      "
    >
      <span style="font-size: 17px"
        ><i>
          Thank you! For easier checkout, use your Jet's Bot Pin for all future
          orders!
        </i>
      </span>
    </p>
  </div>
</div>

<!-- <div *ngIf = "isQuickName">
    <form #f = "ngForm" (submit) = "submitPassword(f)">
          <div class = "wrapper-center">
              <label class = "label-txt"for="pinA" style = "color: black; font-weight: bold; font-size: 30px;
              text-align: center;"> Set up a TEXT-PASSWORD </label>
             
          </div>
          <div class = "wrapper-center">
                  <p for="pinA" style = "color: black; font-size: 18px; padding: 0px;
                  text-align: left; "> Use 8 or more characters with a mix of letters, numbers &amp; symbols for future text orders. </p>
          </div>  
          
                    <div class="input-group input-group-lg input-txt">
                              <input
                                required
                                matInput
                                autocomplete="off"
                                minlength="8"
                                maxlength="16"
                                id = "pin-text"
                                type = "password"
                                class = "form-control"
                                aria-label="Default"
                                aria-describedby="inputGroup-sizing-default"
                                ngModel
                                name = "pinText-2"
                                #nameB = "ngModel"
                                >
                              </div>
                              <div class = "alert alert-danger" *ngIf = "nameB.touched && !nameB.valid">
                                  <div *ngIf = "nameB?.errors.required"> Please enter your Password</div>
                                  <div *ngIf = "nameB?.errors.minlength"> Atleast 8 characters is required </div>
                              </div>
  
                            <div *ngIf = "isPasswordWrong">
                                <div style = "margin-bottom: 0px" id = "alert-number" class="alert alert-danger" *ngIf = "isCheckList.isCaps">
                                    <strong>{{validateCheckList.caps}}</strong>
                                  </div>
                                  <div style = "margin-bottom: 0px" id = "alert-number" class="alert alert-danger" *ngIf = "isCheckList.isSmall">
                                    <strong>{{validateCheckList.small}}</strong>
                                  </div>
                                  <div style = "margin-bottom: 0px" id = "alert-number" class="alert alert-danger" *ngIf = "isCheckList.isNumber">
                                    <strong>{{validateCheckList.number}}</strong>
                                  </div>
                                  <div style = "margin-bottom: 0px" id = "alert-number" class="alert alert-danger" *ngIf = "isCheckList.isSymbols">
                                    <strong>{{validateCheckList.symbols}}</strong>
                                  </div>
                            </div>
                                 

                                
                                <div id = "alert-number" class="alert alert-success" *ngIf = "!pinSet" >
                                  <strong>Congratulations! Text ORDR-PIN to get one topping large pizza @ $3.99 </strong>
                                </div>
  
  
  
        <div class="container wrapper" >
            <button
              mat-raised-button
              type = "submit"
              class="send-btn animated fadeInDown "
              style = "font-size: x-large"
              [disabled]="!f.valid"
               >  Save <i class="material-icons md-light md-36  m-icon"  >
                  fast_forward
                  </i> </button>
          </div>
  
  
              </form>
</div> -->

<div *ngIf="api.quickOrder.isQuickName">
  <app-method></app-method>
  <form #f="ngForm" (submit)="submitPassword(f)">
    <div class="input-group input-group-lg input-txt">
      <div
        class="input-group-addon"
        style="padding-bottom: 4px; background: white"
      >
        <i class="material-icons"> local_phone</i>
      </div>
      <input
        readonly
        [(ngModel)]="displayNum"
        name="phoneNumber"
        style="font-size: 24px"
        id="pinVal_2"
        type="text"
        class="form-control phone-number"
      />
    </div>

    <label class="name-txt">Create a new TEXT-PASSWORD:</label>
    <div class="input-group input-group-lg input-txt">
      <div class="input-group-addon" style="background: white">
        <i class="material-icons"> lock</i>
      </div>
      <div>
        <input
          required
          matInput
          style="margin-top: 0px; font-size: 24px"
          autocomplete="off"
          minlength="8"
          maxlength="16"
          id="pin-text"
          type="password"
          class="form-control"
          aria-label="Default"
          aria-describedby="inputGroup-sizing-default"
          ngModel
          name="pinText-2"
          #nameB="ngModel"
        />
      </div>
    </div>
    <div class="wrapper-center">
      <p
        for="pinA"
        style="
          color: gray;
          padding-left: 8px;
          font-size: 18px;
          padding: 0px;
          text-align: left;
        "
      >
        Use 8 or more characters with a mix of letters, numbers &amp; symbols
        for future text orders.
      </p>
    </div>
    <!-- <div class="input-group input-group-lg input-txt">
                            
                            </div> -->
    <div class="alert alert-danger" *ngIf="nameB.touched && !nameB.valid">
      <div *ngIf="nameB?.errors.required">Please enter your Password</div>
      <div *ngIf="nameB?.errors.minlength">
        Atleast 8 characters is required
      </div>
    </div>

    <div *ngIf="isPasswordWrong">
      <div
        style="margin-bottom: 0px"
        id="alert-number"
        class="alert alert-danger"
      >
        <ul style="padding-left: 12px">
          <li *ngIf="isCheckList.isCaps">
            <strong>{{ validateCheckList.caps }}</strong>
          </li>
          <li *ngIf="isCheckList.isSmall">
            <strong>{{ validateCheckList.small }}</strong>
          </li>
          <li *ngIf="isCheckList.isNumber">
            <strong>{{ validateCheckList.number }}</strong>
          </li>
          <li *ngIf="isCheckList.isSymbols">
            <strong>{{ validateCheckList.symbols }}</strong>
          </li>
        </ul>
      </div>
    </div>

    <div class="container wrapper">
      <button
        mat-raised-button
        type="submit"
        class="send-btn animated fadeInDown"
        style="color: white; font-size: x-large"
        [ngStyle]="{ background: api.primaryColor }"
        [disabled]="!f.valid"
      >
        Save <i class="material-icons md-light md-36 m-icon"> fast_forward </i>
      </button>
    </div>
  </form>
</div>
