<app-pro-navbar id="#header"> </app-pro-navbar>
<div *ngIf="loading" class="text-center">
  <img
    src={{api.loadingGif}}
    class="rounded img-fluid"
    alt="..."
  />
</div>
<div *ngIf="!loading" class="">
  <div *ngIf="!feedbackSuccess">
    <div class="text-center">
      <div class="form-group mL10 mR10 mT20">
        <span class="fLeft label-box">Name<span class="red">*</span></span>
        <input
          type="text"
          [(ngModel)]="name"
          style="margin: auto"
          placeholder="Enter your name"
          class="form-control form-control-lg w90 text-box h40"
        />
      </div>

      <div class="form-group mL10 mR10 mT20">
        <span class="fLeft label-box">Phone<span class="red">*</span></span>
        <input
          [(ngModel)]="phone"
          newPhoneMask
          matInput
          autocomplete="off"
          name="phoneNumber"
          id="phone"
          type="tel"
          value
          #name1="ngModel"
          style="margin: auto"
          placeholder="Enter your phone number"
          class="form-control form-control-lg w90 text-box h40"
        />
      </div>

      <div *ngIf="!data.restInfo[0].isDefaultFeedback" class="form-group mL10 mR10 mT20">
        <div *ngFor="let feild of data.restInfo[0].customQuestionFeedback">
          <div *ngIf="feild.qtype == 'text'">
            <span class="fLeft label-box text-left">{{ feild.question }}<span *ngIf="feild.isMandatory" class="red">*</span></span>
            <input
              type="text"
              [(ngModel)]="feild.value"
              style="margin: auto"
              class="form-control form-control-lg w90 text-box h40"
            />
          </div>
          <div *ngIf="feild.qtype == 'radio'">
            <div class="mT20 label-box text-left">{{ feild.question }}<span *ngIf="feild.isMandatory" class="red">*</span></div>
            <mat-radio-group
              aria-labelledby="example-radio-group-label"
              class="example-radio-group text-left"
              [(ngModel)]="feild.value"
            >
              <div class="mL20">
                <mat-radio-button
                  class="example-radio-button mR20 f-17"
                  *ngFor="let answer of feild.options"
                  [value]="answer"
                >
                  {{ answer }}
                </mat-radio-button>
              </div>
            </mat-radio-group>
          </div>
          <div *ngIf="feild.qtype == 'dropdown'">
            <div class="mT20 label-box text-left">{{ feild.question }}<span *ngIf="feild.isMandatory" class="red">*</span></div>
            <mat-form-field  class="full-width form-field-txt w90 pB0">
              <mat-select placeholder="-Select Option-" [(ngModel)]="feild.value" name="restId">
                <mat-option
                
                  *ngFor="let answer of feild.options"
                  [value]="answer"
                >
                  {{ answer }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div *ngIf="feild.qtype == 'checkbox'">
            <div class="mT20 label-box text-left">{{ feild.question }}<span *ngIf="feild.isMandatory" class="red">*</span></div>
            <section class="example-section text-left mL20" *ngFor="let answer of feild.options">
              <mat-checkbox  class="example-margin f-17"  (change)="onChange($event,feild,answer)">{{answer}}</mat-checkbox>
            </section>
          </div>
          <div *ngIf="feild.qtype == 'textarea'" class="form-group mL10 mR10 mT20">
            <span class="fLeft label-box">{{ feild.question }}<span *ngIf="feild.isMandatory" class="red">*</span></span>
            <textarea
            [(ngModel)]="feild.value"
              style="margin: auto"
              
              class="form-control w90 text-box"
              rows="2"
            >
            </textarea>
          </div>
        </div>
      </div>

      <div *ngIf="data.restInfo[0].isDefaultFeedback" class="form-group mL10 mR10 mT20">
        <span class="fLeft label-box">Feedback<span  class="red">*</span></span>
        <textarea
          [(ngModel)]="notes"
          style="margin: auto"
          placeholder="Enter feedback here"
          class="form-control w90 text-box"
          rows="2"
        >
        </textarea>
      </div>
    </div>
    <div class="form-group mL10 mR10 mT20">
      <span class="fLeft label-box">How would you rate this store ?</span>
      <div class="clearfix"></div>
      <!--    <star-rating class="text-center" value="0" totalstars="5" checkedcolor="gold" uncheckedcolor="grey" size="60px" readonly="false" (rate)="onRate($event)"></star-rating> -->
      <div class="text-center">
        <ngb-rating
          style="
            font-size: 60px;
            color: gold;
            text-align: center;
            outline: none;
          "
          [(rate)]="selected"
          (hover)="hovered = $event"
          (leave)="hovered = 0"
          [readonly]="readonly"
          [max]="5"
        ></ngb-rating>
      </div>
    </div>
    <div *ngIf="data.fbInfo.isOptedIn" class="col-sm-12 pL25 mT30">
      <mat-checkbox color="primary" [(ngModel)]="specialOffers">
        Sign me up to receive exclusive text deals and promotions. Receive 2-4
        text msgs/month. Standard msg and data rates apply.</mat-checkbox
      >
    </div>
    <div class="text-center">
      <button
        type="button"
        class="btn white-bod mT20 pR40 pL40 bold rockwell-nova {{validate()?'back-colour':'disabled'}}"
        (click)="submitFeedback()"
        style="font-size: 27px; border-radius: 0px; letter-spacing: 1px"
      >
        SUBMIT
      </button>
    </div>
    <div class="clearfix"></div>
    <div class="clearfix"></div>
  </div>
  <div *ngIf="feedbackSuccess">
    <div class="pL20 pR20 {{ restName == 'jets' ? '' : 'mT100' }}">
      <p class="f-25 bold rockwell-nova mT30 mB30 text-center thank-your-color">
        THANK YOU!
      </p>
      <p class="f-17 bold wS075 mR10 mB20 text-center">
        The store has received your feedback.
      </p>
    </div>

    <div *ngIf="restName == 'jets'" class="text-center">
      <img src="assets/images/fly3.png" style="width: 66%; height: auto" />
    </div>
  </div>
</div>
<!--footer-->
<app-footer></app-footer>
