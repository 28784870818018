
<app-pro-navbar id="#header"> </app-pro-navbar>

<span >
  <app-order-box  ></app-order-box>
</span>
<div *ngIf="pro.stickyHead" class="spacer">
  &nbsp;
</div>
<span id="menubox">
<app-menu-box></app-menu-box>
</span>