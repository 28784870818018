import { Component, OnInit } from '@angular/core';
import { APICenterService } from '../apicenter.service';

@Component({
  selector: 'app-payment-failure',
  templateUrl: './payment-failure.component.html',
  styleUrls: ['./payment-failure.component.css']
})
export class PaymentFailureComponent implements OnInit {

  constructor(public api:  APICenterService) { }

  ngOnInit() {
    this.api.fraudCounter += 1;
    if (this.api.fraudCounter === this.api.fraudcounterLimit) {
      this.api.isFraud = true;
      localStorage.setItem('fraudCounter', this.api.fraudCounter.toString());
      localStorage.setItem('ordrId', this.api.ordrID);
    }
  }

  reloadBt() {
    this.api.clickValue = 'Wrong Credit Card - Retry'
    this.api.clickAnalytics();

    if (this.api.pinState === '0') {
    this.api.isTip = false;
    this.api.isBt =  true;
    this.api.isFailure = false;
    } else {
      this.api.isFailure = false;
      this.api.isTip = false;
      this.api.isBt =  true;
      this.api.isBraintree =  true;
    }

  }
}
