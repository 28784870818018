<mat-dialog-actions class="close-btn w100 text-center" style="padding-top: 0px">
  <h4
    class="media-css w100"
    style="
      font-size: 22px;
      margin-top: 0px;
      font-family: Arial, Helvetica, sans-serif;
      font-weight: bold;
      letter-spacing: 0.75px;
      word-spacing: 0.5px;
      text-transform: uppercase;
    "
  >
    Allergies
  </h4>
</mat-dialog-actions>

<mat-dialog-content style="max-height: unset">
  <div>
    <p class="f-18 pT20">Select all that apply.</p>
    <div class="f-16 mB10" *ngFor="let allergy of api.allergiesList">
      <mat-card (click)="selectAllegry(allergy)" [ngStyle]="{border:allergy.value?'3px solid'+api.primaryColor:''}"
        ><span class="bold" style = "word-spacing: 1px;">{{ allergy.name }}</span> - 
        <span> <i>
          {{ allergy.description }}
        </i></span></mat-card
      >
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions class="close-btn text-center">
  <div class="text-center w100">
    <button
    mat-raised-button
    [ngStyle]="{ background: api.primaryColor }"
    class="primary f-18"
    style="
      color: white;
      border-radius: 5px;
      width: 150px;
      height: 35px;
      border: none;
    "
    (click)="close()"
  >
    Close
  </button>
  &nbsp;
    <button
      mat-raised-button
      [ngStyle]="{ background: api.primaryColor }"
      class="primary f-18 {{disableNext()?'':'disabled'}}"
      style="
        color: white;
        border-radius: 5px;
        width: 150px;
        height: 35px;
        border: none;
      "
      (click)="save()"
    >
      Next
    </button>
  </div>
</mat-dialog-actions>
