<link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.3.1/css/all.css" integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU" crossorigin="anonymous">
<div *ngIf = "api.isFullFooter">
<hr>
<div class = "container">
    <div class="row">
      <div class="col footer-txt">
          <div>
              <div class = "footer-txt"> © {{year}} <b>OrdrAi</b></div>
           <!--   <div><a class = "a-txt" href="mailto:info@ordrai.com/">info@ordrai.com</a> </div>
              <div>
                <a class = "a-txt" href="tel:+17343698402">(734)-369-8402</a> </div>

              <div>
                  <a class = "a-txt" href="https://www.facebook.com/OrdrInc/" target="_blank">
                    <i class="fab fa-facebook-f icon"></i></a>
                     <a class = "a-txt" href="https://twitter.com/OrdrInc" target="_blank">
                    <i class="fab fa-twitter icon"></i></a>
              </div> -->
            </div>
      </div>
      <div class="col">
        <div style = "float: right">
            <a class = "a-txt" target="_blank" rel="noopener noreferrer" href="/terms">Terms </a> | <a class = "a-txt" target="_blank" rel="noopener noreferrer" href="/privacy"> Privacy</a>
        </div>

      </div>
    </div>
   </div>
   <br>
   <br>
   <br>
   </div>

   <div [hidden]= "api.isFullFooter">
<hr>
<div class = "container"> 
    <div class="row">
      <div class="col footer-txt">
          <div>
              <div class = "footer-txt"> © {{year}} <b>OrdrAi</b></div>
              <div><a class = "a-txt" href="mailto:info@ordrai.com/">info@ordrai.com</a> </div>
              <div>
                <a class = "a-txt" href="tel:+17343698402">(734)-369-8402</a> </div>

              <div>
                  <a class = "a-txt" href="https://www.facebook.com/OrdrInc/" target="_blank">
                    <i class="fab fa-facebook-f icon"></i></a>
                     <a class = "a-txt" href="https://twitter.com/OrdrInc" target="_blank">
                    <i class="fab fa-twitter icon"></i></a>
              </div>
            </div>
      </div>
      <div class="col">
        <div style = "float: right">
            <a class = "a-txt" target="_blank" rel="noopener noreferrer" href="/terms">Terms </a> | <a class = "a-txt" target="_blank" rel="noopener noreferrer" href="/privacy"> Privacy</a>
        </div>

      </div>
    </div>
   </div>
  </div>