import { APICenterService } from "./../apicenter.service";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { LoginPopupComponent } from "../login-popup/login-popup.component";
import { MatDialog } from "@angular/material/dialog";
@Component({
  selector: "app-edit-page",
  templateUrl: "./edit-page.component.html",
  styleUrls: ["./edit-page.component.css"],
})
export class EditPageComponent implements OnInit {
  isLoading: Boolean;
  isFraudActive = false;
  constructor(
    private route: ActivatedRoute,
    private api: APICenterService,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.route.url.subscribe((val) => {
      this.api.ordrID = val[1].path;
      // console.log(this.api.ordrID);
      // console.log(val[1].path);
      this.isLoading = this.api.isLoading;
    });
    if (localStorage.getItem("ordrId") === this.api.ordrID) {
      const fCounter = localStorage.getItem("fraudCounter");
      // tslint:disable-next-line:radix
      if (parseInt(fCounter) >= this.api.fraudcounterLimit) {
        this.isFraudActive = true;
        this.api.isFraud = true;
      }
    }

  
  }
}
