import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { APICenterService } from '../apicenter.service';
import { interval, fromEvent } from 'rxjs';
@Component({
  selector: 'app-worldpay',
  templateUrl: './world-pay.component.html',
  styleUrls: ['./world-pay.component.css']
})
export class WorldPayComponent implements OnInit {
  paymentUrl = "";
  Id = "92639E25-2114-4E97-B8E9-F4E2E1CA91C8";
  url: any;
  loading = true;
  transAmt = "25.00";
  returnUrl = "https://s3.us-east-2.amazonaws.com/www.txtordr.com/assets/payments/payment.html";
  errorMessage = '';
  errorFlag = false;
  object: any;
  showIframe = 'iframe';
  refNum = '';
  ticketNum = '';
  NetTranID='';
  TransactionID='';
  onSubmitIframeData:any;
  userCards = [
    /* { cardName: 'Mastercard',
       cardEnding: '1111',
       cardLogo: 'assets/master.png',
       userselected: false
     },
     {
       cardName: 'Amex',
       cardEnding: '2222',
       cardLogo: 'assets/amex.png',
       userselected: false
     },
     {
       cardName:'Discover',
       cardEnding: '3333',
       cardLogo: 'assets/discover.png',
       userselected: false
     }*/
  ]
  customCss = ".tdTransactionButtons{text-align: center;border:none;}#tdManualEntryCardNumberLabel{width:100%;font-size: 16px;font-weight: bold;position: relative;top: 5px;}.buttonCancel{display:none} .tdHeader{display: none}#tableTransactionInformation{display: none}#tableManualEntry{width: 350px;margin-left: 0px;}#tdManualEntry{border:none;}#lblCardRequired{display:none;}.required{display: none;}#submit{margin-left:25px;border: none;background-color:" + this.api.primaryColor + "!important;font-size: 20px;font-weight: normal;padding: 10px;border-radius: 3px;margin-top: 20px;width:89%;padding-left:5px;text-align:center}#submit:after{content:'Pay';}#tdManualEntryExpDateLabel{font-size:16px;font-weight:bold;}#tdManualEntryCVVLabel{font-size:16px;font-weight:bold;}.cardNumber{height: 30px;position: absolute;top: 16px;border-radius: 3px;padding-left: 10px;font-size: 19px;}#ddlExpirationMonth{height: 30px;border-radius: 3px;font-size: 18px;width:99px}#trManualEntryExpDate{height:65px;}#ddlExpirationYear{height: 30px;border-radius: 3px;font-size: 18px;width:96px}#CVV{height: 30px;width:100px;border-radius: 3px;font-size: 18px;text-align:center}.tableTdErrorMessage{text-align: center;font-size: 18px;padding-top: 25px;}#divProgressMessage{font-size: 22px; padding-top: 20px;font-weight: bold;color:black}#tableLoadFailed{border-radius:20px;font-size: 16px;color: #990000;margin-top: 20px;}#imgError{display: none;}#lblCardNumber{font-weight:bold;position:relative;top: 5px;}#progImage{display:none}";
  @ViewChild('iframe') iframe: ElementRef;

  constructor(private sanitizer: DomSanitizer, private api: APICenterService) {


    this.gettracnsationId();

    if (window.addEventListener) {
      window.addEventListener("message", this.receiveMessage.bind(this), false);
    } else {
      (<any>window).attachEvent("onmessage", this.receiveMessage.bind(this));
    }
  }
  receiveMessage: any = (event: any) => {
    //...
    if (event.data) {
      if (event.data.indexOf("HostedPaymentStatus") > -1) {
        var search = event.data.substring(0);
        let object = JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}')
        console.log(object);
        if (object.ExpressResponseMessage == "Approved") {
          if (object.CVVResponseCode != 'M') {
            this.errorFlag = true;
            this.errorMessage = 'INVALID CVV';
            this.gettracnsationId();
          }
          else {
            this.errorFlag = false;
            this.errorMessage = '';
            object["cartAmt"] = this.transAmt;
            object["refNum"] = this.refNum;
            object["ticketNum"] = this.ticketNum;
            object["custId"] = this.api.customer.cid;
            let payload = {
              payload: object
            }
            this.loading = true
            this.api.OnSubmitWorldPayIframe(payload);
           let submitiframe= this.api.getOnSubmitWorldPayIframe()
              .subscribe((val) => {
                console.log(val);
                this.onSubmitIframeData=val.payload;
                if (val.status == 500) {
                  this.gettracnsationId();
                }
                else{
                  this.showIframe = 'cards';
                  this.loading=false;
                  this.NetTranID=val.payload[0].NetTranID;
                  this.TransactionID=val.payload[0].TransactionID;
                  this.userCards=[];
                  for(var i=0;i<val.payload.length;i++){
                    var payload = {
                      cardName: val.payload[i].cardLogo,
                      cardEnding: val.payload[i].last4,
                      cardLogo: '',
                      exp:'',
                      userselected: false,
                      tokenId:''
                    }
                    if(i==0){
                      payload.userselected=true;
                    }
                    if(i!=0){
                     payload.exp= val.payload[i].expMonth+"/"+val.payload[i].expYear;
                     payload.tokenId=val.payload[i].tokenId;
                    }
                    if (payload.cardName == 'Visa') {
                      payload.cardLogo = 'assets/visa.png'
                    }
                    else if (payload.cardName == 'Mastercard') {
                      payload.cardLogo = 'assets/master.png'
                    }
                    else if (payload.cardName == 'Amex') {
                      payload.cardLogo = 'assets/amex.png'
                    }
                    else if (payload.cardName == 'Discover') {
                      payload.cardLogo = 'assets/discover.png'
                    }
                    
                  
                    this.userCards.push(payload); 
                  }
                }
              })
             
            /* this.showIframe = 'cards';
             var payload = {
               cardName: object.CardLogo,
               cardEnding: object.LastFour,
               cardLogo: '',
               userselected: true
             }
             if (payload.cardName == 'Visa') {
               payload.cardLogo = 'assets/visa.png'
             }
             else if (payload.cardName == 'Mastercard') {
               payload.cardLogo = 'assets/mastercard.png'
             }
             else if (payload.cardName == 'Amex') {
               payload.cardLogo = 'assets/amex.png'
             }
             else if (payload.cardName == 'Discover') {
               payload.cardLogo = 'assets/discover.png'
             }
             this.userCards=[];
             this.userCards.push(payload);
             this.object = object;*/
            /* this.api.worldPaySuccess(object);
             this.api.getworldPaySuccessData()
           .subscribe((resp) => {
             this.showIframe=false;
             } 
           );*/
          }

        }
      }
    }

  }
  submitOrder() {
    this.loading = true
      if(this.userCards[0].userselected){
        var payload={
          payload : {
            orderId : this.api.ordrID,
            custId : this.api.customer.cid,
            cartAmt : this.transAmt,
            NetTranID: this.NetTranID,
            TransactionID: this.TransactionID,
            refNum: this.refNum
         }
         
        }
        this.api.WorldPayNewCustomer(payload);
        let newcustomer=this.api.getWorldPayNewCustomer()
          .subscribe((resp) => {
            if (resp.salesInfo.status == 200) {
              this.showIframe = 'success';
              let payload = {
                chargeAmount: (this.api.total + this.api.tip).toFixed(2).toString(),
                custId: this.api.customer.cid,
                ordrId: this.api.ordrID,
                name: this.api.userName,
                tip: this.api.tip,
                dropin : this.api.dropin,
                showDisclaim: this.api.userSelectionDisClaimer,
                mktOptIn: !(this.api.isMktOptIn),
                editScore: this.api.editScore,
                splInst: (this.api.splInstText !== null ? this.api.splInstText : '*'),
                couponName: this.api.couponName
              }
              this.api.createPurchase(payload)
              this.api.clickAanlysis.push('Pay with Credit Card -  WP - Submit your Order');
              this.api.clickAnalytics();
              this.api.isState_3 = false;
              this.api.pinMsg = true;
              this.loading = false;
            }
            else{
              this.gettracnsationId();
            }
            newcustomer.unsubscribe();
          })
      }
      else{
        for(var i=1;i<=this.userCards.length;i++){
          if(this.userCards[i].userselected){
            var load={
              payload : {
                orderId : this.api.ordrID,
                custId : this.api.customer.cid,
                cartAmt : this.transAmt,
                tokenId:this.userCards[i].tokenId
             }  
            }
            this.api.WorldPayReturnCustomer(load);
            let returnCustomer=this.api.getWorldPayReturnCustomer()
              .subscribe((resp) => {
              
                if (resp.status == 200) {
                  this.showIframe = 'success';
                  this.loading = false;
                }
                else{
                  this.gettracnsationId();
                }
                returnCustomer.unsubscribe()
              })
          }
        }
      
      }
    
    
  }
  selectCard(card) {
    for (var i = 0; i < this.userCards.length; i++) {
      if ((this.userCards[i].cardEnding == card.cardEnding) && (this.userCards[i].cardName == card.cardName)) {
        if (this.userCards[i].userselected) {
          this.userCards[i].userselected = false;
        }
        else {
          this.userCards[i].userselected = true;
        }
      }
      else {
        this.userCards[i].userselected = false;
      }
    }
  }
  differentCard() {
    this.showIframe = 'iframe';
    this.gettracnsationId();
  }
  submitcheck() {
    for (var i = 0; i < this.userCards.length; i++) {
      if (this.userCards[i].userselected == true) {
        return true;
      }
    }
    return false;
  }
  gettracnsationId() {
    this.loading = true;
    var payload =
    {
      transAmt: this.transAmt,
      returnUrl: this.returnUrl,
      customCSS: this.customCss,
      title: "Save Card"
    }
    this.api.worldPayTracationId(payload);
    this.api.getworldPayTransation()
      .subscribe((val) => {
        if (val.TransactionSetupID != '') {
          this.loading = false;
          this.paymentUrl = '';
          this.refNum = val.refNum;
          this.ticketNum = val.ticketNum;
          //his.paymentUrl = 'https://transaction.hostedpayments.com/?TransactionSetupID=' + val.TransactionSetupID;
           this.paymentUrl = 'https://certtransaction.hostedpayments.com/?TransactionSetupID=' + val.TransactionSetupID;
          // this.iframe.nativeElement.contentDocument.location.reload(true);
          //this.loading=false;
        }
      });
  }
  loadingfalse(event) {
    this.loading = false;
  }
  ngOnInit() {
    interval(2000).subscribe(() => {
      console.log(this.paymentUrl);
    });
  }




}
