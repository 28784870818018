<app-pro-navbar id="#header"> </app-pro-navbar>
<div>  
        <div class="f-25">
                <!--<input type="text" (ngModelChange)="changed()"  [ngModel]="api.time" >-->
        </div>
        <div  class = "wrapper" style = "padding: 15px; padding-top: 35px;">
                <div >

                                <div   class="flex-container" style="min-height: 250px;">
                                        <p class="text-center f-22 text-box">{{api.textVar}}  {{api.disaplyVar}}</p>

                                </div>
                                <div *ngIf="isRecording && !loading" class = "img-box-1 animated pluse" > <img src="https://voice.intelligichain.com/static/img/mic.gif" width="50%"  class="img-fluid">
                                
                                </div>
                                <div *ngIf="loading" class="text-center">
                                        <img src={{api.loadingGif}} class="rounded img-fluid" alt="...">
                                      </div>
                   
                    <div *ngIf="!loading" class = "wrapper" style = "    display: flex;
                    justify-content: space-evenly;
                    padding-top: 40px;">
                        <button
                        style="border-radius: 10px; padding-left: 18px; padding-right: 18px;border:solid 1px #07a44e;color:#07a44e"
                        mat-stroked-button
                        class = "ups-btn animated pluse f-24 bold pT10 pB10 "
                        color="primary"
                        (click) = "refresh()"
                        > Reload </button>
  &nbsp;&nbsp;
                            <button
                            *ngIf="!isNext"
                            style="border-radius: 10px;color: white;border:solid 1px #07a44e; padding-left: 18px; padding-right: 18px;"
                            mat-stroked-button
                            class = "ups-btn animated pluse  green-background pT10 pB10 white-c bold f-24"
                            color="primary"
                            (click) = "done()"
                            > Done </button>

                            <button
                            *ngIf="isNext"
                            style="border-radius: 10px;background-color: grey !important; color: white;border:solid 1px grey; padding-left: 18px; padding-right: 18px;"
                            mat-stroked-button
                            class = "ups-btn animated pluse  pT10 pB10 white-c bold f-24"
                            color="primary"
                            > Done </button>

                           
   
                      </div>
                   
                            
                            
              <!--    <img id = "record-stop" alt="record-stop" src="https://www.nicepng.com/png/full/90-902933_to-stop-recording-press-the-stop-button-red.png" style="max-width:30% !important; height:auto !important;" > -->
            </div>
        </div>
        <div *ngIf="!loading" style ="padding: 12px;">
                <h4 *ngIf="isRecording && !blobUrl" class = "wrapper mic-stop">Press DONE to process your order. </h4>
        </div>

  
</div>





<!--
<app-payment-success></app-payment-success> -->