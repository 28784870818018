
<h4 style = "display: flex; justify-content: center; padding: 10px;">Privacy Policy</h4>
<p class = "para">This privacy policy (“Privacy Policy”) describes our, Ordr Inc (“Ordr”, “TxtOrdr”, “we”, “our” or “us”), information practices in connection with www.TxtOrdr.com and other websites operated by TxtOrdr (together with their subdomains, the “Sites” or “Services”).   The section and sub-section headings used below are for convenience of reading only (and are not to be used for interpretive purposes) </p>
<p class = "para">TxtOrdr does not itself host the Services – all hosting is done by third party hosting providers that we engage. This means that data you provide us or that we or third parties collect from you (including any Personal Information) is hosted with such third party service providers on servers that they own or control. Regardless of where such third party service providers themselves are based (and some are US-based), their servers may be located anywhere in the world (including the US). Your data may even be replicated across multiple servers located in multiple countries. So please be aware that you are consenting to your data being transferred to various third party service providers around the world.</p>
<p class = "para">Generally speaking, TxtOrdr receives information because: you have provided it to us; we collect it from you; and/or third parties we engage (such as a data analytics provider) collect it from you on our behalf. This happens in cases where we use or deploy third party tracking technology, or where we otherwise give these third parties access to our technology. So where we describe below in Section _4_ (Information We Collect From You) the ways in which we collect information from you, you acknowledge that such collection may also be carried out by third parties.</p>
<p class = "para">In some cases, our Affiliates (defined below) will be collecting the information from you, or the information you provide will actually be provided to them. So where we describe in this Privacy Policy the ways and circumstances in which we receive or collect information from or about you, it may actually be our Affiliates that receive or collect such information. Similarly, as further described in Section 5 (The Way We Use Personal Information) below, we also reserve the right to transfer your Personal Information to our Affiliates. “Affiliate” means, with respect to TxtOrdr, any person, organization or entity controlling, controlled by, or under common control with, TxtOrdr, where “control” means the possession, directly or indirectly, of the power to direct or cause the direction of the management and policies of such person, organization or entity, whether through the ownership of voting securities or by contract or otherwise.</p>
<p class = "para">The information we receive may be about you, your contacts, the products and services you use, as well as the ways in which you use such products and services (including the Apps and Sites). For the purposes of this Privacy Policy, we categorize information as either Personal Information or Anonymous Information.</p>
<p class = "para">“Personal Information” means information that may be used, either alone or in combination with other information, to personally identify an individual, such as a first and last name, an email address, profile picture, product reviews, phone number, a home or other physical address, and other contact information.</p> 
<p class = "para">“Anonymous Information” means information which is not Personal Information, including without limitation aggregated or statistical information, and Personal Information that has been de-identified, hashed or otherwise anonymized. Consent and Modification. You are not legally obligated to provide us with any Personal Information (and you acknowledge that providing us with Personal Information is done freely). Nor are you required to use the Services in the first place. By using any part of the Services you consent to this Privacy Policy and to our information practices described herein. If you do not agree to this Privacy Policy, please do not access or otherwise use any part of the Services. We reserve the right, in our sole and absolute discretion, to change this Privacy Policy at any time. Such change will be effective ten (10) days following posting of the revised Privacy Policy on the Sites, and your continued use of the Services after such 10-day period shall mean that you accept those changes.</p>   
<p class = "para">Information You Provide To Us. Currently, we require you to provide us with certain types of Personal Information in order to use the Services and benefit from some of its features. Here are some examples: Account Creation. In order to create an account (“Account“), you may be required to have, and provide us with, a telephone number. Creating an Account may also require you (or in some cases, may just give you the option) to provide other Personal Information, such as your name, address and email address.</p>
<p class = "para">‘Contact Us’. If you send us a ‘Contact Us’ (or similar) request, whether by submitting an online form that we make available, by sending an email to an email address that we display, by using an in-App feedback or reporting feature, or by other means, you may be required to provide us with certain Personal Information, such as your name and email address. Purchases. The Sites may offer the option of purchasing certain products or services from us or our Affiliates. If you choose to make a purchase we will require sufficient Personal Information from you in order to process the purchase. Such information could include a credit card number and related account and billing information. We may use the products and services of third party service providers to process these purchases, in which case you will be providing the information to them as well. Information We Collect From You. We may collect Personal Information and Anonymous Information from you in the following ways: Pre-Registration. After you have visited the TxtOrdr Site, but before you create your profile and provide us with certain Personal Information, TxtOrdr automatically collects certain Personal Information: (a) from your device (for example, retrieving your phone number from your SIM card, and other information from SMS messages stored on your device that enables us to verify your identity); and (b) from your publicly-available profiles on other applications (for example your name, and email address).</p>
<p class = "para">Account Login. If, as part of your use of a Site, you connect your Site account with an account from a supported social network account, such as Facebook (a “Social Media Account“), we may receive Personal Information from the corresponding social network that relates to your Social Media Account. Such Personal Information may be about you and/or your Social Media Account contacts. Please check the policies of the applicable social network in order to understand what information we receive. Even if you later disconnect your Site account from your Social Media Account, we still keep a copy of the Personal Information (such as your Social Media Account profile photo and your contact list) that we received from the connection of the two accounts, so that we may more easily connect you with your Social Media Account contacts who register with the App in the future. Invitations. The Site currently allows you to invite your contacts to visit the Site. This can be done using different methods. Depending on the method used, by sending an invitation we may collect Personal Information about the recipient, such as their email address, phone number, and Social Media Account information.Geolocation. We collect information from your device about your location (“Geolocation Information“). Currently, we only use Geolocation Information for routing optimization and user-search optimization on our messaging platform, as well as for general analytics. </p>
<p class = "para">Log Files; Device and Usage Data. We collect information through server log files, but also by deploying tracking technologies within the Services. We do this to analyze trends, track user movement around the Services, gather demographic information, and to generally collect information that we may find insightful or helpful. The type of information collected may include (but is not limited to) internet protocol (IP) addresses, MAC address, phone number, device type, browser type, operating system type, type of browser, Internet Service Provider (ISP), date/time stamp of Services use, referring/exit pages, and clicked pages.</p>
<p class = "para"> Cookies. The Services may use “cookies”. A cookie is text file placed on your hard drive and stored by your browser. We use cookies to administer, and provide you with access to, the Services; but we also use cookies to improve the user experience of the Services (for example, cookies and other technologies may serve to recall an IP address previously indicated by a user) and to collect statistical or aggregated data about how you and other users use and interact with the Services, which may include for the purpose of serving advertisements and other marketing content. Some of the cookies are set by us, and some may be set by third parties, in which case the third party hosts it (for example, social plug-ins, such as a “Like” button, involve the setting of cookies by the applicable third party). If you do not want to receive cookies, consult your respective browser settings. This may allow you to receive notifications when cookies are set, or to otherwise block cookies altogether. You may also be able to delete cookies that have already been set. If you do block or restrict cookies, various features and functionality of the Services may be impaired.</p>
<p class = "para">Transfer To Affiliates. We may transfer your Personal Information to our Affiliates for the purpose of storing such information on our behalf, or for other processing needs. These Affiliates may be based in other countries, and their servers may be located anywhere in the world. We require these Affiliates to agree to process such Personal Information in accordance with this Privacy Policy.
    Third Parties. We share your Personal Information with various third parties that help us with our business operations and help us understand how our users use the Services, and to improve the Services generally. These third parties may have their own privacy policies that they adhere to, so they won’t necessarily adhere to this Privacy Policy; however, we do try to only engage third parties that post a privacy policy. Also, just as with our third party hosting providers, these third parties may be based (and their servers may be located) anywhere in the world. For example, we use email management services in connection with the Services, which helps us manage our database of subscription-based email contacts to communicate with our users via email, and such services collect data concerning the date and time when the email is viewed by the user, as well as when the user interacts with incoming email (such as by clicking on links included in the email).  A representative, non-exhaustive list of third parties we engage is PayPal, BrainTree Payments, Twilio, AWS and Google Cloud.
    Enforcement. We may share your Personal Information with any third party if we believe that disclosure of such information is helpful or reasonably necessary to: (a) comply with any applicable law, regulation, legal process, or governmental request; (b) enforce any of the App Licensing Terms, including investigations of potential violations thereof; (c) detect, prevent, or otherwise address fraud or security issues; and/or (d) protect against harm to the rights, property or safety of TxtOrdr, our users, yourself or the public. 
    Anonymous Information. We may use and share Anonymous Information for any purpose whatsoever and with any third parties (such as advertisers and marketing partners), including in exchange for compensation.
    Advertising and Behavioral Remarketing. To help us deliver meaningful advertisements, offers, and marketing content that are relevant to you based on criteria such as your visits to and/or usage of the Services (or other third party online or mobile websites, applications, platforms or services), we and our third party vendors (including those listed below) may use cookies and other pixels to customize or personalize such advertisements, offers and content, and analyze the performance of those advertisements, offers and content, as well as your interaction with them.  TxtOrdr may use remarketing services to advertise to you on third party web sites after you have visited our Services.</p>
<p class = "para">GOOGLE Google AdWords remarketing service is provided by Google Inc. You can opt-out of Google Analytics for Display Advertising and customize the Google Display Network ads by visiting the Google Ads Settings page: http://www.google.com/settings/adsGoogle also recommends installing the Google Analytics Opt-out Browser Add-on – https://tools.google.com/dlpage/gaoptout – for your web browser. Google Analytics Opt-out Browser Add-on provides visitors with the ability to prevent their data from being collected and used by Google Analytics.For more information on the privacy practices of Google, please visit the Google Privacy & Terms web page: http://www.google.com/intl/en/policies/privacy/</p>
<p class = "para">TWITTER
    Twitter remarketing service is provided by Twitter Inc. You can opt-out from Twitter’s interest-based ads by following their instructions: https://support.twitter.com/articles/20170405 You can learn more about the privacy practices and policies of Twitter by visiting their Privacy Policy page: https://twitter.com/privacy</p>
<p class = "para">FACEBOOK
    Facebook remarketing service is provided by Facebook Inc. You can learn more about interest-based advertising from Facebook by visiting this page: https://www.facebook.com/help/164968693837950 .To opt-out from Facebook’s interest-based ads follow these instructions from Facebook: https://www.facebook.com/about/ads/#568137493302217 Facebook adheres to the Self-Regulatory Principles for Online Behavioral Advertising established by the Digital Advertising Alliance. You can also opt-out from Facebook and other participating companies through the Digital Advertising Alliance in the USA http://www.aboutads.info/choices/, the Digital Advertising Alliance of Canada in Canada http://youradchoices.ca/ or the European Interactive Digital Advertising Alliance in Europe http://www.youronlinechoices.eu/, or opt-out using your mobile device settings. For more information on the privacy practices of Facebook, please visit Facebook’s Data Policy: https://www.facebook.com/privacy/explanation
    Access and Accuracy; Deleting Messages. We would like to maintain accurate Personal Information. If you would like to delete or correct any of your Personal Information that we may be storing, you may use the tools that we make available in the applicable App. Please be aware that in some Apps, communications exchanged with other App users may not be permanently deleted from our servers, even if you delete them from your Account or device. For example, in the TxtOrdr Site, any Message (as defined in its Licensing Terms) you delete (whether directly by you or pursuant to a delete request) may or may not be permanently deleted by TxtOrdr, and may remain stored on our servers. In such cases, the Message will no longer be accessible to you or the recipients to whom you sent the Message. We, in our sole and absolute discretion, decide whether or not to permanently delete communications (including Messages) from our servers.
    Opt-Out. Currently, the Services do not offer tools that allow you to opt-out of the collection of Personal Information. The Services may, however, offer tools that allow you to opt-out of being searchable.
    Links to and Interaction with Third Party Products. The Services may link to third party products and services. Any Personal Information you provide in connection therewith is provided to the third party. We are not responsible for the privacy practices or the content of such products and services, and we encourage you to read the terms and conditions and privacy policy of each such product or service that you choose to use or interact with.</p>
<p class = "para">Children’s Privacy. The Services are not designed to attract children under the age of 13. Accordingly, we do not intend to collect Personal Information from anyone we know to be under 13. If we learn that we have collected Personal Information from a child under 13, we will use commercially reasonable efforts to delete that information as quickly as possible. If you believe that we might have any such information, please contact us at <a href="mailto:info@ordrai.com">info@ordrai.com</a>
  Security. We use administrative, physical and technical safeguards to protect Personal Information. However, no method of transmission over the Internet, or method of electronic storage, is 100% secure. Therefore, while we try to use reasonably acceptable means to protect your Personal Information, we cannot guarantee its absolute security or confidentiality. If you have any questions about security on the Apps, you can contact us at <a href="mailto:info@ordrai.com">info@ordrai.com</a>
  Merger, Sale or Bankruptcy. In the event that TxtOrdr is acquired by or merged with a third party entity, we may (and hereby reserve the right to) transfer or assign the Personal Information and other information we have collected or received. In the event of a bankruptcy, insolvency, receivership, or comparable event, we may not be able to control how your Personal Information is treated, transferred, or used.
  California Privacy Rights. California Civil Code Section 1798.83 permits our customers who are California residents to request certain information regarding our disclosure of Personal Information to third parties for their direct marketing purposes. To make such a request, please send an email to <a href="mailto:info@ordrai.com">info@ordrai.com</a> (Please note that we are only required to respond to one request per customer each year.</p>
<p class = "para">Our California Do Not Track Notice. We do not currently respond or take any action with respect to web browser “do not track” signals or other mechanisms that provide consumers the ability to exercise choice regarding the collection of Personal Information about that individual consumer’s online activities over time and across third-party web sites or online services. We may allow third parties, such as companies that provide us with analytics tools, to collect Personal Information about an individual consumer’s online activities over time and across different web sites when a consumer uses the Apps.
    Deletion of Content from California Residents. If you are a California resident under the age of 18 and a registered user of an App, California Business and Professions Code Section 22581 permits you to remove content or information you have publicly posted. If you wish to remove such content or information and you specify which content or information you wish to be removed, we will do so in accordance with applicable law. Please be aware that after removal you will not be able to restore removed content or information. In addition, such removal does not ensure complete or comprehensive removal of the content or information you have posted and that there may be circumstances in which the law does not require us to enable removal of the content or information.
    Commitment. Protecting your privacy online is an evolving area, and we try to evolve our Apps to meet these demands. If you have any comments or questions regarding our Privacy Policy, please contact us at <a href="mailto:info@ordrai.com">info@ordrai.com</a> </p>
<p class = "para">Last updated: Dec 05, 2018</p>

<app-footer> </app-footer>