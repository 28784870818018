<app-navbar></app-navbar>
<mat-card style = "padding: 0px; " *ngIf = "!api.isPaid"> 
    <div *ngIf = "!loading">
    <table class="table">
        <thead class="table-dark">
          <tr class="flex-container-table">
            <th scope="col" style="font-size: x-large">TOTAL</th>
      
            <th scope="col" style="font-size: x-large">${{(api.total  +  api.tip).toFixed(2)}}</th>
          </tr>
        </thead>
      </table>
<div class="wrapper" style = "padding-left: 10px; padding-right: 10px;">
    <div>
      <button class="tip-f-btn" id="tip-button-0" mat-stroked-button (click)="tip_1_button()">Cash Tip</button>
    </div>
    <div #myDiv>
      <button class="tip-f-btn" id="tip-button-1"
        [ngStyle]="{'color':api.isDefaultTip ? 'green' : 'grey','font-size':api.isDefaultTip ? 'x-large':'medium','border':api.isDefaultTip ? '2px solid green' : '1px solid grey' }"
        mat-stroked-button (click)="tip_2_button()">15%</button>
    </div>
    <div>
      <button class="tip-f-btn" id="tip-button-2" mat-stroked-button (click)="tip_3_button()">20%</button>
    </div>
    <div>
      <button class="tip-f-btn" id='tip-other' mat-stroked-button (click)="tip_4_button()">Custom</button>
    </div>
  </div>

  <div class="container input-group input-txt" style="padding: 10px;">
    <div class="input-group-addon"
      style="background: white;font-size: large; font-weight: bold;  padding-bottom: 0px; padding-top: 0px;">
      Tip $

    </div>

    <div>
      <input matInput autocomplete="off" placeholder="Enter Tip Amount " maxlength="2"
        style="margin-top: 0px; font-size: large" value={{api.tip}} id="custom-tip-box" type="number"
        class="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" [(ngModel)]="api.tip"
        (keypress)="tipChecker($event)" name="tip_amt" #tip="ngModel">
    </div>
  </div>

  <label class="label-txt" style="font-size: 14px; padding: 0px; padding-top: 10px; padding-left: 10px; "> Cash tipping for awesome service is always
    possible.</label><br>
  <label class="label-txt" style="font-size: 14px; padding: 0px; padding-top: 1px; padding-left: 10px; padding-bottom: 10px;">Credit card tipping can only be done here and
    now.</label>
</div>
<app-bt></app-bt>

</mat-card>
<app-payment-success  *ngIf = "api.isPaid"></app-payment-success>


<app-footer></app-footer>