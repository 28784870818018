import { APICenterService } from './../apicenter.service';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, NgModule } from '@angular/core';
import { OrderingStepsDialogComponent } from '../ordering-steps-dialog/ordering-steps-dialog.component';
import { MatDialog } from '../../../node_modules/@angular/material/dialog';
import { DeviceDetectorService } from 'ngx-device-detector';
import { RestContactService } from '../rest-contact.service';

@Component({
  selector: 'app-friend-referral',
  templateUrl: './friend-referral.component.html',
  styleUrls: ['./friend-referral.component.css']
})
export class FriendReferralComponent implements OnInit {
  referalId
  restCode
  date
  today
  smsNumber
  smsNumber1
  contactNum = [ ]
  deviceInfo = null;
  text = "[Coupon: TXTREF] Hi! I'd like to order a..."
  constructor(public api: APICenterService, private restNum: RestContactService, private deviceService: DeviceDetectorService, private route : ActivatedRoute, public dialog: MatDialog) { 
    this.deviceDetect()
  }

  deviceDetect() {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    

    this.api.isMobile = this.deviceService.isMobile();
    this.api.isTablet = this.deviceService.isTablet();
    this.api.isDesktopDevice = this.deviceService.isDesktop();
    //console.log(this.deviceInfo);
    this.api.deviceName = this.deviceInfo.userAgent.split('(')[1].split(';')[0]
    this.api.deviceOs = this.deviceInfo.userAgent.split('(')[1].split(';')[1]

 }

  ngOnInit() {
    this.route.url
      .subscribe((val) => {
      this.referalId = val[1].path;
      console.log(this.referalId);
      this.restCode = this.referalId.slice(-3)
      this.getRestId()
      this.api.referralCustCode = this.referalId.slice(-7).slice(0, 4);
      this.api.getrestinfo()
      this.api.getUpdatedRestInfo()
      .subscribe(data => {
        console.log('@menu')
        console.log(data)
        this.api.restaurant.address = data.restaurant.address;
        this.api.restaurant.color = data.restaurant.color;
        this.api.restaurant.name = data.restaurant.name;
        this.api.restaurant.rid = data.restaurant.rid;
        this.api.phoneFormat()
        document.getElementById('navbar-color').style.background = data.restaurant.color
        }) 
      console.log(this.api.referralCustCode)
      })

      this.smsNumber = this.api.menuRestId.replace('+1','')
      this.smsNumber1 = this.api.restaurant.rid.replace('+1','')


      this.date = new Date();
      this.today =  `${this.date.getMonth() + 1}/${this.date.getDate()}/${this.date.getFullYear()}`
  }

  //  Add the friendly Name and rest Number here:
  getRestId() {
    if(this.restCode == this.restNum.restCode.sammyWestland){
      this.api.menuRestId = this.restNum.restaurant.sammyWestland
    } 
    if(this.restCode == this.restNum.restCode.jetsTn) {
      this.api.menuRestId = this.restNum.restaurant.jets
    }
    if(this.restCode == this.restNum.restCode.helloFaz) {
      this.api.menuRestId = this.restNum.restaurant.faz
    }
    if(this.restCode == this.restNum.restCode.sammyCanton) {
      this.api.menuRestId = this.restNum.restaurant.sammyCanton
    }
    if(this.restCode == this.restNum.restCode.mapa) {
      this.api.menuRestId = this.restNum.restaurant.maPa
    }
    if(this.restCode == this.restNum.restCode.jetsSc) {
      this.api.menuRestId = this.restNum.restaurant.jetsSc
    }
   
  }

  orderNowBtn() {
    console.log('button is clicked')
    if(this.api.isMobile) { 
      if(this.api.deviceName == 'iPhone') {
        console.log('IPHONE')
        window.location.href =`sms:${this.smsNumber}&body=`+ encodeURIComponent(this.text);
      } else {
        console.log('MAY BE andriod')
        window.location.href =`sms:${this.smsNumber}?body=`+ encodeURIComponent(this.text);
      } 
    } else if(this.api.isDesktopDevice) {
      
      const dialogRef = this.dialog.open(OrderingStepsDialogComponent, {
      });

      dialogRef.afterClosed().subscribe(result => {
        // console.log('The dialog was closed');
      });

    } else if(this.api.isTablet) {
      // console.log('TABLET')
      const dialogRef = this.dialog.open(OrderingStepsDialogComponent, {
      });

      dialogRef.afterClosed().subscribe(result => {
       //  console.log('The dialog was closed');
      });
    }

  }


  
  submit(f) {
    // console.log('submit')
    this.api.refFrndContact = f.value.phoneNumber
    const prefix  = '+1'
    this.api.refFrndContact = prefix.concat(this.api.refFrndContact)
    // console.log(this.api.refFrndContact)
    this.api.checkPhoneRefFrnd()
    this.api.getIsRefFrnd()
      .subscribe((val) => {
        // console.log('Refer a Frnd subscribe')
        if(val.Status) {
          // console.log('New Customer')
          this.orderNowBtn()
        } else {
          // console.log('You are not eligible for Refer a Friend')
          this.api.isRefCustomer = true;
        }
        //console.log(val)
      })
  }




}
