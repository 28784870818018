<app-navbar></app-navbar>

<div *ngIf = "!api.isRefCustomer">
    <div style= " padding: 10px;">
    <mat-card> 
    <div class = "wrapper">
      <img  src="https://static1.squarespace.com/static/5b04e1b9a9e02854895f4d04/t/5b2d797af950b74c6ebda7ff/1529706881342/amity_refer_a_friend.png" style="width: 100%; height: 50%" >
    </div>
    
    <form #f = "ngForm" (submit) = "submit(f)"> 
    <div class="input-group input-group-lg input-txt">
        <div class="input-group-addon" style = "padding-bottom: 4px; background: white">
            <i class="material-icons">
                local_phone</i>
        </div>
        <input
          ngModel
          type="tel"
          pattern="[0-9]{10}"
          required
          maxlength="10"
          minlength="10"
          matInput
          autofocus
          autocomplete="off"
         
        
          placeholder="Enter your Phone Number"
          name = "phoneNumber"
          class = "form-control phone-number"
          >
        </div>


    <div class = "button-row" style = "padding: 10px;">
        <button 
          style = "font-size: x-large; padding: 7px;"
          mat-raised-button 
          color="primary"
          type = "submit"
          class="send-btn animated fadeInDown "
          [disabled]="!f.valid"
          > Text Order Now! </button>
    </div>
   </form>
    <div class="container">
        <h2 class = "coupon-txt"><b>3 Topping Pizza for $7.99</b></h2> 
        <h4 class = "coupon-txt"><b> YOUR FIRST TEXT ORDER </b></h4>
        <p class = "coupon-txt-body"> at regular menu price PICKUP ONLY or 
         Delivered with $12 or more after discount, Plus $3.50 delivery fee.
        Valid only with TEXT ordering. Cannot be combined with other offers.</p>
        <p class = "exp-txt"><b>Expires {{today}}</b></p>
      
      </div>
  </mat-card>
  </div>
</div>

<div *ngIf = "api.isRefCustomer">

<div>
        <div style= " padding: 10px;">
                <mat-card> 
                 <h2 class = "coupon-txt"> Thank you! Looks like you are already our Customer.</h2>   
                <div class = "wrapper">
                  <img  src="https://mir-s3-cdn-cf.behance.net/project_modules/fs/eec05013468019.562743398ab1e.png" style="width: 100%; height: 50%" >
                </div>
                
                <div class = "button-row" style = "padding: 10px;">
                        <app-order-now (click) = "orderNowBtn()"> </app-order-now>
                    </div>
                <div class="container">
                    <h2 class = "coupon-txt"><b>25% OFF</b></h2> 
                    <h4 class = "coupon-txt"><b> YOUR NEXT TEXT ORDER </b></h4>
                    <p class = "coupon-txt-body"> at regular menu price PICKUP ONLY or 
                     Delivered with $12 or more after discount, Plus $3.50 delivery fee.
                    Valid only with TEXT ordering. Cannot be combined with other offers.</p>
                    <p class = "exp-txt"><b>Expires {{today}}</b></p>
                  
                  </div>
              </mat-card>
              </div>
</div>

</div>
<app-footer></app-footer>