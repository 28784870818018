<mat-dialog-actions class="close-btn" style="padding-top: 0px">
  <h4
    *ngIf="isShowTitle"
    class="media-css"
    style="
      margin-top: 0px;
      font-family: Arial, Helvetica, sans-serif;
      font-weight: bold;
      letter-spacing: 0.75px;
      word-spacing: 0.5px;
      text-transform: uppercase;
    "
  >
    <span *ngIf="api.restaurant.name.toLowerCase() != 'simplesimon'">
        {{ api.restaurant.name }} <span> {{ secondWord }}</span>
    </span>
    <span *ngIf="api.restaurant.name.toLowerCase()== 'simplesimon'">
      SIMPLE SIMON'S PIZZA</span
    >
  </h4>
</mat-dialog-actions>

<mat-dialog-content>
  <div>
    <div style="padding: 10px">
      <img src="{{ api.restaurant.menuPic }}" alt="Sammy" width="100%" />
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions class="close-btn">
  <button
    mat-raised-button
    [ngStyle]="{ background: api.primaryColor }"
    color="primary"
    style="color: white; border-radius: 5px"
    (click)="close()"
  >
    Close
  </button>
</mat-dialog-actions>
