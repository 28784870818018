import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { APICenterService } from '../apicenter.service';
import { RestContactService } from '../rest-contact.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {

  constructor(public api: APICenterService, private route: ActivatedRoute, private restNum: RestContactService) { }
  restMenuId
  menuLink
  ngOnInit() {
    this.route.url
      .subscribe(val => {
        this.restMenuId = val[1].path;
      })
    this.getRestId()
    this.api.getrestinfo()

    this.api.getUpdatedRestInfo()
      .subscribe(data => {
        // console.log('@menu')
        console.log(data)
        this.menuLink = data.restaurant.menuPic
        this.api.restaurant.address = data.restaurant.address;
        this.api.restaurant.color = data.restaurant.color;
        this.api.restaurant.name = data.restaurant.name;
        this.api.restaurant.rid = data.restaurant.rid;
        // document.getElementById('navbar-color').style.background = data.restaurant.color
      })

  }

  getRestId() {
    if (this.restMenuId == 'sammy') {
      this.api.menuRestId = this.restNum.restaurant.sammyWestland
    }
    if (this.restMenuId == 'sfpslices') {
      this.api.menuRestId = this.restNum.restaurant.superFine
    }
    if (this.restMenuId == 'superfine') {
      this.api.menuRestId = this.restNum.restaurant.superFine
    }
    if (this.restMenuId == 'ps') {
      this.api.menuRestId = this.restNum.restaurant.ps
    }

    if (this.restMenuId == 'jetsmi73') {
      this.api.menuRestId = this.restNum.restaurant.jetsmi73
    }
    if (this.restMenuId == 'jets') {
      this.api.menuRestId = this.restNum.restaurant.jets
    }
    if (this.restMenuId == 'jets_tn') {
      this.api.menuRestId = this.restNum.restaurant.jets
    }
    if (this.restMenuId == 'faz') {
      this.api.menuRestId = this.restNum.restaurant.faz
    }
    if (this.restMenuId == 'hh72') {
      this.api.menuRestId = this.restNum.restaurant.hh72
    }
  }

}
