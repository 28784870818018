<div *ngIf = "!isMicActive">
    <div style = "padding : 10px">
        <!-- PICKUP / DEL Toggle -->
         <div class =  "toggle-button" *ngIf = "!hidePickupDel" style = "padding: 10px; padding-bottom: 20px;">
           <mat-button-toggle-group  #group="matButtonToggleGroup">
              <mat-button-toggle
              value="pick"
              type = "checkbox"
              id = "pickup-btn" 
              style = "padding: 8px; font-size: large"
              [checked]="api.isPickupVal"
              (click)="pickup()">
              <i class="material-icons">
                 directions_run
                   </i>
              Pickup
           </mat-button-toggle>
                <mat-button-toggle
                  value="del"
                  type = "checkbox"
                  id = "del-btn"
                  style = "padding: 8px; font-size: large"
                  [checked]="!api.isPickupVal"
                  (click)= "delivery()"
                  >
                    Delivery <i class="material-icons">
                       directions_car
                       </i>
                 </mat-button-toggle>
       
           </mat-button-toggle-group>
          </div>
       
         <div class="form-group edit-box ">
            <textarea
              autofocus
              value
              id = "user-txt"
              class="form-control rounded-0 user-msg animated bounceIn "
              type = "text user-text-box"
              id="exampleFormControlTextarea2"
              rows="4.5"
              [(ngModel)] = "api.customer.msg_text"></textarea>
        </div>
      
      <!-- <div class="wrapper-btn" style = "padding-bottom: 30px;" >
            <div>
          <h4 class = "mic-proc-txt" style = "font-weight: bold"> (OR)</h4>
             
            </div>
            <div>

       
               </div>
              
               <div class = "menu-btn">
                  <button
                  mat-raised-button
                  type="button"
                  class="voice-btn"
                  (click) = "onMic()" > Voice Your Order</button>
        
                 </div>
            
              </div>   -->
            

       <!--
        <div  *ngIf = "!isWing || !isBread || !isSoda">
         <label>Frequently bought together:</label>
         <div class = "container-flex">
             <button
               mat-stroked-button
               color="primary"
               *ngIf = "!isWing"
               (click) = "isWings()"
               [class.disabled]="isWing"
               (click) = "isWing = true">{{api.fbt.wings}}</button>
             <button
               mat-stroked-button
               color="accent"
               *ngIf = "!isBread"
               (click) = "isBreads()"
               [class.disabled]="isBread"
               (click) = "isBread = true">{{api.fbt.bread}}</button>
             <button
               mat-stroked-button
               color="warn"
               *ngIf = "!isSoda"
               (click) = "isSodas()"
               [class.disabled]="isSoda"
               (click) = "isSoda = true">{{api.fbt.pop}}</button>
         </div>
       </div> 
        -->
       
        <div
         class="form-group edit-box animated fadeInDown "
         @fade-out
         *ngIf = "this.api.isDelivery">
         <label>Delivery Address:</label>
          <textarea
           autofocus
           class="form-control rounded-0 user-msg "
           type = "text user-text-box"
           placeholder=""
           rows="1"
           [(ngModel)] = "api.editStateAddress"></textarea> 
         <!--  <app-address-box (setAddress)="getAddress($event)" adressType="geocode"></app-address-box>  -->
       </div>
       
       
       
           <div class="wrapper-btn" >
             <div>
               
             </div>
             <div>
               <button
                  mat-raised-button
                  type="button"
                  class="btn send-btn animated fadeInDown"
                  (click) = "onUpdate()"
                 >
                Send </button>
       
                </div>
               
               <div class = "menu-btn">
                 <button  mat-fab color="primary" (click) = "openDialog()">Menu</button>  
               </div>
            </div>
            </div>
            </div>

<div *ngIf = "isMicActive">
  <mat-card>
    
    <!--   <app-audio-mic></app-audio-mic> -->


      <div class="wrapper-btn" >
          <div>
           
           
          </div>
          <div>
              <button
              mat-raised-button
              type="button"
              class="back-btn"
              (click) = "onBack()" >  Back </button>
    
             </div>
            
             <div class = "menu-btn">
              <!--  <button  mat-fab color="primary" (click) = "openDialog()">Menu</button> -->
            </div>  
          
         </div>
        
  
  </mat-card>
   
</div>





   

