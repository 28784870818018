<!--<div *ngFor="let reward of rewards" class="container pL0 pR0 w50" style="display: inline-block;">
  <img
    [src]="reward.image"
    style="height: 125px;width: 170px; border-radius: 10px;"
  />
  <div class="text-block">
    <h4 class="f-13 pL10">{{reward.name}} - {{reward.points}}</h4>
    <h4></h4>
   
  </div>
</div>  -->
<div *ngIf="api.isMobile" class="row mL0 mR0">
  <div
    *ngFor="let reward of api.rewards.items"
    (click)="click(reward.reward_key_word, reward)"
    class="col-xs-4 pL0 pR0"
    style="
      display: inline-block;
    
      border-radius: 10px;
      height: 135px;
    "
  >
    <div>
      <img
        [src]="reward.image"
        style="width: 98%; height: 80px; border-radius: 10px; padding-left: 4px"
      />
    </div>
    <div class="text-block w100">
      <h4
       
        style="font-size: 4vw"
        class="mT0 mB0 text-center"
      >
        {{ reward.points }} Points
      </h4>
   

      <h4></h4>
    </div>
    <div>
      <h4 style="font-size: 3vw" class="mB10 mT10 text-center">
        {{ reward.name }}
      </h4>
    </div>
  </div>
</div>
<div *ngIf="!api.isMobile" class="row mL0 mR0">
  <div
    *ngFor="let reward of api.rewards.items"
    (click)="click(reward.reward_key_word, reward)"
    class="col-xs-2 pL0 pR0"
    style="
      display: inline-block;
      border: solid 2px rgb(0, 171, 78);
      border-radius: 10px;
      height: 155px;
    "
  >
    <div>
      <img
        [src]="reward.image"
        style="width: 98%; height: 100px; border-radius: 10px; padding-left: 4px"
      />
    </div>
    <div class="text-block w100">
      <h4
        *ngIf="!reward.selected"
        style="font-size: 20px"
        class="mT0 mB0 text-center"
      >
        {{ reward.points }} Points
      </h4>
      <h4
        *ngIf="reward.selected"
        style="font-size: 20px"
        class="mT0 mB0 text-center"
      >
        <i
          class="fa fa-check-circle"
          style="color: green"
          aria-hidden="true"
        ></i>
      </h4>

      <h4></h4>
    </div>
    <div>
      <h4 style="font-size: 18px" class="mB10 mT10 text-center">
        {{ reward.name }}
      </h4>
    </div>
  </div>
</div>
