
<!-- <div class="embed-responsive embed-responsive-16by9">
    <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/6rXkPl7FL5I" allowfullscreen></iframe>
  </div> -->
  <!--<div class="flex-container">
      <div class = "img-box-1 animated fadeInDown"> <img src="https://ordr-assets.s3.us-east-2.amazonaws.com/logos/logo_1.png"  class="img-fluid rounded"></div>               
  </div> -->


  <br>

  <div align="center" class="embed-responsive embed-responsive-16by9">
    <video id = 'video' (ended) = "onClick()" controls playsinline preload="auto" class="embed-responsive-item" poster="https://ordr-assets.s3.us-east-2.amazonaws.com/logos/logo_1.png" >
        <source src="https://ordr-assets.s3.us-east-2.amazonaws.com/video/infoVideo.mp4" type="video/mp4" >
    </video>
</div>
  
<app-footer></app-footer>