<app-pro-navbar id="#header"> </app-pro-navbar>

<div *ngFor="let cat of categoryType" class="">
  <p class="f-18 bold mL30 text-uppercase">Frequently Asked Questions</p>
  <cdk-accordion class="example-accordion">
    <cdk-accordion-item
      *ngFor="let item of items; let index = index;"
      #accordionItem="cdkAccordionItem"
      class="example-accordion-item"
      role="button"
      tabindex="0"
      [attr.id]="'accordion-header-' + index"
      [attr.aria-expanded]="accordionItem.expanded"
      [attr.aria-controls]="'accordion-body-' + index">
      <div *ngIf="item.category==cat" class="example-accordion-item-header question" (click)="accordionItem.toggle()">
        {{ item.question }}
        <span class="example-accordion-item-description">
        
          <i *ngIf="!accordionItem.expanded" class="fa fa-plus f-20" aria-hidden="true"></i>
          <i *ngIf="accordionItem.expanded" class="fa fa-minus f-20" aria-hidden="true"></i>
        
        </span>
      </div>
      <div
        class="example-accordion-item-body answer"
        role="region"
    
        [style.display]="accordionItem.expanded ? '' : 'none'"
        [attr.id]="'accordion-body-' + index"
        [attr.aria-labelledby]="'accordion-header-' + index">
       <i> {{item.answer}} </i>
      </div>
    </cdk-accordion-item>
  </cdk-accordion>
</div>

<app-footer></app-footer>
