import { APICenterService } from '../apicenter.service';
import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';



@Component({
  selector: 'app-bt',
  templateUrl: './bt.component.html',
  styleUrls: ['./bt.component.css']
})
export class BtComponent implements OnInit {
  token;
  paymentResponse: any;
  chargeAmount = 0.00;
  custId = '6666666666';
  total = '$ 00.00';
  custType = 'old';
  isSaveCard = false;
  loadButton = false;
  checked1: boolean=true;
  paymentSubmitted = false;
  headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  constructor(public api: APICenterService, private http: HttpClient) { }

  ngOnInit() {
    // console.log(this.api.dropin)
    this.api.isDeleteBtn = false;
    this.api.isEditBtn = false;
    this.api.isAddItemBox = false;
    this.api.isState_3 = false;
    this.checked1= this.api.isSaveCard;
    setTimeout(() => {
      this.loadButton = true;
    }, 3000);
    // console.log(this.api.isBraintree)
    // console.log(this.api.dropin)
    // console.log("bt")

  }

  onPaymentStatus(response): void {
    this.paymentResponse = response;
    // console.log(response);
    this.api.setPIN = response.sucessMsg;
  }

  onEdit() {
    // console.log('button clicked');

  }
  onSaveCard() {
    this.api.actionbutton("creditcard_save",	"CS001")
    this.isSaveCard = true;
  }

  getClientToken = () => {
    // console.log('get token');
    return this.http
      .post(`${this.api.address}btPin`, { custId: this.api.customer.cid }, { 'headers': this.headers })
      // tslint:disable-next-line:indent
      .pipe(map((response: any) => {
        // console.log(response);
        return response.token;
        // tslint:disable-next-line:indent
      }));
  }


  createPurchase = (nonce: string, chargeAmount: number) => {
    // // console.log(this.api.clickAanlysis)
    this.api.clickValue = 'submit_ Credit Card';
    this.api.clickAnalytics();
    return this.http
      .post(`${this.api.address}create_purchase`, {
        nonce: nonce,
        chargeAmount: (this.api.total + this.api.tip).toFixed(2).toString(),
        custId: this.api.customer.cid,
        ordrId: this.api.ordrID,
        name: this.api.userName,
        tip: this.api.tip,
        dropin : this.api.dropin,
        showDisclaim: this.api.userSelectionDisClaimer,
        mktOptIn: !(this.api.isMktOptIn),
        editScore: this.api.editScore,
        isSaveCard: this.api.isSaveCard,
        splInst: (this.api.splInstText !== null ? this.api.splInstText : '*'),
        couponName: this.api.couponName
      }, { 'headers': this.headers })



      .pipe(map((response: any) => {
        // console.log(response);
        if (response.msg === 'True') {
          // this.api.notificationScheduler();
          this.api.isSuccess = true;
          this.api.isPaid = true;
          this.api.isBt = false;
          if (!this.api.quickOrder.isQuickName) {
            // text customer 
            if (!this.api.isOnlyPayment){
              this.api.setPin()
            } 
          } else {
            this.api.setPassword();
          }
         this.api.sendordrPOS(response.orderId);
        } else {
          // console.log(response.msg);
          // console.log('msg = false');
          this.api.isFailure = true;
          this.api.isBt = false;
        }

        return response;
      }));
  }

  navigateToPreviousPage() {
    this.api.actionbutton("creditcard_back",	"CS002")
    this.api.clickValue = 'Back_CC';
    this.api.clickAnalytics();
    // this.api.clickAanlysis.push('Back Credit Card')
    // // console.log('button is clicked');
    this.api.isDeleteBtn = true;
    this.api.isEditBtn = true;
    this.api.isAddItemBox = true;
    this.api.isState_2 = false;
    this.api.isBt = false;



    if ((this.api.isPayOnline) && (this.api.isPayAtDoor) && (this.api.isPayAtStore)) {
      console.log('all Are true');
      if(this.api.isDelivery){
        this.api.checkTipState = true
      }
      if (this.api.checkTipState || !this.api.isDelivery)  {
        // PIckup order
        if (this.api.isShowTipState) {
          // Show Tip State
          this.api.tipState();

        } else {
          // Hide TIP state
          if (this.api.isResetPinClicked) {
            if (this.api.isPayAtStoreEligible) {
              this.api.payOptionState();
            } else {
              this.api.nameState();
            }

          } else {
            if (this.api.isDiffCardClicked) {
              if (this.api.isPayAtStoreEligible) {
                this.api.payOptionState();
              } else {
                this.api.nameState();
              }

            } else {
              this.api.setPasswordState();
            }

          }


        }
      } else {
        // Delivery order
        this.api.tipState();
      }

    } else if ((this.api.isPayAtDoor) && (this.api.isPayAtStore)) {
      // console.log('pay at door and pay at store is true');
    } else if ((this.api.isPayOnline) && (this.api.isPayAtDoor)) {
      // console.log('pay online  and pay at door ');
      if (this.api.isShowTipState) {
        // show TIP state
        this.api.tipState();

      } else {
        // No Tip State
        if (this.api.isResetPinClicked) {
          this.api.nameState();

        } else {
          if (this.api.isDiffCardClicked) {
            this.api.nameState();
          } else {
            // Hide Bt
            this.api.isBt = false;
            // Show Tip Compononet => SET PIN
            this.api.setPasswordState();
          }
        }

      }


    } else if ((this.api.isPayOnline) && (this.api.isPayAtStore)) {
      // console.log('pay online and pay at store');
      if (this.api.isShowTipState) {
        // show TIP state
        // console.log('tip state');
        this.api.tipState();

      } else {
        // Dont Show TIP State
        if(this.api.isDelivery){
          this.api.checkTipState = true
        }
        if (this.api.checkTipState || !this.api.isDelivery)  {
          // Pickup order
          // console.log(' No Tip state');
          if (this.api.isResetPinClicked) {
            // console.log('reset clicked');
            if (this.api.isPayAtStoreEligible) {
              this.api.payOptionState();
            } else {
              // console.log('pay at store not eligible');
              this.api.nameState();
            }

          } else {
            if (this.api.isDiffCardClicked) {
              if (this.api.isPayAtStoreEligible) {
                this.api.payOptionState();
              } else {
                this.api.nameState();
              }


            } else {
              this.api.setPasswordState();
            }

          }
        } else {
          // delivery order
          if (this.api.isResetPinClicked) {
            this.api.tipState();
          } else {
            if (this.api.isDiffCardClicked) {
              this.api.tipState();
            } else {
              this.api.setPasswordState();
            }

          }
        }


      }

    } else {
      if (this.api.isPayOnline) {
        // console.log('online is true');
        // online is true
        if (this.api.pinState === '0') {
          // New customer/ NO PIN set customer
          // console.log('new customer/ No PIN set customer');
          if(this.api.isDelivery){
            this.api.checkTipState = true
          }
          if (this.api.checkTipState || !this.api.isDelivery)  {
            // Pick up orders

            if (this.api.isShowTipState) {

              // show Tip State
              this.api.tipState();

            } else {
              // with out Tip State
              // console.log('dont show tip state');
              this.api.setPasswordState();
            }

          } else {

            // delivery order
            this.api.tipState();

          }


        } else {
          // Existing customer
          // console.log('existing customer');


          if(this.api.isDelivery){
            this.api.checkTipState = true
          }
          if (this.api.checkTipState || !this.api.isDelivery)  {
            // Pick up orders

            if (this.api.isShowTipState) {

              // show Tip State
              this.api.tipState();

            } else {
              // with out Tip State
              this.api.nameState();
              // console.log('dont show tip state');
            }

          } else {

            // delivery order
            this.api.tipState();

          }


        }


      }

    }




    /*
    if(this.api.pinState === '0'){
      console.log('new customer')
      if(this.api.isDelivery) {
        console.log('delivery order')
          this.api.isTip = true
          this.api.payOption = true;
          this.api.isPinStored = false;
          this.api.payOnline = false;

      }else {
        console.log('pickup order')
        if(!(this.api.isCashEligible)) {
          console.log('NOT cash eligible')
          this.api.isContact = true;
        } else {
          console.log('cash eligble')
          this.api.isTip = true;
          this.api.payOption = false;
          this.api.payOnline = true;
        }

      }

    } else {
      console.log('existing customer')
      if(this.api.isDelivery) {
        console.log('delivery order')
          this.api.isTip = true
          this.api.payOption = true;
          this.api.isPinStored = false;
          this.api.payOnline = false;
      } else  {
        console.log('pickup order')
        if(!(this.api.isCashEligible)) {
          console.log('NOT cash eligible')
          this.api.isContact = true;
        } else {
          console.log('cash eligible')
          this.api.isTip = true;
          this.api.payOption = false;
          this.api.payOnline = true;
        }
      }

    }
       */

  }

  onSubmit() {
    this.api.actionbutton("creditcard_submit_order",	"CS003")
    this.api.clickAanlysis.push('Pay with Credit Card - Submit your Order');
    this.isSaveCard = true;
    this.api.isState_3 = false;
    this.paymentSubmitted = true;
    this.api.pinMsg = true;
  }
}


