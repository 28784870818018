<div id="content" #content>
  <div id="contentToConvert">
    <app-pro-navbar id="#header"> </app-pro-navbar>
    <h4 style="display: flex; justify-content: flex-start;">
      <!-- <b>Receipt</b> -->
      <h6 class="card-text" style="margin: 2px; margin-left: 20px;
      font-size: 16px;">{{ api.paidTime }}</h6>
    </h4>
    <div class="flex-container">
      <div style="flex-grow: 8">
        <div class="store-name">
       <!--   <h3 class="card-text" style="margin: 2px">
            {{ api.restaurant.name }}
          </h3>
          <h6 class="card-text" style="margin: 2px">
            {{ api.restaurant.address }}
          </h6> 
          <h6 class="card-text" style="margin: 2px">{{ api.displayNum }}</h6> 
          <h6 class="card-text" style="margin: 2px">{{ api.tNum }}</h6> 
          <h6 class="card-text" style="margin: 2px">{{ api.paidTime }}</h6> -->
        </div>
      </div>

      <div>
        <div></div>
      </div>
    </div>

    <div style="padding: 15px">
      <app-method></app-method>
      <app-summary></app-summary>
      <div *ngIf="api.isSplInst">
        <h4 style="font-size: 17px;
margin:0px;
font-family: helvetica;
padding-bottom: 17px;
font-weight: 400;"> Special Instructions: "{{api.splInstText}}"
        </h4>
        </div>
      <div style="font-size: small">
        <p style="margin: 0px">
          <b>Customer Phone: {{ api.CustdisplayNum }} </b>
        </p>
        <p style="margin: 0px">
          <b>Customer Name: {{ api.customer.name }}</b>
        </p>
      </div>
    </div>
    <!-- <div style = "padding: 15px; font-size: large;">
        <div class="alert alert-success" role="alert" *ngIf = "api.method.mtype == 'PICKUP'">
         <div class = "box-text">Thanks for Text Ordering! </div>
         <div class = "box-text">You will receive 20% Off on your next text order!</div>
          </div>
          <div class="alert alert-success" role="alert" *ngIf = "api.method.mtype == 'DELIVERY'">
                <div class = "box-text">Thanks for Text Ordering! </div>
                <div class = "box-text">You will receive 20% Off on your next text order!</div>
            </div>
    </div> -->
    <div class="wrapper" style="padding: 15px; font-size: small">
      <p>Powered by © {{ year }}<b> Ordr Inc.</b></p>
    </div>
  </div>
  <div class="wrapper">
    <button mat-raised-button color="primary" (click)="downloadPDF()">
      Save Receipt as PDF
    </button>
  </div>
</div>

<!--
<div>
<div style="flex-grow: 8" class = "store-name">
    <h3 style = "margin: 0px;"> {{api.restaurant.name}} </h3>
    <p class="card-text"> {{ api.restaurant.address}}</p>
    <h4 class="card-subtitle mb-2 ">{{ api.restaurant.rid}}</h4>
  </div>

<div  style="flex-grow: 4" class = "brand">
<div class = "wrapper" ><p style = "margin-bottom: 0px;"> ordered via </p> </div> <div><b class = "logo wrapper">Ordr Inc</b></div></div>
</div>

-->
