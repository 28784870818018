import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RestContactService {

selectedRest = {
  restName: null,
  smsNumber: null,
  prefillText: null,
  msg: null,
  cid: '+12159608808',
  rid: null,
  logoUrl: null,
}  

//Server C - Dev
  restaurant = {
    faz: '+17343664035',
    jets: '+17344422075',
    sammyWestland: '+17344159690',
    mi026: '+17344422075',
    jetsSc: '',
    superFine: '',
    jetsRyan : '',
    ps:'+13236942044',
    jetsmi73:'+12489262111',
    jetsmi26:'+15862033655',
    sammyCanton: '+17344158828',
    maPa: '+19843003401',
    biggby: '+17158022456',
    joe:'+15862033655',
    hh72: '+17349290072',
    papaDemo:'+13132510670',
    starbucksDemo: '+13132510670',
    jetsTn: '+17066665702',
    dominos:'+14154296935',
    papaJohns:'+16502977388',
    qdoba: '+13094200010',
    hh0072: '+17349290072',
    fiveguys:'+16502978242',
    jimmyjohns: '+14159803189',
    subway: '+16502978344',
    mcdonalds:'+14152369458',
    ordrId: '172F1E'
  }

  url = 'http://textordr.com/'
/*
// Sever B - Test  
  restaurant = {
    faz: '+17403060911',
    jets: '+17343493887',
    ps:'+13236942044', 
    sammyCanton: '+17344159601',
    mi026: '+17344422075',
    jetsSc: '',
    jetsmi73:'+12065694100',
    jetsmi26:'+15862033655',
    biggby: '+17158022456',
    hh72: '+17349290072',
    sammyWestland: '+17344283219',
    papaJohns:'+14154296935',
    qdoba: '+13094200010',
    superFine: '',
    jetsRyan : '',
    starbucksDemo: '+13132510670',
    hh0072: '+17349290072',
    maPa: '+15093591234',
    papaDemo:'+13132510670',
    jetsTn: '+18577631234',
    dominos: '',
    fiveguys: '',
    jimmyjohns: '',
    subway: '',
    mcdonalds: '',
    joe:'+15862033655',
    ordrId: '0E94CB'

  }

url = 'http://txtord.com/'
*/
/*
//Server A - Prod
  restaurant = {
    faz: '+17347417777',
    jets: '+15862033838',
    jetsSc: '+15862033838',
    jetsmi73:'+12489262111',
    ps:'+13236942044',
    jetsmi26:'+15869780888',
    jetsmi51:'+17342813844',
    mi026: '+15862033838',
    hh72: '+17349290072',
    joe:'+15862033838',
    biggby: '+17158022456',
    maPa: '+14148775566',
    sammyWestland: '+17347286600',
    starbucksDemo: '+13132510670',
    superFine: '+13233102070',
    jetsRyan : '+15869780888',
    papaJohns:'+14154296935',
    qdoba: '+13094200010',
    sammyCanton: ' ',
    dominos: '+14154296935',
    fiveguys: '+16502978242',
    jimmyjohns: '+14159803189',
    subway: '+16502978344',
    mcdonalds: '+14152369458',
    papaDemo:'+13132510670',
    ordrId: '75B5AD'
  }

url = 'https://txtordr.com/'
*/
 restCode = {
   sammyWestland: 'SWM',
   sammyCanton: 'SCM',
   jetsTn: 'JMT',
   jetsSc: 'JFS',
   jets18: 'OD01',
   mapa: 'MAPA',
   superfine: 'SLC',
   helloFaz: 'HFM'
 }

 restTitle = {
   papajohns: 'Papa John\'s',
   starbucks: 'Starbucks',
   jets:'Jet\'s',
   biggby: 'Biggby\'s',
   qdoba: 'Qdoba\'s',
   dominos: 'Domino\'s',
   fiveguys: 'Five Guys',
   jimmyjohns: 'Jimmy John\'s',
   subway: 'Subway',
   mcdonalds: 'McDonald\'s'
 }

 smsNumber = {
  papajohns: '3132510670',
  starbucks: '3132510670',
  jets: '7344422075',
  biggby: '7158022456',
  dominos: '4154296935',
  fiveguys: '6502978242',
  jimmyjohns: '4159803189',
  subway: '6502978344',
  qdoba:'4152369458',
  mcdonalds: '4152369458'
 }
 
 prefillText = {
  papajohns: "Hi Papa Johns! I want...",
  starbucks: "Hi Starbucks! I want...",
  qdoba: "Hi Qdoba! I want...",
  jets:"Hi Jets! I want...",
  biggby: 'Hi Biggby! I want...',
  dominos: "Hi Dominos! I want...",
  fiveguys:"Hi Five Guys! I want...",
  jimmyjohns:"Hi Jimmy Johns! I want...",
  subway:"Hi Subway! I want...",
  mcdonalds:"Hi McDonalds! I want..."
 }

 super8 = {
  starbucks : 'https://i.pinimg.com/originals/a0/d1/c2/a0d1c238a238394ab275407ea855a944.gif',
  mcD : 'https://cdn.freebiesupply.com/logos/large/2x/mcdonalds-6-logo-png-transparent.png',
  centralPerk: 'https://img.grouponcdn.com/coupons/73naC16i1mu1tha4ivqZmH/qdoba-highres-500x500',
  wendys: 'https://cdn-media.leanin.org/leanin-circles/2018-03-19/1521466167025/EPS_Stacked_Logo_BlackWhite.jpg',
  dominos: 'https://seeklogo.net/wp-content/uploads/2013/08/dominos-pizza-black-vector-logo.png',
  pizzaHut: 'https://cdn.freebiesupply.com/logos/large/2x/papa-johns-pizza-2-logo-png-transparent.png',
  subway: 'https://cdn.freebiesupply.com/logos/large/2x/subway-10-logo-png-transparent.png',
  jimmyJhons: 'https://d368g9lw5ileu7.cloudfront.net/races/race25259-racesponsor-0.bAQdUj.png'
}


  constructor() { }

getRestName(restId){
  if(restId === 'papajohns'){
    return  this.restTitle.papajohns
  }

  if(restId === 'starbucks'){
    return  this.restTitle.starbucks
  }

  if(restId === 'jets'){
    return  this.restTitle.jets
  }

  if(restId === 'dominos'){
    return  this.restTitle.dominos
  }

  if(restId === 'fiveguys'){
    return  this.restTitle.fiveguys
  }

  if(restId === 'jimmyjohns'){
    return  this.restTitle.jimmyjohns
  }

  if(restId === 'subway'){
    return  this.restTitle.subway
  }

  if(restId === 'mcdonalds'){
    return  this.restTitle.mcdonalds
  }

  if(restId === 'biggby'){
    return  this.restTitle.biggby
  }

  if(restId === 'qdoba'){
    return  this.restTitle.qdoba
  }
  
}

getSMS(restId){
  if(restId === 'papajohns'){
    return  this.smsNumber.papajohns
  }

  if(restId === 'starbucks'){
    return  this.smsNumber.starbucks
  }
  if(restId === 'jets'){
    return  this.smsNumber.jets
  }

  if(restId === 'dominos'){
    return  this.smsNumber.dominos
  }

  if(restId === 'biggby'){
    return  this.smsNumber.biggby
  }
  if(restId === 'fiveguys'){
    return  this.smsNumber.fiveguys
  }

  if(restId === 'jimmyjohns'){
    return  this.smsNumber.jimmyjohns
  }
  if(restId === 'subway'){
    return  this.smsNumber.subway
  }
  if(restId === 'mcdonalds'){
    return  this.smsNumber.mcdonalds
  }

  if(restId === 'qdoba'){
    return  this.smsNumber.qdoba
  }
  
}

getRid(restId){
  if(restId === 'papajohns'){
    return  this.restaurant.papaJohns
  }

  if(restId === 'starbucks'){
    return  this.restaurant.starbucksDemo
  }
  if(restId === 'jets'){
    return  this.restaurant.jets
  }

  if(restId === 'dominos'){
    return  this.restaurant.dominos
  }
  if(restId === 'fiveguys'){
    return  this.restaurant.fiveguys
  }

  if(restId === 'biggby'){
    return  this.restaurant.biggby
  }
  
  if(restId === 'jimmyjohns'){
    return  this.restaurant.jimmyjohns
  }
  if(restId === 'subway'){
    return  this.restaurant.subway
  }
  if(restId === 'mcdonalds'){
    return  this.restaurant.mcdonalds
  }
  if(restId === 'qdoba'){
    return  this.restaurant.qdoba
  }
  
}

getPrefill(restId){
  if(restId === 'papajohns'){
    return  this.prefillText.papajohns
  }

  if(restId === 'starbucks'){
    return  this.prefillText.starbucks
  }
  if(restId === 'jets'){
    return  this.prefillText.jets
  }

  if(restId === 'dominos'){
    return  this.prefillText.dominos
  }
  if(restId === 'fiveguys'){
    return  this.prefillText.fiveguys
  }

  if(restId === 'biggby'){
    return  this.prefillText.biggby
  }

  if(restId === 'jimmyjohns'){
    return  this.prefillText.jimmyjohns
  }
  if(restId === 'subway'){
    return  this.prefillText.subway
  }
  if(restId === 'mcdonalds'){
    return  this.prefillText.mcdonalds
  }
  if(restId === 'qdoba'){
    return  this.prefillText.qdoba
  }
  
}


}

 