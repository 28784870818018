<app-pro-navbar id="#header"> </app-pro-navbar>
<div *ngIf="loading" class="text-center">
  <img src={{api.loadingGif}} class="rounded img-fluid" alt="...">
</div>
<div *ngIf="!loading" class="">
  <div *ngIf="data.curbInfo.InTime=='*'">
    <div class="text-center">
      <div class="form-group mL10 mR10 mT20">
        <span class="fLeft label-box">Name on the Order*</span>
        <input type="text" [(ngModel)]="name" style="margin: auto" placeholder="Enter your name"
          class="form-control form-control-lg w90 text-box h40" />
      </div>

      <div class="form-group mL10 mR10 mT20">
        <span class="fLeft label-box">Phone # on the Order*</span>
        <input [(ngModel)]="phone" newPhoneMask matInput autocomplete="off" name="phoneNumber" id="phone" type="tel"
          value #name1="ngModel" style="margin: auto" placeholder="Enter your phone number"
          class="form-control form-control-lg w90 text-box h40" />
      </div>

      <div class="form-group mL10 mR10 mT20">
        <span class="fLeft label-box">Vehicle Color, Make, Model*</span>
        <input type="text" [(ngModel)]="vehicle" style="margin: auto" placeholder="eg: Black Toyota Camry"
          class="form-control w90 text-box h40" />
      </div>

      <div class="form-group mL10 mR10 mT20">
        <span class="fLeft label-box">Spot Number and Additional Notes:</span>
        <textarea [(ngModel)]="notes" style="margin: auto" placeholder="eg: Put it in trunk, backseat Etc."
          class="form-control  w90 text-box" rows="2"> </textarea>
      </div>
    </div>
    <div *ngIf="data.curbInfo.OrderInfo.isOptedIn==true" class="col-sm-12 pL35 mT30">
      <mat-checkbox color="primary" [(ngModel)]="specialOffers">
        Yes, I would like to receive special <br style="line-height: 15px" />
        offers via SMS.</mat-checkbox>
    </div>
    <div class="text-center">
      <button type="button" class="btn white-buttons mT20 pR40 pL40 bold rockwell-nova {{
      (name != null && name!='') && (phone != null && phone!='' && phone.length==14) && (vehicle != null && vehicle!= '')
        ? 'green-background'
        : 'disabled'
    }}" (click)="iamhere()" style="font-size: 27px; border-radius: 0px; letter-spacing: 1px;">
        I'M HERE
      </button>
    </div>
    <div class="clearfix"></div>
    <div class="clearfix"></div>
  </div>
  <div *ngIf="data.curbInfo.InTime!='*'">
    <div class="pL20 pR20">
      <p class="f-22 bold rockwell-nova mT30 mB30">YOU ARE CHECKED IN!</p>
      <p class="f-15 wS075  mR10 mB20">Please turn your hazard lights on so it is easier to find your vehicle. We will
        be out with your order as soon as we can.</p>
    </div>
    <div class="pL20 mT20 pR20">
      <p class="f-18 mT10 bold fLeft"><span class="pB5">Name: {{data.curbInfo.Name}} </span><br>
        <span class="pB5">Phone: {{api.formatPhone(data.curbInfo.CustId)}}</span>
        <!-- <span class="pB5" *ngIf="data.curbInfo.OrderInfo.ticketId!='*'"> <br>TicketId:
          {{data.curbInfo.OrderInfo.ticketId}}</span> -->
        <!-- <br>
        <span class="pB5" *ngIf="data.curbInfo.OrderInfo.total!='*' && data.curbInfo.OrderInfo.total !='0.00' "> Total:
          ${{data.curbInfo.OrderInfo.total}} </span> --> <br></p>
    </div>
    <div class="text-center">
      <img src="assets/images/fly3.png" style="width: 66% ; height: auto">
    </div>
  </div>
</div>
<!--footer-->
<app-footer></app-footer>